import React, { useState, useEffect, useContext, useRef } from 'react'
import { getValidationErrors } from '../../helpers/functions'
import TextField from '@material-ui/core/TextField'
import formContext from '../../contexts/formContext'

/*
Custom TextInput Input component which uses Meterial TextField Component.
TextInput component uses validaions prop to check to check validations like required, max length and digits etc...
validation goes through the getValidationErrors function the will return errors that we store inside errors state.
It will watch onChange event and on mount to setErrors.With the use of context api we are setting these values to the parent
SubmitForm Component.

TextInput should be the child of SubmitForm because it the provider of context.
*/

const TextInput = ({ validations, onInputChange, onInValid, showErrors, name, initValue, noHelperText, ...rest }) => {
  const [errors, setErrors] = useState([])
  const [value, setValue] = useState('')
  const context = useContext(formContext)
  const inputRef = useRef(null)

  useEffect(() => {
    if (showErrors || context.showErrors) {
      runValidations(true, inputRef.current.querySelector('input').value)
      return
    }
    runValidations(false, inputRef.current.querySelector('input').value)
    context.setValue(name, inputRef.current.querySelector('input').value)
  }, [showErrors, context.showErrors])

  useEffect(() => {
    if (initValue) {
      runValidations(false, String(initValue))
      setValue(initValue)
    } else {
      context.setValue(name, '')
    }
  }, [initValue])

  const handleChange = (e) => {
    if (onInputChange) {
      onInputChange(e.target.value)
    }
    runValidations(true, e.target.value)
    setValue(e.target.value)
  }

  const runValidations = (isPublish, value) => {
    const inputErrors = getValidationErrors(validations, value)
    if (isPublish && inputErrors.length > 0) {
      setErrors([...inputErrors])
    }

    if (inputErrors.length > 0) {
      if (onInValid) onInValid(true)
      context.setFieldInValid(name)
      return
    }
    setErrors([])
    if (onInValid) onInValid(false)
    context.removeFieldInValid(name)
    context.setValue(name, value)
  }

  return (
    <TextField value={value} ref={inputRef} error={errors.length > 0} {...rest} name={name} onChange={handleChange} helperText={noHelperText ? '' : errors.map((e) => e.message).join(' * ')} />
  )
}

export default TextInput
