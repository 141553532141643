import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../components/Ux/Loader'
import apiConfig from '../../apis/api-constants'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import Sidebars from '../../components/layouts/Sidebars'
import { toast } from 'react-toastify'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useViewClasses = makeStyles((theme) => ({
  logo: {
    width: '180px',
    display: 'block',
    objectFit: 'cover'
  },
  showField: {
    borderBottom: '1px solid #ccc'
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
    '& > button': {
      marginRight: '0.625rem'
    }
  }
}))

const useCard = makeStyles(() => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const ViewConstomer = () => {
  const classes = useStyles()
  const cardClasses = useCard()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  const [showLoader, setShowLoader] = useState(false)
  const customerData = useSelector((state) => state.customer)
  const { error, customer } = customerData
  const customerDetails = { ...customer }
  const isMountedError = useRef(false)
  const isMountedCustomer = useRef(false)
  const viewClasses = useViewClasses()
  useSetParentCustomInnerHeaderHeading()
  // On Component Mount getting the customer data
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
  }, [])

  // On getting customers data hiding loader
  useEffect(() => {
    if (isMountedCustomer.current) {
      setShowLoader(false)
    } else {
      isMountedCustomer.current = true
    }
  }, [customer])

  // On Error hiding loader and displaying error
  useEffect(() => {
    if (isMountedError.current && error) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error])

  return (
    <>
      <Loader open={showLoader} />
      <Card className={cardClasses.root}>
        <CardContent>
          <div className={classes.paper}>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.projectId')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.project_id
                  ? customerDetails.project_id
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.name')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.name
                  ? customerDetails.name
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.facility')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.facility
                  ? customerDetails.facility
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.city')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.city
                  ? customerDetails.city
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.country')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.country
                  ? customerDetails.country
                  : ''}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.street')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.street
                  ? customerDetails.street
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.email')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.contact_email
                  ? customerDetails.contact_email
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.customerNumber')}
              </Typography>
              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.customer_nr
                  ? customerDetails.customer_nr
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_customer.uploadLogo')}
              </Typography>

              <p className={viewClasses.showField}>
                {customerDetails && customerDetails.image_url ? (
                  <img
                    alt='logo'
                    className={viewClasses.logo}
                    src={[apiConfig.siteUrl + customerDetails.image_url]}
                  />
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div />
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default Sidebars(ViewConstomer)
