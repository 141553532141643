import { call, put, takeLatest } from 'redux-saga/effects'
import * as conveyorBeltActions from './conveyorBeltActions'
import * as request from '../../apis/conveyor-belt'

function * getAllConveyorBeltData ({ payload }) {
  try {
    const response = yield call(request.getConveyorBelt, payload.id, payload.data)
    const conveyorBelts = response.data
    const data = { conveyorBelts }

    if (payload.data) {
      data.totalPages = response.headers['x-totalpages']
      data.perPage = payload.data.per_page
      data.page = payload.data.page
    }
    yield put({
      type: conveyorBeltActions.CONVEYOR_BELT_LIST_SUCCESS,
      payload: { ...data }
    })
  } catch (error) {
    yield put({
      type: conveyorBeltActions.CONVEYOR_BELT_LIST_FAILURE,
      error: error.message
    })
  }
}

const conveyorBeltSaga = [
  takeLatest(conveyorBeltActions.CONVEYOR_BELT_LIST_REQUEST, getAllConveyorBeltData)
]
export default conveyorBeltSaga
