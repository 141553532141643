import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import Loader from '../../components/Ux/Loader'
import MessagePopup from '../../components/Ux/MessagePopup'
import apiConfig from '../../apis/api-constants'
import { GET_REPRESENTATION_REQUEST } from '../../store/representation/representationActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useViewClasses = makeStyles((theme) => ({
  logo: {
    width: '180px',
    display: 'block',
    objectFit: 'cover'
  },
  showField: {
    borderBottom: '1px solid #ccc'
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
    '& > button': {
      marginRight: '0.625rem'
    }
  }
}))

const useCard = makeStyles(() => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const ViewRepresentation = () => {
  const classes = useStyles()
  const cardClasses = useCard()
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  useSetParentCustomInnerHeaderHeading()
  const [messageData, setMessageData] = useState({
    severity: 'success',
    open: false,
    duration: 6000,
    message: ''
  })

  const [showLoader, setShowLoader] = useState(false)
  const representationData = useSelector((state) => state.representation)
  const { error, representation } = representationData
  const representationDetails = { ...representation }
  const isMountedError = useRef(false)
  const isMountedRepresentation = useRef(false)
  const viewClasses = useViewClasses()
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id: params.id } })
  }, [])

  useEffect(() => {
    if (isMountedRepresentation.current) {
      setShowLoader(false)
    } else {
      isMountedRepresentation.current = true
    }
  }, [representation])

  useEffect(() => {
    if (isMountedError.current && error) {
      setMessageData({
        ...messageData,
        open: true,
        severity: 'error',
        message: error
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setMessageData({ ...messageData, open: false })
  }

  return (
    <>
      <Loader open={showLoader} />
      <MessagePopup messageData={messageData} handleClose={handleClose} />
      <Loader open={showLoader} />
      <Card className={cardClasses.root}>
        <CardHeader
          style={{ backgroundColor: theme.palette.primary.dark, color: '#fff' }}
          className={cardClasses.cardHeader}
        />
        <CardContent>
          <div className={classes.paper}>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.projectId')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.project_id
                  ? representationDetails.project_id
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.name')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.name
                  ? representationDetails.name
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.facility')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.facility
                  ? representationDetails.facility
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.city')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.city
                  ? representationDetails.city
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.country')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.country
                  ? representationDetails.country
                  : ''}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.street')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.street
                  ? representationDetails.street
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.email')}
              </Typography>
              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.contact_email
                  ? representationDetails.contact_email
                  : '-'}
              </p>
            </div>
            <div>
              <Typography component='label' variant='subtitle2'>
                {t('view_representation.uploadLogo')}
              </Typography>

              <p className={viewClasses.showField}>
                {representationDetails && representationDetails.image_url ? (
                  <img
                    alt='logo'
                    className={viewClasses.logo}
                    src={[apiConfig.siteUrl + representationDetails.image_url]}
                  />
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div />
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default Sidebars(ViewRepresentation)
