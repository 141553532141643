/**
 * We're basically wrapping react-select but using a special option,
 * the "allOption". This option is exclusive of any other option
 * and it never hits the user's onChange() handler because it gets
 * replaced with the whole options list.
 *
 * There are many ways to implement this feature, but this one doesn't
 * affect the rest of your app, and it doesn't take any UI space.
 */

import React, { useState } from 'react'
// eslint-disable-next-line
 import { default as ReactSelect } from "react-select";

// specify props.allowSelectAll = true to enable!
const MultiSelect = props => {
  const [isDirty, setIsDirty] = useState(false)
  const { formIsDirty } = props
  const { required } = props.validations
  // if required option is set and user clicked the field at least once
  const showValidationError = required ? props.value.length < 1 && (isDirty || formIsDirty) : false
  const errorStyle = {
    fontSize: '0.75rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    color: '#F44336',
    padding: 0,
    margin: 0
  }
  const ValidationError = () => showValidationError ? <p className='Mui-error' style={errorStyle}>{required.message}</p> : <></>

  const wrapperStyle = { alignSelf: 'flex-start' }
  const customStyles = {
    control: base => ({
      ...base,
      // none of react-select's styles are passed to <Control />
      borderColor: showValidationError ? '#F44336' : '#CCCCCC'
    })
  }

  const onBlur = (e) => { setIsDirty(true) }

  // if it is allowed to select all
  if (props.allowSelectAll) {
    // if all are selected
    if (props.value.length === props.options.length) {
      return (
        <div onBlur={onBlur} style={wrapperStyle}>
          <ReactSelect
            {...props}
            styles={customStyles}
            value={[...props.options]}
            onChange={selected => props.onChange(selected)}
          />
          <ValidationError />
        </div>
      )
    }

    // if it is allowed to select all but not all are selected
    return (
      <div onBlur={onBlur} style={wrapperStyle}>
        <ReactSelect
          {...props}
          styles={customStyles}
          options={[props.allOption, ...props.options]}
          onChange={selected => {
            if (
              selected.length > 0 &&
               selected[selected.length - 1].value === props.allOption.value
            ) {
              return props.onChange(props.options)
            }
            return props.onChange(selected)
          }}
        />
        <ValidationError />
      </div>
    )
  }

  // if select all is not allowed
  return (
    <div onBlur={onBlur} style={wrapperStyle}>
      <ReactSelect
        {...props}
        styles={customStyles}
      />
      <ValidationError />
    </div>
  )
}

export default MultiSelect
