import React, { useState, useEffect } from 'react'
import { LineChart, ScatterChart, Scatter, Bar, BarChart, ZAxis, XAxis, Tooltip, CartesianGrid, Line, YAxis, ResponsiveContainer, Text, Label, Legend } from 'recharts'
import numeral from './customNumeral'
import useWindowResize from '../hooks/useWindowResize'
import { useTranslation } from 'react-i18next'

const positionDividedBy = ({ divisor, data }) => {
  const valuesByPosition = {}
  const retVal = data.map(x => { return { ...x, position: x.position > 0 ? x.position / divisor : 0 } })
  return retVal
}

const withinBoundary = ({ maxX, data }) => {
  const valuesByPosition = {}
  const retVal = data.filter(x => Number(x.position) <= Number(maxX))
  return retVal
}

const withoutDuplicates = (data) => {
  const valuesByPosition = {}
  data.forEach(element => {
    // show if position already exists
    const existingElement = valuesByPosition[element.position]
    // when position already exists => replace element if level is higher
    if (!existingElement) {
      valuesByPosition[element.position] = element
    } else if (existingElement.level < element.level) {
      valuesByPosition[element.position] = element
    }
  })

  const retVal = Object.keys(valuesByPosition)
    .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
    .map(key => valuesByPosition[key])

  return retVal
}

const ScatterBarShape = (props) => {
  const { cx, cy, x, y, barWidth, fill, xAxis } = props
  const xAxisFromTop = xAxis.y
  const barHeight = xAxisFromTop - cy
  return <rect x={cx} y={cy} width={barWidth} height={barHeight} fill={fill} />
}

const LineChartTooltip = (props) => {
  const { payload, label } = props
  return (
    <div style={{ backgroundColor: 'white', border: 'solid 1px #aaaaaa', padding: '10px' }}>
      <div><span>Position</span>: <span>{label} m</span></div>
      {payload.map(item => <div style={{ marginTop: '5px' }}><span style={{ color: item.color }}>{item.name}</span>: <span>{item.value} µV</span></div>)}
    </div>
  )
}

const DamageChart = ({ maxX, isBarChart, strokenDash, yColors, height, data, xAxisName, yAxisNames, allowDecimals, interval, tickCount, tick, domain, xlabel, ylabel, isLegend, resizeContainer }) => {
  numeral.locale('de')
  const { t } = useTranslation()
  const dimensions = useWindowResize()
  const [lineChartHeight, setLineChartHeight] = useState(0)
  const [barChartHeight, setBarChartHeight] = useState(0)
  const [lineChartYLabelHeight, setLineChartYLabelHeight] = useState(0)
  const [barChartYLabelHeight, setBarChartYLabelHeight] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      const resizeContainerEl = document.querySelector(resizeContainer)
      if (resizeContainerEl) {
        const btnHeight = 65

        const containerHeight = (resizeContainerEl.clientHeight - btnHeight)
        let barChartHeight = (containerHeight * 0.2)
        let lineChartHeight = (containerHeight * 0.5)

        // set min height of bar chart
        if (barChartHeight < 100) {
          barChartHeight = 100
        }

        // set min height of line chart
        if (lineChartHeight < 160) {
          lineChartHeight = 160
        }
        setLineChartYLabelHeight(getYHeight(ylabel, lineChartHeight, 16))
        setBarChartYLabelHeight(getYHeight(ylabel, barChartHeight, 16))
        setLineChartHeight(lineChartHeight)
        setBarChartHeight(barChartHeight)
      }
    }, 500)
  }, [dimensions])

  const ceiledMaxX = Math.ceil(maxX)
  const xDomain = [0, ceiledMaxX]
  const xTickStepCount = 10
  const xTickStepSize = ceiledMaxX / xTickStepCount
  const xTicks = [0].concat(Array.from(Array(xTickStepCount).keys()).map(tickIndex => Math.floor((tickIndex + 1) * xTickStepSize)))

  const getYHeight = (label, height, offset) => {
    const span = document.createElement('span')
    span.innerHTML = label
    span.style.position = 'absolute'
    span.style.fontSize = '10px'
    span.style.fontFamily = '"Avenir Next Font", sans-serif'
    document.body.append(span)
    const labelWidth = span.getBoundingClientRect().width
    span.remove()
    return (height / 2) - ((labelWidth / 2) - offset)
  }
  const lineChart = () => (<LineChart
    margin={{ top: 5, right: 10, bottom: 0, left: 5 }}
    data={data}
  >
    <XAxis
      domain={xDomain}
      ticks={xTicks}
      tickCount={xTicks.length}
      interval={0}
      type='number'
      name='Position'
      height={40} allowDataOverflow={false} dataKey={xAxisName}
    >
      <Label value={xlabel} position='insideBottomMiddle' dy={5} />
    </XAxis>
    <YAxis
      tickFormatter={(tick) => {
        return numeral(tick).format('0,0')
      }}
      label={
        <Text
          textAnchor='middle'
          verticalAnchor='middle'
          dy={lineChartYLabelHeight}
          dx={20}
          angle={-90}
        >
          {ylabel}
        </Text>
      }
      allowDecimals
      axisLine
    />
    <Tooltip content={LineChartTooltip} />
    {isLegend && <Legend
      verticalAlign='bottom'
      horizontalAlign='center'
      layout='horizontal'
      align='center'
      wrapperStyle={{
        position: 'relative',
        left: '50%',
        transform: 'translateX(-43%)',
        top: '-15px'
      }}
                 />}

    <CartesianGrid vertical horizontal />
    {yAxisNames.map((keyName) => {
      return (
        <Line strokeDasharray={strokenDash ? strokenDash[keyName] : ''} type='monotone' dataKey={keyName} stroke={yColors ? yColors[keyName] : '#fff'} yAxisId={0} dot={false} activeDot={{ r: 8 }} />
      )
    })}
  </LineChart>)

  const filteredData = (level) => {
    const raw = withinBoundary({ maxX: maxX, data: withoutDuplicates(data) })
    return raw.filter(x => x.level === level)
  }

  const scatterChart = () => (
    <ScatterChart margin={{ top: 5, right: 10, bottom: 5, left: 5 }}>
      <XAxis
        type='number'
        domain={xDomain}
        ticks={xTicks}
        tickCount={xTicks.length}
        interval={0}
        dataKey='position'
        name='Position'
        allowDuplicatedCategory={false}
      >
        <Label value={xlabel} position='insideBottomMiddle' dy={10} />
      </XAxis>
      <YAxis
        dataKey='level'
        name='Level'
        type='number'
        label={
          <Text
            textAnchor='middle'
            verticalAnchor='middle'
            dy={barChartYLabelHeight}
            dx={40}
            angle={-90}
          >
            {ylabel}
          </Text>
        }
        domain={domain}
        tick={tick}
        tickCount={tickCount} allowDecimals={allowDecimals} interval={interval}
      />
      <ZAxis dataKey='value' name='Wert' unit=' µV' />
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <CartesianGrid vertical={false} horizontal />
      {/* <Legend
        verticalAlign="bottom"
        align="left"
        wrapperStyle={{
          paddingLeft: "30px"
        }}
        marginLeft="20px" /> */}
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      <Scatter shape={<ScatterBarShape level={1} barWidth={4} />} name='Level 1' data={filteredData(1)} fill='#4d71b5' />
      <Scatter shape={<ScatterBarShape level={2} barWidth={4} />} name='Level 2' data={filteredData(2)} fill='#4d71b5' />
      <Scatter shape={<ScatterBarShape level={3} barWidth={4} />} name='Level 3' data={filteredData(3)} fill='#4d71b5' />

    </ScatterChart>
  )

  return (((isBarChart && barChartHeight !== 0) || (!isBarChart && lineChartHeight !== 0))
    ? <div style={{
      width: '100%',
      height: isBarChart ? barChartHeight : lineChartHeight,
      fontSize: '10px'
    }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        {isBarChart ? scatterChart() : lineChart()}
      </ResponsiveContainer>
      </div> : null
  )
}

export default DamageChart
