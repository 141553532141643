import { takeLatest, call, put } from 'redux-saga/effects'
import * as customerTokenActions from './representationTokenActions'
import { getRepresentationRfid, createRepresentationRfid, deleteRfid, updateRfid } from '../../apis/rfid-tokens'

function * getRepresentationToken ({ payload }) {
  try {
    const response = yield call(getRepresentationRfid, payload)
    if (response.status === 200) {
      yield put({
        type: customerTokenActions.REPRESENTATION_TOKEN_LIST_SUCCESS,
        payload: {
          tokens: response.data,
          totalPages: response.headers['x-totalpages'],
          perPage: payload.data.per_page,
          page: payload.data.page
        }
      })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_LIST_FAILURE, payload: err.message })
  }
}

function * createRepresentationToken ({ payload }) {
  try {
    const response = yield call(createRepresentationRfid, payload)
    if (response.status === 201) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_ADD_SUCCESS, payload: response.data })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_ADD_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_ADD_FAILURE, payload: err.message })
  }
}

function * deleteRepresentationToken ({ payload }) {
  try {
    const response = yield call(deleteRfid, payload)
    if (response.status === 204) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_DELETE_SUCCESS, payload: payload })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_LIST_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_LIST_FAILURE, payload: err.message })
  }
}

function * updateRepresentationToken ({ payload }) {
  try {
    const response = yield call(updateRfid, payload.id, payload.data)
    if (response.status === 200) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_UPDATE_SUCCESS, payload: payload.id })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_UPDATE_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.REPRESENTATION_TOKEN_UPDATE_FAILURE, payload: err.message })
  }
}

const representationTokenSaga = [
  takeLatest(customerTokenActions.REPRESENTATION_TOKEN_LIST_REQUEST, getRepresentationToken),
  takeLatest(customerTokenActions.REPRESENTATION_TOKEN_ADD_REQUEST, createRepresentationToken),
  takeLatest(customerTokenActions.REPRESENTATION_TOKEN_DELETE_REQUEST, deleteRepresentationToken),
  takeLatest(customerTokenActions.REPRESENTATION_TOKEN_UPDATE_REQUEST, updateRepresentationToken)
]
export default representationTokenSaga
