import React, { useState, useEffect, useContext, useRef } from 'react'
import ImageUploader from 'react-images-upload'
import { useTranslation } from 'react-i18next'
import formContext from '../../contexts/formContext'
import { FormHelperText } from '@material-ui/core'

/*
Custome File upload component which uses react-images-upload library.
FileUpload component uses require prop to check that upload is required or not.
id require it will setError in state other wise it will let go. it will check for validation
on both onMount using useEffect and onChange.It returns single base64 string if singleImage
is passed other wise you will get array of base64 images. Beacuse we are using react-images-upload library
validations like images types allowed to upload or other options could be set from the library documentation

FileUpload should be the child of SubmitForm because it the provider of context.
*/

const FileUpload = ({ required, showErrors, onInputChange, onInValid, name, ...rest }) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState([])
  const [images, setImages] = useState([])
  const context = useContext(formContext)
  const inputRef = useRef(null)
  useEffect(() => {
    if (showErrors || context.showErrors) {
      if ((!images || images.length === 0) && required) { setErrors([{ message: t('file_upload.image_required') }]) }
      return
    }
    if ((!images || images.length === 0) && required) context.setFieldInValid(name)
    else context.removeFieldInValid(name)
  }, [showErrors, context.showErrors])

  useEffect(() => {
    context.setValue(name, '')
  }, [])

  const handleChange = (imageList) => {
    if (onInputChange) {
      onInputChange(imageList)
    }

    if ((!imageList || imageList.length === 0) && required) {
      context.setFieldInValid(name)
      setErrors([{ message: t('file_upload.image_required') }])
    } else {
      context.removeFieldInValid(name)
      setErrors([])
    }
    const convertedImages = []
    imageList.forEach((file, i) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        convertedImages.push(reader.result.split(',').filter((data, i) => i !== 0).join(''))
        if (i === imageList.length - 1) {
          setImages(rest.singleImage ? convertedImages[0] : convertedImages)
          context.setValue(name, rest.singleImage ? convertedImages[0] : convertedImages)
        }
      }
    })
  }

  let uploadErrorStyle = {}
  if (errors.length > 0) {
    uploadErrorStyle = { border: '1px solid red' }
  }
  return (
    <>
      <ImageUploader style={uploadErrorStyle} ref={inputRef} onChange={handleChange} {...rest} />
      <FormHelperText style={{ margin: '0px 14px' }} error={errors.length > 0}>{errors.map((e) => e.message).join(' * ')}</FormHelperText>
    </>
  )
}

export default FileUpload
