export const CUSTOMER_USER_LIST_REQUEST = 'CUSTOMER_USER_LIST_REQUEST'
export const CUSTOMER_USER_LIST_SUCCESS = 'CUSTOMER_USER_LIST_SUCCESS'
export const CUSTOMER_USER_LIST_FAILURE = 'CUSTOMER_USER_LIST_FAILURE'

export const CUSTOMER_USER_DELETE_REQUEST = 'CUSTOMER_USER_DELETE_REQUEST'
export const CUSTOMER_USER_DELETE_SUCCESS = 'CUSTOMER_USER_DELETE_SUCCESS'
export const CUSTOMER_USER_DELETE_FAILURE = 'CUSTOMER_USER_DELETE_FAILURE'
export const CUSTOMER_USER_RESET_DELETE_STATE =
  'CUSTOMER_USER_RESET_DELETE_STATE'

export const CUSTOMER_USER_UPDATE_REQUEST = 'CUSTOMER_USER_UPDATE_REQUEST'
export const CUSTOMER_USER_UPDATE_SUCCESS = 'CUSTOMER_USER_UPDATE_SUCCESS'
export const CUSTOMER_USER_UPDATE_FAILURE = 'CUSTOMER_USER_UPDATE_FAILURE'
