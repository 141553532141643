import * as utilityActions from '../utility/utilityActions'

const initalState = {
  loading: false,
  headerClass: '',
  innerHeader: [],
  customInnerHeader: null,
  showMessageIcons: false,
  search: {
    showSearch: false,
    searchAction: '',
    searchText: '',
    params: null,
    additionalQuery: [],
    sort: ''
  },
  dashboardWidgets: {
    show: false,
    dateWidgetData: null,
    dashboardModuleId: null
  },
  connectionSymbolStatus: null,
  modId: null,
  error: null,
  showConnectionIcon: false
}

const utility = (state = initalState, action) => {
  switch (action.type) {
    case utilityActions.SHOW_GLOBAL_LOADER:
      return { ...state, loading: true }
    case utilityActions.HIDE_GLOBAL_LOADER:
      return { ...state, loading: false }

    case utilityActions.SHOW_LOADER:
      return { ...state, loading: true }
    case utilityActions.HIDE_LOADER:
      return { ...state, loading: false }

    case utilityActions.ADD_CUSTOME_HEADER_CLASSES:
      return { ...state, headerClass: action.payload }
    case utilityActions.REMOVE_CUSTOME_HEADER_CLASSES:
      return { ...state, headerClass: '' }
    case utilityActions.PUSH_RECENT_INNER_HEADER_HEADING:
      return { ...state, innerHeader: [...state.innerHeader, action.payload].map((data) => ({ ...data })) }
    case utilityActions.POP_RECENT_INNER_HEADER_HEADING:
      return { ...state, innerHeader: state.innerHeader.filter((data, i) => i !== state.innerHeader.length - 1) }
    case utilityActions.SPLICE_RECENT_INNER_HEADER_HEADING:
      const headerIndex = state.innerHeader.findIndex(header => action.payload.url === header.url)
      return { ...state, innerHeader: state.innerHeader.filter((data, i) => i <= headerIndex) }
    case utilityActions.UPDATE_RECENT_INNER_HEADER_HEADING:
      return { ...state, innerHeader: action.payload.map((data) => ({ ...data })) }
    case utilityActions.SET_CUSTOM_INNER_HEADER_HEADING:
      return { ...state, customInnerHeader: action.payload }
    case utilityActions.REMOVE_CUSTOM_INNER_HEADER_HEADING:
      return { ...state, customInnerHeader: null }
    case utilityActions.SAVE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: action.payload
        }
      }
    case utilityActions.SHOW_SEARCH:
      return {
        ...state,
        search:
          {
            showSearch: true,
            params: action.payload.params ? { ...action.payload.params } : state.search.params,
            searchAction: action.payload.action,
            searchText: '',
            additionalQuery: action.payload.additionalQuery ? action.payload.additionalQuery : '',
            sort: action.payload.sort
          }
      }
    case utilityActions.HIDE_SEARCH:
      return {
        ...state,
        search: {
          showSearch: false,
          searchAction: '',
          searchText: '',
          params: null,
          additionalQuery: '',
          sort: ''
        }
      }
    case utilityActions.SHOW_MESSAGE_ICONS:
      return {
        ...state,
        showMessageIcons: true
      }
    case utilityActions.HIDE_MESSAGE_ICONS:
      return {
        ...state,
        showMessageIcons: false
      }
    case utilityActions.HIDE_DASHBOARD_WIDGETS:
      return {
        ...state,
        dashboardWidgets: {
          show: false,
          dateWidgetData: null,
          dashboardModuleId: null
        }
      }
    case utilityActions.SHOW_DASHBOARD_WIDGETS:
      return {
        ...state,
        dashboardWidgets: {
          show: true,
          dateWidgetData: { ...action.payload.dateWidgetPayload },
          dashboardModuleId: action.payload.dashboardModuleId
        }
      }
    case utilityActions.SHOW_CONNECTION_WIDGETS:
      return {
        ...state,
        showConnectionIcon: true,
        modId: action.payload
      }
    case utilityActions.HIDE_CONNECTION_WIDGETS:
      return {
        ...state,
        showConnectionIcon: false,
        modId: null
      }
    default:
      return state
  }
}

export default utility
