import request from './request'

export function getRepresentations (payload) {
  return request.get('representations', { params: payload })
}

export function getRepresentation (id) {
  return request.get(`representations/${id}`)
}

export function addRepresentation (payload) {
  return request.post('representations', payload)
}

export function deleteRepresentation (id) {
  return request.delete(`representations/${id}`)
}

export function updateRepresentation (id, payload) {
  return request.patch(`representations/${id}`, payload)
}
