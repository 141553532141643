import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SET_CUSTOM_INNER_HEADER_HEADING, REMOVE_CUSTOM_INNER_HEADER_HEADING } from '../../store/utility/utilityActions'
import { useLocation } from 'react-router-dom'
const useSetParentCustomInnerHeaderHeading = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const parent = location.pathname.split('/').slice(1)[0]

  useEffect(() => {
    dispatch({ type: SET_CUSTOM_INNER_HEADER_HEADING, payload: { name: 'innerHeader.' + parent, url: '/' + parent } })
    return () => {
      dispatch({ type: REMOVE_CUSTOM_INNER_HEADER_HEADING })
    }
  }, [])
}

export default useSetParentCustomInnerHeaderHeading
