import {
  REPRESENTATIVE_CUSTOMER_LIST_SUCCESS
} from './representativeCustomerActions'

export const representativeCustomer = (
  state = {
    loading: false,
    customers: [
      { id: 1, project_id: 1, name: 'Rose', facility: 'Transport', street: '223', city: 'London', country: 'UK', customer_nr: 123454 },
      { id: 2, project_id: 2, name: 'Sam', facility: 'Medical', street: '231', city: 'Hamsburg', country: 'Germany', customer_nr: 123414 },
      { id: 3, project_id: 3, name: 'Danial', facility: 'Transport', street: '778', city: 'London', country: 'UK', customer_nr: 123354 }
    ],
    error: null,
    customer: null
  },
  action
) => {
  switch (action.type) {
    case REPRESENTATIVE_CUSTOMER_LIST_SUCCESS:
      return state

    default:
      return state
  }
}

export default representativeCustomer
