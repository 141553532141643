import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const MessagePopup = ({ messageData, handleClose }) => {
  return (
    <Snackbar open={messageData.open} autoHideDuration={messageData.duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={messageData.severity}>
        {messageData.message}
      </Alert>
    </Snackbar>
  )
}

export default MessagePopup
