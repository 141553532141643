
import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'

function SettingsControl ({ variable, renderType, onChange, readOnly }) {
  let renderInput = <input type='text' value={variable.value} onChange={!readOnly && onChange} placeholder='-' disabled={readOnly} />
  if (renderType) {
    if (renderType.name === 'date') {
      renderInput = (
        <div className='settings-date-select'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={readOnly}
              value={variable.value ? new Date(variable.value.replace(/-/g, '/')) : null}
              {...renderType.meta}
              onChange={onChange}
            />
          </MuiPickersUtilsProvider>
        </div>
      )
    }
    if (renderType.name === 'time') {
      const selectedDateNow = new Date()
      const selectedDate =
                 selectedDateNow.getFullYear() + '/' + ((selectedDateNow.getMonth() + 1) >= 10 ? (selectedDateNow.getMonth() + 1) : '0' + (selectedDateNow.getMonth() + 1)) + '/' + (selectedDateNow.getDate() >= 10 ? selectedDateNow.getDate() : '0' + selectedDateNow.getDate()) + ' ' + variable.value
      renderInput = (
        <div className='settings-time-select'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              disabled={readOnly}
              value={selectedDate}
              {...renderType.meta}
              onChange={onChange}
            />
          </MuiPickersUtilsProvider>

        </div>
      )
    }
  }
  return (
    <div>
      {renderInput}
    </div>
  )
}

export default SettingsControl
