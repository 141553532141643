import * as customerUserActions from './customerUserActions'

const initialState = {
  loading: false,
  customerUsers: [],
  customerUser: null,
  success: false,
  error: null,
  perPage: 10,
  page: 1,
  totalPages: 0,
  deletedId: 0
}

const customerUser = (state = initialState, action) => {
  switch (action.type) {
    case customerUserActions.CUSTOMER_USER_LIST_REQUEST:
      return { ...state, loading: true }
    case customerUserActions.CUSTOMER_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerUsers: action.payload.customerUsers.map((customerUser) => {
          return { ...customerUser }
        }),
        perPage: action.payload.perPage ? action.payload.perPage : state.perPage,
        page: action.payload.page ? action.payload.page : state.page,
        totalPages: action.payload.totalPages ? action.payload.totalPages : state.totalPages
      }
    case customerUserActions.CUSTOMER_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        customerUsers: [],
        error: action.error
      }
    case customerUserActions.CUSTOMER_USER_UPDATE_REQUEST:
      return { ...state, loading: true }
    case customerUserActions.CUSTOMER_USER_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true }
    case customerUserActions.CUSTOMER_USER_UPDATE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }
    case customerUserActions.CUSTOMER_USER_DELETE_REQUEST:
      return { ...state, loading: true }
    case customerUserActions.CUSTOMER_USER_DELETE_SUCCESS:
      return { ...state, loading: false, success: true, deletedId: action.payload.deletedId }
    case customerUserActions.CUSTOMER_USER_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error, deletedId: 0 }
    case customerUserActions.CUSTOMER_USER_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }
    default:
      return state
  }
}

export default customerUser
