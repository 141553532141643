import React from 'react'
import { useParams } from 'react-router-dom'
import Messages from '../../messages/messages'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useTranslation } from 'react-i18next'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'

const ConveyorBeltMessages = () => {
  const params = useParams()
  const { t } = useTranslation()
  useSetControlTitle(params.id)
  useShowConnectionSymbol(params.id)
  return (
    <>
      <PollControlData />
      <Messages
        generalButtonText={t('conveyor_belt_messages.general')}
        customQueryKey='q[field_module_id_eq]'
        noRoles
        customQueryValue={params.id}
        hideEventTitle2
      />
    </>
  )
}

export default ConveyorBeltMessages
