import request from './request'

export function readErrorList (id, payload) {
  return request.post(`field_modules/${id}/read_error_list`, payload)
}

export function readAccessList (id, payload) {
  return request.post(`field_modules/${id}/read_access_list`, payload)
}

export function readVariableList (id, payload) {
  return request.post(`field_modules/${id}/read_variable_list`, payload)
}

export function readMeasurementList (id, payload) {
  return request.post(`field_modules/${id}/read_measurement_run_list`, payload)
}

export function readMeasurementRun (id, payload) {
  return request.post(`field_modules/${id}/read_measurement_run`, payload)
}

export function readBeltDamageList (id, payload) {
  return request.post(`field_modules/${id}/read_belt_damage_list`, payload)
}

export function softwareUpdate (id, payload) {
  return request.post(`field_modules/${id}/software_update`, payload)
}

export function getJobStatus (payload) {
  return request.get(`jobs/${payload}`)
}

export function getRequestedFile (payload) {
  return request.get(`files/${payload}`)
}
