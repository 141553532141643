import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_RECENT_INNER_HEADER_HEADING } from '../../store/utility/utilityActions';
import { useHistory, useLocation } from 'react-router-dom';

const useUpdateInnerHeading = () => {
  const location = useLocation()
  const pathname = location.pathname
  const dispatch = useDispatch()
  const customInnerHeader = useSelector((state) => state.utility.customInnerHeader)
  const headers = useRef([])
  const savedCustomInnerHeader = useRef(null)
  const customerControls = useSelector((state) => state.customerControl.customerControls)


  let resultHeaders = null
  const pathTokens = pathname.split('/').slice(1) || []
  if (pathname.match(/\/customers\/edit\/\d+\/controls\/\d+\/edit/g)) {
    const control = customerControls.find(x => x.id.toString() === pathTokens[4])
    resultHeaders = [
      { name: control?.name, url: null }
    ]
  } else {
    // get headers by current location
    resultHeaders = getHeaders(location, customInnerHeader ? customInnerHeader.name : null, customInnerHeader ? customInnerHeader.customPath : null)
  }

  dipatchHeading(headers, resultHeaders, dispatch, customInnerHeader, savedCustomInnerHeader)
}

const dipatchHeading = (headers, resultHeaders, dispatch, customInnerHeader, savedCustomInnerHeader) => {
  if (!customInnerHeader) {
    savedCustomInnerHeader.current = null
  }
  if (customInnerHeader && (!customInnerHeader.customPath) && JSON.stringify(savedCustomInnerHeader.current) !== JSON.stringify(customInnerHeader)) {
    savedCustomInnerHeader.current = customInnerHeader
    dispatch({ type: UPDATE_RECENT_INNER_HEADER_HEADING, payload: [{ name: customInnerHeader.name, url: customInnerHeader.url }] })
    return
  }
  if (JSON.stringify(headers.current) !== JSON.stringify(resultHeaders)) {
    headers.current = resultHeaders
    dispatch({ type: UPDATE_RECENT_INNER_HEADER_HEADING, payload: headers.current })
  }
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getHeaders = (location, customName, customPath) => {
  const pathname = location.pathname
  const initialPath = []
  let fullTokens = []
  let pathTokens = pathname.split('/').slice(1)
  if (pathname.endsWith('/')) {
    pathTokens.pop()
  }
  if (customName && customPath) {
    const customPathTokens = customPath.split('/')
    fullTokens = pathTokens
    pathTokens = pathTokens.filter((path) => !customPathTokens.includes(path))
    initialPath.push({ name: customName, url: '/' + customPath + '/' })
  }

  if (pathname.match(/\/cenveyor-belts\/menu\/\d+\/scraper\/\d+\/measurements/g)) {
    return [
      { name: 'innerHeader.belt-cenveyor', url: `/cenveyor-belts/menu/${pathTokens[2]}/` },
      { name: 'innerHeader.info', url: `/cenveyor-belts/menu/${pathTokens[2]}/info/` },
      { name: 'innerHeader.scraper', url: `/cenveyor-belts/menu/${pathTokens[2]}/info/scraper/` },
      { name: 'innerHeader.measurements', url: `/cenveyor-belts/menu/${pathTokens[2]}/scraper/${pathTokens[4]}/measurements` }
    ]
  }

  return pathTokens.reduce((prev, pathToken, index) => {
    const path = '/' + (pathTokens.slice(0, index + 1).join('/'))
    if (pathTokens[index - 1] === 'edit' || pathTokens[index - 1] === 'detail' || pathTokens[index + 1] === 'info') {
      return prev
    }
    if (pathToken === 'detail') {
      prev = [...prev, {
        name: 'innerHeader.' + pathTokens[index - 1] + capitalizeFirstLetter(pathToken),
        url: path + '/' + pathTokens[index + 1] + '/'
      }]
    }

    if (pathToken === 'edit') {
      prev = [...prev, {
        name: 'innerHeader.' + pathToken + capitalizeFirstLetter(pathTokens[index - 1]),
        url: path + '/' + pathTokens[index + 1] + '/'
      }]
    }

    if (pathToken === 'add') {
      prev = [...prev, {
        name: 'innerHeader.' + pathToken + capitalizeFirstLetter(pathTokens[index - 1]),
        url: path + '/'
      }]
    }

    if (pathToken === 'info') {
      let infoUrl = ''
      if (fullTokens.length > 0) {
        if (fullTokens.indexOf('info') !== fullTokens.length - 1) {
          infoUrl = '/' + (fullTokens.slice(0, fullTokens.length - 1).join('/'))
        } else {
          infoUrl = '/' + (fullTokens.slice(0, fullTokens.length).join('/'))
        }
        if (!infoUrl.endsWith('/')) {
          infoUrl += '/'
        }
      } else {
        infoUrl = path + '/'
      }
      prev = [...prev, { name: 'innerHeader.' + pathToken, url: infoUrl }]
    }

    if ((pathToken === 'scraper' || pathToken === 'control' || pathToken === 'belt-cenveyor') && pathTokens[index - 1] === 'info') {
      let infoUrl = '/' + (fullTokens.slice(0, fullTokens.length).join('/'))
      if (!infoUrl.endsWith('/')) {
        infoUrl += '/'
      }
      prev = [...prev, { name: 'innerHeader.' + pathToken, url: infoUrl }]
    }

    if (pathToken !== 'edit' && pathToken !== 'add' && pathToken !== 'detail' && pathToken !== 'info' && pathToken !== 'scraper' && pathToken !== 'control' && pathToken !== 'belt-cenveyor') {
      prev = [...prev, { name: 'innerHeader.' + pathToken, url: path + '/' }]
    }

    return prev
  }, initialPath)
}

export default useUpdateInnerHeading
