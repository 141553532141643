import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import PopupDialog from '../../components/PopupDialog'
import Loader from '../../components/Ux/Loader'
import {
  CUSTOMER_USER_LIST_REQUEST,
  CUSTOMER_USER_UPDATE_REQUEST
} from '../../store/customerUser/customerUserActions'
import { CUSTOMER_AREA_LIST_REQUEST } from '../../store/customer-area/customerAreaActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import { Grid } from '@material-ui/core'
import ReadMoreReact from 'read-more-react'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  userTable: {
    minWidth: 150,
    '& thead': {

      width: '150px',
      position: 'sticky',
      top: '0',
      border: 'solid 2px #707070'

    }
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '260px',
    whiteSpace: 'normal'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
}))

const AreaCustomerUser = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const dispatch = useDispatch()

  const [newCustomerUsers, setNewCustomerUsers] = useState([])
  const [areaLength, setAreaLength] = useState({})
  const [areaChangeValues, setAreaChangeValues] = useState({})
  const [openAreaChangeDialog, setOpenAreaChangeDialog] = useState(false)

  const customerUserList = useSelector((state) => state.customerUser)
  const { loading, error, customerUsers } = customerUserList
  const customerAreaList = useSelector((state) => state.customerArea)
  const { customer } = useSelector((state) => state.customer)
  useSetParentCustomInnerHeaderHeading()
  const {
    error: areaError,
    customerAreas
  } = customerAreaList
  // On Component Mount getting the customer data
  useEffect(() => {
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
  }, [])

  // load customer area list
  useEffect(() => {
    dispatch({ type: CUSTOMER_AREA_LIST_REQUEST, payload: { id: params.id } })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: CUSTOMER_USER_LIST_REQUEST, payload: { id: params.id } })
  }, [dispatch])

  useEffect(() => {
    toast.error(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }, [error])

  useEffect(() => {
    toast.error(areaError, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }, [areaError])

  useEffect(() => {
    if (customerUsers.length) {
      setNewCustomerUsers(customerUsers)

      // Setting areas length
      const newAreaLength = {}
      customerUsers.map((user) => {
        user.area_ids.map((id) => {
          newAreaLength[id] =
            newAreaLength[id] === undefined ? 1 : newAreaLength[id] + 1
        })
      })
      setAreaLength(newAreaLength)
    }
  }, [setNewCustomerUsers, customerUsers])

  // current area that is being changed
  const currentArea = customerAreas.find(x => x.id === areaChangeValues.areaId)

  const handleConfirmAreaChange = (e, i, areaId) => {
    setOpenAreaChangeDialog(false)
    // if user clicked yes
    if (e.currentTarget.value === 'true') {
      updateAreaChange(areaChangeValues.value, areaChangeValues.colIndex, areaChangeValues.areaId)
    }
    setAreaChangeValues({})
  }

  const updateAreaChange = (value, i, areaId) => {
    // move dispatching api request here
    const duplicateCustomerUsers = [...newCustomerUsers]
    const customerUser = { ...duplicateCustomerUsers[i] }
    // if checkbox is checked
    if (value) {
      customerUser.area_ids.push(areaId)
      // Increasing area length
      const newAreaLength = { ...areaLength }
      newAreaLength[areaId] =
        newAreaLength[areaId] === undefined ? 1 : newAreaLength[areaId] + 1
      setAreaLength(newAreaLength)
    } else {
      // checkbox is unchecked
      customerUser.area_ids = customerUser.area_ids.filter(
        (area_id) => area_id !== areaId
      )
      // Decreasing area length
      const newAreaLength = { ...areaLength }
      newAreaLength[areaId] = newAreaLength[areaId] - 1
      setAreaLength(newAreaLength)
    }
    duplicateCustomerUsers[i] = customerUser
    setNewCustomerUsers(duplicateCustomerUsers)

    dispatch({
      type: CUSTOMER_USER_UPDATE_REQUEST,
      payload: {
        id: customerUser.id,
        customerUser: { area_ids: customerUser.area_ids }
      }
    })
  }

  const handleAreaChange = (e, colIndex, areaId) => {
    if (areaId) {
      // set values that should be changed when the dialog is confirmed
      setAreaChangeValues({
        value: e.currentTarget.checked,
        colIndex,
        areaId // maybe we need the user id here
      })

      const area = customerAreas.find(x => x.id === areaId)
      // show confirm dialog if there are already 3 users in this area
      // and the checkbox has been checked (we do not have to show anything if it is unchecked)
      if (e.currentTarget.checked && areaLength[areaId] >= 3 && area.assigned_field_modules > 0) {
        setOpenAreaChangeDialog(true)
      } else {
        // if there are less than 3 areas for this user or checkbox is unchecked
        // => commit changes immediately without asking
        updateAreaChange(e.currentTarget.checked, colIndex, areaId)
        setAreaChangeValues({})
      }
    }
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>
          <Loader open={loading} />
          <div className='Inner_scrollTwoTable'>
            <ContentTitle title={t('area_customer_users.title')} />
            <ContentInfoTitle type={t('list_customer.title_singular')} name={customer && customer.name} no={customer && customer.customer_nr} />
            <Grid item xs='12' className='Inner_scrollTwo pb-0'>
              <div className='Inner_scroll-con pl-1 pr-1 pt-0 position-relative' style={{ overflow: 'inherit' }}>
                <TableContainer component={Paper}>
                  <div
                    className=' ml-1 mr-1 tableScrollbar TableFixed UserAreaTable'
                    style={{
                      height: '100%',
                      overflowY: 'auto',
                      position: 'absolute',
                      left: '0',
                      right: '10px',
                      paddingBottom: '10px',
                      paddingRight: '10px'
                    }}
                  >
                    <Table
                      stickyHeader
                      className={classes.areaTable}
                      aria-label='customized table'
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <span className='Text-righthead'>{t('area_customer_users.user')}</span>
                            <span className='Rotate-shape' />
                            <span className='Text-Bootmhead'>{t('area_customer_users.areas')}</span>
                          </StyledTableCell>

                          {newCustomerUsers &&
                      newCustomerUsers.map((user) => (
                        <StyledTableCell key={user.id} className='TextOverflowTh '>

                          {user.email && <ReadMoreReact
                            text={(user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '')}
                            min={30}
                            max={30}
                            ideal={30}
                            readMoreText='...'
                          />}
                        </StyledTableCell>

                      ))}

                          {!newCustomerUsers || (newCustomerUsers && newCustomerUsers.length === 0)
                            ? <StyledTableCell className='text-center'>
                              {t('noRecordsFound')}
                              </StyledTableCell>
                            : null}

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customerAreas &&
                    customerAreas.map((area) => (
                      <StyledTableRow key={area.id}>
                        <TableCell className={classes.stickyCell}>
                          <div className='TextSpartaerDiv' style={{ width: '100%' }}>{area.name ? area.name : ''}</div>
                        </TableCell>
                        {newCustomerUsers.map((user, i) => {
                          return (
                            <StyledTableCell key={i}>
                              <Checkbox
                                checked={user.area_ids.includes(area.id)}
                                onChange={(e) =>
                                  handleAreaChange(e, i, area.id)}
                                inputProps={{
                                  'aria-label': 'primary checkbox'
                                }}
                              />

                            </StyledTableCell>
                          )
                        })}
                      </StyledTableRow>
                    ))}
                      </TableBody>
                    </Table>
                    {!customerAreas.length && (
                      <div
                        style={{
                          padding: '1rem',
                          textAlign: 'center'
                        }}
                      >
                        {t('noRecordsFound')}
                      </div>
                    )}
                  </div>
                </TableContainer>
              </div>
            </Grid>
          </div>
        </CardContent>
      </Card>
      <PopupDialog
        open={openAreaChangeDialog}
        message={t('area_customer_controls.dialog.change_area.message', { additionalUserLicences: currentArea && currentArea.next_user_costs })}
        onClick={handleConfirmAreaChange}
      />
    </>
  )
}

export default Sidebars(AreaCustomerUser)
