import React from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import packageJson from '../../package.json'

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
    marginTop: '64px',
    borderTop: '1px solid #000',
    paddingTop: '0.625rem'
  }
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className='footer'>
      <Box className={classes.root + ' inner-footer'} marginTop={8}>
        <Container fixed>
          <Grid container spacing={0}>
            <Grid className='ftr-text' style={{ textAlign: 'center' }} item xs>
              {/* Schulte Strathaus GmbH & Co. KG <br/>Runtestraße 42 <br/>D-59457 Werl */}
              <strong>Schulte Strathaus GmbH & Co. KG</strong><br />
              <ul className='footer-address'>
                <li>
                Runtestraße 42
                </li>
                <li>&#8226;
                59457
                </li>
                <li>
                Werl
                </li>
                <li>
            &#8226;
                Germany
                </li>
              </ul>
              <span>Fon: +49 (0) 2922 9775 0</span> <br />
              <span>Mail: info@schulte-strathaus.de</span> <br />
              <span>www.schulte-strathaus.de</span>

            </Grid>

          </Grid>
        </Container>
      </Box>
      <div className='Versiontext'>Software Version:<br />{packageJson.version}</div>
    </div>
  )
}

export default Footer
