import { useEffect, useRef } from 'react'
import useSetParentCustomInnerHeaderHeading from './useSetParentCustomInnerHeaderHeading'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_CONTROL_REQUEST } from '../../store/customerControl/customerControlActions'
import { CONTROL_MENU_URL } from '../../constants/urls'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { SET_CUSTOM_INNER_HEADER_HEADING, REMOVE_CUSTOM_INNER_HEADER_HEADING, SHOW_LOADER, HIDE_LOADER } from '../../store/utility/utilityActions'

const useSetControlTitle = (id) => {
  const dispatch = useDispatch()
  const isMount = useRef(false)
  const isErrorMount = useRef(false)
  const { control, error } = useSelector((state) => state.customerControl)
  useSetParentCustomInnerHeaderHeading(true)
  const location = useLocation()
  const parent = location.pathname.split('/').slice(1)[0]
  useEffect(() => {
    dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: id })
    dispatch({ type: SHOW_LOADER })
  }, [])
  useEffect(() => {
    if (isErrorMount.current) {
      dispatch({ type: HIDE_LOADER })
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isMount.current && control) {
      dispatch({ type: SET_CUSTOM_INNER_HEADER_HEADING, payload: { name: control.name, customPath: CONTROL_MENU_URL + id, url: '/' + parent } })
      dispatch({ type: HIDE_LOADER })
    } else {
      isMount.current = true
    }
    return () => {
      dispatch({ type: REMOVE_CUSTOM_INNER_HEADER_HEADING })
    }
  },
  [control])
}

export default useSetControlTitle
