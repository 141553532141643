import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import Pagination from '@material-ui/lab/Pagination'
import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_DELETE_REQUEST
} from '../../store/customer/customerActions'
import Sidebars from '../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import useTogglePageSearch from '../../components/hooks/useTogglePageSearch'
import ReadMoreReact from 'read-more-react'
import {

  SET_SCOPED_CUSTOMER_ID
  , REMOVE_SCOPED_CUSTOMER_ID
} from '../../store/users/userActions'

const useActions = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}))

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const Customers = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const actionsClasses = useActions()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  useTogglePageSearch(CUSTOMER_LIST_REQUEST, null, 'name_or_facility_or_street_or_city_or_country_or_customer_nr', 'name asc')
  const [newCustomers, setNewCustomers] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [customerId, setCustomerId] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const isReadMoreClick = useRef({})
  const readMoreState = useRef({})
  const isMount = useRef(false)
  const isCustomerScopeMount = useRef(false)
  // Setting state with redux store values
  const { scopeCustomerId } = useSelector((state) => state.user)
  const customerList = useSelector((state) => state.customer)
  const searchText = useSelector((state) => state.utility.search.searchText)

  const { loading, error, customers, totalPages, perPage, page } = customerList
  useSetParentCustomInnerHeaderHeading()
  // Dispatching action to fetch  customer list
  useEffect(() => {
    dispatch({ type: CUSTOMER_LIST_REQUEST, payload: { per_page: perPage, page, 'q[sorts]': 'name asc' } })
    dispatch({ type: REMOVE_SCOPED_CUSTOMER_ID })
  }, [dispatch])

  useEffect(() => {
    if (isCustomerScopeMount.current && scopeCustomerId !== null) {
      setShowLoader(false)
      history.replace('/')
    } else {
      isCustomerScopeMount.current = true
    }
  }, [scopeCustomerId])

  useEffect(() => {
    if (isMount.current) {
      readMoreState.current = {}
      isReadMoreClick.current = {}
      setNewCustomers(customers)
    } else {
      isMount.current = true
    }
  }, [setNewCustomers, customers])

  useEffect(() => {
    toast.error(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }, [error])

  // Dispatching action to delete the customer
  const handleConfirmDeleteCustomer = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      const duplicateCustomers = newCustomers.filter(
        (customer) => customer.id !== customerId
      )
      setNewCustomers(duplicateCustomers)
      dispatch({ type: CUSTOMER_DELETE_REQUEST, payload: { id: customerId } })
      setCustomerId(null)
    } else {
      setCustomerId(null)
    }
  }

  // Open confirm delete dialog and set customer id to be deleted
  const handleDeleteCustomer = (id) => {
    setOpenDialog(true)
    setCustomerId(id)
  }

  const onPageChange = (e, selectedPage) => {
    const payload = { per_page: perPage, page: Number(selectedPage), 'q[sorts]': 'name asc' }
    if (searchText) {
      payload['q[name_or_facility_or_street_or_city_or_country_or_customer_nr_cont]'] = searchText
    }
    dispatch({ type: CUSTOMER_LIST_REQUEST, payload })
  }

  const handleCustomerClick = (id) => {
    setShowLoader(true)
    dispatch({ type: SET_SCOPED_CUSTOMER_ID, payload: id })
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>

          <Loader open={loading || showLoader} />
          <Grid container className='MainContInnerStyle' alignContent='space-between'>
            <Grid item xs='1'>
              <div className='AddBtn icon-size AddBtnZ'>
                <IconButton onClick={() => history.push(`${match.path}/add/`)} className='RowData-btn'>
                  <img src='/assets/img/add.png' className='AddbtnImg' alt='Add Customer' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs='11' className='Inner_scroll'>
              <div className='Inner_scroll-con Pr-2'>
                {newCustomers.map((customer) => (
                  <Grid container direction='row' justifyContent='flex-end' key={customer.id} className='RowData '>
                    <Grid item md={1} xs={2} className='left-Con'>
                      <div>
                        <IconButton
                          className='RowData-btn'
                          onClick={() =>
                            history.push(`${match.path}/edit/${customer.id}/`)}
                          color='primary'
                        >
                          <img className='editiconspace' src='/assets/img/edit.png' alt='starclean logo' />
                        </IconButton><br />
                        <IconButton
                          className='RowData-btn'
                          onClick={() => handleDeleteCustomer(customer.id)}
                          style={{ color: 'red' }}
                        >
                          <img src='/assets/img/delete.png' alt='starclean logo' />
                        </IconButton>
                      </div>

                    </Grid>

                    <Grid item md={9} xs={8} className='Mid-Con'>
                      <strong>
                        {customer && customer.name && <span
                          style={{ cursor: 'pointer' }} onClick={(e) => {
                            !isReadMoreClick.current[customer.id] && handleCustomerClick(customer.id)

                            if (readMoreState.current[customer.id] === 'open') {
                              isReadMoreClick.current[customer.id] = false
                              readMoreState.current[customer.id] = 'closed'
                            }

                            if (isReadMoreClick.current[customer.id]) {
                              readMoreState.current[customer.id] = 'open'
                            }
                          }} href='javascipt:void(0)'
                                                      ><ReadMoreReact
                          text={customer.name}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText={
                              <div onClick={(e) => { e.preventDefault(); isReadMoreClick.current[customer.id] = true }}>...</div>
                            }
                        /></span>}
                      </strong>
                      {customer && customer.facility && <ReadMoreReact
                        text={customer.facility}
                        min={50}
                        ideal={50}
                        max={50}
                        readMoreText='...'
                      />}
                      {customer && customer.facility ? null : <br />}
                      <div>
                        {customer && customer.street && <ReadMoreReact
                          text={customer.street}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                        />}
                      </div>
                      <div>
                        {customer && customer.city && <ReadMoreReact
                          text={customer.city}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                      />}
                      </div>
                      <div>
                        {customer && customer.country && <ReadMoreReact
                          text={customer.country}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                         />}
                      </div>
                    </Grid>
                    <Grid item md={2} xs={2} className='Last-Con'>
                      <div>
                        <strong>{t('list_customer.id')}</strong><br />
                        {customer.customer_nr}
                      </div>

                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>

          </Grid>
          {!newCustomers.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>

        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            {newCustomers && newCustomers.length && totalPages ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
          </Box>
        </CardActions>

      </Card>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteCustomer} />
    </>
  )
}

export default Sidebars(Customers, { showSearch: true })
