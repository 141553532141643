import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid } from '@material-ui/core'

import { toast } from 'react-toastify'
import { dbExport } from '../apis/dbExport'

const AdminDashboard = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [, setShowLoader] = useState(false)

  const handleDBExport = async () => {
    try {
      setShowLoader(true)
      const res = await dbExport()
      if (res.status === 204) {
        setShowLoader(false)
        toast.success(t('admin_dashboard.toast.db_export_success'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 403) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <Container component='main' maxWidth='xl' fixed>
      <Grid container spacing={0} className='dashboard-grid AdminDashboard-row AdminList'>

        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={1} justifyContent='space-between'>
            <Grid item xs={12}>
              <Link to='/customers'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('admin_dashboard.customers')}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Button
                className='dashboard-btn'
                onClick={() => history.push('/messages/')}
                variant='outlined'
                color='primary'
                size='large'
                fullWidth
              >
                {t('admin_dashboard.messages_faults')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link to='/service-predictive-maintenance'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('admin_dashboard.service_predictive_maintenance')}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to='/representations'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('admin_dashboard.representations')}
                </Button>
              </Link>
            </Grid>
            <Grid item lg={5} md={6} xs={12} className='Speacing'>
              <Link to='/segment-tables'>
                <Button className='dashboard-btn' variant='outlined' color='primary' size='large' fullWidth>
                  {t('admin_dashboard.segment_table_upload')}
                </Button>
              </Link>
            </Grid>
            <Grid item lg={5} md={6} xs={12} className='Speacing'>
              <Button className='dashboard-btn' variant='outlined' color='primary' size='large' fullWidth onClick={handleDBExport}>
                {t('admin_dashboard.download_backend')}
              </Button>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={1} />

      </Grid>
    </Container>
  )
}

export default AdminDashboard
