import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SidebarSearch from './SidebarSearch'
import { SAVE_SEARCH, SHOW_LOADER } from '../../store/utility/utilityActions'
import { CUSTOMER_CONTROL_VARIABLES_RELOAD_REQUEST } from '../../store/customerControl/customerControlActions'

import { SERVICE_USER, SUPER_USER, REPRESENTATION_USER } from '../../constants/roles'
import { REMOVE_SCOPED_CUSTOMER_ID, REMOVE_SCOPED_REPRESENTATIVE_ID, SET_SCOPED_REPRESENTATIVE_ID } from '../../store/users/userActions'

const SearchToolbarContainer = ({ children }) => {
  return (
    <Grid container style={{ height: '45px', paddingBottom: 10 }}>
      <Grid item xs={1} className='position-relative'>
        {/* left space */}
      </Grid>
      <Grid item xs={10} className='position-relative'>
        {children}
      </Grid>
      <Grid item xs={1} className='position-relative'>
        {/* right space */}
      </Grid>
    </Grid>
  )
}

function Sidebars (Component, props = {}) {
  const { roles, showSearch } = props
  const SideBarWrapper = (props) => {
    const history = useHistory()
    const match = useRouteMatch()
    const dispatch = useDispatch()
    const { profile, scopeCustomerId, scopeRepresentativeId } = useSelector((state) => state.user)
    const search = useSelector((state) => state.utility.search)
    const { show, dateWidgetData, dashboardModuleId } = useSelector((state) => state.utility.dashboardWidgets)

    if (profile && profile.role && roles && !roles.includes(profile.role)) {
      return (<Component />)
    }

    const handleBackClick = () => {
      if (match.path === '/' &&
                  (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER) &&
                  (scopeCustomerId || scopeRepresentativeId)) {
        const custId = scopeCustomerId
        const representativeId = scopeRepresentativeId
        dispatch({ type: REMOVE_SCOPED_CUSTOMER_ID })
        dispatch({ type: REMOVE_SCOPED_REPRESENTATIVE_ID })
        if (profile.role === SERVICE_USER || profile.role === SUPER_USER) {
          if (localStorage.getItem('isRepresentativeSuperAccess')) {
            dispatch({ type: SET_SCOPED_REPRESENTATIVE_ID, payload: localStorage.getItem('isRepresentativeSuperAccess') })
            history.push('/representative-customers')
          } else if (custId) {
            history.push('/customers')
          } else if (representativeId) {
            history.push('/representations')
          }
          return
        }

        if (profile.role === REPRESENTATION_USER) {
          history.push('/representative-customers')
          return
        }
      }

      if (match.path.endsWith(':id')) {
        history.push('../../')
      } else if (match.path.endsWith('software_update')) {
        history.goBack()
      } else if (match.path.endsWith('measurements') || match.path.endsWith('measurements/add')) {
        history.goBack()
      } else {
        history.push('../')
      }
    }

    const handleVariableReferesh = () => {
      dispatch({ type: SHOW_LOADER })
      dispatch({ type: CUSTOMER_CONTROL_VARIABLES_RELOAD_REQUEST, payload: dashboardModuleId })
    }

    const onSearch = (searchText) => {
      dispatch({ type: SAVE_SEARCH, payload: searchText })
      let query = 'name'
      if (search.additionalQuery) {
        query = search.additionalQuery
      }
      query = 'q[' + query + '_cont]'
      let payload = { per_page: 10, page: 1, [query]: searchText }
      if (search.sort) {
        payload['q[sorts]'] = search.sort
      }
      if (search.params) {
        payload = { ...search.params, data: { ...payload } }
      }
      dispatch({ type: search.searchAction, payload: payload })
    }

    const onClear = () => {
      onSearch('')
    }

    const searchElement = showSearch == true ? <div><SidebarSearch clear={onClear} search={onSearch} /></div> : null

    const handleHomeClick = () => {
      if (match.path === '/' && (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER) && (scopeCustomerId || scopeRepresentativeId)) {
        dispatch({ type: REMOVE_SCOPED_CUSTOMER_ID })
        dispatch({ type: REMOVE_SCOPED_REPRESENTATIVE_ID })
      }
      history.push('/')
    }

    let containerClasses = 'Inner_content'
    if (showSearch) {
      containerClasses = containerClasses + ' with-search-toolbar'
    }

    return (
      <Container className={containerClasses} component='main' maxWidth='xl' fixed>
        {showSearch && <Grid item xs={12} className='SideBarSearchContainer'>
          <SearchToolbarContainer>
            <SidebarSearch clear={onClear} search={onSearch} />
          </SearchToolbarContainer>
                       </Grid>}
        <Grid container>
          <Grid item xs={1} className='position-relative'>
            <div className='button-postion-bottom   LeftSide '>
              <a href='javascript:void(0)' className='icon-size BackBtn'>
                <img onClick={handleBackClick} src='/assets/img/back.png' />
              </a>
            </div>

          </Grid>

          <Grid item xs={10} className='position-relative'>
            <div className='MainInnerContent position-absolute'>
              {<Component {...props} />}
            </div>
          </Grid>
          {<Grid item xs={1} className='position-relative'>
            {/* {searchElement} */}
            <div className='button-postion-bottom RightSide'>
              <a href='javascript:void(0)' className='icon-size HomeBtn'>
                <img onClick={() => handleHomeClick()} src='/assets/img/home.png' />
              </a>
            </div>

            {show &&
                            (<>
                              <div style={{ width: '50px', height: '50px', padding: '10px' }} className={'ml-1 w-100 BorderPrimary dflex align-items-center justify-content-center text-center ' + (dateWidgetData && !dateWidgetData.online ? 'refresh-disabled' : '')}>
                                <a href='javascript:void(0)' className='dflex'><img style={{ cursor: 'pointer', width: '32px', height: '32px', display: 'block' }} onClick={() => dateWidgetData && dateWidgetData.online ? handleVariableReferesh() : false} src='/assets/img/refresh.png' /></a>
                              </div>
                            </>
                            )}

           </Grid>}
        </Grid>
      </Container>
    )
  }
  return SideBarWrapper
}

export default Sidebars
