export const BELT_SETTINGS_READ_ERROR_LIST_REQUEST = 'BELT_SETTINGS_READ_ERROR_LIST_REQUEST'
export const BELT_SETTINGS_READ_ERROR_LIST_SUCCESS = 'BELT_SETTINGS_READ_ERROR_LIST_SUCCESS'
export const BELT_SETTINGS_READ_ERROR_LIST_FAILURE = 'BELT_SETTINGS_READ_ERROR_LIST_FAILURE'

export const BELT_SETTINGS_ACCESS_LIST_REQUEST = 'BELT_SETTINGS_ACCESS_LIST_REQUEST'
export const BELT_SETTINGS_ACCESS_LIST_SUCCESS = 'BELT_SETTINGS_ACCESS_LIST_SUCCESS'
export const BELT_SETTINGS_ACCESS_LIST_FAILURE = 'BELT_SETTINGS_ACCESS_LIST_FAILURE'

export const BELT_SETTINGS_VARIABLE_LIST_REQUEST = 'BELT_SETTINGS_VARIABLE_LIST_REQUEST'
export const BELT_SETTINGS_VARIABLE_LIST_SUCCESS = 'BELT_SETTINGS_VARIABLE_LIST_SUCCESS'
export const BELT_SETTINGS_VARIABLE_LIST_FAILURE = 'BELT_SETTINGS_VARIABLE_LIST_FAILURE'

export const BELT_SETTINGS_MEASUREMENT_LIST_REQUEST = 'BELT_SETTINGS_MEASUREMENT_LIST_REQUEST'
export const BELT_SETTINGS_MEASUREMENT_LIST_SUCCESS = 'BELT_SETTINGS_MEASUREMENT_LIST_SUCCESS'
export const BELT_SETTINGS_MEASUREMENT_LIST_FAILURE = 'BELT_SETTINGS_MEASUREMENT_LIST_FAILURE'

export const BELT_SETTINGS_MEASUREMENT_RUN_REQUEST = 'BELT_SETTINGS_MEASUREMENT_RUN_REQUEST'
export const BELT_SETTINGS_MEASUREMENT_RUN_SUCCESS = 'BELT_SETTINGS_MEASUREMENT_RUN_SUCCESS'
export const BELT_SETTINGS_MEASUREMENT_RUN_FAILURE = 'BELT_SETTINGS_MEASUREMENT_RUN_FAILURE'

export const BELT_SETTINGS_BELT_DAMAGE_LIST_REQUEST = 'BELT_SETTINGS_BELT_DAMAGE_LIST_REQUEST'
export const BELT_SETTINGS_BELT_DAMAGE_LIST_SUCCESS = 'BELT_SETTINGS_BELT_DAMAGE_LIST_SUCCESS'
export const BELT_SETTINGS_BELT_DAMAGE_LIST_FAILURE = 'BELT_SETTINGS_BELT_DAMAGE_LIST_FAILURE'

export const BELT_SETTINGS_SOFTWARE_UPDATE_REQUEST = 'BELT_SETTINGS_SOFTWARE_UPDATE_LIST_REQUEST'
export const BELT_SETTINGS_SOFTWARE_UPDATE_SUCCESS = 'BELT_SETTINGS_SOFTWARE_UPDATE_LIST_SUCCESS'
export const BELT_SETTINGS_SOFTWARE_UPDATE_FAILURE = 'BELT_SETTINGS_SOFTWARE_UPDATE_LIST_FAILURE'

export const SETTINGS_JOB_REQUEST = 'SETTINGS_JOB_REQUEST'
export const SETTINGS_JOB_SUCCESS = 'SETTINGS_JOB_SUCCESS'
export const SETTINGS_JOB_FAILURE = 'SETTINGS_JOB_FAILURE'
export const SETTINGS_JOB_CLEAR = 'SETTINGS_JOB_CLEAR'

export const SETTINGS_GET_REQUESTED_FILE_REQUEST = 'SETTINGS_GET_REQUESTED_FILE_REQUEST'
export const SETTINGS_GET_REQUESTED_FILE_SUCCESS = 'SETTINGS_GET_REQUESTED_FILE_SUCCESS'
export const SETTINGS_GET_REQUESTED_FILE_FAILURE = 'SETTINGS_GET_REQUESTED_FILE_FAILURE'
export const SETTINGS_INTERVAL_CLEARED_SUCCESS = 'SETTINGS_INTERVAL_CLEARED_SUCCESS'
export const SETTINGS_INTERVAL_CLEARED_FAILURE = 'SETTINGS_INTERVAL_CLEARED_FAILURE'

export const SETTINGS_CLEAR_FILE_STATUS = 'SETTINGS_CLEAR_FILE_STATUS'
