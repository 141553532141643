import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import theme from '../../theme'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

import { REPRESENTATION_CUSTOMER_LIST_REQUEST } from '../../store/representationCustomer/representationCustomerActions'

import Pagination from '@material-ui/lab/Pagination'
import Loader from '../../components/Ux/Loader'
import { toast } from 'react-toastify'
import useTogglePageSearch from '../../components/hooks/useTogglePageSearch'
import ReadMoreReact from 'read-more-react'
import {
  SET_SCOPED_CUSTOMER_ID
  , REMOVE_SCOPED_CUSTOMER_ID
} from '../../store/users/userActions'

import { SERVICE_USER, SUPER_USER } from '../../constants/roles'
const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}))

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
})

const ListRepresentativeCustomers = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const routerHistory = useHistory()
  const dispatch = useDispatch()
  const isMount = useRef(false)
  const isRepresentativeMount = useRef(false)
  const isCustomerScopeMount = useRef(false)
  const prevSearch = useRef('')
  useSetParentCustomInnerHeaderHeading()
  const [showLoader, setShowLoader] = useState(false)
  const search = useSelector((state) => state.utility.search)
  const customerList = useSelector((state) => state.representationCustomer)
  const profile = useSelector((state) => state.user.profile)
  const isReadMoreClick = useRef({})
  const readMoreState = useRef({})
  const { scopeRepresentativeId, scopeCustomerId } = useSelector((state) => state.user)
  const { error, representationCustomers, perPage, page, totalPages } = customerList
  const actionsClasses = useActions()
  let id = profile.representation_id
  if (scopeRepresentativeId) {
    id = scopeRepresentativeId
  }
  useTogglePageSearch(REPRESENTATION_CUSTOMER_LIST_REQUEST, { id }, 'name_or_facility_or_street_or_city_or_country', 'name asc')
  useEffect(() => {
    if (isCustomerScopeMount.current) {
      setShowLoader(false)
      routerHistory.replace('/')
    } else {
      isCustomerScopeMount.current = true
    }
  }, [scopeCustomerId])

  // Dispatching action to fetch  customer list
  useEffect(() => {
    localStorage.removeItem('isRepresentativeSuperAccess')
    setShowLoader(true)
    let id = profile.representation_id
    if (scopeRepresentativeId) {
      id = scopeRepresentativeId
    }
    dispatch({
      type: REPRESENTATION_CUSTOMER_LIST_REQUEST,
      payload: { id, data: { per_page: perPage, page, 'q[sorts]': 'name asc' } }
    })
    dispatch({ type: REMOVE_SCOPED_CUSTOMER_ID })
  }, [])

  useEffect(() => {
    if (isMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isRepresentativeMount.current) {
      setShowLoader(false)
      readMoreState.current = {}
      isReadMoreClick.current = {}
    } else {
      isRepresentativeMount.current = true
    }
  }, [representationCustomers])

  useEffect(() => {
    if (search.searchText) {
      prevSearch.current = search.searchText
      setShowLoader(true)
      return
    }

    if (!search.searchText && prevSearch.current) {
      setShowLoader(true)
    }
  }, [search])

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage, 'q[sorts]': 'name asc' }
    if (search.searchText) {
      payload['q[name_or_facility_or_street_or_city_or_country_cont]'] = search.searchText
    }
    setShowLoader(true)
    let id = profile.representation_id
    if (scopeRepresentativeId) {
      id = scopeRepresentativeId
    }
    dispatch({
      type: REPRESENTATION_CUSTOMER_LIST_REQUEST,
      payload: { id, data: { ...payload } }
    })
  }

  const handleRepresentationClick = (id) => {
    setShowLoader(true)
    if (profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)) {
      localStorage.setItem('isRepresentativeSuperAccess', scopeRepresentativeId)
    }
    dispatch({ type: SET_SCOPED_CUSTOMER_ID, payload: id })
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <CardContent>
        <Loader open={showLoader} />
        <Grid container className='MainContInnerStyle' alignContent='space-between'>
          <Grid item xs='12' className='Inner_scroll'>
            <div className='Inner_scroll-con Pr-2'>
              {representationCustomers.map((customer) => (
                <Grid container direction='row' justifyContent='flex-end' key={customer.id} className='RowData '>
                  <Grid item xs={1} className='left-Con'>
                    <div />
                  </Grid>
                  <Grid item xs={9} className='Mid-Con'>
                    <strong>
                      {customer && customer.name &&

                        <span
                          style={{ cursor: 'pointer' }} onClick={() => {
                            !isReadMoreClick.current[customer.id] && handleRepresentationClick(customer.id)

                            if (readMoreState.current[customer.id] === 'open') {
                              isReadMoreClick.current[customer.id] = false
                              readMoreState.current[customer.id] = 'closed'
                            }

                            if (isReadMoreClick.current[customer.id]) {
                              readMoreState.current[customer.id] = 'open'
                            }
                          }} href='javascipt:void(0)'
                        >

                          <ReadMoreReact
                            text={customer.name}
                            min={50}
                            ideal={50}
                            max={50}
                            readMoreText={
                              <div onClick={(e) => { e.preventDefault(); isReadMoreClick.current[customer.id] = true }}>...
                              </div>
                            }
                          />
                        </span>}
                    </strong>
                    {customer && customer.facility && <ReadMoreReact
                      text={customer.facility}
                      min={50}
                      ideal={50}
                      max={50}
                      readMoreText='...'
                    />}
                    {customer && customer.facility ? null : <br />}
                    <div>
                      {customer && customer.street && <ReadMoreReact
                        text={customer.street}
                        min={50}
                        ideal={50}
                        max={50}
                        readMoreText='...'
                                                      />}
                    </div>
                    <div>
                      {customer && customer.city && <ReadMoreReact
                        text={customer.city}
                        min={50}
                        ideal={50}
                        max={50}
                        readMoreText='...'
                                                    />}
                    </div>
                    <div>
                      {customer && customer.country && <ReadMoreReact
                        text={customer.country}
                        min={50}
                        ideal={50}
                        max={50}
                        readMoreText='...'
                                                       />}
                    </div>

                  </Grid>
                  <Grid item xs={2} className='Last-Con'>
                    <div />

                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        {!representationCustomers.length && (
          <div
            style={{
              padding: '1rem',
              textAlign: 'center',
              height: '100%',
              position: 'absolute',
              width: '100%',
              top: '0',
              right: '0',
              display: 'flex',
              alignContent: 'center',
              fontSize: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {t('noRecordsFound')}
          </div>
        )}
      </CardContent>

      <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
        <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
          {representationCustomers.length && totalPages ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
        </Box>
      </CardActions>
    </Card>
  )
}

export default Sidebars(ListRepresentativeCustomers, { showSearch: true })
