export const MESSAGE_EVENTS_LIST_REQUEST = 'MESSAGES_EVENTS_LIST_REQUEST'
export const MESSAGE_EVENTS_LIST_SUCCESS = 'MESSAGES_EVENTS_LIST_SUCCESS'
export const MESSAGE_EVENTS_LIST_FAILURE = 'MESSAGES_EVENTS_LIST_FAILURE'

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAILURE = 'MESSAGE_LIST_FAILURE'

export const MESSAGE_FAULTS_STATUS_REQUEST = 'MESSAGE_FAULTS_STATUS_REQUEST'
export const MESSAGE_FAULTS_STATUS_SUCCESS = 'MESSAGE_FAULTS_STATUS_SUCCESS'
export const MESSAGE_FAULTS_STATUS_FAILURE = 'MESSAGE_FAULTS_STATUS_FAILURE'

export const MESSAGE_BULK_READ_REQUEST = 'MESSAGE_BULK_READ_REQUEST'
export const MESSAGE_BULK_READ_SUCCESS = 'MESSAGE_BULK_READ_SUCCESS'
export const MESSAGE_BULK_READ_FAILURE = 'MESSAGE_BULK_READ_FAILURE'

export const MESSAGE_SCRAPER_LIST_REQUEST = 'MESSAGE_SCRAPER_LIST_REQUEST'
export const MESSAGE_SCRAPER_LIST_SUCCESS = 'MESSAGE_SCRAPER_LIST_SUCCESS'
export const MESSAGE_SCRAPER_LIST_FAILURE = 'MESSAGE_SCRAPER_LIST_FAILURE'
