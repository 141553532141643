import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import Loader from '../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCustomerAreas } from '../../apis/customer-areas'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const AddCustomer = () => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const actionsClasses = useActions()
  useSetParentCustomInnerHeaderHeading()
  const handleSubmit = async (formData) => {
    try {
      if (formData && formData.name) {
        formData.name = formData.name.trim()
      }
      const res = await addCustomerAreas(params.id, formData)
      if (res.status === 201) {
        setShowLoader(false)
        history.push('../')
        toast.success(t('add_area_customer_controls.toast.area_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 404) {
        message = error.response.data.message || error.message
      }
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message || error.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <Loader open={showLoader} />

        <SubmitForm
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
          autoComplete='off'
        >
          <CardContent>
            <div className={classes.paper}>
              <div>
                <TextInput
                  validations={{
                    required: {
                      message: t('add_area_customer_controls.validation.area_required')
                    },
                    maxLength: {
                      maxLength: 35,
                      message: t('add_area_customer_controls.validation.area_max_length')
                    }
                  }}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='name'
                  label={t('add_area_customer_controls.name')}
                  type='text'
                />
              </div>
            </div>
          </CardContent>
          <CardActions className='ButtonPanelWrap ButtonPanelWrap-2'>
            <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-end' }}>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('add_area_customer_controls.save')}
              </Button>
            </Box>
          </CardActions>
        </SubmitForm>
      </Card>
    </>
  )
}

export default Sidebars(AddCustomer)
