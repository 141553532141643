import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import {
  CUSTOMER_USER_LIST_REQUEST,
  CUSTOMER_USER_DELETE_REQUEST,
  CUSTOMER_USER_UPDATE_REQUEST
} from '../../store/customerUser/customerUserActions'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    paddingBottom: '6em',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '45px'
    }
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
}))

const CustomerUser = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const { customer } = useSelector((state) => state.customer)
  const [userId, setUserId] = useState(null)
  const [newCustomerUsers, setNewCustomerUsers] = useState([])
  const [openRoleChangeDialog, setOpenRoleChangeDialog] = useState(false)
  const [updateRoleValues, setUpdateRoleValues] = useState({})
  const isMount = useRef(false)
  const customerUserList = useSelector((state) => state.customerUser)
  const { loading, error, customerUsers, perPage, page, totalPages, deletedId } = customerUserList
  useSetParentCustomInnerHeaderHeading()

  // On Component Mount getting the customer data
  useEffect(() => {
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
  }, [])

  useEffect(() => {
    dispatch({ type: CUSTOMER_USER_LIST_REQUEST, payload: { id: params.id, data: { per_page: perPage, page } } })
  }, [dispatch])

  useEffect(() => {
    setNewCustomerUsers(customerUsers)
  }, [setNewCustomerUsers, customerUsers])

  const handleRoleChange = (e, i, user) => {
    if (user.role) {
      setOpenRoleChangeDialog(true)
      setUpdateRoleValues({
        value: e.currentTarget.value,
        i,
        user
      })
      return
    }
    updateRoleChange(e.currentTarget.value, i, user.id)
  }

  const updateRoleChange = (value, i, id) => {
    const duplicateCustomerUsers = [...newCustomerUsers]
    duplicateCustomerUsers[i].role = value
    setNewCustomerUsers(duplicateCustomerUsers)
    dispatch({
      type: CUSTOMER_USER_UPDATE_REQUEST,
      payload: { id, customerUser: { role: value } }
    })
  }

  // Handling error and displaying if comes during api call.
  useEffect(() => {
    if (isMount.current && error) {
      setUserId(null)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (deletedId && userId) {
      setUserId(null)
      dispatch({
        type: CUSTOMER_USER_LIST_REQUEST,
        payload: { id: params.id, data: { per_page: perPage, page: 1 } }
      })
      toast.success(t('list_customer_users.toast.user_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [deletedId])

  const handleConfirmDeleteUser = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      dispatch({
        type: CUSTOMER_USER_DELETE_REQUEST,
        payload: { id: userId }
      })
    } else {
      setUserId(null)
    }
  }

  const handleDeleteUser = (id) => {
    setOpenDialog(true)
    setUserId(id)
  }

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage }
    dispatch({
      type: CUSTOMER_USER_LIST_REQUEST,
      payload: {
        id: params.id,
        data: {
          ...payload
        }
      }
    })
  }

  // navigates to edit user
  const editUser = (user) => () => history.push(`./${user.id}/edit`)

  const handleConfirmRoleChange = (e) => {
    setOpenRoleChangeDialog(false)
    if (e.currentTarget.value === 'true') {
      updateRoleChange(updateRoleValues.value, updateRoleValues.i, updateRoleValues.user.id)
    }
    setUpdateRoleValues({})
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'}>
        <CardContent>
          <Loader open={loading} />
          <ContentTitle title={t('list_customer_users.title_singular')} />
          <ContentInfoTitle type={t('list_customer.title_singular')} name={customer && customer.name} no={customer && customer.customer_nr} />
          <Grid container alignContent='space-between' className={classes.table + ' MainContInnerStyleTwo list-user'}>
            <Grid container direction='row' justifyContent='space-between' alignItems='stretch' className='RowDataHead pr-1-5 pl-1-5'>
              <Grid item xs={2} className='TextLeft flex-fill' />

              <Grid item xs={7} className='TextCenter'><strong>{t('list_customer_users.email')}</strong>
              </Grid>

              <Grid item xs={1} className='TextCenter user-col '><strong>{t('list_customer_users.topUser')}</strong>
              </Grid>

              <Grid item xs={1} className='TextCenter user-col'><strong>{t('list_customer_users.user')}</strong>
              </Grid>
              <Grid item xs={1} className='TextCenter cancel-icon-col' />
            </Grid>

            {(newCustomerUsers && newCustomerUsers.length > 0)
              ? (<Grid item xs='12' className='Inner_scrollTwo' style={{ paddingBottom: '30px' }}>
                <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
                  {newCustomerUsers.map((user, i) => (
                    <Grid
                      container key={user.id} direction='row' justifyContent='space-between'
                      alignItems='stretch' className='RowData TokenUpRow list-user-Area'
                    >
                      <Grid item xs={3} className='TextLeft  WordWrap' onClick={editUser(user)} style={{ cursor: 'pointer' }}>
                        <div style={{ display: 'inline-block' }}>
                          <div style={{ display: 'inline-block', fontWeight: 'bold', whiteSpace: 'nowrap', maxWidth: '25ch', overflow: 'hidden' }}>
                            {user.first_name}<>&nbsp;</>
                          </div>
                          <div style={{ display: 'inline-block', fontWeight: 'bold', whiteSpace: 'nowrap', maxWidth: '25ch', overflow: 'hidden' }}>
                            {user.last_name}
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        style={{
                          wordBreak: 'break-all',
                          cursor: 'pointer'
                        }}
                        onClick={editUser(user)}
                        item xs={6} className='TextLeft pl-0-5 '
                      >
                        <span className={user.pending_invitation ? 'invitation-email-pending' : 'invitation-email-accepted'}>
                          {user.email ? user.email : ''}
                        </span>
                      </Grid>

                      <Grid item xs={1} className='TextCenter user-col ' style={{ paddingRight: '10px' }}>
                        <Radio
                          className=' margin-auto ml-1'
                          checked={user.role === 'top_user'}
                          onChange={(e) => handleRoleChange(e, i, user)}
                          value='top_user'
                          name='role'
                        />

                      </Grid>

                      <Grid item xs={1} className='TextCenter user-col ' style={{ paddingRight: '10px' }}>
                        <Radio
                          className=' margin-auto'
                          checked={user.role === 'user'}
                          onChange={(e) => handleRoleChange(e, i, user)}
                          value='user'
                          name='role'
                        />

                      </Grid>
                      <Grid item xs={1} className='TextCenter cancel-icon cancel-icon-col TextLeft dflex align-items-center justify-content-center '>
                        <img onClick={() => handleDeleteUser(user.id)} src='/assets/img/cancel.png' />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>) : null}

          </Grid>
          {!newCustomerUsers || !newCustomerUsers.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2' style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box className={classes.addButton + ' ButtonPanel pl-1 pr-1'}>

            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./add/')}
              type='button'
              variant='outlined'
            >
              <img src='/assets/img/plus.png' />&nbsp;&nbsp;
              {t('list_customer_users.newUser')}
            </Button>

            {newCustomerUsers.length && totalPages
              ? <Pagination
                className='Pagination'
                page={page}
                onChange={onPageChange}
                count={totalPages}
                showFirstButton
                showLastButton
              /> : null}

            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./areas/')}
              type='button'
              variant='outlined'
            >
              <img src='/assets/img/sport.png' />&nbsp;&nbsp;
              {t('list_customer_users.areaAllocation')}
            </Button>

          </Box>
        </CardActions>
      </Card>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteUser} />
      <PopupDialog open={openRoleChangeDialog} onClick={handleConfirmRoleChange} />
    </>
  )
}

export default Sidebars(CustomerUser, { showSearch: true })
