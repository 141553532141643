import axios from 'axios'
import apiConfig from './api-constants'
const request = axios.create({
  baseURL: apiConfig.baseUrl
})

request.interceptors.request.use(function (config) {
  const user = localStorage.getItem('user')

    ? JSON.parse(localStorage.getItem('user'))
    : null
  if (user) {
    config.headers.Authorization = 'Bearer ' + user.token
  }
  config.headers.ContentType = 'application/json'
  if (config.method.toUpperCase() === 'GET') {
    const customerId = localStorage.getItem('scope_customer_id')
    const urlToken = config.url.split('?')
    if (customerId && urlToken.length === 2) {
      if (config.url.endsWith('&')) {
        config.url += 'customer_id=' + customerId
      } else {
        config.url += '&customer_id=' + customerId
      }
    } else if (customerId && urlToken.length === 1) {
      config.url += '?customer_id=' + customerId
    }
  }
  return config
})

request.interceptors.response.use(undefined,
  (error) => {
    if (error.response &&
       error.response.status === 401 &&
       error.response.data &&
       error.response.data.message === 'token expired' &&
       localStorage.getItem('user')) {
      localStorage.setItem('sessionExpired', 'true')
      window.location.replace('/session-expired')
    }
    throw error
  }
)

export default request
