import * as representationActions from './representationActions'

const initialState = {
  loading: false,
  representations: [],
  representation: null,
  success: false,
  error: null,
  totalPages: 0,
  perPage: 10,
  page: 1,
  isDeleted: 0
}

const representation = (state = initialState, action) => {
  switch (action.type) {
    case representationActions.REPRESENTATION_LIST_REQUEST:
      return { ...state, loading: true }
    case representationActions.REPRESENTATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        representations: action.payload.representations.map((representation) => {
          return { ...representation }
        }),
        totalPages: action.payload.totalPages,
        perPage: action.payload.perPage,
        page: action.payload.page
      }
    case representationActions.REPRESENTATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        representations: [],
        error: action.error
      }
    case representationActions.GET_REPRESENTATION_REQUEST:
      return { ...state, loading: true }
    case representationActions.GET_REPRESENTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        representation: { ...action.representation }
      }
    case representationActions.GET_REPRESENTATION_FAILURE:
      return {
        ...state,
        loading: false,
        representation: null,
        error: action.error
      }
    case representationActions.REPRESENTATION_DELETE_REQUEST:
      return { ...state, loading: true }
    case representationActions.REPRESENTATION_DELETE_SUCCESS:
      return { ...state, loading: false, success: true, isDeleted: action.payload.id }
    case representationActions.REPRESENTATION_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }
    case representationActions.REPRESENTATION_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }
    default:
      return state
  }
}

export default representation
