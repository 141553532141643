import React, { useState, useEffect } from 'react'

export const useFetch = (url, options) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options)
        const json = await res.json()
        if (res.status >= 400) {
          setError(json)
        } else {
          setResponse(json)
        }
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [])

  return { response, error }
}

export default useFetch
