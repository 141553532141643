import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import { toast } from 'react-toastify'
import { REPRESENTATION_TOKEN_LIST_REQUEST, REPRESENTATION_TOKEN_DELETE_REQUEST } from '../../store/representationToken/representationTokenActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'
import PopupDialog from '../../components/PopupDialog'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { tokenRoles } from '../../constants/roles'
import Pagination from '@material-ui/lab/Pagination'
import ReadMoreReact from 'read-more-react'
import { GET_REPRESENTATION_REQUEST } from '../../store/representation/representationActions';

const useActionStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    width: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0fr 2fr 1fr'
    }
  }
}))

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    paddingBottom: '6em',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '45px'
    }
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const ListTokens = () => {
  const { tokens, error, isDeleted, isUpdated, perPage, page, totalPages } = useSelector((state) => state.representationToken)
  const tokenList = tokens ? tokens.filter(() => true).map((data) => ({ ...data })) : []
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const params = useParams()
  const actionsClasses = useActionStyles()
  const { representation } = useSelector((state) => state.representation)
  const dispatch = useDispatch()
  const isMount = useRef(false)
  const isMountSuccess = useRef(false)
  const id = useRef(0)
  const [showLoader, setShowLoader] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  useSetParentCustomInnerHeaderHeading()
  let noRecordsFound = null
  if (!tokenList || tokenList.length === 0) {
    noRecordsFound = (<div
      style={{
        padding: '1rem',
        textAlign: 'center',
        height: '100%',
        position: 'absolute',
        width: '100%',
        top: '0',
        right: '0',
        display: 'flex',
        alignContent: 'center',
        fontSize: '20px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
                      >
      {t('noRecordsFound')}
                      </div>)
  }

  // On Component Mount getting the customer data
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id: params.id } })
  }, [])

  // On Component mount getting token list according to the customer id
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: REPRESENTATION_TOKEN_LIST_REQUEST, payload: { id: params.id, data: { per_page: perPage, page } } })
  }, [])

  useEffect(() => {
    if (isDeleted && id.current) {
      id.current = 0
      setShowLoader(true)
      dispatch({ type: REPRESENTATION_TOKEN_LIST_REQUEST, payload: { id: params.id, data: { per_page: perPage, page: 1 } } })
      toast.success(t('list_user_tokens.toast.token_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [isDeleted])

  useEffect(() => {
    if (isUpdated) {
      id.current = 0
      setShowLoader(false)
      dispatch({ type: REPRESENTATION_TOKEN_LIST_REQUEST, payload: { id: params.id, data: { per_page: perPage, page } } })
      toast.success(t('list_user_tokens.toast.token_role_updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [isUpdated])

  useEffect(() => {
    if (isMountSuccess.current) {
      setShowLoader(false)
    } else {
      isMountSuccess.current = true
    }
  }, [tokens])

  // Handling error and displaying if comes during api call.
  useEffect(() => {
    if (isMount.current && error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
      id.current = 0
    } else {
      isMount.current = true
    }
  }, [error])

  const handleDeleteToken = (deleteId) => {
    setOpenDialog(true)
    id.current = deleteId
  }

  const handleConfirmDeleteControl = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      setShowLoader(true)
      dispatch({ type: REPRESENTATION_TOKEN_DELETE_REQUEST, payload: id.current })
    } else {
      id.current = 0
    }
  }

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage }
    setShowLoader(true)
    dispatch(
      {
        type: REPRESENTATION_TOKEN_LIST_REQUEST,
        payload:
          {
            id: params.id,
            data: { ...payload }
          }
      })
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>

      <CardContent>
        <ContentTitle title={t('list_user_tokens.title_singular')} />
        <ContentInfoTitle type={t('list_representation.title_singular')} name={representation && representation.name} no={representation && representation.representation_nr} />
        <Grid container alignContent='space-between' className={classes.table + ' MainContInnerStyleTwo  pt-1 '} style={{ paddingBottom: '45px' }}>
          <Grid container direction='row' alignItems='stretch' className='RowDataHead  RowHeadwrap' justifyContent='space-between'>

            <Grid item xs={2} className='TextLeft dflex align-items-center' />

            <Grid item xs={3} style={{ paddingLeft: '35px' }} className=' dflex align-items-center TextCenter justify-content-center flex-fill'><strong>{t('list_user_tokens.keyNo')}</strong>
            </Grid>

            <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center  text-center flex-fill '><strong>{t('list_user_tokens.userLevel')}</strong>
            </Grid>

            <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center  text-center flex-fil'><strong>{t('list_user_tokens.uid')}</strong>
            </Grid>
            <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center pl-0 pr-2 position-relative  text-center flex-fill' style={{ paddingRight: '30px' }}><strong>{t('list_user_tokens.role')}</strong>
            </Grid>
            <Grid item xs={1} className='flex-fill border-left-0 TextCenter cancel-icon cancel-iconStyle TextLeft dflex align-items-center justify-content-center cancel-icon-col' />
          </Grid>
          <Grid item xs='12' className='Inner_scrollTwo pb-0'>

            <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
              {tokenList.map((token) => (
                <Grid container key={token.id} direction='row' alignItems='stretch' justifyContent='space-between' className='RowData TokenUpRow '>
                  <Grid item xs={2} className='TextLeft dflex align-items-center WordWrap '>
                    <strong>
                      {token && <ReadMoreReact
                        text={token.name ? token.name : ''}
                        min={20}
                        max={20}
                        ideal={20}
                        readMoreText='...'
                      />}
                    </strong>
                  </Grid>

                  <Grid item xs={3} className=' dflex align-items-center TextCenter justify-content-center flex-fill'>
                    {token.key_number}
                  </Grid>

                  <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center  text-center flex-fill '>
                    {token.user_level}
                  </Grid>

                  <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center  text-center flex-fill'>
                    {token.uid}
                  </Grid>

                  <Grid item xs={2} className='TextCenter dflex align-items-center justify-content-center  text-center flex-fill'>
                    {token && token.role && tokenRoles.find(role => role.id === token.role) ? tokenRoles.find(role => role.id === token.role).name : '-'}
                  </Grid>
                  <Grid item xs={1} className='flex-fill TextCenter cancel-icon cancel-iconStyle TextLeft dflex align-items-center justify-content-center cancel-icon-col'>
                    <img onClick={() => handleDeleteToken(token.id)} src='/assets/img/cancel.png' />
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        {!showLoader ? noRecordsFound : <Loader open />}
      </CardContent>

      <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2' style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
          <div />
          {tokenList.length && totalPages ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
          <Button
            style={{ marginRight: '0.625rem' }}
            onClick={() => history.push('./add/')}
            type='button'
            variant='outlined'
          >
            <img src='/assets/img/plus.png' />&nbsp;&nbsp;
            {t('list_user_tokens.addToken')}
          </Button>
        </Box>
      </CardActions>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteControl} />
    </Card>
  )
}

export default Sidebars(ListTokens)
