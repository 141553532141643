import React from 'react'
import { useTranslation } from 'react-i18next'
import { disabledIcon, activeIcon, inactiveIcon } from './Icons'

export const ScraperStatusIndicator = ({ status, showText, available }) => {
  const { t } = useTranslation()

  if (!available) {
    return disabledIcon
  } else if (status === 0) {
    return disabledIcon
  } else if (status === 1) {
    return activeIcon
  } else if (status === 2) {
    return inactiveIcon
  } else {
    return null
  }
}

export default ScraperStatusIndicator
