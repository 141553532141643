export const GET_DAMAGE_MEASUREMENT_CHART_REQUEST = 'GET_DAMAGE_MEASUREMENT_CHART_REQUEST'
export const GET_DAMAGE_MEASUREMENT_CHART_SUCCESS = 'GET_DAMAGE_MEASUREMENT_CHART_SUCCESS'
export const GET_DAMAGE_MEASUREMENT_CHART_FAILURE = 'GET_DAMAGE_MEASUREMENT_CHART_FAILURE'

export const GET_DAMAGE_EVENTS_CHART_REQUEST = 'GET_DAMAGE_EVENTS_CHART_REQUEST'
export const GET_DAMAGE_EVENTS_CHART_SUCCESS = 'GET_DAMAGE_EVENTS_CHART_SUCCESS'
export const GET_DAMAGE_EVENTS_CHART_FAILURE = 'GET_DAMAGE_EVENTS_CHART_FAILURE'

export const DELETE_DAMAGE_EVENTS_REQUEST = 'DELETE_DAMAGE_EVENTS_REQUEST'
export const DELETE_DAMAGE_EVENTS_SUCCESS = 'DELETE_DAMAGE_EVENTS_SUCCESS'
export const DELETE_DAMAGE_EVENTS_FAILURE = 'DELETE_DAMAGE_EVENTS_FAILURE'

export const MARK_READ_EVENTS_REQUEST = 'MARK_READ_EVENTS_REQUEST'
export const MARK_READ_EVENTS_SUCCESS = 'MARK_READ_EVENTS_SUCCESS'
export const MARK_READ_EVENTS_FAILURE = 'MARK_READ_EVENTS_FAILURE'
