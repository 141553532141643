import request from './request'

export function getEvents (params, data) {
  if (data) {
    return request.get('events?' + params, { params: data })
  }
  return request.get('events?' + params)
}

export function deleteEvents (params) {
  return request.delete('events?' + params)
}

export function getEventTypes (params) {
  if (params) {
    return request.get('event_types', { params })
  }
  return request.get('event_types')
}

export function getFaults () {
  return request.get('faults')
}

export function markEventsAsRead (payload) {
  return request.post('events/bulk_read', payload)
}
