import { useEffect } from 'react'
import { SHOW_DASHBOARD_WIDGETS, HIDE_DASHBOARD_WIDGETS } from '../../store/utility/utilityActions'
import { useDispatch } from 'react-redux'
function useToggleDashboardWidgets () {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch({ type: HIDE_DASHBOARD_WIDGETS })
    }
  }, [])
  return (dateWidgetPayload, dashboardModuleId) => {
    dispatch({ type: SHOW_DASHBOARD_WIDGETS, payload: { dateWidgetPayload, dashboardModuleId } })
  }
}

export default useToggleDashboardWidgets
