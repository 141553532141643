export const BELT_CONVEYOR = 'BELT_CONVEYOR'

export const BELT_CONVEYOR_CONTROL_TITLE = 'BELT_CONVEYOR_CONTROL_TITLE'
export const BELT_CONVEYOR_CONTROL_1 = 'BELT_CONVEYOR_CONTROL_1'
export const BELT_CONVEYOR_CONTROL_2 = 'BELT_CONVEYOR_CONTROL_2'
export const BELT_CONVEYOR_CONTROL_3 = 'BELT_CONVEYOR_CONTROL_3'

export const BELT_CONVEYOR_SCRAPERS_LIST = 'BELT_CONVEYOR_SCRAPERS_LIST'
export const BELT_CONVEYOR_SCRAPERS_1_INFO = 'BELT_CONVEYOR_SCRAPERS_1_INFO'
export const BELT_CONVEYOR_SCRAPERS_2_INFO = 'BELT_CONVEYOR_SCRAPERS_2_INFO'
export const BELT_CONVEYOR_SCRAPERS_3_INFO = 'BELT_CONVEYOR_SCRAPERS_3_INFO'
export const BELT_CONVEYOR_SCRAPERS_4_INFO = 'BELT_CONVEYOR_SCRAPERS_4_INFO'

export const BELT_CONVEYOR_SCRAPERS_1_SEGMENT = 'BELT_CONVEYOR_SCRAPERS_1_SEGMENT'
export const BELT_CONVEYOR_SCRAPERS_2_SEGMENT = 'BELT_CONVEYOR_SCRAPERS_2_SEGMENT'
export const BELT_CONVEYOR_SCRAPERS_3_SEGMENT = 'BELT_CONVEYOR_SCRAPERS_3_SEGMENT'
export const BELT_CONVEYOR_SCRAPERS_4_SEGMENT = 'BELT_CONVEYOR_SCRAPERS_4_SEGMENT'

export const BELT_CONVEYOR_SCRAPERS_1_DRIVE = 'BELT_CONVEYOR_SCRAPERS_1_DRIVE'
export const BELT_CONVEYOR_SCRAPERS_2_DRIVE = 'BELT_CONVEYOR_SCRAPERS_2_DRIVE'
export const BELT_CONVEYOR_SCRAPERS_3_DRIVE = 'BELT_CONVEYOR_SCRAPERS_3_DRIVE'
export const BELT_CONVEYOR_SCRAPERS_4_DRIVE = 'BELT_CONVEYOR_SCRAPERS_4_DRIVE'

export const BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS = 'BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS'
export const BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS = 'BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS'
export const BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS = 'BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS'
export const BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS = 'BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS'

export const BELT_CONVEYOR_SETTINGS = 'BELT_CONVEYOR_SETTINGS'
export const BELT_CONVEYOR_SETTINGS_SCRAPER_1 = 'BELT_CONVEYOR_SETTINGS_SCRAPER_1'
export const BELT_CONVEYOR_SETTINGS_SCRAPER_2 = 'BELT_CONVEYOR_SETTINGS_SCRAPER_2'
export const BELT_CONVEYOR_SETTINGS_SCRAPER_3 = 'BELT_CONVEYOR_SETTINGS_SCRAPER_3'
export const BELT_CONVEYOR_SETTINGS_SCRAPER_4 = 'BELT_CONVEYOR_SETTINGS_SCRAPER_4'

export const BELT_CONVEYOR_SCRAPER_1_NO = 601
export const BELT_CONVEYOR_SCRAPER_2_NO = 701
export const BELT_CONVEYOR_SCRAPER_3_NO = 801
export const BELT_CONVEYOR_SCRAPER_4_NO = 901

export const BELT_CONVEYOR_SCRAPPER_SEGMENT_MAP = 'BELT_CONVEYOR_SCRAPPER_SEGMENT_MAP'

export const BELT_CONVEYOR_SCRAPER_1_SEGMENT_CHART = 654
export const BELT_CONVEYOR_SCRAPER_2_SEGMENT_CHART = 754
export const BELT_CONVEYOR_SCRAPER_3_SEGMENT_CHART = 854
export const BELT_CONVEYOR_SCRAPER_4_SEGMENT_CHART = 954

export const BELT_CONVEYOR_SCRAPER_SUBGROUP_INFO = 'BELT_CONVEYOR_SCRAPER_SUBGROUP_INFO'
export const BELT_CONVEYOR_SCRAPER_SUBGROUP_SEGMENT = 'BELT_CONVEYOR_SCRAPER_SUBGROUP_SEGMENT'
export const BELT_CONVEYOR_SCRAPER_SUBGROUP_DRIVE = 'BELT_CONVEYOR_SCRAPER_SUBGROUP_DRIVE'
export const BELT_CONVEYOR_SCRAPER_SUBGROUP_CURVE = 'BELT_CONVEYOR_SCRAPER_SUBGROUP_CURVE'

export const BELT_CONVEYOR_SCRAPERS_1_MESSAGES_QUERY = 'q[event_type_id_lt]=8&q[var_nr_gt]=600&q[var_nr_lt]=700&q[field_module_id_eq]='
export const BELT_CONVEYOR_SCRAPERS_2_MESSAGES_QUERY = 'q[event_type_id_lt]=8&q[var_nr_gt]=700&q[var_nr_lt]=800&q[field_module_id_eq]='
export const BELT_CONVEYOR_SCRAPERS_3_MESSAGES_QUERY = 'q[event_type_id_lt]=8&q[var_nr_gt]=800&q[var_nr_lt]=900&q[field_module_id_eq]='
export const BELT_CONVEYOR_SCRAPERS_4_MESSAGES_QUERY = 'q[event_type_id_lt]=8&q[var_nr_gt]=900&q[var_nr_lt]=1000&q[field_module_id_eq]='

//  Belt Conveyor Dashboard

export const BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS = 'BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS'
export const BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS = 'BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS'
export const BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS_VAR_NO = 540

export const BELT_CONVEYOR_DASHBOARD_COUNTERCLOCLWISE_ROTATION = 'BELT_CONVEYOR_DASHBOARD_COUNTERCLOCLWISE_ROTATION'

export const BELT_CONVEYOR_DASHBOARD_CLOCKWISE_ROTATION = 'BELT_CONVEYOR_DASHBOARD_CLOCKWISE_ROTATION'

export const BELT_CONVEYOR_DASHBOARD_SCRAPER_1 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_1'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_1_SUBSECTION_1 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_1_SUBSECTION_1'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS_VAR_NO = 663

export const BELT_CONVEYOR_DASHBOARD_SCRAPER_2 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_2'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_2_SUBSECTION_1 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_2_SUBSECTION_1'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS_VAR_NO = 763

export const BELT_CONVEYOR_DASHBOARD_SCRAPER_3 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_3'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_3_SUBSECTION_1 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_3_SUBSECTION_1'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS_VAR_NO = 863

export const BELT_CONVEYOR_DASHBOARD_SCRAPER_4 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_4'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_4_SUBSECTION_1 = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_4_SUBSECTION_1'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS = 'BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS'
export const BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS_VAR_NO = 963

export const BELT_CONVEYOR_DASHBOARD_CONTROL = 'BELT_CONVEYOR_DASHBOARD_CONTROL'

// Damage Contole Variables

export const BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_1 = 'BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_1'
export const BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_2 = 'BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_2'
export const BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_3 = 'BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_3'

export const VARIABLE_MAP = {
  [BELT_CONVEYOR]: [
    {
      varNo: 522,
      position: 1
    },
    {
      varNo: 10016,
      position: 2
    },
    {
      varNo: 10017,
      position: 3
    },
    {
      varNo: 521,
      position: 4
    },
    {
      varNo: 10003,
      position: 5
    },
    {
      varNo: 526,
      position: 7
    },
    {
      varNo: 525,
      position: 8
    },
    {
      varNo: 528,
      position: 10
    },
    {
      varNo: 527,
      position: 11
    }
  ],

  [BELT_CONVEYOR_CONTROL_TITLE]: 10001,
  [BELT_CONVEYOR_CONTROL_1]: [
    {
      varNo: 504,
      position: 1
    },
    {
      varNo: 505,
      position: 2
    },
    {
      varNo: 506,
      position: 2
    },
    {
      varNo: 507,
      position: 3
    },
    {
      varNo: 508,
      position: 4
    },
    {
      varNo: 509,
      position: 4
    }
  ],
  [BELT_CONVEYOR_CONTROL_2]: [
    {
      varNo: 510,
      position: 4
    },
    {
      varNo: 511,
      position: 5
    }
  ],
  [BELT_CONVEYOR_CONTROL_3]: [
    {
      varNo: 543,
      position: 6
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_LIST]: [
    {
      varNo: 601,
      position: 1
    },
    {
      varNo: 701,
      position: 2
    },
    {
      varNo: 801,
      position: 3
    },
    {
      varNo: 901,
      position: 4
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_1_INFO]: [
    {
      varNo: 10004,
      position: 1
    },
    {
      varNo: 10008,
      position: 2
    },
    {
      varNo: 602,
      position: 3
    },
    {
      varNo: 626,
      position: 4
    },
    {
      varNo: 625,
      position: 5
    },
    {
      varNo: 653,
      position: 6
    },
    {
      varNo: 605,
      position: 7
    },
    {
      varNo: 615,
      position: 8
    }

  ],
  [BELT_CONVEYOR_SCRAPERS_2_INFO]: [
    {
      varNo: 10005,
      position: 1
    },
    {
      varNo: 10009,
      position: 2
    },
    {
      varNo: 702,
      position: 3
    },
    {
      varNo: 726,
      position: 4
    },
    {
      varNo: 725,
      position: 5
    },
    {
      varNo: 753,
      position: 6
    },
    {
      varNo: 705,
      position: 7
    },
    {
      varNo: 715,
      position: 8
    }

  ],
  [BELT_CONVEYOR_SCRAPERS_3_INFO]: [
    {
      varNo: 10006,
      position: 1
    },
    {
      varNo: 10010,
      position: 2
    },
    {
      varNo: 802,
      position: 3
    },
    {
      varNo: 826,
      position: 4
    },
    {
      varNo: 825,
      position: 5
    },
    {
      varNo: 853,
      position: 6
    },
    {
      varNo: 805,
      position: 7
    },
    {
      varNo: 815,
      position: 8
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_4_INFO]: [
    {
      varNo: 10007,
      position: 1
    },
    {
      varNo: 10011,
      position: 2
    },
    {
      varNo: 902,
      position: 3
    },
    {
      varNo: 926,
      position: 4
    },
    {
      varNo: 925,
      position: 5
    },
    {
      varNo: 953,
      position: 6
    },
    {
      varNo: 905,
      position: 7
    },
    {
      varNo: 915,
      position: 8
    }
  ],

  [BELT_CONVEYOR_SCRAPERS_1_SEGMENT]: [
    {
      varNo: 603,
      position: 1
    },
    {
      varNo: 602,
      position: 2
    },

    {
      varNo: 628,
      position: 3
    },

    {
      varNo: 627,
      position: 4
    },
    {
      varNo: 654,
      position: 5
    }

  ],
  [BELT_CONVEYOR_SCRAPERS_2_SEGMENT]: [
    {
      varNo: 703,
      position: 1
    },
    {
      varNo: 702,
      position: 2
    },
    {
      varNo: 728,
      position: 3
    },
    {
      varNo: 727,
      position: 4
    },
    {
      varNo: 754,
      position: 5
    }

  ],
  [BELT_CONVEYOR_SCRAPERS_3_SEGMENT]: [
    {
      varNo: 803,
      position: 1
    },
    {
      varNo: 802,
      position: 2
    },
    {
      varNo: 828,
      position: 3
    },
    {
      varNo: 827,
      position: 4
    },
    {
      varNo: 854,
      position: 5
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_4_SEGMENT]: [
    {
      varNo: 903,
      position: 1
    },
    {
      varNo: 902,
      position: 2
    },
    {
      varNo: 928,
      position: 3
    },
    {
      varNo: 927,
      position: 4
    },
    {
      varNo: 954,
      position: 5
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_1_DRIVE]: [
    {
      varNo: 631,
      position: 1
    },
    {
      varNo: 632,
      position: 2
    },
    {
      varNo: 633,
      position: 3
    },
    {
      varNo: 634,
      position: 4
    },
    {
      varNo: 635,
      position: 5
    },
    {
      varNo: 636,
      position: 6
    },
    {
      varNo: 637,
      position: 7
    },
    {
      varNo: 638,
      position: 8
    },
    {
      varNo: 639,
      position: 9
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_2_DRIVE]: [
    {
      varNo: 731,
      position: 1
    },
    {
      varNo: 732,
      position: 2
    },
    {
      varNo: 733,
      position: 3
    },
    {
      varNo: 734,
      position: 4
    },
    {
      varNo: 735,
      position: 5
    },
    {
      varNo: 736,
      position: 6
    },
    {
      varNo: 737,
      position: 7
    },
    {
      varNo: 738,
      position: 8
    },
    {
      varNo: 739,
      position: 9
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_3_DRIVE]: [
    {
      varNo: 831,
      position: 1
    },
    {
      varNo: 832,
      position: 2
    },
    {
      varNo: 833,
      position: 3
    },
    {
      varNo: 834,
      position: 4
    },
    {
      varNo: 835,
      position: 5
    },
    {
      varNo: 836,
      position: 6
    },
    {
      varNo: 837,
      position: 7
    },
    {
      varNo: 838,
      position: 8
    },
    {
      varNo: 839,
      position: 9
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_4_DRIVE]: [
    {
      varNo: 931,
      position: 1
    },
    {
      varNo: 932,
      position: 2
    },
    {
      varNo: 933,
      position: 3
    },
    {
      varNo: 934,
      position: 4
    },
    {
      varNo: 935,
      position: 5
    },
    {
      varNo: 936,
      position: 6
    },
    {
      varNo: 937,
      position: 7
    },
    {
      varNo: 938,
      position: 8
    },
    {
      varNo: 939,
      position: 9
    }
  ],
  [BELT_CONVEYOR_SETTINGS]: [
    {
      varNo: 501,
      position: 1
    },
    {
      varNo: 502,
      position: 2
    },
    {
      varNo: 10001,
      position: 3
    },
    {
      varNo: 10002,
      position: 4
    },
    {
      varNo: 522,
      position: 5
    },
    {
      varNo: 10016,
      position: 6
    },
    {
      varNo: 10017,
      position: 7
    },
    {
      varNo: 10003,
      position: 8
    }
  ],
  [BELT_CONVEYOR_SETTINGS_SCRAPER_1]: [
    {
      varNo: 10004,
      position: 1
    },
    {
      varNo: 10008,
      position: 2
    }
  ],
  [BELT_CONVEYOR_SETTINGS_SCRAPER_2]: [
    {
      varNo: 10005,
      position: 1
    },
    {
      varNo: 10009,
      position: 2
    }
  ],
  [BELT_CONVEYOR_SETTINGS_SCRAPER_3]: [
    {
      varNo: 10006,
      position: 1
    },
    {
      varNo: 10010,
      position: 2
    }
  ],
  [BELT_CONVEYOR_SETTINGS_SCRAPER_4]: [
    {
      varNo: 10007,
      position: 1
    },
    {
      varNo: 10011,
      position: 2
    }
  ],
  [BELT_CONVEYOR_SCRAPPER_SEGMENT_MAP]: [
    {
      varNo: BELT_CONVEYOR_SCRAPER_1_SEGMENT_CHART,
      position: 1
    },
    {
      varNo: BELT_CONVEYOR_SCRAPER_2_SEGMENT_CHART,
      position: 2
    },
    {
      varNo: BELT_CONVEYOR_SCRAPER_3_SEGMENT_CHART,
      position: 3
    },
    {
      varNo: BELT_CONVEYOR_SCRAPER_4_SEGMENT_CHART,
      position: 4
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS]: [
    {
      varNo: BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS_VAR_NO,
      position: 1
    },
    {
      varNo: 541,
      position: 2
    },
    {
      varNo: 542,
      position: 3
    },
    {
      varNo: 10003,
      position: 4
    },
    {
      varNo: 10016,
      position: 5
    },
    {
      varNo: 522,
      position: 6
    },
    {
      varNo: 10012,
      position: 7
    }

  ],
  [BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS]: 540,
  [BELT_CONVEYOR_DASHBOARD_COUNTERCLOCLWISE_ROTATION]: [
    {
      varNo: 527,
      position: 1
    },
    {
      varNo: 528,
      position: 2
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_CLOCKWISE_ROTATION]: [
    {
      varNo: 525,
      position: 1
    },
    {
      varNo: 526,
      position: 2
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_1]: [
    {
      varNo: BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS_VAR_NO,
      position: 1
    },
    {
      varNo: 10004,
      position: 2
    },
    {
      varNo: 10008,
      position: 3
    },
    {
      varNo: 626,
      position: 4
    },
    {
      varNo: 625,
      position: 5
    },
    {
      varNo: 651,
      position: 6
    },
    {
      varNo: 653,
      position: 7
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_1_SUBSECTION_1]: [
    {
      varNo: 603,
      position: 1
    },
    {
      varNo: 628,
      position: 2
    },
    {
      varNo: 627,
      position: 3
    },
    {
      varNo: 654,
      position: 4
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS]: 663,
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_2]: [
    {
      varNo: BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS_VAR_NO,
      position: 1
    },
    {
      varNo: 10005,
      position: 2
    },
    {
      varNo: 10009,
      position: 3
    },
    {
      varNo: 726,
      position: 4
    },
    {
      varNo: 725,
      position: 5
    },
    {
      varNo: 751,
      position: 6
    },
    {
      varNo: 753,
      position: 7
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_2_SUBSECTION_1]: [
    {
      varNo: 703,
      position: 1
    },
    {
      varNo: 728,
      position: 2
    },
    {
      varNo: 727,
      position: 3
    },
    {
      varNo: 754,
      position: 4
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS]: 763,
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_3]: [
    {
      varNo: BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS_VAR_NO,
      position: 1
    },
    {
      varNo: 10006,
      position: 2
    },
    {
      varNo: 10010,
      position: 3
    },
    {
      varNo: 826,
      position: 4
    },
    {
      varNo: 825,
      position: 5
    },
    {
      varNo: 851,
      position: 6
    },
    {
      varNo: 853,
      position: 7
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_3_SUBSECTION_1]: [
    {
      varNo: 803,
      position: 1
    },
    {
      varNo: 828,
      position: 2
    },
    {
      varNo: 827,
      position: 3
    },
    {
      varNo: 854,
      position: 4
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS]: 863,
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_4]: [
    {
      varNo: BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS_VAR_NO,
      position: 1
    },
    {
      varNo: 10007,
      position: 2
    },
    {
      varNo: 10011,
      position: 3
    },
    {
      varNo: 926,
      position: 4
    },
    {
      varNo: 925,
      position: 5
    },
    {
      varNo: 951,
      position: 6
    },
    {
      varNo: 953,
      position: 7
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_4_SUBSECTION_1]: [
    {
      varNo: 903,
      position: 1
    },
    {
      varNo: 928,
      position: 2
    },
    {
      varNo: 927,
      position: 3
    },
    {
      varNo: 954,
      position: 4
    }
  ],
  [BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS]: 963,
  [BELT_CONVEYOR_DASHBOARD_CONTROL]: [
    {
      varNo: 10001,
      position: 1
    },
    {
      varNo: 511,
      position: 2
    },
    {
      varNo: 510,
      position: 3
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS]: [
    {
      varNo: 664,
      position: 1,
      meta: {
        name: 'varA'
      }
    },
    {
      varNo: 665,
      position: 2,
      meta: {
        name: 'varB'
      }
    },
    {
      varNo: 666,
      position: 3,
      meta: {
        name: 'varC'
      }
    },
    {
      varNo: 667,
      position: 4,
      meta: {
        name: 'varD'
      }
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS]: [
    {
      varNo: 764,
      position: 1,
      meta: {
        name: 'varA'
      }
    },
    {
      varNo: 765,
      position: 2,
      meta: {
        name: 'varB'
      }
    },
    {
      varNo: 766,
      position: 3,
      meta: {
        name: 'varC'
      }
    },
    {
      varNo: 767,
      position: 4,
      meta: {
        name: 'varD'
      }
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS]: [
    {
      varNo: 864,
      position: 1,
      meta: {
        name: 'varA'
      }
    },
    {
      varNo: 865,
      position: 2,
      meta: {
        name: 'varB'
      }
    },
    {
      varNo: 866,
      position: 3,
      meta: {
        name: 'varC'
      }
    },
    {
      varNo: 867,
      position: 4,
      meta: {
        name: 'varD'
      }
    }
  ],
  [BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS]: [
    {
      varNo: 964,
      position: 1,
      meta: {
        name: 'varA'
      }
    },
    {
      varNo: 965,
      position: 2,
      meta: {
        name: 'varB'
      }
    },
    {
      varNo: 966,
      position: 3,
      meta: {
        name: 'varC'
      }
    },
    {
      varNo: 967,
      position: 4,
      meta: {
        name: 'varD'
      }
    }
  ],
  [BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_1]: [
    {
      varNo: 1010,
      position: 1
    },
    {
      varNo: 1006,
      position: 2
    },
    {
      varNo: 1007,
      position: 3
    }
  ],
  [BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_2]: [
    {
      varNo: 1011,
      position: 1
    },
    {
      varNo: 1012,
      position: 2
    },
    {
      varNo: 1013,
      position: 3
    }
  ],
  [BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_3]: [
    {
      varNo: 1022,
      position: 1
    },
    {
      varNo: 1023,
      position: 2
    },
    {
      varNo: 1024,
      position: 3
    }
  ]
}
