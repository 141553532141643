import { EMAIL_REGEX } from '../constants'
import { EMAIL, MAX_LENGTH, PHONE, REQUIRED, DIGITS, LENGTH, UID, REQUIRED_IF, NUMBER } from '../constants/validation-errors'
export const validationCheck = (type, typeParams, value, message) => {
  if (type === EMAIL && typeParams) {
    if (value && !(EMAIL_REGEX.test(value))) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === MAX_LENGTH && typeParams && typeParams.maxLength) {
    if (value.length > typeParams.maxLength) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === PHONE && typeParams) {
    if (!(/^\d{11,11}$/.test(value))) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === DIGITS && typeParams) {
    if (!(/^\d+$/.test(value))) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === NUMBER && typeParams) {
    if (!/^[+-]?((\d*(\.|\,)?\d+)|(\d+(\.|\,)?\d*))$/.test(value)) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === LENGTH && typeParams && typeParams.length) {
    if (value !== '' && String(value).length !== typeParams.length) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === UID && typeParams) {
    if (value !== '' && !(/^[0-9a-zA-Z]+$/.test(value))) {
      return {
        type,
        value,
        message
      }
    }
  }

  if (type === REQUIRED_IF && typeParams) {
    if (typeParams.isRequired && (!value || !String(value).trim())) {
      return {
        type,
        value,
        message
      }
    }
  }
  if (type === REQUIRED && typeParams) {
    if (!value || !String(value).trim()) {
      return {
        type,
        value,
        message
      }
    }
  }

  return false
}

export const getValidationErrors = (errors, value) => {
  const errorMessages = []
  for (const error in errors) {
    const result = validationCheck(error, errors[error], value, errors[error] && typeof (errors[error]) === 'object' && errors[error].message ? errors[error].message : 'Invalid value entered')
    if (result) errorMessages.push({ ...result })
  }
  return errorMessages
}
