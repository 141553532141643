import { useEffect } from 'react'
import { SHOW_SEARCH, HIDE_SEARCH } from '../../store/utility/utilityActions'
import { useDispatch } from 'react-redux'
function useTogglePageSearch (action, params, additionalQuery, sort) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: SHOW_SEARCH, payload: { params, action, additionalQuery, sort } })
    return () => {
      dispatch({ type: HIDE_SEARCH })
    }
  }, [])
}

export default useTogglePageSearch
