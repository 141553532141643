import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../../components/Ux/Loader'
import { useParams } from 'react-router-dom'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_VARIABLES_REQUEST } from '../../../store/customerControl/customerControlActions'
import { mapVariables } from '../../../helpers/variableMappers'
import { BELT_CONVEYOR } from '../../../constants/variables'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const ConveyorBeltInfoBeltConveyor = () => {
  const classes = useStyles()
  const isMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  const variables = useSelector((state) => state.customerControl.variables)
  const control = useSelector((state) => state.customerControl.control)
  const [listVariables, setListVariables] = useState([])
  useSetControlTitle(params.id)

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
  }, [])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      const listVariables = mapVariables(Object.values(variables), BELT_CONVEYOR)
      setListVariables(listVariables)
    } else {
      isMount.current = true
    }
  }, [variables])
  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <PollControlData />
      <Loader open={showLoader} />
      <CardContent>
        <Grid container className='MainContInnerStyle' alignContent='space-between'>
          <div className='Inner_scroll pb-0 w-100'>
            <div className='Inner_scroll-con BeltCenveyorStyle pt-0'>
              <Grid style={{ margin: '10px 0px' }} container>
                <Grid Item lg='5' xs='7'>
                  <strong>{t('conveyor_belt_info_belt_conveyor.control_name')}:</strong>
                </Grid>
                <Grid Item lg='7' xs='5'>
                  {control && control.name}
                </Grid>
              </Grid>
              {listVariables.map((variable, i) => (
                <>
                  <Grid style={{ margin: '10px 0px' }} container>
                    <Grid Item lg='5' xs='7'>
                      <strong>{variable.label + (variable.unit ? ' [' + variable.unit + ']' : '')}: </strong>
                    </Grid>
                    <Grid Item lg='7' xs='5'>
                      {variable.value}
                    </Grid>
                  </Grid>
                  {i === 4 || i === 6 ? <Grid style={{ margin: '20px 0px 10px 0px' }} container>
                    {
                      i === 4
                        ? <Grid Item lg='5' xs='7'>
                          <strong>{t('conveyor_belt_info_belt_conveyor.clockwise_rotation')}:</strong>
                        </Grid>
                        : <Grid Item lg='5' xs='7'>
                          <strong>{t('conveyor_belt_info_belt_conveyor.counterclockwise_rotation')}:</strong>
                        </Grid>
                    }
                                        </Grid> : null}
                </>
              ))}
            </div>
          </div>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Sidebars(ConveyorBeltInfoBeltConveyor, { showSearch: false })
