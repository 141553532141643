import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { REPRESENTATION_USER, SERVICE_USER, SUPER_USER, TOP_USER, USER } from '../constants/roles'

const ProtectedRoute = ({ component: Component, predictiveMaintenanceRouteCheck, roles, coreRoles, skipDashboardCheck, ...rest }) => {
  const { userSwitch } = useSelector((state) => state.user)
  const profile = useSelector((state) => state.user.profile)
  const scopeCustomerId = localStorage.getItem('scope_customer_id')
  const scopeRepresentativeId = localStorage.getItem('scope_representative_id')
  const savedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false

  if (!savedUser) {
    return <Redirect to='/login' />
  }

  if (!profile) {
    return <Redirect to='/waiting' />
  }

  const grantPermission = (requestedRoles) =>
    requestedRoles.includes(profile.role)

  // TODO: what does "other role" mean? Does this mean that the user can impersonate these roles?
  // returns true if role of the current user is included in coreRoles => so the requesting user
  const isOtherRolesAllowed = () => {
    const isCoreRole = coreRoles.includes(profile.role)
    if (isCoreRole) {
      return true
    }
    // true if all roles return true
    // => coreRoles must be scoped by customer or representation id
    const isAllowed = coreRoles.every((cRole) => {
      if (cRole === TOP_USER || cRole === USER) {
        // TOP_USER and USER are only allowed if it is scoped by customerId
        return scopeCustomerId != null
      }

      if (cRole === REPRESENTATION_USER) {
        // REPRESENTATION_USER is only allowed if it is scoped by representativeId
        return scopeRepresentativeId != null
      }
      return false
    })
    return isAllowed
  }

  // Redirects user to login page if not logged in

  /*
  skipDashboardCheck => used to skip the blocked check in case it's dashboard
  So if not the dashboard =>
   First check => if user by token is Representative but currently logged in as customer note: user switch just a flag to avoid refresh issue while switching users.
    => Then redirect back to home page
   Secound check => if user is by token is admin and logged in as customer or representative note:   user switch just a flag to avoid refresh issue while switching users.
    => Then redirect back to home page
  */
  if (!skipDashboardCheck) {
    if (profile.role === REPRESENTATION_USER && scopeCustomerId && coreRoles.includes(profile.role) && !userSwitch) {
      return <Redirect to='/' />
    }

    if ((profile.role === SERVICE_USER || profile.role === SUPER_USER) && (scopeCustomerId || scopeRepresentativeId) && coreRoles.includes(profile.role) && !userSwitch) {
      return <Redirect to='/' />
    }
  }

  if (predictiveMaintenanceRouteCheck) {
    if (scopeCustomerId) {
      return <Redirect to='/' />
    }
  }

  return grantPermission(roles) && isOtherRolesAllowed() ? (
    <Route {...rest}>
      <Component />
    </Route>
  ) : (
    <Redirect to='/' />
  )
}

export default ProtectedRoute
