import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../../components/forms/TextInput'
import SelectInput from '../../../components/forms/SelectInput'
import SubmitForm from '../../../components/forms/SubmitForm'
import Loader from '../../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import MenuItem from '@material-ui/core/MenuItem'
import { tokenRoles } from '../../../constants/roles'
import {
  CUSTOMER_CONTROL_GET_CONTROL_REQUEST,
  CUSTOMER_CONTROL_SCRAPER_REQUEST,
  CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST
} from '../../../store/customerControl/customerControlActions'
import { useDispatch, useSelector } from 'react-redux'
import Sidebars from '../../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ScraperImage from './components/ScraperImage'

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '50px'
    }
  },
  variant: 'menu',
  getContentAnchorEl: null
}
const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const ScraperMeasurementForm = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const routerHistory = useHistory()
  const params = useParams()
  const actionsClasses = useActions()
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const isMount = useRef(false)
  const isMountSuccess = useRef(false)

  const controlId = params.id
  const scraperNr = params.scraper_nr
  const { control, currentScraper, error } = useSelector(
    (state) => state.customerControl
  )
  const scraperIds = (control && control.scraper_ids) || {}
  const scraperId = scraperIds[scraperNr]
  const { segmentMeasurements, operatingHours, updateSuccess, segmentType } =
    currentScraper || {}

  // Get control (FM) with scraper ids
  useEffect(() => {
    controlId &&
      dispatch({
        type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST,
        payload: controlId
      })
  }, [dispatch, controlId])

  // Get scraper details
  useEffect(() => {
    scraperId &&
      dispatch({ type: CUSTOMER_CONTROL_SCRAPER_REQUEST, payload: scraperId })
  }, [dispatch, scraperId])

  const handleSubmit = (formData) => {
    setShowLoader(true)
    // TODO: get scraper and add the measurement in the segment_measurements array
    // TODO: maybe allow editing the measurement

    // TODO: limit 4 measurements => automatically mark all older measurements for deletion

    const newMeasurement = { ...formData, operatingHours }
    const scraperData = {
      volumeCritPct: currentScraper.volumeCritPct,
      segmentMeasurementsAttributes: [
        ...currentScraper.segmentMeasurements,
        newMeasurement
      ]
    }

    dispatch({
      type: CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST,
      payload: { scraper: { ...scraperData }, id: scraperId }
    })
  }

  useEffect(() => {
    if (updateSuccess) {
      routerHistory.goBack()
      setShowLoader(false)
    }
  }, [updateSuccess])

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />

      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent className=''>
          <div className={classes.paper}>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t(
                      'scraper_measurement_form.validation.height1_required'
                    )
                  },
                  number: {
                    message: t(
                      'scraper_measurement_form.validation.must_be_number'
                    )
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='height1'
                label={t('scraper_measurement_form.height1')}
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t(
                      'scraper_measurement_form.validation.height2_required'
                    )
                  },
                  number: {
                    message: t(
                      'scraper_measurement_form.validation.must_be_number'
                    )
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='height2'
                label={t('scraper_measurement_form.height2')}
              />
            </div>
          </div>
          <ScraperImage segmentType={segmentType} />
        </CardContent>
        <CardActions className='ButtonPanelWrap ButtonPanelWrap-2'>
          <Box
            className={actionsClasses.actionButtons + ' ButtonPanel'}
            style={{ justifyContent: 'flex-end' }}
          >
            <Button className={classes.submit} type='submit' variant='outlined'>
              {t('add_user_tokens.save')}
            </Button>
          </Box>
        </CardActions>
      </SubmitForm>
    </Card>
  )
}

export default Sidebars(ScraperMeasurementForm)
