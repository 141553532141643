import * as representationCustomerActions from './representationCustomerActions'

const initialState = {
  loading: false,
  representationCustomers: [],
  representationCustomer: null,
  success: false,
  error: null,
  perPage: 10,
  page: 1,
  totalPages: 0,
  isDeleted: 0
}

const representationCustomer = (state = initialState, action) => {
  switch (action.type) {
    case representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_REQUEST:
      return { ...state, loading: true }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        representationCustomers: action.payload.representationCustomers.map(
          (representationCustomer) => {
            return { ...representationCustomer }
          }
        ),
        perPage: action.payload.perPage,
        page: action.payload.page,
        totalPages: action.payload.totalPages
      }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        representationCustomers: [],
        error: action.error
      }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_REQUEST:
      return { ...state, loading: true }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_SUCCESS:
      return { ...state, loading: false, success: true, isDeleted: action.payload.id }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }
    case representationCustomerActions.REPRESENTATION_CUSTOMER_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }
    default:
      return state
  }
}

export default representationCustomer
