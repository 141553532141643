import { useState, useEffect } from 'react'

function useWindowResize () {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  })
  const updateDimensions = (e) => {
    setDimensions({
      width: window.innerHeight,
      height: window.innerWidth
    })
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
  }, () => {
    window.removeEventListener(updateDimensions)
  }, [])
  return dimensions
}

export default useWindowResize
