import * as customerAction from './customerActions'

const initialState = {
  loading: false,
  customers: [],
  totalPages: 0,
  customer: null,
  success: false,
  error: null,
  perPage: 10,
  page: 1
}

const customer = (state = initialState, action) => {
  switch (action.type) {
    case customerAction.CUSTOMER_LIST_REQUEST:
      return { ...state, loading: true }
    case customerAction.CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPages: action.payload.totalPages,
        customers: action.payload.customers.map((customer) => {
          return { ...customer }
        }),
        perPage: action.payload.perPage,
        page: action.payload.page
      }
    case customerAction.CUSTOMER_LIST_FAILURE:
      return { ...state, loading: false, customers: [], error: action.error }
    case customerAction.GET_CUSTOMER_REQUEST:
      return { ...state, loading: true }
    case customerAction.GET_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customer: { ...action.customer } }
    case customerAction.GET_CUSTOMER_FAILURE:
      return { ...state, loading: false, customer: null, error: action.error }
    case customerAction.CUSTOMER_DELETE_REQUEST:
      return { ...state, loading: true }
    case customerAction.CUSTOMER_DELETE_SUCCESS:
      return { ...state, loading: false, success: true }
    case customerAction.CUSTOMER_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }
    case customerAction.CUSTOMER_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }
    default:
      return state
  }
}

export default customer
