import { call, put, takeLatest } from 'redux-saga/effects'
import * as damageActions from './damageActions'
import * as request from '../../apis/damage'
import { getEvents, deleteEvents, markEventsAsRead } from '../../apis/events'

function * getDamageMeasurementChart ({ payload }) {
  try {
    const response = yield call(request.getMeasurementChart, payload)
    const chartData = { ...response.data }
    yield put({
      type: damageActions.GET_DAMAGE_MEASUREMENT_CHART_SUCCESS,
      payload: chartData
    })
  } catch (error) {
    yield put({
      type: damageActions.GET_DAMAGE_MEASUREMENT_CHART_FAILURE,
      error: error.message
    })
  }
}

function * deleteDamageEvents ({ payload }) {
  try {
    const response = yield call(deleteEvents, payload)
    if (response.status === 200) {
      yield put({
        type: damageActions.DELETE_DAMAGE_EVENTS_SUCCESS,
        payload: { ...response.data }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: damageActions.DELETE_DAMAGE_EVENTS_FAILURE,
      payload: err.message
    })
  }
}

function * getDamageEventChart ({ payload }) {
  try {
    const response = yield call(getEvents, payload)
    if (response.status === 200) {
      yield put({
        type: damageActions.GET_DAMAGE_EVENTS_CHART_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: damageActions.GET_DAMAGE_EVENTS_CHART_FAILURE,
      payload: err.message
    })
  }
}

function * markEventsRead ({ payload }) {
  try {
    const response = yield call(markEventsAsRead, payload)
    if (response.status === 200) {
      yield put({
        type: damageActions.MARK_READ_EVENTS_SUCCESS,
        payload: { ...response.data }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: damageActions.MARK_READ_EVENTS_FAILURE,
      payload: err.message
    })
  }
}

const damangeSaga = [
  takeLatest(
    damageActions.GET_DAMAGE_MEASUREMENT_CHART_REQUEST,
    getDamageMeasurementChart
  ),
  takeLatest(
    damageActions.GET_DAMAGE_EVENTS_CHART_REQUEST,
    getDamageEventChart
  ),
  takeLatest(
    damageActions.DELETE_DAMAGE_EVENTS_REQUEST,
    deleteDamageEvents
  ),
  takeLatest(
    damageActions.MARK_READ_EVENTS_REQUEST,
    markEventsRead
  )
]
export default damangeSaga
