import React, { useEffect, useRef } from 'react'
import * as roles from '../constants/roles'
import AdminDashboard from './AdminDashboard'
import CustomerDashboard from './CustomerDashboard'
import RepresentativeDashboard from './RepresentativeDashboard'
import { useDispatch, useSelector } from 'react-redux'
import {
  USER_PROFILE_REQUEST,
  USER_LOGOUT_REQUEST
} from '../store/users/userActions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import * as utilityActions from '../store/utility/utilityActions'

const Dashboard = (props) => {
  // Setting state with redux store values
  const { i18n } = useTranslation()
  const { error, profile } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  // Redirects user to login page if not logged in

  if (!profile) {
    dispatch({ type: USER_PROFILE_REQUEST })
  }

  useEffect(() => {
    if (profile && profile.locale) {
      i18n.changeLanguage(profile.locale)
    }
  }, [profile])
  useEffect(() => {
    dispatch({ type: utilityActions.ADD_CUSTOME_HEADER_CLASSES, payload: 'mega-header' })
    return () => {
      dispatch({ type: utilityActions.REMOVE_CUSTOME_HEADER_CLASSES })
    }
  }, [])

  useEffect(() => {
    if (error && isMounted.current) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      dispatch({ type: USER_LOGOUT_REQUEST })
    } else {
      isMounted.current = true
    }
  }, [error])

  if (!profile || !profile.role) {
    return <div>
      Waiting for profile...
    </div>
  }

  // Returns dashboard component according to user role

  if (localStorage.getItem('scope_representative_id')) {
    return <RepresentativeDashboard {...props} />
  }

  if (localStorage.getItem('scope_customer_id')) {
    return <CustomerDashboard {...props} />
  }

  switch (profile.role) {
    case roles.USER:
      return <CustomerDashboard {...props} />
    case roles.TOP_USER:
      return <CustomerDashboard {...props} />
    case roles.SUPER_USER:
      return <AdminDashboard {...props} />
    case roles.SERVICE_USER:
      return <AdminDashboard {...props} />
    case roles.REPRESENTATION_USER:
      return <RepresentativeDashboard {...props} />
    default:
      return <p>Invalid Role.</p>
  }
}

export default Dashboard
