import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import Loader from '../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addRepresentationCustomer } from '../../apis/representationCustomers'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const useActions = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const AddCustomerRepresentation = (props) => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const actionsClasses = useActions()
  useSetParentCustomInnerHeaderHeading()
  const handleSubmit = async (formData) => {
    try {
      setShowLoader(true)
      const res = await addRepresentationCustomer(params.id, formData)
      if (res.status === 200) {
        setShowLoader(false)
        history.push('../')
        toast.success(t('edit_representation_add_customer.toast.customer_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 500) {
        // message = error.response.data.message || error.message;
        message = t('edit_representation_add_customer.toast.invalid_customer')
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (

    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />
      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent>
          <Grid container className='MainContInnerStyle' alignContent='space-between'>
            <Grid container justifyContent='center '>
              <Grid item xs='12' className='Inner_scroll pl-1 pr-1'>
                <div className='Inner_scroll-con Pr-2'>
                  <div className={classes.paper}>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation_add_customer.validation.number_required')
                          },
                          maxLength: {
                            maxLength: 7,
                            message: t('edit_representation_add_customer.validation.number_max_length')
                          }
                        }}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='customer_nr'
                        label={t('edit_representation_add_customer.customerNumber')}
                        type='text'
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'}>
            <Box className={classes.addButton} style={{ justifyContent: 'flex-end' }}>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('add_customer.save')}
              </Button>
            </Box>
          </Box>
        </CardActions>

      </SubmitForm>
    </Card>

  )
}

export default Sidebars(AddCustomerRepresentation)
