import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import {
  REPRESENTATION_LIST_REQUEST,
  REPRESENTATION_DELETE_REQUEST
} from '../../store/representation/representationActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import useTogglePageSearch from '../../components/hooks/useTogglePageSearch'
import ReadMoreReact from 'read-more-react'
import {
  SET_SCOPED_REPRESENTATIVE_ID
  , REMOVE_SCOPED_REPRESENTATIVE_ID
} from '../../store/users/userActions'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const Representations = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [newRepresentations, setNewRepresentations] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [representationId, setRepresentationId] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const isMount = useRef(false)
  const isListMount = useRef(false)
  const isRepresentativeScopeMount = useRef(false)
  // Setting state with redux store values
  const representationList = useSelector((state) => state.representation)
  const searchText = useSelector((state) => state.utility.search.searchText)
  const { scopeRepresentativeId } = useSelector((state) => state.user)
  const isReadMoreClick = useRef({})
  const readMoreState = useRef({})

  const { loading, error, representations, success, totalPages, page, perPage, isDeleted } = representationList
  useSetParentCustomInnerHeaderHeading()
  useTogglePageSearch(REPRESENTATION_LIST_REQUEST, null, 'name_or_facility_or_street_or_city_or_country_or_representation_nr', 'name asc')
  // Dispatching action to fetch representatin list
  useEffect(() => {
    dispatch({ type: REPRESENTATION_LIST_REQUEST, payload: { per_page: perPage, page, 'q[sorts]': 'name asc' } })
    dispatch({ type: REMOVE_SCOPED_REPRESENTATIVE_ID })
  }, [dispatch, success])

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage, 'q[sorts]': 'name asc' }
    if (searchText) {
      payload['q[name_or_facility_or_street_or_city_or_country_or_representation_nr_cont]'] = searchText
    }
    dispatch({ type: REPRESENTATION_LIST_REQUEST, payload })
  }
  useEffect(() => {
    if (isRepresentativeScopeMount.current && scopeRepresentativeId != null) {
      setShowLoader(false)
      history.replace('/')
    } else {
      isRepresentativeScopeMount.current = true
    }
  }, [scopeRepresentativeId])

  useEffect(() => {
    if (isListMount.current) {
      readMoreState.current = {}
      isReadMoreClick.current = {}
      setNewRepresentations(representations)
    } else {
      isListMount.current = true
    }
  }, [setNewRepresentations, representations])

  useEffect(() => {
    if (isMount.current && error) {
      setRepresentationId(null)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isDeleted && representationId) {
      setRepresentationId(null)
      dispatch({ type: REPRESENTATION_LIST_REQUEST, payload: { per_page: perPage, page } })
      toast.success(t('list_representation.toast.representation_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [isDeleted])

  // Dispatching action to delete the representatin
  const handleConfirmDeleteRepresentation = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      dispatch({
        type: REPRESENTATION_DELETE_REQUEST,
        payload: { id: representationId }
      })
    } else {
      setRepresentationId(null)
    }
  }

  // Open confirm delete dialog and set representatin id to be deleted
  const handleDeleteRepresentation = (id) => {
    setOpenDialog(true)
    setRepresentationId(id)
  }

  const handleRepresentationClick = (id) => {
    setShowLoader(true)
    dispatch({ type: SET_SCOPED_REPRESENTATIVE_ID, payload: id })
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>
          <Loader open={loading || showLoader} />
          <Grid container className='MainContInnerStyle' alignContent='space-between'>
            <Grid item xs='1'>
              <div className='AddBtn icon-size AddBtnZ'>
                <IconButton onClick={() => history.push(`${match.path}/add/`)} className='RowData-btn'>
                  <img src='/assets/img/add.png' className='AddbtnImg' alt='Add Representation' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs='11' className='Inner_scroll'>
              <div className='Inner_scroll-con pr-1 mr-1  pl-0 '>
                {newRepresentations.map((representation) => (
                  <Grid container direction='row' justifyContent='flex-end' key={representation.id} className='RowData '>
                    <Grid item xs={1} className='left-Con'>
                      <div>
                        <IconButton
                          className='RowData-btn'
                          onClick={() =>
                            history.push(
                                  `${match.path}/edit/${representation.id}/`
                          )}
                          color='primary'
                        >
                          <img className='editiconspace' src='/assets/img/edit.png' alt='starclean logo' />
                        </IconButton><br />
                        <IconButton
                          className='RowData-btn'
                          onClick={() =>
                            handleDeleteRepresentation(representation.id)}
                          style={{ color: 'red' }}
                        >
                          <img src='/assets/img/delete.png' alt='starclean logo' />
                        </IconButton>
                      </div>

                    </Grid>

                    <Grid item xs={10} className='Mid-Con'>

                      <strong>
                        {representation && representation.name &&
                          <span
                            style={{ cursor: 'pointer' }} onClick={() => {
                              !isReadMoreClick.current[representation.id] && handleRepresentationClick(representation.id)

                              if (readMoreState.current[representation.id] === 'open') {
                                isReadMoreClick.current[representation.id] = false
                                readMoreState.current[representation.id] = 'closed'
                              }

                              if (isReadMoreClick.current[representation.id]) {
                                readMoreState.current[representation.id] = 'open'
                              }
                            }} href='javascipt:void(0)'
                          >
                            <ReadMoreReact
                              text={representation.name}
                              min={50}
                              ideal={50}
                              max={50}
                              readMoreText={
                                <div onClick={(e) => { e.preventDefault(); isReadMoreClick.current[representation.id] = true }}>...</div>
                              }
                            />
                          </span>}
                      </strong>
                      {representation && representation.facility && <ReadMoreReact
                        text={representation.facility}
                        min={50}
                        ideal={50}
                        max={50}
                        readMoreText='...'
                                                                    />}
                      {representation && representation.facility ? null : <br />}
                      <div>
                        {representation && representation.street && <ReadMoreReact
                          text={representation.street}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                                    />}
                      </div>
                      <div>
                        {representation && representation.street && <ReadMoreReact
                          text={representation.city}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                                    />}
                      </div>
                      <div>
                        {representation && representation.country && <ReadMoreReact
                          text={representation.country}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                                                                     />}
                      </div>
                    </Grid>

                    <Grid item xs={1} className='Last-Con'>
                      <div>
                        <strong>{t('list_representation.projectID')}</strong><br />
                        {representation.representation_nr}
                      </div>

                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
          {!newRepresentations.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>

        <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2'>
          {newRepresentations && newRepresentations.length && totalPages
            ? <Pagination style={{ margin: 'auto' }} className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
        </CardActions>
      </Card>
      <PopupDialog
        open={openDialog}
        onClick={handleConfirmDeleteRepresentation}
      />
    </>
  )
}

export default Sidebars(Representations, { showSearch: true })
