import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ReadMoreReact from 'read-more-react'
import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import { GET_REPRESENTATION_REQUEST } from '../store/representation/representationActions'
import apiConfig from '../apis/api-constants'
import { toast } from 'react-toastify'
import Loader from '../components/Ux/Loader'
import { REMOVE_SCOPED_CUSTOMER_ID } from '../store/users/userActions'
import { SERVICE_USER, SUPER_USER } from './../constants/roles'
import Sidebars from '../components/layouts/Sidebars'
const RepresentativeDashboard = () => {
  const errorImagePath = '/assets/img/representations_logo.png'
  const { t } = useTranslation()
  const history = useHistory()
  const { profile, scopeRepresentativeId } = useSelector((state) => state.user)
  const representationData = useSelector((state) => state.representation)
  const { error, representation } = representationData
  const isMountedRepresentation = useRef(false)
  const isMountedError = useRef(false)
  const isRepresentativeData = useRef(false)
  const [showLoader, setShowLoader] = useState(true)
  const [errorImage, setErrorImage] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: REMOVE_SCOPED_CUSTOMER_ID })
  }, [])
  useEffect(() => {
    if (profile) {
      setShowLoader(true)
      let id = profile.representation_id
      if (scopeRepresentativeId) {
        id = scopeRepresentativeId
      }
      dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id } })
    }
  }, [profile])

  useEffect(() => {
    if (isMountedRepresentation.current) {
      setShowLoader(false)
      isRepresentativeData.current = true
    } else {
      isMountedRepresentation.current = true
    }
  }, [representation])

  useEffect(() => {
    if (isMountedError.current && error) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error])

  return (
    <Container component='main' maxWidth='xl' fixed>
      <Loader open={showLoader} />
      <Grid container spacing={0} className='dashboard-grid AdminDashboard-row'>

        <Grid item xs={1} />

        <Grid item xs={10}>
          <Grid
            container spacing={1} className='MainContInnerStyle' justifyContent='space-between'
          >
            <Grid item xs={12} sm={7} spacing={5}>
              <List dense='true' className='ListItem ListItemDashboard'>
                <ListItem>
                  <ListItemText

                    secondary={
                      <Typography
                        component='span'
                        variant='body1'
                        style={{ fontWeight: 'bold' }}
                      >
                        {isRepresentativeData.current &&
                      representation &&
                      representation.name &&
                        <ReadMoreReact
                          text={representation.name ? representation.name : ''}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                        />}

                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText

                    secondary={
                      <Typography
                        component='span'
                        variant='body1'
                        style={{ }}
                      >
                        {isRepresentativeData.current && representation && representation.facility &&
                          <ReadMoreReact
                            text={representation.facility ? representation.facility : ''}
                            min={50}
                            ideal={50}
                            max={50}
                            readMoreText='...'
                          />}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText

                    secondary={
                      <Typography
                        component='span'
                        variant='body1'
                        style={{ }}
                      >
                        {isRepresentativeData.current && representation && representation.street && <ReadMoreReact
                          text={representation.street ? representation.street : ''}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                        />}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText

                    secondary={
                      <Typography
                        component='span'
                        variant='body1'
                        style={{ }}
                      >
                        {isRepresentativeData.current && representation && representation.city && <ReadMoreReact
                          text={representation.city ? representation.city : ''}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                        />}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <Typography
                        component='span'
                        variant='body1'
                        style={{ }}
                      >
                        {isRepresentativeData.current && representation && representation.country && <ReadMoreReact
                          text={representation.country ? representation.country : ''}
                          min={50}
                          ideal={50}
                          max={50}
                          readMoreText='...'
                        />}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={3} alignContent='center' justifyContent='flex-end' className=' dflex align-items-center'>
              <div className=' imgthumb'>
                <img src={errorImage || (representation && apiConfig.siteUrl + representation.image_url)} onError={() => { setErrorImage(errorImagePath) }} alt='representative logo' />
              </div>

            </Grid>

            <Grid item xs={12}>
              <Button
                className='dashboard-btn'
                onClick={() => history.push('/representative-customers')}
                variant='outlined'
                color='primary'
                size='large'
                fullWidth
              >
                {t('representative_dashboard.customers')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className='dashboard-btn'
                variant='outlined' color='primary' size='large' onClick={() => history.push('/messages/')} fullWidth
              >
                {t('representative_dashboard.messages_faults')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link to='/service-predictive-maintenance'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('admin_dashboard.service_predictive_maintenance')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1} />

      </Grid>
    </Container>
  )
}

export default Sidebars(RepresentativeDashboard, { roles: [SERVICE_USER, SUPER_USER] })
