import { kebabCase } from 'lodash'
import React from 'react'

export const PackageRow = (props) => {
  const { name, currentVersion, targetVersion, updateAvailable, state, texts } = props

  // const isRunning = state !== "unstarted" && state != undefined && state != "succeeded" && state != "failed"
  const isRunning = state !== undefined && state !== 'succeeded' && state !== 'failed' && state !== 'skipped'
  const showSpinner = isRunning && updateAvailable
  // show checkmark if installing succeeded or if there is no update available
  const showCheckmark = state === 'succeeded' || !updateAvailable
  const showFailed = state === 'failed' || state === 'skipped' && updateAvailable
  const showArrowUp = updateAvailable && !isRunning && !showCheckmark && !showFailed
  const showStateText = showSpinner || showArrowUp || state === 'succeeded' || showFailed
  const { packageState, packageLabels } = texts

  return (
    <>
      <div>{packageLabels[kebabCase(name)]}</div>
      <div>{currentVersion}</div>
      <div>{updateAvailable}
        {showArrowUp && <i className='fas fa-arrow-up' />}
        {showSpinner && <i className='fas fa-spinner fa-spin' />}
        {showCheckmark && <i className='fas fa-check' />}
        {showFailed && <i class='fas fa-exclamation-triangle' />}
      </div>
      <div>{showStateText && packageState[state]}</div>
      <div>{targetVersion}</div>
    </>
  )
}
