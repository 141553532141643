import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../../components/Ux/Loader'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST, CUSTOMER_CONTROL_GET_CONTROL_REQUEST } from '../../../store/customerControl/customerControlActions'
import {
  BELT_CONVEYOR_SETTINGS,
  BELT_CONVEYOR_SETTINGS_SCRAPER_1,
  BELT_CONVEYOR_SETTINGS_SCRAPER_2,
  BELT_CONVEYOR_SETTINGS_SCRAPER_3,
  BELT_CONVEYOR_SETTINGS_SCRAPER_4,
  BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS,
  BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS,
  BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS,
  BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS
}
  from '../../../constants/variables'
import { mapVariables } from '../../../helpers/variableMappers'
import SettingsControl from '../../../components/utilities/SettingsControl'
import { toast } from 'react-toastify'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import {
  BELT_SETTINGS_ACCESS_LIST_REQUEST,
  BELT_SETTINGS_BELT_DAMAGE_LIST_REQUEST,
  BELT_SETTINGS_MEASUREMENT_LIST_REQUEST,
  BELT_SETTINGS_READ_ERROR_LIST_REQUEST,
  BELT_SETTINGS_SOFTWARE_UPDATE_REQUEST,
  BELT_SETTINGS_VARIABLE_LIST_REQUEST
  , SETTINGS_JOB_REQUEST
} from '../../../store/beltSettings/beltSettingsActions'
import {
  SERVICE_USER,
  SUPER_USER,
  REPRESENTATION_USER,
  TOP_USER,
  USER
} from '../../../constants/roles'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { SETTINGS_FILE_JOB_INTERVAL } from '../../../constants/pollingIntervals'

import { PollControlData } from '../../../components/polling'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

function ConveyorBeltSettings (props) {
  const classes = useStyles()
  const isMount = useRef(false)
  const isUpdatedVariablesMount = useRef(false)
  const isSettingsErrorMount = useRef(false)
  const isReadErrorMount = useRef(false)
  const isErrorMount = useRef(false)
  const isReadAccessMount = useRef(false)
  const isReadVariableMount = useRef(false)
  const isReadMeasurementMount = useRef(false)
  const isReadBeltDamageMount = useRef(false)
  const isSoftwareUpdateMount = useRef(false)
  const jobsIntervalRef = useRef({})
  const isSettingsIntervalClearedKeyMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  const match = useRouteMatch()
  const actionsClasses = useActions()
  const { t } = useTranslation()
  const routerHistory = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const variables = useSelector((state) => state.customerControl.variables)
  const control = useSelector((state) => state.customerControl.control)
  const { profile } = useSelector((state) => state.user)
  const { hasRole } = profile || { hasRole: {} }
  const {
    jobStatus,
    settingsIntervalClearedKey,
    readErrorList,
    readAccessList,
    readVariableList,
    readMeasurementList,
    readBeltDamageList,
    softwareUpdate,
    error: settingsError
  } = useSelector((state) => state.beltSettings)
  const { updatedVariable, error } = useSelector((state) => state.customerControl)
  useSetControlTitle(params.id)
  const [settings, setSettings] = useState(null)
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  const [settingsScraper1, setSettingsScraper1] = useState(null)
  const [settingsScraper2, setSettingsScraper2] = useState(null)
  const [settingsScraper3, setSettingsScraper3] = useState(null)
  const [settingsScraper4, setSettingsScraper4] = useState(null)

  const [settingsScraperLearningCurve1, setSettingsScraperLearningCurve1] = useState(null)
  const [settingsScraperLearningCurve2, setSettingsScraperLearningCurve2] = useState(null)
  const [settingsScraperLearningCurve3, setSettingsScraperLearningCurve3] = useState(null)
  const [settingsScraperLearningCurve4, setSettingsScraperLearningCurve4] = useState(null)

  const dateVariable = 501
  const timeVariable = 502

  const dateVariableType = {
    name: 'date',
    meta: {
      label: t('conveyor_belt_settings.choose_date'),
      format: 'yyyy-MM-dd'
    }
  }
  const timeVariableType = {
    name: 'time',
    meta: {
      label: t('conveyor_belt_settings.choose_time'),
      format: 'HH:mm:ss',
      views: ['hours', 'minutes', 'seconds'],
      ampm: false
    }
  }

  useEffect(() => {

  }, [control])
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
    dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: params.id })
  }, [])

  useEffect(() => {
    if (isSettingsErrorMount.current) {
      setShowLoader(false)
      toast.error(settingsError && settingsError.message ? settingsError.message : t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isSettingsErrorMount.current = true
    }
  }, [settingsError])

  useEffect(() => {
    if (isReadErrorMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.error_list_download'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      watchJob(t('conveyor_belt_settings.watchjob.error_list_download'), readErrorList.job_id)
    } else {
      isReadErrorMount.current = true
    }
  }, [readErrorList])

  useEffect(() => {
    if (isReadAccessMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.access_list_download'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      watchJob(t('conveyor_belt_settings.watchjob.access_list_download'), readAccessList.job_id)
    } else {
      isReadAccessMount.current = true
    }
  }, [readAccessList])

  useEffect(() => {
    if (isReadVariableMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.variable_list_download'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      watchJob(t('conveyor_belt_settings.watchjob.variable_list_download'), readVariableList.job_id)
    } else {
      isReadVariableMount.current = true
    }
  }, [readVariableList])

  useEffect(() => {
    if (isReadMeasurementMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.measurement_list_download'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      watchJob(t('conveyor_belt_settings.watchjob.measurement_list_download'), readMeasurementList.job_id)
    } else {
      isReadMeasurementMount.current = true
    }
  }, [readMeasurementList])

  useEffect(() => {
    if (isReadBeltDamageMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.belt_damage_list_download'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      watchJob(t('conveyor_belt_settings.watchjob.belt_damage_list_download'), readBeltDamageList.job_id)
    } else {
      isReadBeltDamageMount.current = true
    }
  }, [readBeltDamageList])

  useEffect(() => {
    if (isSoftwareUpdateMount.current) {
      setTimeout(() => {
        setShowLoader(false)
      }, 2000)
      toast.success(t('conveyor_belt_settings.toast.software_updated'), {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isSoftwareUpdateMount.current = true
    }
  }, [softwareUpdate])

  useEffect(() => {
    if (isUpdatedVariablesMount.current) {
      setShowLoader(false)
      if (match.url.endsWith('/')) {
        routerHistory.push('../')
      } else {
        routerHistory.push('/cenveyor-belts/menu/' + params.id)
      }
      toast.success(t('conveyor_belt_settings.toast.settings_updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isUpdatedVariablesMount.current = true
    }
  },
  [updatedVariable])

  useEffect(() => {
    if (isSettingsIntervalClearedKeyMount.current) {
      if (jobsIntervalRef.current[settingsIntervalClearedKey]) {
        delete jobsIntervalRef.current[settingsIntervalClearedKey]
      }
    } else {
      isSettingsIntervalClearedKeyMount.current = true
    }
  }, [settingsIntervalClearedKey])

  useEffect(() => {
    if (isErrorMount.current) {
      setShowLoader(false)
      toast.error(error && error.message ? error.message : t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  },
  [error])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      const settings = mapVariables(Object.values(variables), BELT_CONVEYOR_SETTINGS)
      const settingsScraper1 = mapVariables(Object.values(variables), BELT_CONVEYOR_SETTINGS_SCRAPER_1)
      const settingsScraper2 = mapVariables(Object.values(variables), BELT_CONVEYOR_SETTINGS_SCRAPER_2)
      const settingsScraper3 = mapVariables(Object.values(variables), BELT_CONVEYOR_SETTINGS_SCRAPER_3)
      const settingsScraper4 = mapVariables(Object.values(variables), BELT_CONVEYOR_SETTINGS_SCRAPER_4)
      const learningCurveScraper1 = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS)
      const learningCurveScraper2 = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS)
      const learningCurveScraper3 = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS)
      const learningCurveScraper4 = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS)
      setSettings(settings)
      setSettingsScraper1(settingsScraper1)
      setSettingsScraper2(settingsScraper2)
      setSettingsScraper3(settingsScraper3)
      setSettingsScraper4(settingsScraper4)
      setSettingsScraperLearningCurve1(learningCurveScraper1)
      setSettingsScraperLearningCurve2(learningCurveScraper2)
      setSettingsScraperLearningCurve3(learningCurveScraper3)
      setSettingsScraperLearningCurve4(learningCurveScraper4)
    } else {
      isMount.current = true
    }
  }, [variables])

  const submit = () => {
    // dont submit for users that do not have the right to change any settings
    if (hasRole.representation) {
      // abort => representation is not allowed to change settings
      return
    }

    const mappedSettings = settings.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed }))

    const mappedSettingsScraper1 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER || profile.role === TOP_USER)
         ? settingsScraper1.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraper2 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER || profile.role === TOP_USER)
         ? settingsScraper2.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraper3 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER || profile.role === TOP_USER)
         ? settingsScraper3.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed }))
         : []

    const mappedSettingsScraper4 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER || profile.role === REPRESENTATION_USER || profile.role === TOP_USER)
         ? settingsScraper4.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraperLearningCurve1 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
         ? settingsScraperLearningCurve1.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraperLearningCurve2 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
         ? settingsScraperLearningCurve2.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraperLearningCurve3 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
         ? settingsScraperLearningCurve3.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    const mappedSettingsScraperLearningCurve4 =
       profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
         ? settingsScraperLearningCurve4.map((variable) => ({ id: variable.id, value: variable.value, changed: !!variable.changed })) : []

    // merge all changed settings and filter only changed settings
    const data = [...mappedSettings,
      ...mappedSettingsScraper1,
      ...mappedSettingsScraper2,
      ...mappedSettingsScraper3,
      ...mappedSettingsScraper4,
      ...mappedSettingsScraperLearningCurve1,
      ...mappedSettingsScraperLearningCurve2,
      ...mappedSettingsScraperLearningCurve3,
      ...mappedSettingsScraperLearningCurve4].filter(variable => variable.changed)
    console.log('Changed Settings', data)
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST, payload: { id: params.id, data } })
  }

  const handleSettingControlChange = (e, i, isDate, isTime) => {
    const settingCopy = settings.map((setting) => ({ ...setting }))
    const value = getEventValue(e, isDate, isTime)
    if (value !== null) {
      settingCopy[i].value = value
      settingCopy[i].changed = true
      setSettings(settingCopy)
      return
    }
    toast.error(t('conveyor_belt_settings.toast.invalid_value'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }

  const handleScrapper1Change = (e, i) => {
    const settingCopy = settingsScraper1.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraper1(settingCopy)
  }

  const handleScrapper2Change = (e, i) => {
    const settingCopy = settingsScraper2.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraper2(settingCopy)
  }

  const handleScrapper3Change = (e, i) => {
    const settingCopy = settingsScraper3.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraper3(settingCopy)
  }

  const handleScrapper4Change = (e, i) => {
    const settingCopy = settingsScraper4.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraper4(settingCopy)
  }

  const handleScrapper1LearningCurveChange = (e, i) => {
    const settingCopy = settingsScraperLearningCurve1.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraperLearningCurve1(settingCopy)
  }

  const handleScrapper2LearningCurveChange = (e, i) => {
    const settingCopy = settingsScraperLearningCurve2.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraperLearningCurve2(settingCopy)
  }

  const handleScrapper3LearningCurveChange = (e, i) => {
    const settingCopy = settingsScraperLearningCurve3.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraperLearningCurve3(settingCopy)
  }
  const handleScrapper4LearningCurveChange = (e, i) => {
    const settingCopy = settingsScraperLearningCurve4.map((setting) => ({ ...setting }))
    settingCopy[i].value = e.target.value
    settingCopy[i].changed = true
    setSettingsScraperLearningCurve4(settingCopy)
  }

  const getEventValue = (e, isDate, isTime) => {
    if (e instanceof Date && !isNaN(e)) {
      if (isDate) {
        return e ? e.getFullYear() + '-' +
          (((e.getMonth() + 1) < 10) ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1)) + '-' +
          (e.getDate() < 10 ? ('0' + e.getDate()) : e.getDate()) : ''
      }
      if (isTime) {
        return e ? (((e.getHours() < 10) ? ('0' + e.getHours()) : e.getHours()) + ':' +
                ((e.getMinutes() < 10) ? ('0' + e.getMinutes()) : e.getMinutes()) + ':' +
                ((e.getSeconds() < 10) ? '0' + e.getSeconds() : e.getSeconds())) : ''
      }
      return null
    }

    if (typeof (e) !== 'object' && isNaN(e)) return ''
    return e && e.target ? e.target.value : ''
  }

  const handleReadOutErrorList = () => {
    setShowLoader(true)
    if (control && control.online && !(t('conveyor_belt_settings.watchjob.error_list_download') in jobStatus) && !(t('conveyor_belt_settings.watchjob.error_list_download') in jobsIntervalRef.current)) {
      dispatch({ type: BELT_SETTINGS_READ_ERROR_LIST_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
    } else if (t('conveyor_belt_settings.watchjob.error_list_download') in jobStatus || t('conveyor_belt_settings.watchjob.error_list_download') in jobsIntervalRef.current) {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.download_running'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const handleReadOutInterventionList = () => {
    setShowLoader(true)
    if (control && control.online && !(t('conveyor_belt_settings.watchjob.access_list_download') in jobStatus) && !(t('conveyor_belt_settings.watchjob.access_list_download') in jobsIntervalRef.current)) {
      dispatch({ type: BELT_SETTINGS_ACCESS_LIST_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
    } else if (t('conveyor_belt_settings.watchjob.access_list_download') in jobStatus || t('conveyor_belt_settings.watchjob.access_list_download') in jobsIntervalRef.current) {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.download_running'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const handleReadOutVariableList = () => {
    setShowLoader(true)
    if (control && control.online && !(t('conveyor_belt_settings.watchjob.variable_list_download') in jobStatus) && !(t('conveyor_belt_settings.watchjob.variable_list_download') in jobsIntervalRef.current)) {
      dispatch({ type: BELT_SETTINGS_VARIABLE_LIST_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
    } else if (t('conveyor_belt_settings.watchjob.variable_list_download') in jobStatus || t('conveyor_belt_settings.watchjob.variable_list_download') in jobsIntervalRef.current) {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.download_running'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const handleReadOutMeasurementList = () => {
    setShowLoader(true)
    if (control && control.online && !(t('conveyor_belt_settings.watchjob.measurement_list_download') in jobStatus) && !(t('conveyor_belt_settings.watchjob.measurement_list_download') in jobsIntervalRef.current)) {
      dispatch({ type: BELT_SETTINGS_MEASUREMENT_LIST_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
    } else if (t('conveyor_belt_settings.watchjob.measurement_list_download') in jobStatus || t('conveyor_belt_settings.watchjob.measurement_list_download') in jobsIntervalRef.current) {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.download_running'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const handleReadOutDamageList = () => {
    setShowLoader(true)
    if (control && control.online && !(t('conveyor_belt_settings.watchjob.belt_damage_list_download') in jobStatus) && !(t('conveyor_belt_settings.watchjob.belt_damage_list_download') in jobsIntervalRef.current)) {
      dispatch({ type: BELT_SETTINGS_BELT_DAMAGE_LIST_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
    } else if (t('conveyor_belt_settings.watchjob.belt_damage_list_download') in jobStatus || t('conveyor_belt_settings.watchjob.belt_damage_list_download') in jobsIntervalRef.current) {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.download_running'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      setShowLoader(false)
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const handleSoftwareUpdate = () => {
    // navigate to SoftwareUpdate component
    routerHistory.push('/cenveyor-belts/menu/' + params.id + '/software_update')
    // setShowLoader(true)
    // dispatch({ type: BELT_SETTINGS_SOFTWARE_UPDATE_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
  }

  const watchJob = (key, id) => {
    jobsIntervalRef.current[key] = setInterval(() => {
      dispatch({ type: SETTINGS_JOB_REQUEST, payload: { key, id, ref: jobsIntervalRef.current[key] } })
    }, SETTINGS_FILE_JOB_INTERVAL)
  }

  const showSubmitButton = hasRole && hasRole.superUser || hasRole.topUser || hasRole.serviceUser

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <PollControlData />
      <Loader open={showLoader} />

      <Grid container direction='row' className=' h-100'>

        <Grid item xs={8} md={8} xxl={9} className=' h-100'>
          <CardContent className=' h-100' style={{ borderRight: profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) ? 'solid 1px #707070' : '' }}>
            <Grid container alignContent='space-between' className='MainContInnerStyle h-100'>
              <Grid item xs={12} className=' h-100'>
                <div className='Inner_scroll pt-0 pb-0 Inner_scrollSetting' style={{ paddingBottom: '0px', paddingRight: '30px' }}>
                  <div className='Inner_scroll-con pt-0'>
                    <Grid container direction='row' alignItems='flex-start' spacing='' className=' h-100 '>

                      <Grid item xs={12} className='SettingsCustOuter pb-1'>
                        {settings && settings.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}:</strong>
                              </Grid>
                              <Grid item xs={6} className='DatePicker date'>
                                <SettingsControl
                                  className='formcontrol'
                                  variable={variable}
                                  readOnly={hasRole.representation}
                                  onChange={(e) => handleSettingControlChange(e, i, variable.var_nr === dateVariable, variable.var_nr === timeVariable)}
                                  renderType={variable.var_nr === dateVariable && dateVariableType ||
                                                   variable.var_nr === timeVariable && timeVariableType}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}
                        <br />
                        <Grid container direction='row' alignItems='center' spacing='0'>
                          <Grid item xs={6}>
                            <strong>{t('conveyor_belt_settings.scraper_1')}</strong>
                          </Grid>
                          <Grid item xs={6} />
                        </Grid>

                        {settingsScraper1 && settingsScraper1.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  disabled={profile && (profile.role !== SERVICE_USER && profile.role !== SUPER_USER && profile.role !== TOP_USER)}
                                  onChange={(e) => handleScrapper1Change(e, i)} type='text' className='formcontrol' value={variable.value} placeholder='-'
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        <br />
                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) && settingsScraperLearningCurve1 && settingsScraperLearningCurve1.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  onChange={(e) => handleScrapper1LearningCurveChange(e, i)} type='text'
                                  className='formcontrol' value={variable.value} placeholder='-'
                                  disabled={hasRole.representation}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}
                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) ? <br /> : null}

                        <Grid container direction='row' alignItems='center' spacing='0'>
                          <Grid item xs={6}>
                            <strong>{t('conveyor_belt_settings.scraper_2')}</strong>
                          </Grid>
                          <Grid item xs={6} />
                        </Grid>

                        {settingsScraper2 && settingsScraper2.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  disabled={profile && (profile.role !== SERVICE_USER && profile.role !== SUPER_USER && profile.role !== TOP_USER)}
                                  onChange={(e) => handleScrapper2Change(e, i)} type='text' className='formcontrol' value={variable.value} placeholder='-'
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        <br />
                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) && settingsScraperLearningCurve2 && settingsScraperLearningCurve2.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  onChange={(e) => handleScrapper2LearningCurveChange(e, i)} type='text'
                                  className='formcontrol' value={variable.value} placeholder='-'
                                  disabled={hasRole.representation}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) ? <br /> : null}

                        <Grid container direction='row' alignItems='center' spacing='0'>
                          <Grid item xs={6}>
                            <strong>{t('conveyor_belt_settings.scraper_3')}</strong>
                          </Grid>
                          <Grid item xs={6} />
                        </Grid>

                        {settingsScraper3 && settingsScraper3.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  disabled={profile && (profile.role !== SERVICE_USER && profile.role !== SUPER_USER && profile.role !== TOP_USER)}
                                  onChange={(e) => handleScrapper3Change(e, i)} type='text' className='formcontrol' value={variable.value} placeholder='-'
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        <br />
                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) && settingsScraperLearningCurve3 && settingsScraperLearningCurve3.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  onChange={(e) => handleScrapper3LearningCurveChange(e, i)} type='text'
                                  className='formcontrol' value={variable.value} placeholder='-'
                                  disabled={hasRole.representation}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) ? <br /> : null}

                        <Grid container direction='row' alignItems='center' spacing='0'>
                          <Grid item xs={6}>
                            <strong>{t('conveyor_belt_settings.scraper_4')}</strong>
                          </Grid>
                          <Grid item xs={6} />
                        </Grid>

                        {settingsScraper4 && settingsScraper4.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  disabled={profile && (profile.role !== SERVICE_USER && profile.role !== SUPER_USER && profile.role !== TOP_USER)}
                                  onChange={(e) => handleScrapper4Change(e, i)} type='text' className='formcontrol' value={variable.value} placeholder='-'
                                />
                              </Grid>
                            </Grid>
                          )
                        })}

                        <br />
                        {profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER) && settingsScraperLearningCurve4 && settingsScraperLearningCurve4.map((variable, i) => {
                          return (
                            <Grid container direction='row' alignItems='center' spacing='0'>
                              <Grid item xs={6}>
                                <strong>{variable.label} {variable.unit}:</strong>
                              </Grid>
                              <Grid item xs={6}>
                                <input
                                  onChange={(e) => handleScrapper4LearningCurveChange(e, i)} type='text'
                                  className='formcontrol' value={variable.value} placeholder='-'
                                  disabled={hasRole.representation}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}
                        <br />

                      </Grid>

                    </Grid>
                  </div>
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Grid>

        <Grid item xs={4} md={4} xxl={3} className=' h-100'>
          <CardActions className={'pl-1 pr-1  ButtonPanelSettingWrap h-100 ' + (profile && (profile.role !== SERVICE_USER && profile.role !== SUPER_USER) ? 'only-btn' : '')} style={{ borderTop: 'none', alignItems: 'flex-center' }}>
            <Box className={actionsClasses.actionButtons + ' ButtonPanel ButtonPanelSetting'}>

              <Box className={classes.addButton + ' SettingButtonDiv only-btn'} style={{ justifyContent: 'center', marginTop: '10px' }}>

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      onClick={handleReadOutErrorList}
                      className={classes.submit}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.read_out_error_list')}
                    </Button> : null
                }

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      className={classes.submit}
                      onClick={handleReadOutInterventionList}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.read_out_intervention_list')}
                    </Button> : null
                }

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      onClick={handleReadOutVariableList}
                      className={classes.submit}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.read_out_variable_list')}
                    </Button> : null
                }

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      onClick={handleReadOutMeasurementList}
                      className={classes.submit}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.read_out_measurement_run_list')}
                    </Button> : null
                }

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      onClick={handleReadOutDamageList}
                      className={classes.submit}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.read_out_belt_damage_list')}
                    </Button>
                    : null
                }

                {
                  profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                    ? <Button
                      onClick={handleSoftwareUpdate}
                      className={classes.submit}
                      type='submit'
                      variant='outlined'
                    >
                      {t('conveyor_belt_settings.software_update')}
                    </Button> : null
                }

                {showSubmitButton && <Button
                  className='SubmitSettingBtn' style={{ marginTop: '20px' }}
                  // className={classes.submit}
                  type='submit'
                  onClick={submit}
                  variant='outlined'
                >
                  <img src='/assets/img/save.png' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {t('conveyor_belt_notifications.save')}
                </Button>}
              </Box>

            </Box>
          </CardActions>
        </Grid>

      </Grid>

    </Card>
  )
}

export default Sidebars(ConveyorBeltSettings)
