import { call, put, takeLatest } from 'redux-saga/effects'
import * as customerAreaActions from './customerAreaActions'
import * as request from '../../apis/customer-areas'

function * getAllCustomerAreaData ({ payload }) {
  try {
    const response = yield call(request.getCustomerAreas, payload.id)
    const customerAreas = response.data
    yield put({
      type: customerAreaActions.CUSTOMER_AREA_LIST_SUCCESS,
      customerAreas
    })
  } catch (error) {
    yield put({
      type: customerAreaActions.CUSTOMER_AREA_LIST_FAILURE,
      error: error.message
    })
  }
}

function * deleteCustomerArea ({ payload }) {
  try {
    yield call(request.deleteCustomerArea, payload.id)
    yield put({
      type: customerAreaActions.CUSTOMER_AREA_DELETE_SUCCESS,
      payload: { success: true, id: payload.id }
    })
  } catch (error) {
    yield put({
      type: customerAreaActions.CUSTOMER_AREA_DELETE_FAILURE,
      error: error.message
    })
    yield put({
      type: customerAreaActions.CUSTOMER_AREA_RESET_DELETE_STATE
    })
  }
}

const customerAreaSaga = [
  takeLatest(
    customerAreaActions.CUSTOMER_AREA_LIST_REQUEST,
    getAllCustomerAreaData
  ),
  takeLatest(
    customerAreaActions.CUSTOMER_AREA_DELETE_REQUEST,
    deleteCustomerArea
  )
]
export default customerAreaSaga
