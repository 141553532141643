import request from './request'

export function getCustomers (payload) {
  return request.get('customers', { params: payload })
}

export function getCustomer (id) {
  return request.get(`customers/${id}`)
}

export function deleteCustomer (id) {
  return request.delete(`customers/${id}`)
}

export function addCustomer (payload) {
  return request.post('customers', payload)
}

export function updateCustomer (id, payload) {
  return request.patch('customers/' + id, payload)
}
