import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import SubmitForm from '../../../../components/forms/SubmitForm'
import TextInput from '../../../../components/forms/TextInput'
import { Box, Button } from '@material-ui/core'
import Loader from '../../../../components/Ux/Loader'
import { CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST } from '../../../../store/customerControl/customerControlActions'
import './ScraperParamsForm.css'

const DEFAULT_M = '0'
const DEFAULT_B = '100'
const DEFAULT_VOLUME_CRIT_PCT = '20'

const ScraperParamsForm = ({ currentScraper, controlId, scraperNr }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { t } = useTranslation()
  const { id, loading, isCarbideSegment, volumeCritPct, mCustom, bCustom } = currentScraper || {}
  const handleSubmit = (formData) => {
    dispatch({
      type: CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST,
      payload: { scraper: { ...formData }, id }
    })
  }

  return (
    <div>
      <Loader open={loading} />
      <div style={{ fontWeight: 'bold' }}>{t('scraper_params_form.regression_line')}:</div>
      <SubmitForm
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent className=''>
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: '1em' }}>
              <TextInput
                noHelperText
                validations={{
                  number: {
                    message: t(
                      'scraper_params_form.validation.must_be_number'
                    )
                  }
                }}
                initValue={mCustom || DEFAULT_M}
                variant='outlined'
                margin='normal'
                name='m_custom'
                label={t('scraper_params_form.m')}
              />
            </div>
            <div style={{ paddingRight: '1em' }}>
              <TextInput
                noHelperText
                validations={{
                  number: {
                    message: t(
                      'scraper_params_form.validation.must_be_number'
                    )
                  }
                }}
                initValue={bCustom || DEFAULT_B}
                variant='outlined'
                margin='normal'
                name='b_custom'
                label={t('scraper_params_form.b')}
              />
            </div>
          </div>
          <div style={{ width: '200px' }}>
            <TextInput
              noHelperText
              validations={{
                required: {
                  message: t(
                    'scraper_params_form.validation.volume_crit_pct_required'
                  )
                },
                number: {
                  message: t(
                    'scraper_params_form.validation.must_be_number'
                  )
                }
              }}
              initValue={volumeCritPct || DEFAULT_VOLUME_CRIT_PCT}
              variant='outlined'
              margin='normal'
              name='volumeCritPct'
              label={(t('scraper_params_form.volume_crit_pct'))}
            />
          </div>
          <div>
            <span>y = mx + b</span>
          </div>
        </CardContent>
        <div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {isCarbideSegment && <button
              className='scraper-params-form-button'
              style={{ marginRight: '0.5em' }}
              onClick={() => history.push(`/cenveyor-belts/menu/${controlId}/scraper/${scraperNr}/measurements`)}
              type='button'
            >
              <span className='MuiButton-label'>
                <img src='/assets/img/edit.png' style={{ width: '20px', paddingRight: 4 }} />
                {t('conveyor_belt_info_belt_scraper.measurements')}
              </span>
            </button>}
            <button className='scraper-params-form-button' tabindex='0' type='submit'>
              <span className='MuiButton-label'><img src='/assets/img/save.png' style={{ width: '20px', paddingRight: 4 }} />
                {t('scraper_params_form.save')}
              </span>
              <span className='MuiTouchRipple-root' />
            </button>
          </div>
        </div>
      </SubmitForm>
    </div>
  )
}

export default ScraperParamsForm
