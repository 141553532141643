import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  USER_PROFILE_REQUEST
} from '../store/users/userActions'
import {
  Container,
  Grid
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
function Waiting (props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.user.profile)
  const { t } = useTranslation()
  useEffect(
    () => {
      if (profile && profile.role) {
        history.replace('/')
      }
    },
    [profile])

  if (!profile) {
    dispatch({ type: USER_PROFILE_REQUEST })
  }
  return (
    <Container component='main' maxWidth='xl' fixed className='h-100'>
      <div
        className='dashboard-grid h-100' style={{
          marginTop: 15
        }}
      >
        <div className='MenuScroll' style={{ position: 'relative' }}>
          <Grid container spacing={0} className='AdminDashboard-row'>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {t('innerHeader.waiting')}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>
      </div>
    </Container>
  )
}

export default Waiting
