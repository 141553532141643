import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useSelector, useDispatch } from 'react-redux'
import { SETTINGS_GET_REQUESTED_FILE_REQUEST, SETTINGS_CLEAR_FILE_STATUS } from '../../store/beltSettings/beltSettingsActions'
import config from '../../apis/api-constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid } from '@material-ui/core'

function FileLoader (props) {
  const { t } = useTranslation()
  const jobStatusRef = useRef(false)
  const jobStatus2Ref = useRef(false)
  const fileResDataRef = useRef(false)
  const { jobStatus, fileStatus: fileResData } = useSelector((state) => state.beltSettings)
  const [filesStatus, setFileStatus] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (fileResDataRef.current) {
      for (const key in fileResData) {
        dispatch({ type: SETTINGS_CLEAR_FILE_STATUS })
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
        iframe.src = config.siteUrl + fileResData[key].url
      }
    } else {
      fileResDataRef.current = true
    }
  }, [fileResData])

  useEffect(() => {
    if (jobStatusRef.current) {
      let filesStatus = []
      for (const key in jobStatus) {
        if (jobStatus[key].status.succeeded === false) {
          filesStatus = [...filesStatus, { key, status: jobStatus[key].status.state }]
        }
      }
      setFileStatus(filesStatus)
    } else {
      jobStatusRef.current = true
    }
  }, [jobStatus])

  useEffect(() => {
    if (jobStatus2Ref.current) {
      for (const key in jobStatus) {
        if (jobStatus[key].status && jobStatus[key].status.succeeded === true) {
          const fileId = jobStatus[key].status.job_result_id
          clearInterval(jobStatus[key].ref)
          dispatch({ type: SETTINGS_GET_REQUESTED_FILE_REQUEST, payload: { fileId, key } })
        }
      }
    } else {
      jobStatus2Ref.current = true
    }
  }, [jobStatus])

  // const formatStatus = (status) => {
  //     if(status) {
  //         return status.replace(/_/g," ");
  //     }
  //     return '';
  // }
  return (
    <div className='file-loader-settings'>
      {
        filesStatus && filesStatus.length > 0
          ? <List>
            {
              filesStatus.map((fStatus) => {
                return (<ListItem key={fStatus.key}>
                  <Grid container>
                    <Grid item xs='4'>
                      <CircularProgress color='inherit' />
                    </Grid>
                    <Grid item xs='8'>
                      <ListItemText
                        primary={fStatus.key}
                        secondary={t(`conveyor_belt_settings.watchjob.${fStatus.status}`)}
                      />
                    </Grid>
                  </Grid>
                        </ListItem>
                )
              })
            }
            </List> : null
      }
    </div>
  )
}

export default FileLoader
