import React from 'react'
function NumberFormat ({ locale, children, format, decimalPlaces }) {
  let number
  if (decimalPlaces) {
    number = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(children)
  }

  if (!decimalPlaces) {
    number = new Intl.NumberFormat('de-DE').format(children)
  }
  return (
    <>
      {number}
    </>
  )
}

export default NumberFormat
