import { takeLatest, call, put } from 'redux-saga/effects'
import * as customerActions from './customerLoginActions'
import { getEvents } from '../../apis/events'

function * getLogins ({ payload }) {
  try {
    const response = yield call(getEvents, payload.query, payload.data)
    if (response.status === 200) {
      yield put({
        type: customerActions.CUSTOMER_LOGIN_LIST_SUCCESS,
        payload: {
          logins: response.data,
          totalPages: response.headers['x-totalpages'],
          perPage: payload.data.per_page,
          page: payload.data.page
        }
      })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    yield put({ type: customerActions.CUSTOMER_LOGIN_LIST_FAILURE, payload: err.message })
  }
}

const customerLoginsSaga = [
  takeLatest(customerActions.CUSTOMER_LOGIN_LIST_REQUEST, getLogins)
]
export default customerLoginsSaga
