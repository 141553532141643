export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS'
export const CUSTOMER_LIST_FAILURE = 'CUSTOMER_LIST_FAILURE'

export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST'
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS'
export const CUSTOMER_DELETE_FAILURE = 'CUSTOMER_DELETE_FAILURE'
export const CUSTOMER_RESET_DELETE_STATE = 'CUSTOMER_RESET_DELETE_STATE'

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE'
