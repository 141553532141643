import { takeLatest, call, put } from 'redux-saga/effects'
import * as userActions from './userActions'
import * as beltConveyorSettingsActions from '../beltSettings/beltSettingsActions'
import * as request from '../../apis/users'

function * userLogin ({ payload }) {
  try {
    const response = yield call(
      request.userLogin, payload
    )

    if (!response.data || !response.data.access_token) {
      throw Error('Request failed.')
    }

    response.data.token = response.data.access_token
    delete response.data.access_token
    yield put({
      type: userActions.USER_LOGIN_SUCCESS,
      payload: { ...response.data }
    })
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status !== 200) {
      yield put({
        type: userActions.USER_LOGIN_FAIL,
        payload: { message: 'Invalid email or password.' }
      })
    } else {
      yield put({
        type: userActions.USER_LOGIN_FAIL,
        payload: { message: error.message }
      })
    }
  }
}

function * userLogout () {
  try {
    yield put({
      type: beltConveyorSettingsActions.SETTINGS_JOB_CLEAR
    })
    yield put({
      type: userActions.USER_LOGOUT_SUCCESS
    })
  } catch (error) {
    yield put({
      type: userActions.USER_LOGOUT_FAIL,
      payload: error.message
    })
  }
}

function * userProfile () {
  try {
    const response = yield call(
      request.userProfile
    )

    if (response.status === 200) {
      yield put({ type: userActions.USER_PROFILE_SUCCESS, payload: response.data })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (error) {
    yield put({
      type: userActions.USER_PROFILE_FAIL,
      payload: error.message
    })
  }
}

function * userProfileLocaleUpdate ({ payload }) {
  try {
    const response = yield call(
      request.updateProfileLanguage,
      payload
    )

    if (response.status === 200) {
      yield put({ type: userActions.USER_LOCALE_UPDATE_SUCCESS, payload: response.data })
      return
    }

    throw (new Error('Something Went Wrong'))
  } catch (error) {
    yield put({
      type: userActions.USER_LOCALE_UPDATE_FAILURE,
      payload: error.message
    })
  }
}

const userSaga = [
  takeLatest(userActions.USER_LOGIN_REQUEST, userLogin),
  takeLatest(userActions.USER_LOGIN_SUCCESS, userProfile),
  takeLatest(userActions.USER_LOGOUT_REQUEST, userLogout),
  takeLatest(userActions.USER_PROFILE_REQUEST, userProfile),
  takeLatest(userActions.USER_LOCALE_UPDATE_REQUEST, userProfileLocaleUpdate)
]
export default userSaga
