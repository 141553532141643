export const REPRESENTATION_CUSTOMER_LIST_REQUEST =
  'REPRESENTATION_CUSTOMER_LIST_REQUEST'
export const REPRESENTATION_CUSTOMER_LIST_SUCCESS =
  'REPRESENTATION_CUSTOMER_LIST_SUCCESS'
export const REPRESENTATION_CUSTOMER_LIST_FAILURE =
  'REPRESENTATION_CUSTOMER_LIST_FAILURE'

export const REPRESENTATION_CUSTOMER_DELETE_REQUEST =
  'REPRESENTATION_CUSTOMER_DELETE_REQUEST'
export const REPRESENTATION_CUSTOMER_DELETE_SUCCESS =
  'REPRESENTATION_CUSTOMER_DELETE_SUCCESS'
export const REPRESENTATION_CUSTOMER_DELETE_FAILURE =
  'REPRESENTATION_CUSTOMER_DELETE_FAILURE'
export const REPRESENTATION_CUSTOMER_RESET_DELETE_STATE =
  'REPRESENTATION_CUSTOMER_RESET_DELETE_STATE'

export const REPRESENTATION_CUSTOMER_UPDATE_REQUEST =
  'REPRESENTATION_CUSTOMER_UPDATE_REQUEST'
export const REPRESENTATION_CUSTOMER_UPDATE_SUCCESS =
  'REPRESENTATION_CUSTOMER_UPDATE_SUCCESS'
export const REPRESENTATION_CUSTOMER_UPDATE_FAILURE =
  'REPRESENTATION_CUSTOMER_UPDATE_FAILURE'

// REPRESENTATION_CUSTOMER;
