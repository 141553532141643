import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loader from '../components/Ux/Loader'
import {
  Container,
  Grid
} from '@material-ui/core'
import { USER_LOGOUT_REQUEST } from '../store/users/userActions'
import { toast } from 'react-toastify'
function SessionExpired (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const isMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  useEffect(
    () => {
      if (localStorage.getItem('sessionExpired') === 'true') {
        localStorage.removeItem('sessionExpired')
        toast.error(t('error.session_expired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        setShowLoader(true)
        dispatch({ type: USER_LOGOUT_REQUEST })
      } else {
        history.replace('/')
      }
    },
    [])

  useEffect(() => {
    if (isMount.current && !user) {
      history.replace('/login')
      setShowLoader(false)
    } else {
      isMount.current = true
    }
  }, [user])

  return (
    <Container component='main' maxWidth='xl' fixed className='h-100'>
      <Loader open={showLoader} />
      <div
        className='dashboard-grid h-100' style={{
          marginTop: 15
        }}
      >
        <div className='MenuScroll' style={{ position: 'relative' }}>
          <Grid container spacing={0} className='AdminDashboard-row'>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {t('innerHeader.session-expired')}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>
      </div>
    </Container>
  )
}

export default SessionExpired
