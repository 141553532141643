import React, { useEffect, useRef } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import FileLoader from './FileLoader'
import {
  TOP_USER,
  USER
} from '../../constants/roles'
import { MESSAGE_FAULTS_STATUS_REQUEST } from '../../store/message/messageAction'
import { MESSAGE_FAULTS_UPDATE_INTERVAL } from '../../constants/pollingIntervals'

const GlobalUilities = () => {
  const { profile, scopeCustomerId } = useSelector((state) => state.user)
  const intervalRef = useRef(null)
  const dispatch = useDispatch()
  const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const storedProfile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null
  useEffect(() => {
    if (!intervalRef.current && storedUser && storedProfile) {
      intervalRef.current = setInterval(() => {
        const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        const storedProfile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null
        if (storedUser) {
          console.log('polling faults from API...')
          dispatch({ type: MESSAGE_FAULTS_STATUS_REQUEST })
        } else {
          clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }, MESSAGE_FAULTS_UPDATE_INTERVAL)
    }
  }, [profile, scopeCustomerId])
  return (<>
    <ToastContainer />
    <FileLoader />
  </>)
}
export default GlobalUilities
