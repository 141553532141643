import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

const PopupDialog = ({ open, onClick, message }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Dialog open={open} maxWidth='xs'>
        <DialogTitle>{t('confirm_popup_dialog.confirm_text')}</DialogTitle>
        {message && <DialogContent dividers>
          <Typography gutterBottom>
            {message}
          </Typography>
        </DialogContent>}
        <DialogActions className='d-flex justify-content-space-between'>
          <Button onClick={onClick} color='primary' value>
            {t('confirm_popup_dialog.yes')}
          </Button>
          <Button onClick={onClick} color='primary' value={false}>
            {t('confirm_popup_dialog.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PopupDialog
