import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
function SidebarSearch ({ search, clear, showClearButton }) {
  const inputRef = useRef()
  const { t } = useTranslation()
  const searchText = useSelector((state) => state.utility.search.searchText)
  const [textSearch, setTextSearch] = useState('')

  useEffect(() => {
    search(inputRef.current.value)
  }, [textSearch])

  return (
    <>
      <div class='input-group SideBarSearch'>
        <div class='input-group-append'>
          <button class='btn btn-secondary' style={{ cursor: 'pointer' }} onClick={() => search(inputRef.current.value)}>
            <img alt='icon' src='/assets/img/search.png' />
          </button>
        </div>
        <input onChange={(e) => setTextSearch(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && search(inputRef.current.value)} ref={inputRef} type='text' class='form-control' placeholder={t('rightSidebar.search')} />
        {
          searchText
            ? <>
              {showClearButton && <button
                style={{
                  borderLeft: '1px solid',
                  background: '#fff',
                  cursor: 'pointer'
                }} class='btn btn-secondary' onClick={() => { inputRef.current.value = ''; clear() }}
                                  >
                <img alt='icon' src='/assets/img/close-icon.png' />
              </button>}
              </>
            : null
        }
      </div>

    </>
  )
}

export default SidebarSearch
