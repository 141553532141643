import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import Loader from '../../components/Ux/Loader'
import TextInput from '../../components/forms/TextInput'
import FileUpload from '../../components/forms/FileUpload'
import SubmitForm from '../../components/forms/SubmitForm'
import apiConfig from '../../apis/api-constants'
import { toast } from 'react-toastify'
import { updateCustomer } from '../../apis/customers'
import { Box, Grid } from '@material-ui/core'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'

    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const EditCustomer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const actionsClasses = useActions()
  useSetParentCustomInnerHeaderHeading()
  const [showLoader, setShowLoader] = useState(false)
  const customerData = useSelector((state) => state.customer)
  const { error, customer } = customerData
  let editCustomer = { ...customer }
  const isMountedError = useRef(false)
  const isMountedCustomer = useRef(false)

  // On Component Mount getting the customer data
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
  }, [])

  // On getting customers data hiding loader
  useEffect(() => {
    if (isMountedCustomer.current) {
      setShowLoader(false)
    } else {
      isMountedCustomer.current = true
    }
  }, [customer])

  // On Error hiding loader and displaying error
  useEffect(() => {
    if (isMountedError.current && error) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error])

  // submitting the form and redirecting to listing and showing errors if there is one.
  const handleSubmit = async (formData) => {
    try {
      setShowLoader(true)
      editCustomer = formData
      const res = await updateCustomer(params.id, formData)
      if (res.status === 200) {
        setShowLoader(false)
        history.push('/customers')
        toast.success(t('edit_customer.toast.customer_updated'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <>
      <Loader open={showLoader} />
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <SubmitForm
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
          autoComplete='off'
        >

          <CardContent>

            <Grid container className='MainContInnerStyle' alignContent='space-between'>
              <div className='Inner_scroll'>
                <div className='Inner_scroll-con '>
                  <div className={classes.paper + ' h-100 align-content-start'}>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_customer.validation.number_required')
                          },
                          maxLength: {
                            maxLength: 7,
                            message: t('edit_customer.validation.number_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.customer_nr
                            ? editCustomer.customer_nr
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='customer_nr'
                        label={t('edit_customer.customerNumber')}
                        type='email'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_customer.validation.name_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.name_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.name ? editCustomer.name : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='name'
                        label={t('edit_customer.name')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.facility_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.facility
                            ? editCustomer.facility
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='facility'
                        label={t('edit_customer.facility')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_customer.validation.city_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.city_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.city ? editCustomer.city : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='city'
                        label={t('edit_customer.city')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_customer.validation.country_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.country_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.country ? editCustomer.country : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='country'
                        label={t('edit_customer.country')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_customer.validation.street_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.street_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.street
                            ? editCustomer.street
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='street'
                        label={t('edit_customer.street')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          email: {
                            message: t('edit_customer.validation.email_validation')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_customer.validation.email_max_length')
                          }
                        }}
                        initValue={
                          editCustomer && editCustomer.contact_email
                            ? editCustomer.contact_email
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='contact_email'
                        label={t('edit_customer.email')}
                        type='email'
                      />
                    </div>
                    <div className='fullwidth'>
                      <FileUpload
                        withIcon
                        name='image'
                        defaultImages={
                          editCustomer && editCustomer.image_url
                            ? [apiConfig.siteUrl + editCustomer.image_url]
                            : ''
                        }
                        buttonText={t('edit_customer.uploadLogo')}
                        withPreview
                        pictures
                        singleImage
                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                        maxFileSize={5242880}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </Grid>
          </CardContent>
          <CardActions className='pl-1 pr-1 ButtonPanelWrap'>

            <Box className={actionsClasses.actionButtons + ' ButtonPanel ml-1 mr-1'}>
              <Button
                type='button'
                variant='outlined'
                onClick={() => history.push('./controls/')}
              >
                <img src='/assets/img/controls.png' />
                      &nbsp;&nbsp;
                {t('view_customer.controls')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                onClick={() => history.push('./users/')}
              >
                <img src='/assets/img/user.png' />
                      &nbsp;&nbsp;
                {t('view_customer.users')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                onClick={() => {
                  history.push('./tokens/')
                }}
              >
                <img src='/assets/img/tokens.png' />
                      &nbsp;&nbsp;
                {t('view_customer.tokens')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                onClick={() => {
                  history.push('./logins/')
                }}
              >
                <img src='/assets/img/enter.png' />
                      &nbsp;&nbsp;
                {t('view_customer.logins')}
              </Button>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('edit_customer.update')}
              </Button>
            </Box>
          </CardActions>
        </SubmitForm>
      </Card>
    </>
  )
}

export default Sidebars(EditCustomer)
