import React from 'react'
import { useTranslation } from 'react-i18next'
import { activeIcon, inactiveIcon } from './Icons'

export const BeltConveyorStatus = ({ status, showText }) => {
  const { t } = useTranslation()

  if (status === 0) {
    // return red icon (or red text if showText is true)
    return showText ? <div class='red-text'>{t('dashboard_status_indicator.inactive')}</div> : inactiveIcon
  } else if (status === 1) {
    // return green icon (or green text if showText is true)
    return showText ? <div class='greenText'>{t('dashboard_status_indicator.active')}</div> : activeIcon
  } else {
    // unsupported status
    return null
  }
}

export default BeltConveyorStatus
