export const REPRESENTATION_TOKEN_LIST_REQUEST = 'REPRESENTATION_TOKEN_LIST_REQUEST'
export const REPRESENTATION_TOKEN_LIST_SUCCESS = 'REPRESENTATION_TOKEN_LIST_SUCCESS'
export const REPRESENTATION_TOKEN_LIST_FAILURE = 'REPRESENTATION_TOKEN_LIST_FAILURE'

export const REPRESENTATION_TOKEN_ADD_REQUEST = 'REPRESENTATION_TOKEN_ADD_REQUEST'
export const REPRESENTATION_TOKEN_ADD_SUCCESS = 'REPRESENTATION_TOKEN_ADD_SUCCESS'
export const REPRESENTATION_TOKEN_ADD_FAILURE = 'REPRESENTATION_TOKEN_ADD_FAILURE'

export const REPRESENTATION_TOKEN_DELETE_REQUEST = 'REPRESENTATION_TOKEN_DELETE_REQUEST'
export const REPRESENTATION_TOKEN_DELETE_SUCCESS = 'REPRESENTATION_TOKEN_DELETE_SUCCES'
export const REPRESENTATION_TOKEN_DELETE_FAILURE = 'REPRESENTATION_TOKEN_DELETE_FAILURE'

export const REPRESENTATION_TOKEN_UPDATE_REQUEST = 'REPRESENTATION_TOKEN_UPDATE_REQUEST'
export const REPRESENTATION_TOKEN_UPDATE_SUCCESS = 'REPRESENTATION_TOKEN_UPDATE_SUCCES'
export const REPRESENTATION_TOKEN_UPDATE_FAILURE = 'REPRESENTATION_TOKEN_UPDATE_FAILURE'
