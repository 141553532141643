import { useEffect } from 'react'
import { SHOW_MESSAGE_ICONS, HIDE_MESSAGE_ICONS } from '../../store/utility/utilityActions'
import { useDispatch } from 'react-redux'
function useSetMessageIcon (isHide) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isHide) {
      dispatch({ type: SHOW_MESSAGE_ICONS })
    }
    return () => {
      if (!isHide) {
        dispatch({ type: HIDE_MESSAGE_ICONS })
      }
    }
  }, [])
}

export default useSetMessageIcon
