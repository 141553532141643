import React from 'react'
import { Grid } from '@material-ui/core'

function ContentTitle ({ title }) {
  return (
    <div className='green-bg ContentHeader CardInnerHeader ml-1 mr-1'>
      <Grid container justifyContent='center'>
        <Grid item xs={1} />
        <Grid item xs='10' className='white-bg'>
          <span class='CardInnerHeader-title MuiTypography-root MuiCardHeader-title MuiTypography-h5 MuiTypography-displayBlock'>
            {title}
          </span>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  )
}

export default ContentTitle
