import { call, takeLatest, put } from 'redux-saga/effects'
import * as messageActions from './messageAction'
import { getEventTypes, getEvents, getFaults, markEventsAsRead } from '../../apis/events'

function * setMessagesRead ({ payload }) {
  try {
    const response = yield call(markEventsAsRead, payload)
    if (response.status === 200) {
      yield put({
        type: messageActions.MESSAGE_BULK_READ_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: messageActions.MESSAGE_BULK_READ_FAILURE,
      payload: err.message
    })
  }
}

function * getMessageEvents ({ payload }) {
  try {
    const response = yield call(getEventTypes, payload)
    if (response.status === 200) {
      yield put({
        type: messageActions.MESSAGE_EVENTS_LIST_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: messageActions.MESSAGE_EVENTS_LIST_FAILURE,
      payload: err.message
    })
  }
}

function * getMessagesFaults () {
  try {
    const response = yield call(getFaults)
    if (response.status === 200) {
      yield put({
        type: messageActions.MESSAGE_FAULTS_STATUS_SUCCESS,
        payload: { ...response.data }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: messageActions.MESSAGE_FAULTS_STATUS_FAILURE,
      payload: err.message
    })
  }
}

function * getMessages ({ payload, per_page, page }) {
  try {
    const response = yield call(getEvents, payload)
    if (response.status === 200) {
      yield put({
        type: messageActions.MESSAGE_LIST_SUCCESS,
        payload: {
          messages: response.data,
          totalPages: response.headers['x-totalpages'],
          perPage: per_page,
          page: page
        }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: messageActions.MESSAGE_LIST_FAILURE,
      payload: err.message
    })
  }
}

function * getScraperMessages ({ payload }) {
  try {
    const response = yield call(getEvents, payload)
    if (response.status === 200) {
      yield put({
        type: messageActions.MESSAGE_SCRAPER_LIST_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: messageActions.MESSAGE_SCRAPER_LIST_FAILURE,
      payload: err.message
    })
  }
}
const messagesSaga = [
  takeLatest(messageActions.MESSAGE_EVENTS_LIST_REQUEST, getMessageEvents),
  takeLatest(messageActions.MESSAGE_LIST_REQUEST, getMessages),
  takeLatest(messageActions.MESSAGE_FAULTS_STATUS_REQUEST, getMessagesFaults),
  takeLatest(messageActions.MESSAGE_BULK_READ_REQUEST, setMessagesRead),
  takeLatest(messageActions.MESSAGE_SCRAPER_LIST_REQUEST, getScraperMessages)
]
export default messagesSaga
