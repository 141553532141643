import React, { useEffect, useState } from 'react'
import { useActionCableSubscription, useFetch } from '../hooks'
import styled from 'styled-components'
import { PackageRow } from './PackageRow'
import camelize from 'camelize'

const objectKeysToLower = (obj) => {
  return Object.assign(...Object.keys(obj).map(key => ({ [normalizeKey(key)]: obj[key] })))
}

const normalizeKey = (key) => {
  return key.replace(/-/gi, '').replace(/_/gi, '').toLowerCase()
}

const HeaderCell = styled.div`
  font-weight: bold;
`

const PackagesTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 40px 1fr 1fr;
  grid-gap: 4px;
  grid-auto-rows: minmax(30px, auto);
`

const AutoUpdateContainer = styled.div`
`

const InstallButton = styled.button`
  -webkit-font-smoothing: antialiased;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  color: #fff;
  background-color: ${props => props.disabled ? 'gray' : '#008244'};
  width: 10em;
  margin: 24px 0px 16px;
`

const bannerColorByJobState = (state) => {
  if (state === 'succeeded') {
    return 'green'
  } else {
    return 'red'
  }
}

const Banner = styled.div`
  color: white;
  padding: 1em;
  margin-bottom: 1em;
`

const SuccessBanner = styled(Banner)`
  background-color: green;
`

const ErrorBanner = styled(Banner)`
  background-color: red;
`

const StatusBanner = styled(Banner)`
  background-color: ${props => bannerColorByJobState(props.state)};
`

const BannerText = styled.span`
`

const bannerTextByJobState = (state, texts) => {
  if (state === 'succeeded') {
    return texts.succeeded
  } else if (state === 'waiting' || state === 'step_finished' || state === 'continue_step' || state === 'creating_next_step' || state === 'enqueued' || state === 'sending_request') {
    return texts.installing
  } else if (state === 'failed') {
    return texts.failed
  }
}

const UpdateStatusBanner = ({ jobState, online, readyForUpdates, texts, updatesAvailable, running }) => {
  if (!updatesAvailable) {
    return <SuccessBanner><BannerText>{texts.noUpdatesAvailable}</BannerText></SuccessBanner>
  } else if (!online) {
    return <ErrorBanner><BannerText>{texts.fmOffline}</BannerText></ErrorBanner>
  } else if (!readyForUpdates) {
    return <ErrorBanner><BannerText>{texts.fmNotReadyForUpdates}</BannerText></ErrorBanner>
  } else if (jobState !== undefined) {
    return <StatusBanner state={jobState.state}><BannerText>{bannerTextByJobState(jobState.state, texts)}</BannerText></StatusBanner>
  } else {
    return null
  }
}

export const AutoUpdate = ({
  apiToken, fieldModule, cableUrl, baseUrl, texts
}) => {
  // if runningJobId is not undefined/null then there is already a running update job
  const [currentJob, setCurrentJob] = useState({ id: null })
  const [installRequestPending, setInstallRequestPending] = useState(false)

  const jobState = useActionCableSubscription({ channel: 'StatefulJobChannel', id: currentJob.id, token: apiToken, cableUrl: cableUrl })
  const { online, readyForUpdates, updatesAvailable } = fieldModule

  const result = camelize(useFetch(
    `${baseUrl}/field_modules/${fieldModule.id}/software_update`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json'
      }
    }
  ))

  if (!result.response) {
    return <div>{texts.loading}</div>
  } else if (result.error) {
    return <div>{texts.loading_error}</div>
  } else if (currentJob.id !== result.response.runningJobId && result.response.runningJobId !== null) {
    // only use runningJobId if it is present and only if it has changed
    setCurrentJob({ id: result.response.runningJobId })
  }

  const { packages } = result.response

  const handleInstall = (e) => {
    e.preventDefault()

    setInstallRequestPending(true)

    window
      .fetch(
        `${baseUrl}/field_modules/${fieldModule.id}/software_update`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apiToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => response.json())
      .then(job => {
        setCurrentJob(camelize(job))
        setInstallRequestPending(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setInstallRequestPending(false)
      })
  }

  var stepStates = {}

  if (jobState) {
    stepStates = objectKeysToLower(jobState.context.stepStates)
  }

  let installButtonEnabled = false
  if (installRequestPending) {
    installButtonEnabled = false
  } else if (!online) {
    installButtonEnabled = false
  } else if (!updatesAvailable) {
    installButtonEnabled = false
  } else if (!readyForUpdates) {
    installButtonEnabled = false
  } else if (jobState == undefined) {
    installButtonEnabled = true
  } else if (jobState && ['succeeded', 'failed'].includes(jobState.state)) {
    installButtonEnabled = true
  }

  const sortedPackages = packages.sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <AutoUpdateContainer>
      <UpdateStatusBanner
        jobState={jobState}
        online={online}
        readyForUpdates={readyForUpdates}
        updatesAvailable={updatesAvailable}
        texts={texts.banner}
      />
      <PackagesTable>
        <HeaderCell>{texts.packageHeaders.name}</HeaderCell>
        <HeaderCell>{texts.packageHeaders.current_version}</HeaderCell>
        <HeaderCell>{texts.packageHeaders.state_symbol}</HeaderCell>
        <HeaderCell>{texts.packageHeaders.state}</HeaderCell>
        <HeaderCell>{texts.packageHeaders.available_version}</HeaderCell>
        {sortedPackages && sortedPackages.map(pkg => <PackageRow key={pkg.name} state={stepStates[normalizeKey(pkg.name)]} texts={texts} {...pkg} />)}
      </PackagesTable>
      <InstallButton disabled={!installButtonEnabled} onClick={handleInstall}>{texts.buttons.install}</InstallButton>
    </AutoUpdateContainer>
  )
}

export default AutoUpdate
