import * as damageActions from './damageActions'

const initialState = {
  measurementRunByFmId: {},
  success: false,
  error: null,
  events: [],
  isDeleted: null,
  isMarkedRead: null
}

const damageReducer = (state = initialState, action) => {
  switch (action.type) {
    case damageActions.GET_DAMAGE_MEASUREMENT_CHART_SUCCESS:
      const measurementRunByFmId = {
        ...state.measurementRunByFmId
      }
      measurementRunByFmId[action.payload.field_module_id] = action.payload.data ? { timestamp: action.payload.timestamp, data: action.payload.data.map((data) => ({ ...data })) } : { timestamp: '', data: [] }

      return {
        ...state,
        success: true,
        measurementRunByFmId: measurementRunByFmId
      }
    case damageActions.GET_DAMAGE_MEASUREMENT_CHART_FAILURE:
      return {
        ...state,
        success: false,
        measurementRunByFmId: {},
        error: action.error
      }
    case damageActions.GET_DAMAGE_EVENTS_CHART_SUCCESS:
      return {
        ...state,
        success: true,
        events: action.payload ? action.payload.map((data) => ({ ...data })) : []
      }
    case damageActions.GET_DAMAGE_EVENTS_CHART_FAILURE:
      return {
        ...state,
        success: false,
        events: [],
        error: action.error
      }
    case damageActions.DELETE_DAMAGE_EVENTS_SUCCESS:
      return {
        ...state,
        success: true,
        isDeleted: { ...action.payload }
      }
    case damageActions.DELETE_DAMAGE_EVENTS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.error
      }
    case damageActions.MARK_READ_EVENTS_SUCCESS:
      return {
        ...state,
        success: true,
        isMarkedRead: { ...action.payload }
      }
    case damageActions.MARK_READ_EVENTS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.error
      }
    default:
      return state
  }
}

export default damageReducer
