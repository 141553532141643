import request from './request'

export function getCustomerControls (id, payload) {
  if (payload) {
    return request.get(`field_modules?customer_id=${id}`, { params: payload })
  }
  return request.get(`field_modules?customer_id=${id}`)
}

export function addCustomerControl (control) {
  return request.post('field_modules', control)
}

export function updateCustomerControl (id, control) {
  return request.patch(`field_modules/${id}`, control)
}

export function deleteCustomerControl (id) {
  return request.delete(`field_modules/${id}`)
}

export function getCustomerControl (id) {
  return request.get(`field_modules/${id}`)
}

export function getCustomerControlVariables (id) {
  return request.get(`field_modules/${id}/variables/`)
}

export function getCustomerControlVariablesChart (id, varId) {
  return request.get(`field_modules/${id}/variables/${varId}/values`)
}

export function updateCustomerControlVariables (id, payload) {
  return request.post(`field_modules/${id}/variables/`, payload)
}

export function reloadCustomerControlVariables (id) {
  return request.post(`field_modules/${id}/variables/reload`)
}

export function getScraper (id) {
  return request.get(`scrapers/${id}`, { headers: { 'Key-Inflection': 'camel' } })
}

export function updateScraper (id, scraper) {
  return request.patch(`scrapers/${id}`, scraper, { headers: { 'Key-Inflection': 'camel' } })
}
