import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SHOW_CONNECTION_WIDGETS, HIDE_CONNECTION_WIDGETS } from '../../store/utility/utilityActions'
const useShowConnectionSymbol = (id) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: SHOW_CONNECTION_WIDGETS, payload: id })
    return () => {
      dispatch({ type: HIDE_CONNECTION_WIDGETS })
    }
  }, [])
}

export default useShowConnectionSymbol
