import * as messagesEvents from './messageAction'

const initialState = {
  loading: false,
  success: false,
  error: null,
  events: [],
  messages: [],
  totalPages: 0,
  perPage: 10,
  page: 1,
  messagesUnread: {
    error: false,
    warning: false,
    damage: false,
    offline: false
  },
  readMessages: null,
  scraperMessages: []
}

const messages = (state = initialState, action) => {
  switch (action.type) {
    case messagesEvents.MESSAGE_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        events: action.payload.map((data) => ({ ...data }))
      }
    case messagesEvents.MESSAGE_EVENTS_LIST_FAILURE:
      return { ...state, success: false, error: action.payload }
    case messagesEvents.MESSAGE_LIST_REQUEST:
      return { ...state, loading: true }
    case messagesEvents.MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        messages: action.payload.messages.map((data) => ({ ...data })),
        totalPages: action.payload.totalPages,
        perPage: action.payload.perPage,
        page: action.payload.page
      }
    case messagesEvents.MESSAGE_LIST_FAILURE:
      return { ...state, success: false, error: action.payload }
    case messagesEvents.MESSAGE_FAULTS_STATUS_SUCCESS:
      return { ...state, success: true, messagesUnread: { ...action.payload } }
    case messagesEvents.MESSAGE_FAULTS_STATUS_FAILURE:
      return { ...state, success: false, error: action.payload }
    case messagesEvents.MESSAGE_BULK_READ_SUCCESS:
      return { ...state, success: true, readMessages: { ...action.payload } }
    case messagesEvents.MESSAGE_BULK_READ_FAILURE:
      return { ...state, success: false, error: action.payload }
    case messagesEvents.MESSAGE_SCRAPER_LIST_SUCCESS:
      return { ...state, success: true, scraperMessages: action.payload.map((data) => ({ ...data })) }
    case messagesEvents.MESSAGE_SCRAPER_LIST_FAILURE:
      return { ...state, success: false, error: action.payload }
    default:
      return state
  }
}

export default messages
