import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'inline-block',
    marginTop: 12
  }
}))

const ErrorAlert = ({ message }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Alert severity='error'>{message}</Alert>
    </div>
  )
}

export default ErrorAlert
