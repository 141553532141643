import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../../components/Ux/Loader'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid, TextField, FormHelperText, Box } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_UPDATE_REQUEST } from '../../../store/noiifications/notificationAction'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'
import { NotificationContacts } from './components/notifications/NotificationContacts'
import { Notifications } from './components/notifications/Notifications'
import { EMAIL_REGEX } from '../../../constants'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

// sort order for events by their id
const eventSortOrderById = {
  2: 8, // Verschleißwarnung
  3: 9, // Segment verschlissen
  4: 10, // Außerhalb Toleranz
  5: 3, // Kein Motorstrom
  6: 4, // Motor Überstrom
  7: 6, // Motor Messsystemfehler
  8: 1, // Batteriewechsel nötig
  9: 2, // Spannung zu niedrig
  10: 7, // Motor Endschalterfehler
  11: 11, // Gurtschaden Level 1
  12: 12, // Gurtschaden Level 2
  13: 13, // Gurtschaden Level 3
  24: 5 // Motor Fahrzeitfehler
}

function ConveyorBeltNotifications (props) {
  const classes = useStyles()
  const isMount = useRef(false)
  const isMountedError = useRef(false)
  const isMountedUpdate = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  const match = useRouteMatch()
  const actionsClasses = useActions()
  const { t } = useTranslation()
  const routerHistory = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [contactPersons, setContactPersons] = useState([])
  const [contactPersonsSettings, setContactPersonsSettings] = useState([])
  const { notifications, error, isUpdated } = useSelector((state) => state.notification)
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  const [emailError, setEmailError] = useState({
    email1: '',
    email2: '',
    email3: ''
  })
  const [ccEmailError, setCCEmailError] = useState({
    ccEmail1: '',
    ccEmail2: '',
    ccEmail3: ''
  })

  const [smsError, setSmsError] = useState({
    sms1: '',
    sms2: '',
    sms3: ''
  })

  const [nameError, setNameError] = useState({
    name1: '',
    name2: '',
    name3: ''
  })

  useSetControlTitle(params.id)

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: NOTIFICATION_LIST_REQUEST, payload: params.id })
  }, [])

  // On Error hiding loader and displaying error
  useEffect(() => {
    if (isMountedError.current && error) {
      setShowLoader(false)
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMountedError.current = true
    }
  }, [error])

  useEffect(() => {
    if (isMountedUpdate.current) {
      setShowLoader(false)
      toast.success(t('conveyor_belt_notifications.toast.notifications_updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      if (match.url.endsWith('/')) {
        routerHistory.push('../')
      } else {
        routerHistory.push('/cenveyor-belts/menu/' + params.id)
      }
    } else {
      isMountedUpdate.current = true
    }
  }, [isUpdated])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      const contacts = notifications.map((notification) => notification.contact)
      // sort events by their sort order
      const sortEvents = (events) => {
        return events.sort((a, b) => (eventSortOrderById[a.id] > eventSortOrderById[b.id]) ? 1 : -1)
      }
      const contactEvents = notifications.map((notification) => sortEvents(notification.events))
      setContactPersons(contacts)
      setContactPersonsSettings(contactEvents)
    } else {
      isMount.current = true
    }
  }, [notifications])

  const submit = () => {
    if (!Object.values(emailError).join('') &&
         !Object.values(smsError).join('') &&
         !Object.values(nameError).join('') &&
         !Object.values(ccEmailError).join('')) {
      const data = contactPersons.reduce((data, contact, index) => {
        data = [...data, { contact, events: contactPersonsSettings[index] }]
        return data
      }, [])
      setShowLoader(true)
      dispatch({ type: NOTIFICATION_LIST_UPDATE_REQUEST, payload: { id: params.id, data } })
      return
    }
    toast.error(t('conveyor_belt_notifications.toast.check_error'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }

  const handleOnCheck = (personIndex, noifySettingIndex, checked) => {
    const contactPersonsSettingsCopy = contactPersonsSettings.map((notications) => notications.map((notication) => ({ ...notication })))
    contactPersonsSettingsCopy[personIndex][noifySettingIndex].is_selected = checked
    setContactPersonsSettings(contactPersonsSettingsCopy)
    const isNotificationSelected = contactPersonsSettingsCopy[personIndex].find((notification) => notification.is_selected)

    if (isNotificationSelected) {
      checkNameIsFilled(personIndex)
      checkSmsIsFilled(personIndex)
      checkEmailIsFilled(personIndex)
      checkCCEmailIsFilled(personIndex)
    }

    if (!isNotificationSelected) {
      if (nameError[`name${personIndex + 1}`] === t('conveyor_belt_notifications.validation.name_required')) {
        const error = { ...nameError }
        error[`name${personIndex + 1}`] = ''
        setNameError(error)
      }

      if (smsError[`sms${personIndex + 1}`] === t('conveyor_belt_notifications.validation.sms_required')) {
        const error = { ...smsError }
        error[`sms${personIndex + 1}`] = ''
        setSmsError(error)
      }
      if (emailError[`email${personIndex + 1}`] === t('conveyor_belt_notifications.validation.email_required')) {
        const error = { ...emailError }
        error[`email${personIndex + 1}`] = ''
        setEmailError(error)
      }

      if (ccEmailError[`ccEmail${personIndex + 1}`] === t('conveyor_belt_notifications.validation.cc_without_email')) {
        const error = { ...ccEmailError }
        error[`ccEmail${personIndex + 1}`] = ''
        setCCEmailError(error)
      }
    }
  }

  const checkNameIsFilled = (personIndex, text) => {
    let name = contactPersons[personIndex].name

    if (text !== undefined) {
      name = text
    }

    if (!name || !(name.trim())) {
      const error = { ...nameError }
      error[`name${personIndex + 1}`] = t('conveyor_belt_notifications.validation.name_required')
      setNameError(error)
      return true
    } else {
      const error = { ...nameError }
      error[`name${personIndex + 1}`] = ''
      setNameError(error)
      return false
    }
  }

  const checkSmsIsFilled = (personIndex, text, emailText) => {
    let email = contactPersons[personIndex].email
    let sms = contactPersons[personIndex].sms

    if (text !== undefined && text !== null) {
      sms = text
    }
    if (emailText !== undefined && emailText !== null) {
      email = emailText
    }

    if ((!email || !email.trim()) && (!sms || !(sms.trim()))) {
      const error = { ...smsError }
      error[`sms${personIndex + 1}`] = t('conveyor_belt_notifications.validation.sms_required')
      setSmsError(error)
      return true
    }

    return handleSmsGeneralValidations(sms, personIndex)
  }

  const checkEmailIsFilled = (personIndex, text, smsText) => {
    let sms = contactPersons[personIndex].sms
    let email = contactPersons[personIndex].email
    if (text !== undefined && text !== null) {
      email = text
    }

    if (smsText !== undefined && smsText !== null) {
      sms = smsText
    }

    if ((!sms || !sms.trim()) && (!email || !(email.trim()))) {
      const error = { ...emailError }
      error[`email${personIndex + 1}`] = t('conveyor_belt_notifications.validation.email_required')
      setEmailError(error)
      return true
    }
    return handleEmailGeneralValidations(email, personIndex)
  }

  const checkCCEmailIsFilled = (personIndex, emailCCtext, emailText) => {
    let emailCC = contactPersons[personIndex].email_cc
    let email = contactPersons[personIndex].email
    if (emailText !== undefined && emailText !== null) {
      email = emailText
    }

    if (emailCCtext !== undefined && emailCCtext !== null) {
      emailCC = emailCCtext
    }

    if ((!email || !email.trim()) && (emailCC || emailCC.trim())) {
      const error = { ...ccEmailError }
      error[`ccEmail${personIndex + 1}`] = t('conveyor_belt_notifications.validation.cc_without_email')
      setCCEmailError(error)
      return true
    }
    return handleCCEmailGeneralValidations(emailCC, personIndex)
  }

  const handleOnNameChange = (personIndex, text) => {
    const contactPersonsCopy = contactPersons.map((person) => ({ ...person }))
    contactPersonsCopy[personIndex].name = text
    setContactPersons(contactPersonsCopy)
    const error = { ...nameError }
    const isNotificationSelected = contactPersonsSettings[personIndex].find((notification) => notification.is_selected)
    if (isNotificationSelected && checkNameIsFilled(personIndex, text)) {
      return
    }
    if (text.trim() && text.length <= 35 || text.length === 0) {
      error[`name${personIndex + 1}`] = ''
      setNameError(error)
      return
    }

    if (!text.trim()) {
      error[`name${personIndex + 1}`] = t('conveyor_belt_notifications.validation.no_whitespace')
      setNameError(error)
      return
    }

    if (text.length > 35) {
      error[`name${personIndex + 1}`] = t('conveyor_belt_notifications.validation.name_max_length')
      setNameError(error)
    }
  }

  const handleOnEmailChange = (personIndex, text) => {
    const contactPersonsCopy = contactPersons.map((person) => ({ ...person }))
    contactPersonsCopy[personIndex].email = text
    setContactPersons(contactPersonsCopy)
    const isNotificationSelected = contactPersonsSettings[personIndex].find((notification) => notification.is_selected)
    checkSmsIsFilled(personIndex, null, text)
    if (isNotificationSelected) {
      checkCCEmailIsFilled(personIndex, null, text)
    }
    if (isNotificationSelected && checkEmailIsFilled(personIndex, text)) {
      return
    }
    handleEmailGeneralValidations(text, personIndex)
  }

  const handleEmailGeneralValidations = (text, personIndex) => {
    const error = { ...emailError }
    if (text.length > 50) {
      error[`email${personIndex + 1}`] = t('conveyor_belt_notifications.validation.email_max_length')
      setEmailError(error)
      return true
    }

    if (EMAIL_REGEX.test(text) || text.length === 0) {
      error[`email${personIndex + 1}`] = ''
      setEmailError(error)
      return false
    }

    error[`email${personIndex + 1}`] = t('conveyor_belt_notifications.validation.email_validation')
    setEmailError(error)
    return true
  }
  const handleOnCCEmailChange = (personIndex, text) => {
    const contactPersonsCopy = contactPersons.map((person) => ({ ...person }))
    contactPersonsCopy[personIndex].email_cc = text
    setContactPersons(contactPersonsCopy)
    const isNotificationSelected = contactPersonsSettings[personIndex].find((notification) => notification.is_selected)
    if (isNotificationSelected && checkCCEmailIsFilled(personIndex, text, null)) {
      return
    }
    handleCCEmailGeneralValidations(text, personIndex)
  }

  const handleCCEmailGeneralValidations = (text, personIndex) => {
    const error = { ...ccEmailError }
    if (text.length > 50) {
      error[`ccEmail${personIndex + 1}`] = t('conveyor_belt_notifications.validation.cc_email_max_length')
      setCCEmailError(error)
      return true
    }

    if (EMAIL_REGEX.test(text) || text.length === 0) {
      error[`ccEmail${personIndex + 1}`] = ''
      setCCEmailError(error)
      return false
    }

    error[`ccEmail${personIndex + 1}`] = t('conveyor_belt_notifications.validation.email_validation')
    setCCEmailError(error)
    return true
  }

  const handleOnSmsChange = (personIndex, text) => {
    const contactPersonsCopy = contactPersons.map((person) => ({ ...person }))
    contactPersonsCopy[personIndex].sms = text
    setContactPersons(contactPersonsCopy)
    const isNotificationSelected = contactPersonsSettings[personIndex].find((notification) => notification.is_selected)
    checkEmailIsFilled(personIndex, null, text)
    if (isNotificationSelected && checkSmsIsFilled(personIndex, text)) {
      return
    }
    handleSmsGeneralValidations(text, personIndex)
  }

  const handleSmsGeneralValidations = (text, personIndex) => {
    const error = { ...smsError }
    if (/^([0-9+])[0-9\/\s-]{10,30}$/.test(text) || text.length === 0) {
      error[`sms${personIndex + 1}`] = ''
      setSmsError(error)
      return false
    }
    error[`sms${personIndex + 1}`] = t('conveyor_belt_notifications.validation.sms_validation')
    setSmsError(error)
    return true
  }

  const { profile } = useSelector((state) => state.user)
  const { hasRole } = profile
  const showSubmitButton = hasRole.superUser || hasRole.topUser || hasRole.serviceUser

  return (
    <Card className={classes.root + ' CardBorderStyle'}>
      <PollControlData />
      <Loader open={showLoader} />

      <CardContent style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '180px 1fr 50px',
            gap: 0,
            height: '100%'
          }}
        >
          <Box sx={{ gridRow: '1', overflowX: 'hidden', overflowY: 'hidden' }}>
            <NotificationContacts
              contactPersons={contactPersons}
              nameError={nameError}
              emailError={emailError}
              ccEmailError={ccEmailError}
              handleOnEmailChange={handleOnEmailChange}
              handleOnCCEmailChange={handleOnCCEmailChange}
              handleOnNameChange={handleOnNameChange}
            />
          </Box>
          <Box sx={{ gridRow: '2', overflowX: 'hidden', overflowY: 'scroll' }}>
            <Notifications contactPersonsSettings={contactPersonsSettings} handleOnCheck={handleOnCheck} />
          </Box>
          <Box sx={{ gridRow: '3', overflow: 'hidden' }}>
            <Box className='ButtonPanel' sx={{ display: 'flex', paddingTop: '10px', flexDirection: 'row-reverse', height: '100%' }}>
              {showSubmitButton && <Button
                className={classes.submit}
                type='submit'
                onClick={submit}
                variant='outlined'
                                   >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('conveyor_belt_notifications.save')}
                                   </Button>}
            </Box>
          </Box>
        </Box>

        {/* <CardContent style={{backgroundColor: "yellow", height: "80%"}}>
            <Grid container alignContent="flex-start" className="MainContInnerStyle notifications-form h-100">
            <Grid item xs="12" className="h-100">
                <Grid container direction="row" justifyContent="center" alignContent="flex-start" spacing="" className="h-100  BeltCenveyorStyle position-relative BeltCenveyorStyleNoti" style={{padding:'0px 25px', }} >
                  <Grid item xs={12}>
                    <NotificationContacts contactPersons={contactPersons}
                                          nameError={nameError}
                                          emailError={emailError}
                                          ccEmailError={ccEmailError}
                                          handleOnEmailChange={handleOnEmailChange}
                                          handleOnCCEmailChange={handleOnCCEmailChange}
                                          handleOnNameChange={handleOnNameChange} />
                  </Grid>

                  <Grid item xs={12}>
                    <Notifications contactPersonsSettings={contactPersonsSettings} handleOnCheck={handleOnCheck} />
                  </Grid>

                </Grid>
            </Grid>
            </Grid>
            </CardContent>

            <CardActions className="pl-1 pr-1 ButtonPanelWrap" style={{backgroundColor: "green"}}>
            <Box className={actionsClasses.actionButtons + ' ButtonPanel'} >
            <Box className={classes.addButton} style={{ justifyContent:'flex-end'}}>
                <Button
                  className={classes.submit}
                  type="submit"
                  onClick={submit}
                  variant="outlined"
                >
                  <img src="/assets/img/save.png"/>&nbsp;&nbsp;
                  {t('conveyor_belt_notifications.save')}
                </Button>
              </Box>
            </Box>
          </CardActions> */}

      </CardContent>

    </Card>
  )
}

export default Sidebars(ConveyorBeltNotifications)
