import request from './request'

export function getCustomerUsers (id, payload) {
  if (payload) {
    return request.get(`customers/${id}/users`, { params: payload })
  }
  return request.get(`customers/${id}/users`)
}

export function addCustomerUser (id, user) {
  return request.post(`customers/${id}/users`, user)
}

export function updateCustomerUser (id, payload) {
  return request.patch(`users/${id}`, payload)
}

export function deleteCustomerUser (id) {
  return request.delete(`users/${id}`)
}
