import { call, put, takeLatest } from 'redux-saga/effects'
import * as customerAction from './customerActions'
import * as request from '../../apis/customers'

function * getAllCustomerData ({ payload }) {
  try {
    const response = yield call(request.getCustomers, payload)
    const customers = response.data
    yield put({
      type: customerAction.CUSTOMER_LIST_SUCCESS,
      payload: {
        customers,
        totalPages: response.headers['x-totalpages'],
        perPage: payload.per_page,
        page: payload.page
      }
    })
    yield put({ type: customerAction.CUSTOMER_RESET_DELETE_STATE })
  } catch (error) {
    yield put({
      type: customerAction.CUSTOMER_LIST_FAILURE,
      error: error.message
    })
  }
}

function * deleteCustomer ({ payload }) {
  try {
    yield call(request.deleteCustomer, payload.id)
    yield put({ type: customerAction.CUSTOMER_DELETE_SUCCESS, success: true })
  } catch (error) {
    yield put({
      type: customerAction.CUSTOMER_DELETE_FAILURE,
      error: error.message
    })
  }
}

function * getCustomerData ({ payload }) {
  try {
    const response = yield call(request.getCustomer, payload.id)
    const customer = response.data
    yield put({ type: customerAction.GET_CUSTOMER_SUCCESS, customer })
  } catch (error) {
    yield put({
      type: customerAction.GET_CUSTOMER_FAILURE,
      error: error.message
    })
  }
}

const customerSaga = [
  takeLatest(customerAction.CUSTOMER_LIST_REQUEST, getAllCustomerData),
  takeLatest(customerAction.GET_CUSTOMER_REQUEST, getCustomerData),
  takeLatest(customerAction.CUSTOMER_DELETE_REQUEST, deleteCustomer)
]
export default customerSaga
