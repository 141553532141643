export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'

export const SET_SCOPED_CUSTOMER_ID = 'SET_SCOPED_CUSTOMER_ID'
export const SET_SCOPED_REPRESENTATIVE_ID = 'SET_SCOPED_REPRESENTATIVE_ID'

export const REMOVE_SCOPED_CUSTOMER_ID = 'REMOVE_SCOPED_CUSTOMER_ID'
export const REMOVE_SCOPED_REPRESENTATIVE_ID = 'REMOVE_SCOPED_REPRESENTATIVE_ID'

export const USER_LOCALE_UPDATE_REQUEST = 'USER_LOCALE_UPDATE_REQUEST'
export const USER_LOCALE_UPDATE_SUCCESS = 'USER_LOCALE_UPDATE_SUCCESS'
export const USER_LOCALE_UPDATE_FAILURE = 'USER_LOCALE_UPDATE_FAILURE'
