
import * as customerTokenActions from './customerLoginActions'

const initialState = {
  loading: false,
  logins: [],
  token: null,
  success: false,
  error: null,
  perPage: 10,
  page: 1,
  totalPages: 0
}

const customerToken = (state = initialState, action) => {
  switch (action.type) {
    case customerTokenActions.CUSTOMER_LOGIN_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        logins: action.payload.logins.map((data) => ({ ...data })),
        perPage: action.payload.perPage,
        page: action.payload.page,
        totalPages: action.payload.totalPages
      }
    case customerTokenActions.CUSTOMER_LOGIN_LIST_FAILURE:
      return { ...state, success: false, error: action.payload }
    default:
      return state
  }
}

export default customerToken
