import request from './request'

export function getCustomerRfid (payload) {
  return request.get('customers/' + payload.id + '/rfid_tokens', { params: payload.data })
}

export function createCustomerRfid (payload) {
  return request.post('customers/' + payload.customer_id + '/rfid_tokens', payload)
}

export function getRepresentationRfid (payload) {
  return request.get('representations/' + payload.id + '/rfid_tokens', { params: payload.data })
}

export function createRepresentationRfid (payload) {
  return request.post('representations/' + payload.representation_id + '/rfid_tokens', payload)
}

export function deleteRfid (id) {
  return request.delete(`rfid_tokens/${id}`)
}

export function updateRfid (id, data) {
  return request.patch(`rfid_tokens/${id}`, data)
}
