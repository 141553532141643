import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import SelectInput from '../../components/forms/SelectInput'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from '../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCustomerUser } from '../../apis/customerUsers'
import { CUSTOMER_AREA_LIST_REQUEST } from '../../store/customer-area/customerAreaActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import MultiSelect from '../../components/forms/MultiSelect'
import PopupDialog from '../../components/PopupDialog'

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '50px'
    }
  },
  variant: 'menu',
  getContentAnchorEl: null
}

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const AddCustomerUser = () => {
  const classes = useStyles()
  const actionsClasses = useActions()
  const [showLoader, setShowLoader] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [formParams, setFormParams] = useState(null)
  const [openAreaChangeDialog, setOpenAreaChangeDialog] = useState(false)
  const [areaChangeConfirmed, setAreaChangeConfirmed] = useState(false)
  const [additionalUserLicences, setAdditionalUserLicences] = useState(0)
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const customerAreaList = useSelector((state) => state.customerArea)
  const { loading, customerAreas } = customerAreaList
  const [selectedAreas, setSelectedAreas] = useState([])
  const isCustomerAreasMount = useRef(false)
  const [customerAreaOptions, setCustomerAreaOptions] = useState([])
  // eslint-disable-next-line

  useSetParentCustomInnerHeaderHeading()
  useEffect(() => {
    dispatch({ type: CUSTOMER_AREA_LIST_REQUEST, payload: { id: params.id } })
  }, [dispatch])

  // submit form automatically when area change has been confirmed and formParams are present
  useEffect(() => {
    if (areaChangeConfirmed && formParams) {
      submitForm(formParams)
    }
  }, [areaChangeConfirmed, formParams])

  useEffect(() => {
    if (isCustomerAreasMount.current) {
      setCustomerAreaOptions(customerAreas.map((customerArea) => {
        return { label: customerArea.name, value: customerArea.id }
      }))
    } else {
      isCustomerAreasMount.current = true
    }
  }, [customerAreas])

  const handleAreaChange = (selectedOption) => {
    setSelectedAreas(selectedOption)
  }

  const submitForm = async (formData) => {
    try {
      const newFormData = { ...formData, area_ids: selectedAreas ? selectedAreas.map((selectedArea) => selectedArea.value) : [] }
      setShowLoader(true)
      const res = await addCustomerUser(params.id, newFormData)
      if (res.status === 201) {
        setShowLoader(false)
        history.push('../')
        toast.success(t('add_customer_users.toast.user_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
    // reset form state
    setFormParams(null)
    setAreaChangeConfirmed(false)
  }

  const handleSubmit = async (formData) => {
    // dont submit if no area is selected
    if (selectedAreas.length === 0) {
      return
    }
    // user has clicked/typed in at least one form field
    setFormIsDirty(true)
    // remember new form data
    setFormParams(formData)
    // sum costs of selected areas if there are selected areas
    const additionalUserLicencesNeeded = selectedAreas.map(x => customerAreas.find(ca => ca.id === Number(x.value)).next_user_costs).reduce((a, b) => a + b)
    const moreUserLicencesNeeded = additionalUserLicencesNeeded > 0
    if (moreUserLicencesNeeded) {
      setAdditionalUserLicences(additionalUserLicencesNeeded)
      // let the user confirm
      setOpenAreaChangeDialog(true)
    } else {
      // user does not need to confirm
      setAreaChangeConfirmed(true)
    }
  }

  const handleConfirmAreaChange = (e, i, areaId) => {
    setOpenAreaChangeDialog(false)
    if (e.currentTarget.value === 'true') {
      // user clicked yes
      setAreaChangeConfirmed(true)
    } else {
      // user clicked no
      setAreaChangeConfirmed(false)
    }
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader || loading} />

      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent className='pl-1 pr-1 '>

          <div className='MainContInnerStyleTwo'>
            <div className={classes.paper}>
              <div>
                <TextInput
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.first_name_required')
                    },
                    maxLength: {
                      maxLength: 30,
                      message: t('add_customer_users.validation.first_name_max_length')
                    }
                  }}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='first_name'
                  label={t('add_customer_users.firstname')}
                  type='text'
                />
              </div>
              <div>
                <TextInput
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.last_name_required')
                    },
                    maxLength: {
                      maxLength: 30,
                      message: t('add_customer_users.validation.last_name_max_length')
                    }
                  }}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='last_name'
                  label={t('add_customer_users.lastname')}
                  type='text'
                />
              </div>
              <div>
                <TextInput
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.email_required')
                    },
                    email: {
                      message: t('add_customer_users.validation.email_validation')
                    },
                    maxLength: {
                      maxLength: 100,
                      message: t('add_customer_users.validation.email_max_length')
                    }
                  }}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='email'
                  label={t('add_customer_users.email')}
                  type='email'
                />
              </div>
              <div style={{ alignSelf: 'flex-start' }}>
                <SelectInput
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.role_required')
                    }
                  }}
                  variant='outlined'
                  fullWidth
                  name='role'
                  label={t('add_customer_users.role')}
                  type='text'
                  MenuProps={MenuProps}
                >
                  <MenuItem value='top_user'>Top User</MenuItem>
                  <MenuItem value='user'>User</MenuItem>
                </SelectInput>
              </div>
              <div>
                <MultiSelect
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.area_required')
                    }
                  }}
                  // onMenuOpen={() =>  setAreaPlaceholder('') }
                  // onMenuClose={() => setAreaPlaceholder(t('add_customer_users.areas'))}
                  formIsDirty={formIsDirty}
                  value={selectedAreas}
                  allowSelectAll
                  allOption={{ label: t('add_customer_users.selectAll'), value: 'Select all' }}
                  className='app-multiselect'
                  onChange={handleAreaChange}
                  options={customerAreaOptions}
                  placeholder={t('add_customer_users.areas')}
                  isMulti
                  open
                />
              </div>
              <div style={{ alignSelf: 'flex-start' }}>
                <SelectInput
                  validations={{
                    required: {
                      message: t('add_customer_users.validation.locale_required')
                    }
                  }}
                  variant='outlined'
                  fullWidth
                  name='locale'
                  label={t('add_customer_users.locale')}
                  type='text'
                  MenuProps={MenuProps}
                >
                  <MenuItem value='de'>Deutsch</MenuItem>
                  <MenuItem value='en'>English</MenuItem>
                </SelectInput>
              </div>
            </div>
          </div>
        </CardContent>
        <CardActions className=' pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'} style={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setFormIsDirty(true)}
              className={classes.submit}
              type='submit'
              variant='outlined'
            >
              <img src='/assets/img/save.png' />&nbsp;&nbsp;
              {t('add_customer_users.save')}
            </Button>
          </Box>
        </CardActions>
      </SubmitForm>
      <PopupDialog
        open={openAreaChangeDialog}
        message={t('area_customer_controls.dialog.change_area.message', { additionalUserLicences: additionalUserLicences })}
        onClick={handleConfirmAreaChange}
      />
    </Card>
  )
}

export default Sidebars(AddCustomerUser)
