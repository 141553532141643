import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { GET_REPRESENTATION_REQUEST } from '../../store/representation/representationActions'
import Loader from '../../components/Ux/Loader'
import TextInput from '../../components/forms/TextInput'
import FileUpload from '../../components/forms/FileUpload'
import SubmitForm from '../../components/forms/SubmitForm'
import apiConfig from '../../apis/api-constants'
import { toast } from 'react-toastify'
import { updateRepresentation } from '../../apis/representations'
import { Box, Grid } from '@material-ui/core'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      marginRight: '0.625rem'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const EditRepresentation = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const actionsClasses = useActions()

  const [showLoader, setShowLoader] = useState(false)
  const representationData = useSelector((state) => state.representation)
  const { error, representation } = representationData
  let editRepresentation = { ...representation }
  const isMountedError = useRef(false)
  const isMountedRepresentation = useRef(false)
  useSetParentCustomInnerHeaderHeading()
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id: params.id } })
  }, [])

  useEffect(() => {
    if (isMountedRepresentation.current) {
      setShowLoader(false)
    } else {
      isMountedRepresentation.current = true
    }
  }, [representation])

  useEffect(() => {
    if (isMountedError.current && error) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error])

  const handleSubmit = async (formData) => {
    try {
      setShowLoader(true)
      editRepresentation = formData
      const res = await updateRepresentation(params.id, formData)
      if (res.status === 200) {
        setShowLoader(false)
        history.push('/representations')
        toast.success(t('edit_representation.toast.representation_upated'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <>
      <Loader open={showLoader} />
      <Card className={classes.root + ' CardBorderStyle'}>
        <SubmitForm
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
          autoComplete='off'
        >

          <CardContent>
            <Grid container className='MainContInnerStyle' alignContent='space-between'>
              <Grid item xs='12' className='Inner_scroll pl-1 pr-1'>
                <div className='Inner_scroll-con Pr-2'>
                  <div className={classes.paper}>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.number_required')
                          },
                          maxLength: {
                            maxLength: 7,
                            message: t('edit_representation.validation.number_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.representation_nr
                            ? editRepresentation.representation_nr
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='representation_nr'
                        label={t('edit_representation.representationNumber')}
                        type='email'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.name_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.name_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.name
                            ? editRepresentation.name
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='name'
                        label={t('edit_representation.name')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.facility_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.facility
                            ? editRepresentation.facility
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='facility'
                        label={t('edit_representation.facility')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.city_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.city_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.city
                            ? editRepresentation.city
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='city'
                        label={t('edit_representation.city')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.country_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.country_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.country
                            ? editRepresentation.country
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='country'
                        label={t('edit_representation.country')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.street_required')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.street_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.street
                            ? editRepresentation.street
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='street'
                        label={t('edit_representation.street')}
                        type='text'
                      />
                    </div>
                    <div>
                      <TextInput
                        validations={{
                          required: {
                            message: t('edit_representation.validation.email_required')
                          },
                          email: {
                            message: t('edit_representation.validation.email_validation')
                          },
                          maxLength: {
                            maxLength: 100,
                            message: t('edit_representation.validation.email_max_length')
                          }
                        }}
                        initValue={
                          editRepresentation && editRepresentation.contact_email
                            ? editRepresentation.contact_email
                            : ''
                        }
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='contact_email'
                        label={t('edit_representation.email')}
                        type='email'
                      />
                    </div>
                    <div className='fullwidth'>
                      <FileUpload
                        withIcon
                        name='image'
                        defaultImages={
                          editRepresentation && editRepresentation.image_url
                            ? [apiConfig.siteUrl + editRepresentation.image_url]
                            : ''
                        }
                        buttonText={t('edit_representation.uploadLogo')}
                        withPreview
                        pictures
                        singleImage
                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                        maxFileSize={5242880}
                      />
                    </div>
                    <div />
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
            <Box className={actionsClasses.actionButtons + ' ButtonPanel ml-1 mr-1'}>
              <Button
                type='button'
                variant='outlined'
                onClick={() => history.push('./customers/')}
              >
                <img src='/assets/img/fabrik.png' />
            &nbsp;&nbsp;
                {t('edit_representation.customers')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                onClick={() => history.push('./logins/')}
              >
                <img src='/assets/img/enter.png' />
            &nbsp;&nbsp;
                {t('edit_representation.logins')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                onClick={() => {
                  history.push('./tokens/')
                }}
              >
                <img src='/assets/img/tokens.png' />
                &nbsp;&nbsp;
                {t('view_customer.tokens')}
              </Button>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('edit_customer.update')}
              </Button>
            </Box>
          </CardActions>
        </SubmitForm>
      </Card>
    </>
  )
}

export default Sidebars(EditRepresentation)
