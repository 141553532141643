import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import FileUpload from '../../components/forms/FileUpload'
import Loader from '../../components/Ux/Loader'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCustomer } from '../../apis/customers'
import { PROJECT_ID } from '../../constants/projectId'
import Sidebars from '../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const AddCustomer = () => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const actionsClasses = useActions()
  const { t } = useTranslation()
  const routerHistory = useHistory()
  useSetParentCustomInnerHeaderHeading()
  // submitting the form and redirecting to listing and showing errors if there is one.
  const handleSubmit = async (formData) => {
    try {
      const newFormData = { ...formData, project_id: PROJECT_ID }
      setShowLoader(true)
      const res = await addCustomer(newFormData)
      if (res.status === 201) {
        setShowLoader(false)
        routerHistory.push('/customers')
        toast.success(t('add_customer.toast.customer_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />
      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent>
          <Grid container className='MainContInnerStyle' alignContent='space-between'>
            <div className='Inner_scroll'>
              <div className='Inner_scroll-con'>
                <div className={classes.paper + ' h-100 align-content-start'}>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_customer.validation.number_required')
                        },
                        maxLength: {
                          maxLength: 7,
                          message: t('add_customer.validation.number_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='customer_nr'
                      label={t('add_customer.customerNumber')}
                      type='email'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_customer.validation.name_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.name_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='name'
                      label={t('add_customer.name')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.facility_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='facility'
                      label={t('add_customer.facility')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_customer.validation.city_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.city_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='city'
                      label={t('add_customer.city')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_customer.validation.country_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.country_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='country'
                      label={t('add_customer.country')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_customer.validation.street_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.street_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='street'
                      label={t('add_customer.street')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        email: {
                          message: t('add_customer.validation.email_validation')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_customer.validation.email_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='contact_email'
                      label={t('add_customer.email')}
                      type='email'
                    />
                  </div>
                  <div className='fullwidth'>
                    <FileUpload
                      name='image'
                      withIcon
                      buttonText={t('add_customer.uploadLogo')}
                      withPreview
                      singleImage
                      imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                      maxFileSize={5242880}
                    />
                  </div>
                  <div />
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'}>
            <Box className={classes.addButton} style={{ justifyContent: 'flex-end' }}>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('add_customer.save')}
              </Button>
            </Box>
          </Box>
        </CardActions>
      </SubmitForm>
    </Card>
  )
}

export default Sidebars(AddCustomer)
