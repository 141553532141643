import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import 'moment-timezone'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import { toast } from 'react-toastify'
import { CUSTOMER_LOGIN_LIST_REQUEST } from '../../store/customerLogin/customerLoginActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { GET_REPRESENTATION_REQUEST } from '../../store/representation/representationActions'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const useActions = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const ListRepresentationCustomerLogin = () => {
  const { logins, error, perPage, page, totalPages } = useSelector(
    (state) => state.customerLogin
  )
  const representationData = useSelector((state) => state.representation)
  const { representation } = representationData
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const dispatch = useDispatch()
  const isMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  useSetParentCustomInnerHeaderHeading()
  const isMountLogin = useRef(false)
  let noRecordsFound = null
  const actionsClasses = useActions()
  let loader = null
  if (showLoader) {
    loader = <Loader open />
  }
  if (!logins || logins.length === 0) {
    noRecordsFound = (
      <div
        style={{
          padding: '1rem',
          textAlign: 'center',
          height: '100%',
          position: 'absolute',
          width: '100%',
          top: '0',
          right: '0',
          display: 'flex',
          alignContent: 'center',
          fontSize: '20px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {t('noRecordsFound')}
      </div>
    )
  }

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id: params.id } })
  }, [])

  useEffect(() => {
    setShowLoader(true)
    dispatch({
      type: CUSTOMER_LOGIN_LIST_REQUEST,
      payload: {
        query: 'q[event_type_id_eq]=8&q[representation_id_eq]=' + params.id,
        data: { per_page: perPage, page }
      }
    })
  }, [])

  useEffect(() => {
    if (isMountLogin.current) {
      setShowLoader(false)
    } else {
      isMountLogin.current = true
    }
  }, [logins])

  useEffect(() => {
    if (isMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  const onPageChange = (e, selectedPage) => {
    setShowLoader(true)
    const payload = { per_page: perPage, page: selectedPage }
    dispatch({
      type: CUSTOMER_LOGIN_LIST_REQUEST,
      payload: {
        query: 'q[event_type_id_eq]=8&q[representation_id_eq]=' + params.id,
        data: { ...payload }
      }
    })
  }
  return (
    <Card className={classes.root + ' CardBorderStyle'}>
      <CardContent>
        <ContentTitle title={t('edit_representation_login.title')} />
        <ContentInfoTitle
          type={t('list_representation.title')}
          name={representation && representation.name}
          no={representation && representation.representation_nr}
        />
        <Grid container className='MainContInnerStyleThree mt-1' alignContent='space-between'>
          <Grid container direction='row' alignItems='stretch' justifyContent='space-between' className='RowDataHead pl-1 pr-1 '>
            <Grid item xl={3} xs={4} className='TextCenter'>
              <strong>{t('edit_representation_login.date')}</strong>
            </Grid>
            <Grid item xl={8} xs={7} className='TextCenter'>
              <strong>{t('edit_representation_login.emailAddress')}</strong>
            </Grid>
            <Grid item xs={1} className='TextCenter' />
          </Grid>
          <Grid item xs='12' className='Inner_scrollTwo'>
            <div className='Inner_scroll-con pl-1 pr-1 pt-0 pb-0'>
              {logins.map((login) => (
                <Grid container key={login.id} direction='row' justifyContent='space-between' alignItems='stretch' className='RowData TokenUpRow '>
                  <Grid item xl={3} xs={4} className='TextLeft'>
                    <Moment tz="Europe/Berlin" format='YYYY-MM-DD HH:mm:ss'>
                      {login.timestamp}
                    </Moment>
                  </Grid>
                  <Grid
                    style={{
                      wordBreak: 'break-all'
                    }}
                    item xl={8} xs={7} className='TextCenter'
                  >
                    {login && login.event_title1 ? login.event_title1 : ''}
                  </Grid>
                  <Grid item xs={1} className='cancel-icon-col' />
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        {!loader ? noRecordsFound : loader}
      </CardContent>
      <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2' style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'} style={{ justifyContent: 'center' }}>
          {logins.length && totalPages ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
        </Box>
      </CardActions>
    </Card>
  )
}

export default Sidebars(ListRepresentationCustomerLogin)
