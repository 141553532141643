import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Loader from '../../components/Ux/Loader'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { segmentTableUpload } from '../../apis/segmentTable'
import { Grid, FormHelperText } from '@material-ui/core'
import Sidebars from '../../components/layouts/Sidebars'

import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  }
}))

const SegmentTableUpload = () => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileError, setSelectedFileError] = useState('')
  const actionsClasses = useActions()
  const { t } = useTranslation()
  const history = useHistory()
  useSetParentCustomInnerHeaderHeading()
  const handleFileSelect = (e) => {
    const file = e.target.files[0]
    const extensionTokens = file.name.split('.')
    const extension = extensionTokens[extensionTokens.length - 1]
    if (extension === 'ini') {
      setSelectedFile(e.target.files[0])
      setSelectedFileError('')
      return
    }
    setSelectedFileError('file is required')
    setSelectedFile(null)
    toast.error('Invalid extension only ini files are allowed.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
    e.target.value = ''
  }

  const handleSubmit = async (e) => {
    if (!selectedFile) {
      setSelectedFileError(t('segment_table_upload.validation.file_required'))
      return
    }
    setSelectedFileError('')
    e.preventDefault()
    try {
      setShowLoader(true)
      const formData = new FormData()
      formData.append('segment_table_file', selectedFile)
      const res = await segmentTableUpload(formData)
      if (res.status === 204) {
        setShowLoader(false)
        history.push('/')
        toast.success(t('segment_table_upload.toast.file_upload_success'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response) {
        message = error.response.data.message || error.message
      }
      toast.error(message || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container className='MainContInnerStyle' alignContent='space-between'>
            <div className='Inner_scroll' style={{ flex: '0 0 100%' }}>
              <div className='Inner_scroll-con pl-1 pr-1'>
                <div className={classes.paper + ' h-100 align-content-start'}>
                  <div>
                    <TextField
                      className='segmentTable'
                      type='file'
                      inputProps={{ accept: '.ini' }}
                      variant='outlined'
                      onChange={handleFileSelect}
                    />
                    {selectedFileError
                      ? <FormHelperText
                        error
                      >
                        {selectedFileError}
                        </FormHelperText>
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>

        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-end' }}>
            <Box className={classes.addButton}>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('add_customer.save')}
              </Button>
            </Box>
          </Box>
        </CardActions>
      </form>
    </Card>
  )
}

export default Sidebars(SegmentTableUpload)
