import { React, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
  , IconButton
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../components/Ux/Loader'
import { GET_CUSTOMER_REQUEST } from '../store/customer/customerActions'
import { toast } from 'react-toastify'
import apiConfig from '../apis/api-constants'
import ReadMoreReact from 'read-more-react'
import { MESSAGE_FAULTS_STATUS_REQUEST } from '../store/message/messageAction'

import { REMOVE_SCOPED_REPRESENTATIVE_ID } from '../store/users/userActions'
import Sidebars from '../components/layouts/Sidebars'
import { SERVICE_USER, SUPER_USER, REPRESENTATION_USER } from './../constants/roles'
const CustomerDashboard = () => {
  const errorImagePath = '/assets/img/representations_logo.png'
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { profile, scopeCustomerId } = useSelector((state) => state.user)
  const customerData = useSelector((state) => state.customer)
  const { error: errorMessage, messagesUnread } = useSelector(
    (state) => state.messages
  )
  const { error, customer } = customerData
  const isMountedCustomer = useRef(false)
  const isMountedError = useRef(false)
  const showCustomerData = useRef(false)
  const [showLoader, setShowLoader] = useState(true)
  const [errorImage, setErrorImage] = useState('')

  useEffect(() => {
    dispatch({ type: MESSAGE_FAULTS_STATUS_REQUEST })
    dispatch({ type: REMOVE_SCOPED_REPRESENTATIVE_ID })
  }, [])

  // On Component Mount getting the customer data
  useEffect(() => {
    if (profile) {
      setShowLoader(true)
      let id = profile.customer_id
      if (scopeCustomerId) {
        id = scopeCustomerId
      }
      dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id } })
    }
  }, [profile])

  // On getting customers data hiding loader
  useEffect(() => {
    if (isMountedCustomer.current) {
      setShowLoader(false)
      showCustomerData.current = true
    } else {
      isMountedCustomer.current = true
    }
  }, [customer])

  // On Error hiding loader and displaying error
  useEffect(() => {
    if (isMountedError.current && error) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    } else {
      isMountedError.current = true
    }
  }, [error, errorMessage])

  const handleDamageClick = (id) => {
    history.push(`/cenveyor-belts/menu/${id}/damage/`)
  }

  return (
    <Container component='main' maxWidth='xl' fixed className='h-100'>
      <Loader open={showLoader} />
      <div className='dashboard-grid h-100'>
        <div className='MenuScroll' style={{ position: 'relative' }}>
          <Grid container spacing={0} className='AdminDashboard-row' style={{ position: 'absolute' }}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Grid container spacing={2} className='MainContInnerStyle' justifyContent='space-between'>
                <Grid item xs={12} sm={7} spacing={5}>

                  <List dense='true' className='ListItem ListItemDashboard'>
                    <ListItem>
                      <ListItemText

                        secondary={
                          <Typography
                            component='span'
                            variant='body1'
                            style={{ fontWeight: 'bold' }}
                          >
                            {showCustomerData.current && customer && customer.name &&
                              <ReadMoreReact
                                text={customer.name}
                                min={50}
                                ideal={50}
                                max={50}
                                readMoreText='...'
                              />}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText

                        secondary={
                          <Typography
                            component='span'
                            variant='body1'
                            style={{ }}
                          >

                            {
                              showCustomerData.current && customer && customer.facility &&
                                <ReadMoreReact
                                  text={customer.facility ? customer.facility : ''}
                                  min={50}
                                  ideal={50}
                                  max={50}
                                  readMoreText='...'
                                />
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText

                        secondary={
                          <Typography
                            component='span'
                            variant='body1'
                            style={{ }}
                          >

                            {
                              showCustomerData.current && customer && customer.street &&
                                <ReadMoreReact
                                  text={customer.street}
                                  min={50}
                                  ideal={50}
                                  max={50}
                                  readMoreText='...'
                                />
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        secondary={
                          <Typography
                            component='span'
                            variant='body1'
                            style={{ }}
                          >

                            {
                              showCustomerData.current && customer && customer.city &&
                                <ReadMoreReact
                                  text={customer.city}
                                  min={50}
                                  ideal={50}
                                  max={50}
                                  readMoreText='...'
                                />
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        secondary={
                          <Typography
                            component='span'
                            variant='body1'
                            style={{ }}
                          >
                            {
                              showCustomerData.current && customer && customer.country &&
                                <ReadMoreReact
                                  text={customer.country}
                                  min={50}
                                  ideal={50}
                                  max={50}
                                  readMoreText='...'
                                />
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                </Grid>

                <Grid item xs={12} sm={3} alignContent='center' justifyContent='flex-end' className=' dflex align-items-center'>
                  <div className=' imgthumb'>
                    <img src={errorImage || (customer && apiConfig.siteUrl + customer.image_url)} onError={() => { setErrorImage(errorImagePath) }} alt='representative logo' />
                  </div>

                </Grid>

                <Grid item xs={12} className='mt-1'>
                  <Button
                    className='dashboard-btn'
                    onClick={() => history.push('/cenveyor-belts/')}
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('customer_dashboard.belt_conveyor')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    onClick={() => history.push('/messages/')} fullWidth
                  >
                    {t('customer_dashboard.messages_faults')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction='row' justifyContent='center'>
                    <Grid md={6} xs={12}>
                      {
                        messagesUnread && messagesUnread.damaged_field_module_ids &&
                              messagesUnread.damaged_field_module_ids.length > 0 &&
                                <Button
                                  onClick={() => handleDamageClick(messagesUnread.damaged_field_module_ids[0])}
                                  className='dflex justify-content-space-between buttonDamage'
                                  variant='outlined' color='warrning' size='large' fullWidth
                                >
                                  <span className='DamageCounter'>{messagesUnread.unread_damage_count}</span>
                                  <img src='/assets/img/achtung.png' />
                                  <strong>{t('customer_dashboard.belt_damage')}</strong>
                                  <img src='/assets/img/achtung.png' />
                                </Button>
                      }

                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid container spacing={0}>
              <Grid container direction='row' className='InfoTabContent CustomerdashboardButton' justifyContent='center'>
                <Grid>
                  {messagesUnread.error
                    ? <IconButton onClick={() => { history.push('messages?type=desruptions') }} style={{ color: '#FD9C00', marginRight: '1rem' }}>
                      <strong>{'! ' + t('user_messages.event_shortcode_S')}</strong>
                      </IconButton>
                    : null}
                        &nbsp;&nbsp;&nbsp;
                  {
                    messagesUnread.warning
                      ? <IconButton onClick={() => { history.push('messages?type=messages') }} style={{ color: '#EACF00' }}>
                        <strong>{'! ' + t('user_messages.event_shortcode_M')}</strong>
                        </IconButton>
                      : null
                  }
                </Grid>
                {/* <Grid  xs={6}>
                        {
                          messagesUnread.offline ?
                          <img className="WifiIcon" style={{display:'block', marginLeft:'auto'}} src="/assets/img/no-signal.png" />
                        : <img className="WifiIcon" style={{display:'block', marginLeft:'auto'}} src="/assets/img/signal.png" />
                        }
                          </Grid> */}
              </Grid>
            </Grid>

          </Grid>
          &nbsp;
        </div>
      </div>
    </Container>
  )
}

export default Sidebars(CustomerDashboard, { roles: [SERVICE_USER, SUPER_USER, REPRESENTATION_USER], showSearch: false })
