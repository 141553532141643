import * as notificationEvents from './notificationAction'

const initialState = {
  loading: false,
  success: false,
  error: null,
  notifications: [],
  isUpdated: null
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationEvents.NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        notifications: action.payload.map((data) => ({ ...data }))
      }
    case notificationEvents.NOTIFICATION_LIST_FAILURE:
      return { ...state, success: false, error: action.payload }
    case notificationEvents.NOTIFICATION_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        success: true,
        isUpdated: action.payload
      }
    case notificationEvents.NOTIFICATION_LIST_UPDATE_FAILURE:
      return { ...state, success: false, error: action.payload }
    default:
      return state
  }
}

export default notificationReducer
