import React from 'react'
import {
  Button,
  Container,
  Grid
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'

import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import Sidebars from '../../../components/layouts/Sidebars'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'

function ConveyorBeltInfo (props) {
  const { t } = useTranslation()
  const params = useParams()
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  useSetControlTitle(params.id)
  return (
    <Container component='main' maxWidth='xl' fixed>
      <PollControlData />
      <Grid container spacing={1} className='AdminDashboard-row'>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={12}>
              <Link to='./belt-cenveyor/'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('conveyor_belt_info.belt_conveyor')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={12}>
              <Link to='./control/'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('conveyor_belt_info.belt_control')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={12}>
              <Link to='./scraper/'>
                <Button
                  className='dashboard-btn'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                >
                  {t('conveyor_belt_info.scraper')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Container>
  )
}

export default Sidebars(ConveyorBeltInfo, { showSearch: false })
