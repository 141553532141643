import { Grid } from '@material-ui/core'
import { ErrorBoundary } from '../../../../../helpers/ErrorBoundary'
import { useTranslation } from 'react-i18next'

// notification settings in groups are shown in this order (numbers are event codes from backend)
const GROUP_CONTROL = [9, 8, 27]
const GROUP_SCRAPER = [5, 6, 24, 7, 10, 2, 3, 4]
const GROUP_GSD = [11, 12, 13, 34, 33, 32]

const collectGroup = (group, settings) => {
  return group.map(id => ({ ...settings.find(setting => setting.id === id) }))
}

const settingsControl = (settings) => collectGroup(GROUP_CONTROL, settings)
const settingsScraper = (settings) => collectGroup(GROUP_SCRAPER, settings)
const settingsGsd = (settings) => collectGroup(GROUP_GSD, settings)

// adds index to all objects in array
const withIndex = (settings) => settings.map((notificationSetting, index) => ({ ...notificationSetting, index }))

const NotificationColumn = ({settings, person, handleOnCheck, showGroupHeaders = true}) => {
  const { t } = useTranslation()

  if (settings == null) {
    // do nothing if there are no settings yet
    return <></>
  }

  const personIndex = person - 1
  const settingsWithIndex = withIndex(settings)

  return (
    <Grid item xs={4} className='h-100'>
      {showGroupHeaders ? <h3 style={{marginBottom: '0.3em'}}>{t('conveyor_belt_notifications.group_headers.control')}</h3> : <h3 style={{marginBottom: '0.3em'}}>&nbsp;</h3>}
      <hr />
      <div className={`h-100 notificationColumn${person}`}>
        {settings && settings.length > 0 && settingsControl(settingsWithIndex).map((notificationSetting) => (
          <label className='container-Checkbox'>{notificationSetting.description}
            <input onChange={(e) => handleOnCheck(personIndex, notificationSetting.index, e.target.checked)} checked={notificationSetting.is_selected} type='checkbox' />
            <span className='checkmark' />
          </label>))}
      </div>
      {showGroupHeaders ? <h3 style={{marginBottom: '0.3em'}}>{t('conveyor_belt_notifications.group_headers.scraper')}</h3> : <h3 style={{marginBottom: '0.3em'}}>&nbsp;</h3>}
      <hr />
      <div className={`h-100 notificationColumn${person}`}>
        {settings && settings.length > 0 && settingsScraper(settingsWithIndex).map((notificationSetting) => (
          <label className='container-Checkbox'>{notificationSetting.description}
            <input onChange={(e) => handleOnCheck(personIndex, notificationSetting.index, e.target.checked)} checked={notificationSetting.is_selected} type='checkbox' />
            <span className='checkmark' />
          </label>))}
      </div>
      {showGroupHeaders ? <h3 style={{marginBottom: '0.3em'}}>{t('conveyor_belt_notifications.group_headers.belt_monitoring')}</h3> : <h3 style={{marginBottom: '0.3em'}}>&nbsp;</h3>}
      <hr />
      <div className={`h-100 notificationColumn${person}`}>
        {settings && settings.length > 0 && settingsGsd(settingsWithIndex).map((notificationSetting) => (
          <label className='container-Checkbox'>{notificationSetting.description}
            <input onChange={(e) => handleOnCheck(personIndex, notificationSetting.index, e.target.checked)} checked={notificationSetting.is_selected} type='checkbox' />
            <span className='checkmark' />
          </label>))}
      </div>
    </Grid>
  )
}

export const Notifications = ({ contactPersonsSettings, handleOnCheck }) => {
  return (
    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
      <Grid container direction='row' alignItems='center' spacing='' className='h-100'>
        <Grid item xs={1} className='h-100'>
          {/* Dummy column */}
        </Grid>
        <Grid item xs={11} className='h-100'>
          <Grid container columns={12} direction='row' alignItems='center' alignContent='stretch' spacing='' className='h-100'>
            <NotificationColumn person={1} settings={contactPersonsSettings[0]} handleOnCheck={handleOnCheck} />

            <NotificationColumn person={2} settings={contactPersonsSettings[1]} handleOnCheck={handleOnCheck} showGroupHeaders={false} />

            <NotificationColumn person={3} settings={contactPersonsSettings[2]} handleOnCheck={handleOnCheck} showGroupHeaders={false} />


          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Notifications
