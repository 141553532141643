import { Grid, TextField, FormHelperText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const NotificationContacts = ({ contactPersons, nameError, emailError, ccEmailError, handleOnEmailChange, handleOnCCEmailChange, handleOnNameChange }) => {
  const { t } = useTranslation()

  return (
    <Grid container style={{ paddingTop: '5px' }}>
      <Grid item xs='1' className='flex-wrap d-flex'>
        <label style={{ display: 'block', width: '100%' }} className='label-text label-text-notifi-form'><strong>{t('conveyor_belt_notifications.name')}</strong></label>
        <label style={{ display: 'block', width: '100%' }} className='label-text label-text-notifi-form'><strong>{t('conveyor_belt_notifications.email')}</strong></label>
        <label style={{ display: 'block', width: '100%' }} className='label-text label-text-notifi-form'><strong>{t('conveyor_belt_notifications.ccEmail')}</strong></label>
        {/* <label style={{ display:'block', width:'100%'}} className="label-text label-text-notifi-form"><strong>{t('conveyor_belt_notifications.sms')}</strong></label> */}
      </Grid>
      <Grid item xs='11' className='NotificationsHead'>
        <Grid container>
          {contactPersons && contactPersons.map((contact, i) => {
            return (
              <Grid key={'contact' + i} item xs='4' style={{ padding: '0px 8px' }}>
                <div style={{ marginBottom: '20px', position: 'relative' }}>
                  <TextField
                    value={contact.name}
                    onChange={(e) => handleOnNameChange(i, e.target.value)}
                    id='outlined-basic'
                    className='form-control'
                    variant='outlined'
                    fullWidth
                    label={t('conveyor_belt_notifications.name_label')}
                    size='small'
                  />
                  <FormHelperText error={Boolean(nameError['name' + (i + 1)])}>{nameError['name' + (i + 1)]}</FormHelperText>
                </div>
                <div style={{ marginBottom: '20px', position: 'relative' }}>
                  <TextField
                    onChange={(e) => handleOnEmailChange(i, e.target.value)}
                    value={contact.email}
                    id='outlined-basic'
                    className='form-control'
                    variant='outlined'
                    fullWidth
                    label={t('conveyor_belt_notifications.email_label')}
                    size='small'
                  />
                  <FormHelperText error={Boolean(emailError['email' + (i + 1)])}> {emailError['email' + (i + 1)]} </FormHelperText>
                </div>

                <div style={{ marginBottom: '20px', position: 'relative' }}>
                  <TextField
                    onChange={(e) => handleOnCCEmailChange(i, e.target.value)}
                    value={contact.email_cc}
                    id='outlined-basic'
                    className='form-control'
                    variant='outlined'
                    fullWidth
                    label={t('conveyor_belt_notifications.ccEmail_label')}
                    size='small'
                  />
                  <FormHelperText error={Boolean(ccEmailError['ccEmail' + (i + 1)])}> {ccEmailError['ccEmail' + (i + 1)]} </FormHelperText>
                </div>

                {/* <div style={{marginBottom: '20px', position: 'relative'}}>
                <TextField
                onChange={(e) => handleOnSmsChange(i,e.target.value)}
                value={contact.sms}
                id="outlined-basic"
                className="form-control"
                variant="outlined"
                fullWidth
                label={t('conveyor_belt_notifications.sms_label')}
                size="small" />
                <FormHelperText error={Boolean(smsError['sms'+(i+1)])} > {smsError['sms'+(i+1)]} </FormHelperText>
              </div> */}

              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotificationContacts
