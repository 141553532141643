import { call, put, takeLatest } from 'redux-saga/effects'
import * as customerUserActions from './customerUserActions'
import * as request from '../../apis/customerUsers'

function * getAllCustomerUserData ({ payload }) {
  try {
    const response = yield call(request.getCustomerUsers, payload.id, payload.data)
    const customerUsers = response.data
    const data = { customerUsers }
    if (payload.data) {
      data.totalPages = response.headers['x-totalpages']
      data.perPage = payload.data.per_page
      data.page = payload.data.page
    }
    yield put({
      type: customerUserActions.CUSTOMER_USER_LIST_SUCCESS,
      payload: { ...data }
    })
    yield put({ type: customerUserActions.CUSTOMER_USER_RESET_DELETE_STATE })
  } catch (error) {
    yield put({
      type: customerUserActions.CUSTOMER_USER_LIST_FAILURE,
      error: error.message
    })
  }
}

function * updateCustomerUser ({ payload }) {
  try {
    yield call(request.updateCustomerUser, payload.id, payload.customerUser)
    yield put({
      type: customerUserActions.CUSTOMER_USER_UPDATE_SUCCESS,
      success: true
    })
  } catch (error) {
    yield put({
      type: customerUserActions.CUSTOMER_USER_UPDATE_FAILURE,
      error: error.message
    })
  }
}

function * deleteCustomerUser ({ payload }) {
  try {
    yield call(request.deleteCustomerUser, payload.id)
    yield put({
      type: customerUserActions.CUSTOMER_USER_DELETE_SUCCESS,
      payload: {
        success: true,
        deletedId: payload.id
      }
    })
  } catch (error) {
    yield put({
      type: customerUserActions.CUSTOMER_USER_DELETE_FAILURE,
      error: error.message
    })
  }
}

const customerUserSaga = [
  takeLatest(
    customerUserActions.CUSTOMER_USER_LIST_REQUEST,
    getAllCustomerUserData
  ),
  takeLatest(
    customerUserActions.CUSTOMER_USER_UPDATE_REQUEST,
    updateCustomerUser
  ),
  takeLatest(
    customerUserActions.CUSTOMER_USER_DELETE_REQUEST,
    deleteCustomerUser
  )
]
export default customerUserSaga
