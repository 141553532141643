import request from './request'

export function getCustomerAreas (id) {
  return request.get(`customers/${id}/areas`)
}

export function addCustomerAreas (id, area) {
  return request.post(`customers/${id}/areas`, area)
}

export function deleteCustomerArea (id) {
  return request.delete(`areas/${id}`)
}
