import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../../components/Ux/Loader'
import { useParams } from 'react-router-dom'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_VARIABLES_REQUEST } from '../../../store/customerControl/customerControlActions'
import { mapVariables } from '../../../helpers/variableMappers'
import { BELT_CONVEYOR_CONTROL_1, BELT_CONVEYOR_CONTROL_2, BELT_CONVEYOR_CONTROL_3, BELT_CONVEYOR_CONTROL_TITLE } from '../../../constants/variables'
import Moment from 'react-moment'
import 'moment-timezone'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const ConveyorBeltInfoControl = () => {
  const classes = useStyles()
  const isMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  const variables = useSelector((state) => state.customerControl.variables)
  const control = useSelector((state) => state.customerControl.control)
  const [topControl, setTopControl] = useState(null)
  const [listVariablesOne, setListVariablesOne] = useState([])
  const [listVariablesTwo, setListVariablesTwo] = useState([])
  const [listVariablesThree, setListVariablesThree] = useState([])
  useSetControlTitle(params.id)

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
  }, [])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      const control = mapVariables(Object.values(variables), BELT_CONVEYOR_CONTROL_TITLE)
      setTopControl(control)

      const listVariablesOne = mapVariables(Object.values(variables), BELT_CONVEYOR_CONTROL_1)
      setListVariablesOne(listVariablesOne)
      const listVariablesTwo = mapVariables(Object.values(variables), BELT_CONVEYOR_CONTROL_2)
      setListVariablesTwo(listVariablesTwo)
      const listVariablesThree = mapVariables(Object.values(variables), BELT_CONVEYOR_CONTROL_3)
      setListVariablesThree(listVariablesThree)
    } else {
      isMount.current = true
    }
  }, [variables])

  const getCombinedValues = (variable) => {
    if (variable[0].value != null && variable[1].value != null) {
      return String(variable[0].value) + '.' + String(variable[1].value)
    } else if (variable[0].value != null) {
      return String(variable[0].value)
    } else if (variable[1].value != null) {
      return String(variable[1].value)
    }
    return ''
  }
  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <PollControlData />
      <Loader open={showLoader} />
      <CardContent>
        <Grid container className='MainContInnerStyle' alignContent='space-between'>
          <div className='Inner_scroll InfoTabContent' style={{ flex: '0 0 100%' }}>
            <div className='Inner_scroll-con pl-1 pr-1'>
              <Grid style={{ margin: '10px 0px' }} container>
                <Grid Item lg='5' xs='7'>
                  <strong> {topControl && topControl.label}:</strong>
                </Grid>
                <Grid lg='7' xs='5'>

                  {topControl && topControl.value}

                </Grid>
              </Grid>
              <Grid style={{ margin: '10px 0px' }} container>
                <Grid Item lg='5' xs='7'>
                  <strong>{t('conveyor_belt_info_belt_control.updated_date')} [YYYY-MM-DD]: </strong>
                </Grid>
                <Grid lg='7' xs='5'>
                  {control &&
                    <Moment format='YYYY-MM-DD'>
                      {control.variables_updated_at}
                    </Moment>}

                </Grid>
              </Grid>
              <Grid style={{ margin: '10px 0px' }} container>
                <Grid Item lg='5' xs='7'>
                  <strong>{t('conveyor_belt_info_belt_control.updated_time')} [hh:mm:ss]:</strong>
                </Grid>
                <Grid Item lg='7' xs='5'>
                  {control &&
                    <Moment tz="Europe/Berlin" format='HH:mm:ss'>
                      {control.variables_updated_at}
                    </Moment>}

                </Grid>
              </Grid>
              <br />
              <Grid style={{ margin: '10px 0px' }} container>
                <Grid Item lg='5' xs='7'>
                  <strong>{t('conveyor_belt_info_belt_control.id_control')}:</strong>
                </Grid>
                <Grid Item lg='7' xs='5'>
                  {control &&
                                control.serial_number}

                </Grid>
              </Grid>
              {listVariablesOne.map((variable) => {
                if ('length' in variable) {
                  return (<Grid style={{ margin: '10px 0px' }} container>
                    <Grid Item lg='5' xs='7'>
                      <strong>{variable[0].label}:</strong>
                    </Grid>
                    <Grid Item lg='7' xs='5'>
                      {getCombinedValues(variable)}
                    </Grid>
                          </Grid>)
                }
                return (<Grid style={{ margin: '10px 0px' }} container>
                  <Grid lg='5' xs='7'>
                    <strong>{variable.label}:</strong>
                  </Grid>
                  <Grid Item lg='7' xs='5'>
                    {variable.value}
                  </Grid>
                        </Grid>)
              })}
              <br />
              {listVariablesTwo.map((variable) => (
                <Grid style={{ margin: '10px 0px' }} container>
                  <Grid Item lg='5' xs='7'>
                    <strong>{variable.label}:</strong>
                  </Grid>
                  <Grid Item lg='7' xs='5'>
                    {variable.value}
                  </Grid>
                </Grid>
              ))}
              <br />
              {listVariablesThree.map((variable) => (
                <Grid style={{ margin: '10px 0px' }} container>
                  <Grid Item lg='5' xs='7'>
                    <strong>{variable.label}:</strong>
                  </Grid>
                  <Grid style={{ color: (variable.value.toLowerCase() === 'leer' || variable.value.toLowerCase() === 'empty') ? 'red' : 'black' }} Item lg='7' xs='5'>
                    {variable.value}
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Sidebars(ConveyorBeltInfoControl, { showSearch: false })
