import React, { useEffect } from 'react'
import {
  Button,
  Container,
  Grid
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'

import useSetControlTitle from '../../components/hooks/useSetControlTitle'
import Sidebars from '../../components/layouts/Sidebars'
import useSetMessageIcon from '../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../components/hooks/useShowConnectionSymbol'
import { CUSTOMER_CONTROL_GET_CONTROL_REQUEST } from '../../store/customerControl/customerControlActions'

import { PollControlData } from '../../components/polling'
import AutoUpdate from '../../components/AutoUpdate/components/AutoUpdate'
import config from '../../apis/api-constants'

function SoftwareUpdate (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  const fieldModuleId = params.id
  // useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  useSetControlTitle(params.id)
  useEffect(() => {
    dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: fieldModuleId })
  }, [])

  const apiToken = useSelector((state) => state.user.user.token)
  const control = useSelector((state) => state.customerControl.control)

  // get locales of AutoUpdate from i18next
  const texts = t('auto_update', { returnObjects: true })
  if (texts === 'auto_update') {
    // locales not ready yet...
    return <></>
  }

  return (
    <Container component='main' maxWidth='xl' fixed>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AutoUpdate
            fieldModule={{
              id: fieldModuleId,
              online: control && control.online,
              readyForUpdates: control && control.ready_for_updates,
              updatesAvailable: control && control.updates_available
            }}
            apiToken={apiToken}
            baseUrl={config.baseUrl}
            cableUrl={config.cableUrl}
            texts={texts}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Sidebars(SoftwareUpdate, { showSearch: false })
