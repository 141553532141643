import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import reduxSagaMiddleware from 'redux-saga'
import user from './users/userReducer'
import customer from './customer/customerReducer'
import customerUser from './customerUser/customerUserReducer'
import customerArea from './customer-area/customerAreaReducer'
import customerControl from './customerControl/customerControlReducer'
import representation from './representation/representationReducer'
import representationCustomer from './representationCustomer/representationCustomerReducer'
import representativeCustomer from './representative-customer/representativeCustomerReducer'
import conveyorBelt from './conveyor-belt/conveyorBeltReducer'
import utility from './utility/utilityReducer'
import customerToken from './customerToken/customerTokenReducer'
import representationToken from './representationToken/representationTokenReducer'
import customerLogin from './customerLogin/customerLoginReducer'
import messages from './message/messageReducer'
import notification from './noiifications/notificationReducer'
import damage from './damage/damageReducer'
import beltSettings from './beltSettings/beltSettingsReducer'
import rootSaga from './saga'
const sagaMiddleware = reduxSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    user,
    customer,
    customerUser,
    customerArea,
    customerControl,
    representation,
    representationCustomer,
    representativeCustomer,
    conveyorBelt,
    utility,
    customerToken,
    representationToken,
    customerLogin,
    messages,
    notification,
    damage,
    beltSettings
  }),
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga)
export default store
