import camelize from 'camelize'

export const variablesByVarNr = (state) => {
  const retVal = {}
  Object.values(state.customerControl.variables || {}).forEach(variable => {
    retVal[variable.var_nr] = { ...variable }
  });
  return retVal
}

export const scrapersByNr = (state) => {
  const retVal = {}
  state.customerControl.control?.scrapers?.forEach(scraper => {
    retVal[scraper.number] = camelize({ ...scraper })
  });
  return retVal
}
