import { call, put, takeLatest } from 'redux-saga/effects'
import * as representationCustomerActions from './representationCustomerActions'
import * as request from '../../apis/representationCustomers'

function * getAllRepresentationCustomerData ({ payload }) {
  try {
    const response = yield call(request.getRepresentationCustomers, payload.id, payload.data)
    const representationCustomers = response.data
    yield put({
      type: representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_SUCCESS,
      payload: {
        representationCustomers,
        totalPages: response.headers['x-totalpages'],
        perPage: payload.data.per_page,
        page: payload.data.page
      }
    })
    yield put({
      type: representationCustomerActions.REPRESENTATION_CUSTOMER_RESET_DELETE_STATE
    })
  } catch (error) {
    yield put({
      type: representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_FAILURE,
      error: error.message
    })
  }
}

function * deleteRepresentationCustomer ({ payload }) {
  try {
    yield call(
      request.deleteRepresentationCustomer,
      payload.representationId,
      payload.customerId
    )
    yield put({
      type: representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_SUCCESS,
      payload: { success: true, id: payload.customerId }
    })
  } catch (error) {
    yield put({
      type: representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_FAILURE,
      error: error.message
    })
  }
}

const representationCustomerSaga = [
  takeLatest(
    representationCustomerActions.REPRESENTATION_CUSTOMER_LIST_REQUEST,
    getAllRepresentationCustomerData
  ),
  takeLatest(
    representationCustomerActions.REPRESENTATION_CUSTOMER_DELETE_REQUEST,
    deleteRepresentationCustomer
  )
]
export default representationCustomerSaga
