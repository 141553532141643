import { call, put, takeLatest } from 'redux-saga/effects'
import * as representationActions from './representationActions'
import * as request from '../../apis/representations'

function * getAllRepresentationData ({ payload }) {
  try {
    const response = yield call(request.getRepresentations, payload)
    const representations = response.data
    yield put({
      type: representationActions.REPRESENTATION_LIST_SUCCESS,
      payload: {
        representations,
        totalPages: response.headers['x-totalpages'],
        perPage: payload.per_page,
        page: payload.page
      }
    })
    yield put({
      type: representationActions.REPRESENTATION_RESET_DELETE_STATE
    })
  } catch (error) {
    yield put({
      type: representationActions.REPRESENTATION_LIST_FAILURE,
      error: error.message
    })
  }
}

function * deleteRepresentation ({ payload }) {
  try {
    yield call(request.deleteRepresentation, payload.id)
    yield put({
      type: representationActions.REPRESENTATION_DELETE_SUCCESS,
      payload: { success: true, id: payload.id }
    })
  } catch (error) {
    yield put({
      type: representationActions.REPRESENTATION_DELETE_FAILURE,
      error: error.message
    })
  }
}

function * getRepresentationData ({ payload }) {
  try {
    const response = yield call(request.getRepresentation, payload.id)
    const representation = response.data
    yield put({
      type: representationActions.GET_REPRESENTATION_SUCCESS,
      representation
    })
  } catch (error) {
    yield put({
      type: representationActions.GET_REPRESENTATION_FAILURE,
      error: error.message
    })
  }
}

const representationSaga = [
  takeLatest(
    representationActions.REPRESENTATION_LIST_REQUEST,
    getAllRepresentationData
  ),
  takeLatest(
    representationActions.GET_REPRESENTATION_REQUEST,
    getRepresentationData
  ),
  takeLatest(
    representationActions.REPRESENTATION_DELETE_REQUEST,
    deleteRepresentation
  )
]
export default representationSaga
