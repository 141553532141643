import * as userActions from './userActions'
import {
  SERVICE_USER,
  SUPER_USER,
  REPRESENTATION_USER,
  TOP_USER,
  USER
} from '../../constants/roles'

const initalState = {
  loading: false,
  scopeCustomerId: localStorage.getItem('scope_customer_id')
    ? JSON.parse(localStorage.getItem('scope_customer_id'))
    : null,
  scopeRepresentativeId: localStorage.getItem('scope_representative_id')
    ? JSON.parse(localStorage.getItem('scope_representative_id'))
    : null,
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null,
  profile: localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : null,
  error: false,
  userSwitch: false,
  updatedLocale: { status: true }
}

const roles = (role) => ({
  representation: role === REPRESENTATION_USER,
  superUser: role === SUPER_USER,
  topUser: role === TOP_USER,
  user: role === USER,
  serviceUser: role === SERVICE_USER
})

const user = (state = initalState, action) => {
  switch (action.type) {
    case userActions.USER_LOGIN_REQUEST:
      return { ...state, loading: true }
    case userActions.USER_LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.payload))
      return { ...state, loading: false, error: false, user: { ...action.payload } }
    case userActions.USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload }
    case userActions.USER_LOGOUT_REQUEST:
      return { ...state, loading: true }
    case userActions.USER_LOGOUT_SUCCESS:
      localStorage.removeItem('user')
      localStorage.removeItem('profile')
      localStorage.removeItem('scope_customer_id')
      localStorage.removeItem('scope_representative_id')
      localStorage.removeItem('isRepresentativeSuperAccess')
      return { ...state, loading: false, user: null, profile: null, scopeCustomerId: null, scopeRepresentativeId: null }

    case userActions.USER_LOGOUT_FAIL:
      return { ...state, loading: false, error: action.payload }

    case userActions.USER_PROFILE_SUCCESS:
      const nextProfileState = { ...action.payload, hasRole: roles(action.payload.role) }
      localStorage.setItem('profile', JSON.stringify(nextProfileState))

      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (action && action.payload && action.payload.role) {
          user.role = action.payload.role
          localStorage.setItem('user', JSON.stringify(user))
          return { ...state, profile: { ...nextProfileState }, user: { ...user } }
        }
      }

      return { ...state, profile: { ...nextProfileState } }

    case userActions.USER_PROFILE_FAIL:
      return { ...state, profile: null, error: action.payload }

    case userActions.SET_SCOPED_CUSTOMER_ID:
      localStorage.setItem('scope_customer_id', action.payload)
      localStorage.removeItem('scope_representative_id')
      return { ...state, scopeCustomerId: action.payload, userSwitch: true }

    case userActions.SET_SCOPED_REPRESENTATIVE_ID:
      localStorage.setItem('scope_representative_id', action.payload)
      localStorage.removeItem('scope_customer_id')
      return { ...state, scopeRepresentativeId: action.payload, userSwitch: true }

    case userActions.REMOVE_SCOPED_CUSTOMER_ID:
      localStorage.removeItem('scope_customer_id')
      return { ...state, scopeCustomerId: null, userSwitch: false }

    case userActions.REMOVE_SCOPED_REPRESENTATIVE_ID:
      localStorage.removeItem('scope_representative_id')
      return { ...state, scopeRepresentativeId: null, userSwitch: false }
    case userActions.USER_LOCALE_UPDATE_SUCCESS:
      localStorage.setItem('profile', JSON.stringify({ ...action.payload, hasRole: roles(action.payload.role) }))
      return { ...state, profile: { ...state.profile, ...action.payload, hasRole: roles(action.payload.role) }, updatedLocale: { status: true } }
    case userActions.USER_LOCALE_UPDATE_FAILURE:
      return { ...state, profile: null, updatedLocale: { status: false } }

    default:
      return state
  }
}

export default user
