import { takeLatest, call, put } from 'redux-saga/effects'
import * as customerTokenActions from './customerTokenActions'
import { getCustomerRfid, createCustomerRfid, deleteRfid, updateRfid } from '../../apis/rfid-tokens'

function * getCustomerToken ({ payload }) {
  try {
    const response = yield call(getCustomerRfid, payload)
    if (response.status === 200) {
      yield put({
        type: customerTokenActions.CUSTOMER_TOKEN_LIST_SUCCESS,
        payload: {
          tokens: response.data,
          totalPages: response.headers['x-totalpages'],
          perPage: payload.data.per_page,
          page: payload.data.page
        }
      })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    yield put({ type: customerTokenActions.CUSTOMER_TOKEN_LIST_FAILURE, payload: err.message })
  }
}

function * createCustomerToken ({ payload }) {
  try {
    const response = yield call(createCustomerRfid, payload)
    if (response.status === 201) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_ADD_SUCCESS, payload: response.data })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_ADD_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.CUSTOMER_TOKEN_ADD_FAILURE, payload: err.message })
  }
}

function * deleteCustomerToken ({ payload }) {
  try {
    const response = yield call(deleteRfid, payload)
    if (response.status === 204) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_DELETE_SUCCESS, payload: payload })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_LIST_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.CUSTOMER_TOKEN_LIST_FAILURE, payload: err.message })
  }
}

function * updateCustomerToken ({ payload }) {
  try {
    const response = yield call(updateRfid, payload.id, payload.data)
    if (response.status === 200) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_UPDATE_SUCCESS, payload: payload.id })
      return
    }
    throw (new Error('Something Went Wrong'))
  } catch (err) {
    const { response } = err
    if (response && response.status === 422) {
      yield put({ type: customerTokenActions.CUSTOMER_TOKEN_UPDATE_FAILURE, payload: response.data.message })
    }
    yield put({ type: customerTokenActions.CUSTOMER_TOKEN_UPDATE_FAILURE, payload: err.message })
  }
}

const customerTokenSaga = [
  takeLatest(customerTokenActions.CUSTOMER_TOKEN_LIST_REQUEST, getCustomerToken),
  takeLatest(customerTokenActions.CUSTOMER_TOKEN_ADD_REQUEST, createCustomerToken),
  takeLatest(customerTokenActions.CUSTOMER_TOKEN_DELETE_REQUEST, deleteCustomerToken),
  takeLatest(customerTokenActions.CUSTOMER_TOKEN_UPDATE_REQUEST, updateCustomerToken)
]
export default customerTokenSaga
