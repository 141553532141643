import * as customerTokenActions from './customerTokenActions'

const initialState = {
  loading: false,
  tokens: [],
  token: null,
  success: false,
  error: null,
  data: null,
  isDeleted: 0,
  isUpdated: 0,
  perPage: 10,
  page: 1,
  totalPages: 0
}

const customerToken = (state = initialState, action) => {
  switch (action.type) {
    case customerTokenActions.CUSTOMER_TOKEN_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        isUpdated: 0,
        isDeleted: 0,
        data: null,
        tokens: action.payload.tokens.map((data) => ({ ...data })),
        perPage: action.payload.perPage,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        error: null
      }
    case customerTokenActions.CUSTOMER_TOKEN_LIST_FAILURE:
      return { ...state, success: false, data: null, error: action.payload }
    case customerTokenActions.CUSTOMER_TOKEN_ADD_SUCCESS:
      return { ...state, success: true, error: null, data: { ...action.payload } }
    case customerTokenActions.CUSTOMER_TOKEN_ADD_FAILURE:
      return { ...state, success: false, data: null, error: action.payload }
    case customerTokenActions.CUSTOMER_TOKEN_DELETE_SUCCESS:
      return { ...state, success: false, isDeleted: action.payload }
    case customerTokenActions.CUSTOMER_TOKEN_DELETE_FAILURE:
      return { ...state, success: false, error: action.payload }
    case customerTokenActions.CUSTOMER_TOKEN_UPDATE_SUCCESS:
      return { ...state, success: false, isUpdated: action.payload }
    case customerTokenActions.CUSTOMER_TOKEN_UPDATE_FAILURE:
      return { ...state, success: false, error: action.payload }

    default:
      return state
  }
}

export default customerToken
