
import * as beltSettingsActions from './beltSettingsActions'

const initalState = {
  readErrorList: null,
  readAccessList: null,
  readVariableList: null,
  readMeasurementList: null,
  readBeltDamageList: null,
  softwareUpdate: null,
  error: null,
  jobStatus: {},
  fileStatus: null,
  settingsIntervalClearedKey: ''
}

const beltSettingsReducer = (state = initalState, action) => {
  switch (action.type) {
    case beltSettingsActions.BELT_SETTINGS_READ_ERROR_LIST_SUCCESS:
      return {
        ...state,
        readErrorList: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_READ_ERROR_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }

    case beltSettingsActions.BELT_SETTINGS_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        readAccessList: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_ACCESS_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.BELT_SETTINGS_VARIABLE_LIST_SUCCESS:
      return {
        ...state,
        readVariableList: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_VARIABLE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.BELT_SETTINGS_MEASUREMENT_LIST_SUCCESS:
      return {
        ...state,
        readMeasurementList: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_MEASUREMENT_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.BELT_SETTINGS_BELT_DAMAGE_LIST_SUCCESS:
      return {
        ...state,
        readBeltDamageList: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_BELT_DAMAGE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.BELT_SETTINGS_SOFTWARE_UPDATE_SUCCESS:
      return {
        ...state,
        softwareUpdate: { ...action.payload }
      }
    case beltSettingsActions.BELT_SETTINGS_SOFTWARE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.SETTINGS_JOB_SUCCESS:
      return {
        ...state,
        jobStatus: { ...state.jobStatus, [action.payload.key]: { ref: action.payload.ref, status: action.payload.status } }
      }
    case beltSettingsActions.SETTINGS_JOB_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case beltSettingsActions.SETTINGS_JOB_CLEAR:
      for (const key in state.jobStatus) {
        if (state.jobStatus[key].status.succeeded === false) {
          clearInterval(state.jobStatus[key].ref)
        }
      }
      return { ...state, jobStatus: {} }
    case beltSettingsActions.SETTINGS_GET_REQUESTED_FILE_SUCCESS:
      const jobStatusCopy = { ...state.jobStatus }
      delete jobStatusCopy[action.payload.key]
      return {
        ...state,
        fileStatus: { ...state.fileStatus, [action.payload.key]: action.payload.data },
        jobStatus: { ...jobStatusCopy }
      }
    case beltSettingsActions.SETTINGS_GET_REQUESTED_FILE_FAILURE:
      const jobStatusCopy2 = { ...state.jobStatus }
      delete jobStatusCopy2[action.ref]
      return {
        ...state,
        error: action.error,
        jobStatus: { ...jobStatusCopy2 }
      }
    case beltSettingsActions.SETTINGS_INTERVAL_CLEARED_SUCCESS:
      return {
        ...state,
        settingsIntervalClearedKey: action.payload
      }
    case beltSettingsActions.SETTINGS_INTERVAL_CLEARED_FAILURE:
      return {
        ...state,
        settingsIntervalClearedKey: action.payload
      }
    case beltSettingsActions.SETTINGS_CLEAR_FILE_STATUS:
      return {
        ...state,
        fileStatus: {}
      }
    default:
      return state
  }
}

export default beltSettingsReducer
