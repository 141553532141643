import { call, put, takeLatest } from 'redux-saga/effects'
import * as customerControlActions from './customerControlActions'
import * as request from '../../apis/customerControls'

function * getAllCustomerControlData ({ payload }) {
  try {
    const response = yield call(request.getCustomerControls, payload.id, payload.data)
    const customerControls = response.data
    const data = { customerControls }

    if (payload.data) {
      data.totalPages = response.headers['x-totalpages']
      data.perPage = payload.data.per_page
      data.page = payload.data.page
    }
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_LIST_SUCCESS,
      payload: { ...data }
    })
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_RESET_DELETE_STATE
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_LIST_FAILURE,
      error: error.message
    })
  }
}

function * getCustomerControlData ({ payload }) {
  try {
    const response = yield call(request.getCustomerControl, payload)
    const control = response.data
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_CONTROL_SUCCESS,
      payload: { ...control }
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_CONTROL_FAILURE,
      error: error.message
    })
  }
}

function * getCustomerControlVariables ({ payload }) {
  try {
    const response = yield call(request.getCustomerControlVariables, payload)
    const control = response.data
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_SUCCESS,
      payload: { ...control }
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_FAILURE,
      error: error.message
    })
  }
}

function * getScraper ({ payload }) {
  try {
    const response = yield call(request.getScraper, payload)
    const scraper = response.data
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_SCRAPER_SUCCESS,
      payload: { ...scraper }
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_SCRAPER_FAILURE,
      error: error.message
    })
  }
}

function * updateScraper ({ payload }) {
  try {
    const response = yield call(
      request.updateScraper,
      payload.id,
      payload.scraper
    )
    const data = response.data
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_SUCCESS,
      payload: { ...data },
      success: true
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_FAILURE,
      error: error.message
    })
  }
}

function * getCustomerControlVariablesChart ({ payload }) {
  try {
    const response = yield call(request.getCustomerControlVariablesChart, payload.id, payload.varId)
    const chartData = response.data
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_CHART_SUCCESS,
      payload: [...chartData]
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_CHART_FAILURE,
      error: error.message
    })
  }
}

function * updateCustomerControl ({ payload }) {
  try {
    yield call(
      request.updateCustomerControl,
      payload.id,
      payload.customerControl
    )
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_UPDATE_SUCCESS,
      success: true
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_UPDATE_FAILURE,
      error: error.message
    })
  }
}

function * updateCustomerControlVariables ({ payload }) {
  try {
    const response = yield call(
      request.updateCustomerControlVariables,
      payload.id,
      payload.data
    )
    if (response.status === 204) {
      yield put({
        type: customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_SUCCESS,
        payload: { success: true }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    if (err && err.response.status && err.response.status === 422 && err.response.data) {
      yield put({
        type: customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_FAILURE,
        payload: err.response.data
      })
      return
    }
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_FAILURE,
      payload: { message: err.message }
    })
  }
}

function * getCustomerControlVariablesReload ({ payload }) {
  try {
    const response = yield call(
      request.reloadCustomerControlVariables,
      payload
    )
    if (response.status === 204) {
      yield put({
        type: customerControlActions.CUSTOMER_CONTROL_VARIABLES_RELOAD_SUCCESS,
        payload: { success: true }
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_VARIABLES_RELOAD_FAILURE,
      payload: err.message
    })
  }
}

function * deleteCustomerControl ({ payload }) {
  try {
    yield call(request.deleteCustomerControl, payload.id)
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_DELETE_SUCCESS,
      payload: {
        success: true,
        deletedId: payload.id
      }
    })
  } catch (error) {
    yield put({
      type: customerControlActions.CUSTOMER_CONTROL_DELETE_FAILURE,
      error: error.message
    })
  }
}

const customerControlSaga = [
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_LIST_REQUEST,
    getAllCustomerControlData
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_UPDATE_REQUEST,
    updateCustomerControl
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_DELETE_REQUEST,
    deleteCustomerControl
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_GET_CONTROL_REQUEST,
    getCustomerControlData
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_REQUEST,
    getCustomerControlVariables
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_CHART_REQUEST,
    getCustomerControlVariablesChart
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST,
    updateCustomerControlVariables
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_VARIABLES_RELOAD_REQUEST,
    getCustomerControlVariablesReload
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_SCRAPER_REQUEST,
    getScraper
  ),
  takeLatest(
    customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST,
    updateScraper
  )
]
export default customerControlSaga
