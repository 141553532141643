import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import Loader from '../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCustomerControl } from '../../apis/customerControls'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { CUSTOMER_AREA_LIST_REQUEST } from '../../store/customer-area/customerAreaActions'
import SelectInput from '../../components/forms/SelectInput'
import MenuItem from '@material-ui/core/MenuItem'
import PopupDialog from '../../components/PopupDialog'

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '50px'
    }
  },
  variant: 'menu',
  getContentAnchorEl: null
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const AddCustomerControl = () => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const [formParams, setFormParams] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const [openAreaChangeDialog, setOpenAreaChangeDialog] = useState(false)
  const [areaChangeConfirmed, setAreaChangeConfirmed] = useState(false)
  const customerAreaList = useSelector((state) => state.customerArea)
  const { customerAreas } = customerAreaList
  const { t } = useTranslation()
  const actionsClasses = useActions()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  useSetParentCustomInnerHeaderHeading()

  const submitForm = async (formData) => {
    try {
      setShowLoader(true)
      formData.customer_id = params.id
      const res = await addCustomerControl(formData)
      if (res.status === 200) {
        setShowLoader(false)
        history.push('../')
        toast.success(t('add_customer_controls.toast.control_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 404) {
        message = error.response.data.message || error.message
      } else if (error && error.response && error.response.status === 422) {
        message = error.response.data.message || error.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
    // reset form state
    setFormParams(null)
    setAreaChangeConfirmed(false)
  }

  // submit form automatically when area change has been confirmed and formParams are present
  useEffect(() => {
    if (areaChangeConfirmed && formParams) {
      submitForm(formParams)
    }
  }, [areaChangeConfirmed, formParams])

  useEffect(() => {
    dispatch({ type: CUSTOMER_AREA_LIST_REQUEST, payload: { id: params.id } })
  }, [dispatch])

  const customerAreaOptions = customerAreas.map((customerArea) => {
    return { label: customerArea.name, value: customerArea.id }
  })

  const handleSubmit = async (formData) => {
    // remember new form data
    setFormParams(formData)
    // set selected area to be able to show cost hint in dialog
    const area = customerAreas.find(x => x.id === Number(formData.area_id))
    const moreUserLicencesNeeded = area && area.next_field_module_costs > 0
    setSelectedArea(area)
    // open dialog if necessary (user needs to confirm if there are additional costs)
    if (moreUserLicencesNeeded) {
      // let the user confirm
      setOpenAreaChangeDialog(true)
    } else {
      // user does not need to confirm
      setAreaChangeConfirmed(true)
    }
  }

  const handleConfirmAreaChange = (e, i, areaId) => {
    setOpenAreaChangeDialog(false)
    if (e.currentTarget.value === 'true') {
      // user clicked yes
      setAreaChangeConfirmed(true)
    } else {
      // user clicked no
      setAreaChangeConfirmed(false)
    }
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />
      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent className=''>
          <div className={classes.paper}>

            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.name_required')
                  },
                  maxLength: {
                    maxLength: 35,
                    message: t('add_customer_controls.validation.name_max_length')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='name'
                label={t('add_customer_controls.name')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.control_name_required')
                  },
                  maxLength: {
                    maxLength: 35,
                    message: t('add_customer_controls.validation.control_name_max_length')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='control_name'
                label={t('add_customer_controls.control_name')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.serial_number_required')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='serial_number'
                label={t('add_customer_controls.serialNumber')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.licence_required')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='license_number'
                label={t('add_customer_controls.licenseNumber')}
                type='text'
              />
            </div>
            <div style={{ alignSelf: 'flex-start' }}>
              <SelectInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.area_required')
                  }
                }}
                variant='outlined'
                fullWidth
                name='area_id'
                label={t('add_customer_controls.area')}
                type='text'
                MenuProps={MenuProps}
              >
                {customerAreaOptions.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)}
              </SelectInput>
            </div>
          </div>
        </CardContent>

        <CardActions className='ButtonPanelWrap ButtonPanelWrap-2'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-end' }}>
            <Button
              className={classes.submit}
              type='submit'
              variant='outlined'
            >
              <img src='/assets/img/save.png' />&nbsp;&nbsp;
              {t('add_customer_controls.save')}
            </Button>
          </Box>
        </CardActions>
      </SubmitForm>
      <PopupDialog
        open={openAreaChangeDialog}
        message={t('area_customer_controls.dialog.change_area.message', { additionalUserLicences: selectedArea && selectedArea.next_field_module_costs })}
        onClick={handleConfirmAreaChange}
      />
    </Card>
  )
}

export default Sidebars(AddCustomerControl)
