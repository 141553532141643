import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import './i18n'
import App from './App'
import store from './store/store'
import theme from './theme/index'
import './index.css'
import './custom.css'
import './fonts/Avenir-Next-Font.woff'
import './fonts/Avenir-Next-Font.woff2'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
