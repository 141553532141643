import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Moment from 'react-moment'
import 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import Pagination from '@material-ui/lab/Pagination'
import { MESSAGE_LIST_REQUEST } from '../../store/message/messageAction'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import {
  REPRESENTATION_USER
} from '../../constants/roles'
const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const Customers = () => {
  const perPage = 10
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const actionsClasses = useActions()
  const [page, setPage] = useState(1)
  const { user, scopeRepresentativeId } = useSelector((state) => state.user)
  const profile = useSelector((state) => state.user.profile)
  const isErrorMount = useRef(false)
  // Setting state with redux store values
  const { loading, error, messages, totalPages } = useSelector(
    (state) => state.messages
  )
  useSetParentCustomInnerHeaderHeading()
  // only fetch events with the following event codes
  let query = 'q[code_in][]=2&q[code_in][]=3&q[code_in][]=16&q[code_in][]=17'
  useEffect(() => {
    if (user.role === REPRESENTATION_USER || scopeRepresentativeId) {
      query += `&q[representation_id_eq]=${scopeRepresentativeId || profile.representation_id}`
    }
    query += `&per_page=${perPage}&page=${page}`
    dispatch({
      type: MESSAGE_LIST_REQUEST,
      payload: query
    })
  }, [dispatch, page, perPage])

  useEffect(() => {
    if (isErrorMount.current) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    setPage(selectedPage)
  }
  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>
          <Loader open={loading} />

          <Grid container alignContent='space-between' className='MainContInnerStyle h-100'>
            <Grid item xs='12' className='Inner_scroll'>
              <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
                {messages.map((message) => (
                  <Grid container direction='row' justifyContent='space-between' key={message.id} className='RowData RowData-list-service'>

                    <Grid item xs={11}>
                      <Grid container direction='row' justifyContent='space-between' className='pr-2 '>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pt-1 MessageEventTitle'>
                          <Moment tz="Europe/Berlin" format='YYYY-MM-DD'>{message.timestamp}</Moment>
                        </Grid>

                        <Grid item xs={8} className='Mid-Con'>
                          <Grid container direction='row' justifyContent='space-between' className='pt-1'>

                            <Grid item className='pl-0 MessageEventTitle'>
                              <strong>{message.event_title1}</strong>
                            </Grid>
                            <Grid item textAlign='right' className='MessageEventTitle'>
                              {message.event_title2}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con '>
                          <div>
                            <Moment tz="Europe/Berlin" format=' HH:mm:ss'>{message.timestamp}</Moment>
                          </div>
                        </Grid>

                        <Grid item xs={8} className='Mid-Con'>
                          <hr className='HrBorder' style={{ padding: 0, margin: 0 }} />
                          {message.description1}
                        </Grid>
                      </Grid>

                      <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pb-1 MessageEventTitleBottom'>
                          {message.event_type_name}
                        </Grid>

                        <Grid item xs={8} className='Mid-Con pb-1 MessageEventTitleBottom'>
                          <div>
                            {message.description2}
                          </div>

                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={1} className='Last-Con cancel-icon-col'>
                      {message.event_type_short}
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
          {!messages.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}

        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>

          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            {messages.length && totalPages ? <Pagination style={{ margin: 'auto' }} className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
          </Box>
        </CardActions>
      </Card>
    </>
  )
}

export default Sidebars(Customers)
