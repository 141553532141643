import React, { useState } from 'react'
import FormContext from '../../contexts/formContext'

/*
Submit Form is the wrappper component for the custom component's that we have created.
So far three SelectInput, SubmitForm and Text Input component.

We have three main function in out submit form context

1. setFieldInValid
it will set the field name at invalidFields array. Child input call this function to update error.
2.removeFieldInValid
child components will call this to remove themself from invalid from invalid fields array.
3. setValue
It will set the value of the formData state. It is called by their children so they will set their latest value

Handle Submit function is called which check's that is there an error in invalidField if there are invalid values
then it will set showErrors state which is in context when it updates top true then children component's have to show
errors so user can correct them if invalidFields array is empty the it will call the onSubmit function which we can use
to fire an api request.
*/

const SubmitForms = ({ className, onSubmit, children, ...rest }) => {
  const [showErrors, setShowErrors] = useState(false)
  const [invalidFields, setInvalidFields] = useState([])
  const [formData, setFormData] = useState({})
  const handleSubmit = (e) => {
    e.preventDefault()
    if (invalidFields.length === 0 && onSubmit) {
      onSubmit(formData)
      return
    }

    if (invalidFields.length !== 0) {
      setShowErrors(true)
    }
  }
  return (
    <form className={(className || '') + ' ux-custom-form'} {...rest} onSubmit={handleSubmit}>
      <FormContext.Provider
        value={{
          showErrors,
          setFieldInValid: (field) => {
            setInvalidFields((state) => {
              return [...state, field]
            })
          },
          removeFieldInValid: (field) => {
            setInvalidFields((state) => {
              return state.filter(
                (invalidFieldName) => invalidFieldName !== field
              )
            })
          },
          setValue: (name, value) => {
            setFormData((oldFormData) => ({ ...oldFormData, [name]: value }))
          }
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  )
}

export default SubmitForms
