export const SUPER_USER = 'super_user'
export const SERVICE_USER = 'service_user'
export const REPRESENTATION_USER = 'representation'
export const TOP_USER = 'top_user'
export const USER = 'user'

export const roles = [
  { id: SUPER_USER, name: 'Super user' },
  { id: SERVICE_USER, name: 'Service user' },
  { id: REPRESENTATION_USER, name: 'Representation' },
  { id: TOP_USER, name: 'Top user' },
  { id: USER, name: 'User' }
]

export const tokenRoles = [
  { id: SUPER_USER, name: 'Super user' },
  { id: SERVICE_USER, name: 'Service user' },
  { id: USER, name: 'User' }
]
