import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSelector, useDispatch } from 'react-redux'
import { USER_LOGIN_REQUEST, USER_LOCALE_UPDATE_REQUEST } from '../store/users/userActions'
import Loader from '../components/Ux/Loader'
import ErrorAlert from '../components/ErrorAlert'
import TextInput from '../components/forms/TextInput'
import SubmitForm from '../components/forms/SubmitForm'
import * as utilityActions from '../store/utility/utilityActions'
import { toast } from 'react-toastify'
import siteConfig from '../apis/api-constants'
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    textDecoration: 'none'
  }
}))

const Login = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const isUpdatedLocaleMount = useRef(false)
  const isErrorMount = useRef(false)
  const userLogin = useSelector((state) => state.user)
  const [showLoader, setShowLoader] = useState(false)

  const { error, user, updatedLocale } = userLogin

  useEffect(() => {
    dispatch({ type: utilityActions.ADD_CUSTOME_HEADER_CLASSES, payload: 'mega-header' })
    return () => {
      dispatch({ type: utilityActions.REMOVE_CUSTOME_HEADER_CLASSES })
    }
  }, [])

  useEffect(() => {
    if (isErrorMount.current && error) {
      setShowLoader(false)
    } else {
      isErrorMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isUpdatedLocaleMount.current) {
      if (updatedLocale && Boolean(updatedLocale.status) === false) {
        toast.error('failed to update language.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
      }
      history.replace('/')
      setShowLoader(false)
    } else {
      isUpdatedLocaleMount.current = true
    }
  }, [updatedLocale])

  useEffect(() => {
    if (user) {
      const language = localStorage.getItem('i18nextLng')
      dispatch({ type: USER_LOCALE_UPDATE_REQUEST, payload: language || 'en' })
    }
  }, [history, user])

  const handleSubmit = (account) => {
    setShowLoader(true)
    dispatch({
      type: USER_LOGIN_REQUEST,
      payload: {
        client_id: siteConfig.clientId,
        grant_type: 'password',
        email: account.email,
        password: account.password,
        scope: 'frontend'
      }

    })
  }

  return (
    <div className='LogIn'>
      <Container component='main' maxWidth='xs'>
        <Loader open={showLoader} />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {t('login.title')}
          </Typography>
          {isErrorMount.current && error && <ErrorAlert message={error.message ? error.message : 'Something went wrong'} />}
          <SubmitForm className={classes.form} onSubmit={handleSubmit}>
            <TextInput
              validations={{
                required: {
                  message: t('login.email_validation')
                }
              }}
              variant='outlined'
              margin='normal'
              fullWidth
              name='email'
              label={t('login.email_label')}
              type='email'
              autoFocus
            />
            <TextInput
              validations={{
                required: {
                  message: t('login.password_validation')
                }
              }}
              variant='outlined'
              margin='normal'
              fullWidth
              name='password'
              label={t('login.password_label')}
              type='password'
            />
            <Button
              className={classes.submit}
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
            >
              {t('login.title')}
            </Button>
          </SubmitForm>
          <div>
            <a href={`${siteConfig.forgotPasswordUrl}?locale=${localStorage.getItem('i18nextLng')}`}>{t('forgotpassword.title')}</a>
          </div>

        </div>
      </Container>
    </div>
  )
}

export default Login
