import * as conveyorBeltActions from './conveyorBeltActions'

const initalState = {
  cenveyorBelts: [],
  perPage: 10,
  page: 1,
  totalPages: 0
}

const cenveyorBeltReducer = (state = initalState, action) => {
  switch (action.type) {
    case conveyorBeltActions.CONVEYOR_BELT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cenveyorBelts: action.payload.conveyorBelts.map((customerControl) => {
          return { ...customerControl }
        }),
        perPage: action.payload.perPage ? action.payload.perPage : state.perPage,
        page: action.payload.page ? action.payload.page : state.page,
        totalPages: action.payload.totalPages ? action.payload.totalPages : state.totalPages
      }
    case conveyorBeltActions.CONVEYOR_BELT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        cenveyorBelts: [],
        error: action.error
      }
    default:
      return state
  }
}

export default cenveyorBeltReducer
