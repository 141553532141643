export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER'
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER'

export const SHOW_LOADER = 'SHOW_GLOBAL_LOADER'
export const HIDE_LOADER = 'HIDE_GLOBAL_LOADER'

export const ADD_CUSTOME_HEADER_CLASSES = 'ADD_CUSTOME_HEADER_CLASSES'
export const REMOVE_CUSTOME_HEADER_CLASSES = 'REMOVE_CUSTOME_HEADER_CLASSES'

export const POP_RECENT_INNER_HEADER_HEADING = 'POP_RECENT_INNER_HEADER_HEADING'
export const PUSH_RECENT_INNER_HEADER_HEADING = 'PUSH_RECENT_INNER_HEADER_HEADING'
export const SPLICE_RECENT_INNER_HEADER_HEADING = 'SPLICE_RECENT_INNER_HEADER_HEADING'

export const UPDATE_RECENT_INNER_HEADER_HEADING = 'UPDATE_RECENT_INNER_HEADER_HEADING'
export const SET_CUSTOM_INNER_HEADER_HEADING = 'SET_CUSTOM_INNER_HEADER_HEADING'
export const REMOVE_CUSTOM_INNER_HEADER_HEADING = 'REMOVE_CUSTOM_INNER_HEADER_HEADING'

export const SHOW_SEARCH = 'SHOW_SEARCH'
export const HIDE_SEARCH = 'HIDE_SEARCH'
export const SAVE_SEARCH = 'SAVE_SEARCH'

export const HIDE_MESSAGE_ICONS = 'HIDE_MESSAGE_ICONS'
export const SHOW_MESSAGE_ICONS = 'SHOW_MESSAGE_ICONS'

export const HIDE_DASHBOARD_WIDGETS = 'HIDE_DASHBOARD_WIDGETS'
export const SHOW_DASHBOARD_WIDGETS = 'SHOW_DASHBOARD_WIDGETS'

export const HIDE_CONNECTION_WIDGETS = 'HIDE_CONNECTION_WIDGETS'
export const SHOW_CONNECTION_WIDGETS = 'SHOW_CONNECTION_WIDGETS'
