import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import TextInput from '../../components/forms/TextInput';
import SubmitForm from '../../components/forms/SubmitForm';
import Loader from '../../components/Ux/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateCustomerControl } from '../../apis/customerControls';
import Sidebars from '../../components/layouts/Sidebars';
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading';
import RfidVariablesForm from './components/RfidVariablesForm';
import { useStyles, useActionStyles } from './styles';
import {
  CUSTOMER_CONTROL_GET_VARIABLES_REQUEST,
  CUSTOMER_CONTROL_LIST_REQUEST
} from '../../store/customerControl/customerControlActions';
import config from '../../apis/api-constants'
import styled from 'styled-components';
import { getValidationErrors } from '../../helpers/functions';
import useSetControlTitle from '../../components/hooks/useSetControlTitle';

const RfidInputsContainer = styled.div`
  height: 20em;
`

const FormLayout = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  padding: 1em;
`

const EditCustomerControl = () => {
  const classes = useStyles()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const [readVariablesPending, setReadVariablesPending] = useState(false)
  const [writeVariablesPending, setWriteVariablesPending] = useState(false)
  const [rfidVariables, setRfidVariables] = useState([])
  const [rfidVariablesFormState, setRfidVariablesFormState] = useState({})
  const { t } = useTranslation()
  const actionsClasses = useActionStyles()
  const history = useHistory()
  const params = useParams()
  const apiToken = useSelector((state) => state.user.user.token)
  const dispatch = useDispatch()
  //useSetParentCustomInnerHeaderHeading()

  const control = useSelector((state) => state.customerControl.customerControls.find(x => x.id == params.controlId))

  useEffect(() => {
    dispatch({
      type: CUSTOMER_CONTROL_LIST_REQUEST,
      payload: { id: params.id, data: { per_page: 100, page: 1 } }
    })
  }, [dispatch])

  useEffect(() => {
    if (control) {
      dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: control.id })
    }
  }, [control])

  const { loading } = control || { loading: true }

  const submitForm = async (formData) => {
    try {
      setPendingSubmit(true)
      formData.customer_id = params.id
      const res = await updateCustomerControl(params.controlId, formData)
      if (res.status === 200) {
        setPendingSubmit(false)
        history.push('../')
        toast.success(t('add_customer_controls.toast.control_updated'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 404) {
        message = error.response.data.message || error.message
      } else if (error && error.response && error.response.status === 422) {
        message = error.response.data.message || error.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setPendingSubmit(false)
    }
  }

  const writeRfidVariables = async (params) => {
    // only write variables if there are any changes
    const body = Object.entries(params).map((x) => ({ var_nr: x[0], value: x[1] }))
    if (body.length > 0) {
      setWriteVariablesPending(true)

      try {
        const url = `${config.baseUrl}/field_modules/${control.id}/variables/write`
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apiToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
        const res = await fetch(url, options)
        setWriteVariablesPending(false)
        if (res.status >= 400) {
          toast.error(t('add_customer_controls.toast.write_rfid_variables_failed'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
          })
        }
      } catch (error) {
        console.log(error)
        toast.error(t('add_customer_controls.toast.write_rfid_variables_failed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        setWriteVariablesPending(false)
      }
    }
  }

  const handleSubmit = async (formData) => {
    const validations =      {
      number: {
        message: t('control_rfid_form.validation.must_be_number'
        )
      },
      maxLength: {
        maxLength: 4,
          message: t('control_rfid_form.validation.rfid_variable_max_length')
      }
    }
    const inputErrors = Object.values(rfidVariablesFormState).map(value => getValidationErrors(validations, value)).flat()
    const rfidVariablesValid = inputErrors.length === 0

    if (rfidVariablesValid) {
      await writeRfidVariables(rfidVariablesFormState)
      await submitForm(formData)
    }
  }

  const readRfidVariables = async () => {
    setReadVariablesPending(true)
    // reset rfid variables form state
    setRfidVariablesFormState({})

    try {
      const url = `${config.baseUrl}/field_modules/${control.id}/variables/read`
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ var_numbers: [550, 551, 552, 553, 554, 555, 556, 557] })
      }
      const res = await fetch(url, options)
      const json = await res.json()
      if (res.status >= 400) {
        console.log(json)
        toast.error(t('add_customer_controls.toast.read_rfid_variables_failed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        setReadVariablesPending(false)
      } else {
        console.log(json)
        setRfidVariables(json)
        setReadVariablesPending(false)
      }
    } catch (error) {
      console.log(error)
      toast.error(t('add_customer_controls.toast.read_rfid_variables_failed'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setReadVariablesPending(false)
    }
  }

  const handleRfidVariableInputChanged = (name, value) => {
    const newState = { ...rfidVariablesFormState }
    newState[name] = value
    setRfidVariablesFormState(newState)
  }

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={loading || pendingSubmit || readVariablesPending || writeVariablesPending} />
      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <FormLayout>
          <div className={classes.paper} >

            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.name_required')
                  },
                  maxLength: {
                    maxLength: 35,
                    message: t('add_customer_controls.validation.name_max_length')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                initValue={control && control.name}
                name='name'
                label={t('add_customer_controls.name')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.control_name_required')
                  },
                  maxLength: {
                    maxLength: 35,
                    message: t('add_customer_controls.validation.control_name_max_length')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                initValue={control && control.control_name}
                name='control_name'
                label={t('add_customer_controls.control_name')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_customer_controls.validation.licence_required')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                initValue={control && control.license_number}
                name='license_number'
                label={t('add_customer_controls.licenseNumber')}
                type='text'
              />
            </div>
          </div>

          <RfidInputsContainer>
            <RfidVariablesForm control={control} variables={rfidVariables} onChange={handleRfidVariableInputChanged} />
          </RfidInputsContainer>
        </FormLayout>

        <CardActions className='ButtonPanelWrap ButtonPanelWrap-2'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-start' }}>
            <Button
              className={classes.submit}
              onClick={readRfidVariables}
              variant='outlined'
            >
              <img src='/assets/img/tokens.png' />&nbsp;&nbsp;
              {t('add_customer_controls.read_rfid_variables')}
            </Button>
          </Box>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-end' }}>
            <Button
              className={classes.submit}
              type='submit'
              variant='outlined'
            >
              <img src='/assets/img/save.png' />&nbsp;&nbsp;
              {t('add_customer_controls.save')}
            </Button>
          </Box>
        </CardActions>
      </SubmitForm>
    </Card>
  )
}

export default Sidebars(EditCustomerControl)
