import { call, takeLatest, put } from 'redux-saga/effects'
import * as notificationEvents from './notificationAction'
import { getBeltNoifications, updateBeltNoifications } from '../../apis/notifications'

function * getNoifications ({ payload }) {
  try {
    const response = yield call(getBeltNoifications, payload)
    if (response.status === 200) {
      yield put({
        type: notificationEvents.NOTIFICATION_LIST_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: notificationEvents.NOTIFICATION_LIST_FAILURE,
      payload: err.message
    })
  }
}

function * updateNoifications ({ payload }) {
  try {
    const response = yield call(updateBeltNoifications, payload.id, payload.data)
    if (response.status === 200) {
      yield put({
        type: notificationEvents.NOTIFICATION_LIST_UPDATE_SUCCESS,
        payload: response.data
      })
      return
    }
    throw new Error('Something Went Wrong')
  } catch (err) {
    yield put({
      type: notificationEvents.NOTIFICATION_LIST_UPDATE_FAILURE,
      payload: err.message
    })
  }
}

const notificationSaga = [
  takeLatest(notificationEvents.NOTIFICATION_LIST_REQUEST, getNoifications),
  takeLatest(notificationEvents.NOTIFICATION_LIST_UPDATE_REQUEST, updateNoifications)
]
export default notificationSaga
