import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import * as beltSettingsActions from './beltSettingsActions'
import * as request from '../../apis/belt-settings'

function * readErrorList ({ payload }) {
  try {
    const response = yield call(request.readErrorList, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_READ_ERROR_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_READ_ERROR_LIST_FAILURE,
      error: { message: error.message }
    })
  }
}

function * readAccessList ({ payload }) {
  try {
    const response = yield call(request.readAccessList, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_ACCESS_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_ACCESS_LIST_FAILURE,
      error: { message: error.message }
    })
  }
}

function * readVariableList ({ payload }) {
  try {
    const response = yield call(request.readVariableList, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_VARIABLE_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_VARIABLE_LIST_FAILURE,
      error: { message: error.message }
    })
  }
}

function * readMeasurementList ({ payload }) {
  try {
    const response = yield call(request.readMeasurementList, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_MEASUREMENT_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_MEASUREMENT_LIST_FAILURE,
      error: { message: error.message }
    })
  }
}

function * readMeasurementRun ({ payload }) {
  try {
    const response = yield call(request.readMeasurementRun, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_MEASUREMENT_RUN_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_MEASUREMENT_RUN_FAILURE,
      error: { message: error.message }
    })
  }
}

function * readBeltDamageList ({ payload }) {
  try {
    const response = yield call(request.readBeltDamageList, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_BELT_DAMAGE_LIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_BELT_DAMAGE_LIST_FAILURE,
      error: { message: error.message }
    })
  }
}

function * softwareUpdate ({ payload }) {
  try {
    yield call(request.softwareUpdate, payload.id, payload.data)
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_SOFTWARE_UPDATE_SUCCESS,
      payload: { success: true }
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.BELT_SETTINGS_SOFTWARE_UPDATE_FAILURE,
      error: { message: error.message }
    })
  }
}

function * getJobStatus ({ payload }) {
  try {
    const response = yield call(request.getJobStatus, payload.id)
    yield put({
      type: beltSettingsActions.SETTINGS_JOB_SUCCESS,
      payload: { key: payload.key, status: response.data, ref: payload.ref }
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.SETTINGS_JOB_FAILURE,
      error: { message: error.message }
    })
  }
}

function * getRequestedFile ({ payload }) {
  try {
    const response = yield call(request.getRequestedFile, payload.fileId)
    yield put({
      type: beltSettingsActions.SETTINGS_INTERVAL_CLEARED_SUCCESS,
      payload: payload.key
    })
    yield put({
      type: beltSettingsActions.SETTINGS_GET_REQUESTED_FILE_SUCCESS,
      payload: { data: { ...response.data }, key: payload.key }
    })
  } catch (error) {
    yield put({
      type: beltSettingsActions.SETTINGS_INTERVAL_CLEARED_FAILURE,
      payload: payload.key
    })
    yield put({
      type: beltSettingsActions.SETTINGS_GET_REQUESTED_FILE_FAILURE,
      error: { message: error.message },
      ref: payload.key
    })
  }
}

const beltSettingsSaga = [
  takeLatest(beltSettingsActions.BELT_SETTINGS_READ_ERROR_LIST_REQUEST, readErrorList),
  takeLatest(beltSettingsActions.BELT_SETTINGS_ACCESS_LIST_REQUEST, readAccessList),
  takeLatest(beltSettingsActions.BELT_SETTINGS_VARIABLE_LIST_REQUEST, readVariableList),
  takeLatest(beltSettingsActions.BELT_SETTINGS_MEASUREMENT_LIST_REQUEST, readMeasurementList),
  takeLatest(beltSettingsActions.BELT_SETTINGS_MEASUREMENT_RUN_REQUEST, readMeasurementRun),
  takeLatest(beltSettingsActions.BELT_SETTINGS_BELT_DAMAGE_LIST_REQUEST, readBeltDamageList),
  takeLatest(beltSettingsActions.BELT_SETTINGS_SOFTWARE_UPDATE_REQUEST, softwareUpdate),
  takeLatest(beltSettingsActions.SETTINGS_JOB_REQUEST, getJobStatus),
  takeEvery(beltSettingsActions.SETTINGS_GET_REQUESTED_FILE_REQUEST, getRequestedFile)
]
export default beltSettingsSaga
