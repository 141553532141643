import request from './request'

export function getRepresentationCustomers (id, payload) {
  return request.get(`representations/${id}/customers`, { params: payload })
}

export function addRepresentationCustomer (id, customer) {
  return request.post(`representations/${id}/customers`, customer)
}

export function deleteRepresentationCustomer (representationId, customerId) {
  return request.delete(
    `representations/${representationId}/customers/${customerId}`
  )
}
