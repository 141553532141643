import React from 'react'
import { Grid } from '@material-ui/core'

function ContentInfoTitle ({ type, name, no }) {
  return (
    <div className=' ml-1 mr-1'>
      <Grid container direction='row' alignItems='stretch' className='RowData TokenUpRow  mb-0'>
        <Grid item xs={3} className='left-Con TextLeft'>
          <strong>{type}</strong>
        </Grid>
        <Grid item xs='8' className='Mid-Con TextCenter' style={{ justifyContent: 'center' }}>
          {name}
        </Grid>
        <Grid item xs={1} className='Last-Con'>
          {no}
        </Grid>
      </Grid>
    </div>
  )
}

export default ContentInfoTitle
