import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Loader from '../../../components/Ux/Loader'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid, Box } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, CUSTOMER_CONTROL_GET_CONTROL_REQUEST } from '../../../store/customerControl/customerControlActions'
import { mapVariables } from '../../../helpers/variableMappers'
import {
  BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS,
  BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS,
  BELT_CONVEYOR_DASHBOARD_COUNTERCLOCLWISE_ROTATION,
  BELT_CONVEYOR_DASHBOARD_CLOCKWISE_ROTATION,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_1,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_1_SUBSECTION_1,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_2,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_2_SUBSECTION_1,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_3,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_3_SUBSECTION_1,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_4,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_4_SUBSECTION_1,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS,
  BELT_CONVEYOR_DASHBOARD_CONTROL,
  BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS_VAR_NO,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS_VAR_NO,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS_VAR_NO,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS_VAR_NO,
  BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS_VAR_NO,
  BELT_CONVEYOR_SCRAPER_1_NO,
  BELT_CONVEYOR_SCRAPER_2_NO,
  BELT_CONVEYOR_SCRAPER_3_NO,
  BELT_CONVEYOR_SCRAPER_4_NO,
  BELT_CONVEYOR_SCRAPERS_LIST
} from '../../../constants/variables'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useToggleDashboardWidgets from '../../../components/hooks/useToggleDashboardWidgets'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { HIDE_LOADER } from '../../../store/utility/utilityActions'
import { DASHBOARD_UPDATE_INTERVAL } from '../../../constants/pollingIntervals'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { ScraperStatusText, BeltConveyorStatus, ScraperStatusIndicator } from './components'
import { variablesByVarNr, scrapersByNr } from '../../../store/customerControl/selectors'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const calculateAxialDistance = (beltLength, drumDiameter) => {
  // Axial distance in m = ((belt length in mm - (PI * Drum-Ø in mm)) / 2) / 1000
  const distanceInMeters = ((beltLength - (Math.PI * drumDiameter)) / 2.0) / 1000.0
  return Number.parseFloat(distanceInMeters).toFixed(1)
}
const overrideAxialDistance = (variables) => {
  const axialDistanceVar = variables.find(x => Number(x.var_nr) === 10012)
  const drumDiameterVar = variables.find(x => Number(x.var_nr) === 10016)
  const beltLengthVar = variables.find(x => Number(x.var_nr) === 522)

  let axialDistance
  // if all needed variables exist and are not 0
  if (axialDistanceVar && drumDiameterVar && beltLengthVar && Number(drumDiameterVar.value_raw) > 0 && Number(beltLengthVar.value_raw) > 0) {
    axialDistance = calculateAxialDistance(Number(beltLengthVar.value_raw * 1000.0), Number(drumDiameterVar.value_raw))
  } else {
    // default to "-"
    axialDistance = '-'
  }
  return variables.map(variable => {
    if (variable.var_nr === axialDistanceVar.var_nr) {
      return { ...axialDistanceVar, value: axialDistance, value_raw: axialDistance }
    } else {
      return { ...variable }
    }
  })
}

const overrideSpeedIfInactive = (variables) => {
  // override speed (var 541) with 0 if belt is not active (var 540)
  const beltActiveVar = variables.find(x => Number(x.var_nr) === 540)
  const beltSpeedVar = variables.find(x => Number(x.var_nr) === 541)
  // if belt is not active
  if (beltActiveVar && beltSpeedVar && Number(beltActiveVar.value_raw) === 0) {
    return variables.map(variable => {
      // override belt speed
      if (variable.var_nr === beltSpeedVar.var_nr) {
        return { ...beltSpeedVar, value: '0.0', value_raw: '0' }
      } else {
        return { ...variable }
      }
    })
  } else {
    return variables
  }
}

// returns placeholder if value is not present
const withPlaceholder = (value, placeholderForZero = false, placeholder = '-') => {
  if (value === null || value === undefined) {
    return placeholder
  } else if (placeholderForZero && Number(value) === 0) {
    return placeholder
  } else {
    return value
  }
}

const VariableRow = ({variable, unavailable, placeholderForZero = false}) => {
  if (variable == null || variable == undefined) {
    return <></>
  }

  return (<tr key={variable.id}>
    <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
    <td style={{ width: '100px' }}>{(unavailable ? '-' : withPlaceholder(variable.value, placeholderForZero))}</td>
  </tr>)
}

const ScraperStatusRow = ({variable, unavailable}) => {
  if (variable == null || variable == undefined) {
    return <></>
  }

  return (<tr>
    <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
    <td style={{ width: '100px' }}>
      <ScraperStatusText status={Number(variable.value_raw)} text={variable.value} available={!unavailable} />
    </td>
  </tr>)
}

function ConveyorBeltDashboard (props) {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const isMount = useRef(false)
  const isErrorMount = useRef(false)
  const isControlMount = useRef(false)
  const isControlErrorMount = useRef(false)
  const isReloadVariablesMount = useRef(false)
  const intervalRef = useRef(null)
  const isLoadingMount = useRef(false)
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  useSetMessageIcon()
  useShowConnectionSymbol(params.id)
  useSetControlTitle(params.id)
  const control = useSelector((state) => state.customerControl.control)
  const variables = useSelector((state) => state.customerControl.variables)
  const variablesByNr = useSelector(variablesByVarNr) || {}
  const { variableReloaded, error: controlError } = useSelector((state) => state.customerControl)
  const { loading } = useSelector((state) => state.utility)
  const { error } = useSelector(
    (state) => state.messages
  )
  const [expanded, setExpanded] = useState(false)
  const toggleDashboardWidgets = useToggleDashboardWidgets()
  const [beltDemanders, setBeltDemanders] = useState(null)
  const [beltDemandersStatus, setBeltDemandersStatus] = useState(null)

  const [countercloclwiseRotation, setCountercloclwiseRotation] = useState(null)
  const [cloclwiseRotation, setCloclwiseRotation] = useState(null)

  const [scraper1Data, setScraper1Data] = useState(null)
  const [scraper1SubsectionData, setScraper1SubsectionData] = useState(null)
  const [scraper1Status, setScraper1Status] = useState(null)
  const [isScraper1Unavailable, setIsScraper1Unavailable] = useState(null)

  const [scraper2Data, setScraper2Data] = useState(null)
  const [scraper2SubsectionData, setScraper2SubsectionData] = useState(null)
  const [scraper2Status, setScraper2Status] = useState(null)
  const [isScraper2Unavailable, setIsScraper2Unavailable] = useState(null)

  const [scraper3Data, setScraper3Data] = useState(null)
  const [scraper3SubsectionData, setScraper3SubsectionData] = useState(null)
  const [scraper3Status, setScraper3Status] = useState(null)
  const [isScraper3Unavailable, setIsScraper3Unavailable] = useState(null)

  const [scraper4Data, setScraper4Data] = useState(null)
  const [scraper4SubsectionData, setScraper4SubsectionData] = useState(null)
  const [scraper4Status, setScraper4Status] = useState(null)
  const [isScraper4Unavailable, setIsScraper4Unavailable] = useState(null)

  const [controls, setControls] = useState(null)

  const scrapers = useSelector(scrapersByNr) || {}

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
    intervalRef.current = setInterval(() => {
      dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
      dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: params.id })
    }, DASHBOARD_UPDATE_INTERVAL)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isControlMount.current) {
      toggleDashboardWidgets(control, params.id)
    } else {
      isControlMount.current = true
    }
  }, [control])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      const beltDemanders = overrideSpeedIfInactive(overrideAxialDistance(mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS)))
      const beltDemandersStatus = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS)

      const countercloclwiseRotation = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_COUNTERCLOCLWISE_ROTATION)
      const cloclwiseRotation = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_CLOCKWISE_ROTATION)

      const scrapersActivatedStatus = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_LIST)

      const scraper1Data = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_1)
      const scraper1SubsectionData = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_1_SUBSECTION_1)
      const scraper1Status = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_1_STATUS)
      const scraper1Available = scrapersActivatedStatus.find(x => x.var_nr === BELT_CONVEYOR_SCRAPER_1_NO)
      if (scraper1Available) {
        setIsScraper1Unavailable(Number(scraper1Available.value_raw) === 0)
      }

      const scraper2Data = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_2)
      const scraper2SubsectionData = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_2_SUBSECTION_1)
      const scraper2Status = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_2_STATUS)
      const scraper2Available = scrapersActivatedStatus.find(x => x.var_nr === BELT_CONVEYOR_SCRAPER_2_NO)
      if (scraper2Available) {
        setIsScraper2Unavailable(Number(scraper2Available.value_raw) === 0)
      }

      const scraper3Data = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_3)
      const scraper3SubsectionData = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_3_SUBSECTION_1)
      const scraper3Status = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_3_STATUS)
      const scraper3Available = scrapersActivatedStatus.find(x => x.var_nr === BELT_CONVEYOR_SCRAPER_3_NO)
      if (scraper3Available) {
        setIsScraper3Unavailable(Number(scraper3Available.value_raw) === 0)
      }

      const scraper4Data = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_4)
      const scraper4SubsectionData = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_4_SUBSECTION_1)
      const scraper4Status = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_SCRAPER_4_STATUS)
      const scraper4Available = scrapersActivatedStatus.find(x => x.var_nr === BELT_CONVEYOR_SCRAPER_4_NO)
      if (scraper4Available) {
        setIsScraper4Unavailable(Number(scraper4Available.value_raw) === 0)
      }

      const controls = mapVariables(Object.values(variables), BELT_CONVEYOR_DASHBOARD_CONTROL)

      setBeltDemanders(beltDemanders)
      setBeltDemandersStatus(beltDemandersStatus)
      setCountercloclwiseRotation(countercloclwiseRotation)
      setCloclwiseRotation(cloclwiseRotation)

      setScraper1Data(scraper1Data)
      setScraper1SubsectionData(scraper1SubsectionData)
      setScraper1Status(scraper1Status)

      setScraper2Data(scraper2Data)
      setScraper2SubsectionData(scraper2SubsectionData)
      setScraper2Status(scraper2Status)

      setScraper3Data(scraper3Data)
      setScraper3SubsectionData(scraper3SubsectionData)
      setScraper3Status(scraper3Status)

      setScraper4Data(scraper4Data)
      setScraper4SubsectionData(scraper4SubsectionData)
      setScraper4Status(scraper4Status)

      setControls(controls)
    } else {
      isMount.current = true
    }
  }, [variables])

  useEffect(() => {
    if (isErrorMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isControlErrorMount.current && controlError) {
      setShowLoader(false)
      dispatch({ type: HIDE_LOADER })
      toast.error(controlError, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    } else {
      isControlErrorMount.current = true
    }
  }, [controlError])

  useEffect(() => {
    if (isLoadingMount.current) {
      setShowLoader(loading)
    } else {
      isLoadingMount.current = true
    }
  }, [loading])

  useEffect(() => {
    if (isReloadVariablesMount.current) {
      setShowLoader(false)
      dispatch({ type: HIDE_LOADER })
      toast.success(t('conveyor_belt_dashboard.toast.reload_request'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isReloadVariablesMount.current = true
    }
  }, [variableReloaded])

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <Card className={classes.root + ' CardBorderStyle border-0 ConBeltDashboard'} border={0}>
      <Loader open={showLoader} />
      <CardContent>
        <Grid container className='MainContInnerStyle ' alignContent='space-between'>
          <div className='Inner_scroll pb-0 w-100'>
            <div className='Inner_scroll-con pt-0'>
              <Grid direction='row' container justifyContent='space-between'>

                <Box xs='12' sm='12' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} className='pl-2'>
                  <Grid direction='row' container alignItems='stretch' className='mt-1 pb-1' style={{ position: 'relative', width: '100%' }}>
                    <Grid Item xs='12' className='pr-2 pl-2'>
                      <img
                        src='/assets/img/Anlage-Steuerung.png' className='imgfluid'
                        loading='lazy'
                      />
                    </Grid>

                    <Grid Item xs='12'>
                      <Grid direction='row' container justifyContent='space-evenly' className='mt-2 mb-1 ConveyorBeltDashboardStatus'>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-4.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_active')}
                        </Grid>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-7.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_inactive')}
                        </Grid>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-8.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_unavailable')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Grid Item xs='12' md='5' lg='4' xl='4'>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>1</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.belt_demanders')}</Typography>

                        </div>
                        <div>
                          {beltDemandersStatus && <BeltConveyorStatus status={Number(beltDemandersStatus.value_raw)} />}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        {beltDemanders && beltDemanders.map((variable) => {
                          return (<tr key={variable.id}>
                            <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
                            <td style={{ width: '100px' }}>
                              {variable.var_nr === BELT_CONVEYOR_DASHBOARD_BELT_DEMANDERS_STATUS_VAR_NO
                                ? <BeltConveyorStatus status={Number(beltDemandersStatus.value_raw)} showText />
                                : (withPlaceholder(variable.value))}
                            </td>
                          </tr>)
                        })}
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleAccordianChange('panel2')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>2</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.counterclockwise_rotation')}</Typography>

                        </div>
                        <div className='visbilty-hidden'>
                          <img className='ArrowIcon' src='/assets/img/Ellipse-4.png' />
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        {countercloclwiseRotation && countercloclwiseRotation.map((variable) => {
                          return (<tr key={variable.id}>
                            <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
                            <td style={{ width: '100px' }}>{withPlaceholder(variable.value)}</td>
                          </tr>)
                        })}
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleAccordianChange('panel3')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>3</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.clockwise_rotation')}</Typography>

                        </div>
                        <div className='visbilty-hidden'>
                          <img className='ArrowIcon' src='/assets/img/Ellipse-4.png' />
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        {cloclwiseRotation && cloclwiseRotation.map((variable) => {
                          return (<tr>
                            <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
                            <td style={{ width: '100px' }}>{withPlaceholder(variable.value)}</td>
                          </tr>)
                        })}

                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>4</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.scraper_1')}</Typography>

                        </div>
                        <div>
                          {scraper1Status && <ScraperStatusIndicator status={Number(scraper1Status.value_raw)} available={!isScraper1Unavailable} />}
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        <ScraperStatusRow variable={variablesByNr[663]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[10004]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[10008]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[626]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[625]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[651]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[653]} unavailable={isScraper1Unavailable} placeholderForZero />
                      </table>

                      <div className='mt-1 fs-13'> <strong>{t('conveyor_belt_dashboard.segment')}</strong> </div>
                      <table className='w-100 TableAccordion'>
                        <VariableRow variable={variablesByNr[603]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[628]} unavailable={isScraper1Unavailable} />
                        <VariableRow variable={variablesByNr[627]} unavailable={isScraper1Unavailable} />
                        {
                          scrapers && scrapers[1]?.isCarbideSegment
                            ? <VariableRow variable={{ ...variablesByNr[654], value: Number.parseFloat(scrapers[1].segmentVolume).toFixed(1) }} unavailable={isScraper1Unavailable} />
                            : <VariableRow variable={variablesByNr[654]} unavailable={isScraper1Unavailable} />
                        }
                      </table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleAccordianChange('panel5')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>5</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.scraper_2')}</Typography>

                        </div>
                        <div>
                          {scraper2Status && <ScraperStatusIndicator status={Number(scraper2Status.value_raw)} available={!isScraper2Unavailable} />}
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        <ScraperStatusRow variable={variablesByNr[763]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[10005]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[10009]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[726]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[725]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[751]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[753]} unavailable={isScraper2Unavailable} placeholderForZero />
                      </table>

                      <div className='mt-1 fs-13'> <strong>{t('conveyor_belt_dashboard.segment')}</strong> </div>
                      <table className='w-100 TableAccordion'>
                        <VariableRow variable={variablesByNr[703]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[728]} unavailable={isScraper2Unavailable} />
                        <VariableRow variable={variablesByNr[727]} unavailable={isScraper2Unavailable} />
                        {
                          scrapers && scrapers[2]?.isCarbideSegment
                            ? <VariableRow variable={{ ...variablesByNr[754], value: Number.parseFloat(scrapers[2].segmentVolume).toFixed(1) }} unavailable={isScraper2Unavailable} />
                            : <VariableRow variable={variablesByNr[754]} unavailable={isScraper2Unavailable} />
                        }
                      </table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel6'} onChange={handleAccordianChange('panel6')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>6</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.scraper_3')}</Typography>

                        </div>
                        <div>
                          {scraper3Status && <ScraperStatusIndicator status={Number(scraper3Status.value_raw)} available={!isScraper3Unavailable} />}
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        <ScraperStatusRow variable={variablesByNr[863]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[10006]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[10010]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[826]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[825]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[851]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[853]} unavailable={isScraper3Unavailable} placeholderForZero />
                      </table>

                      <div className='mt-1 fs-13'> <strong>{t('conveyor_belt_dashboard.segment')}</strong> </div>
                      <table className='w-100 TableAccordion'>
                        <VariableRow variable={variablesByNr[803]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[828]} unavailable={isScraper3Unavailable} />
                        <VariableRow variable={variablesByNr[827]} unavailable={isScraper3Unavailable} />
                        {
                          scrapers && scrapers[3]?.isCarbideSegment
                            ? <VariableRow variable={{ ...variablesByNr[854], value: Number.parseFloat(scrapers[3].segmentVolume).toFixed(1) }} unavailable={isScraper3Unavailable} />
                            : <VariableRow variable={variablesByNr[854]} unavailable={isScraper3Unavailable} />
                        }
                      </table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel7'} onChange={handleAccordianChange('panel7')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>7</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.scraper_4')}</Typography>

                        </div>
                        <div>
                          {scraper4Status && <ScraperStatusIndicator status={Number(scraper4Status.value_raw)} available={!isScraper4Unavailable} />}
                        </div>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>
                        <ScraperStatusRow variable={variablesByNr[963]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[10007]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[10011]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[926]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[925]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[951]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[953]} unavailable={isScraper4Unavailable} placeholderForZero />
                      </table>

                      <div className='mt-1 fs-13'> <strong>{t('conveyor_belt_dashboard.segment')}</strong> </div>
                      <table className='w-100 TableAccordion'>
                        <VariableRow variable={variablesByNr[903]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[928]} unavailable={isScraper4Unavailable} />
                        <VariableRow variable={variablesByNr[927]} unavailable={isScraper4Unavailable} />
                        {
                          scrapers && scrapers[4]?.isCarbideSegment
                            ? <VariableRow variable={{ ...variablesByNr[954], value: Number.parseFloat(scrapers[4].segmentVolume).toFixed(1) }} unavailable={isScraper4Unavailable} />
                            : <VariableRow variable={variablesByNr[954]} unavailable={isScraper4Unavailable} />
                        }
                      </table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel8'} onChange={handleAccordianChange('panel8')} className='AccordionPanel'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <div className='d-flex justify-content-space-between w-100'>
                        <div>
                          <strong>8</strong>
                        </div>
                        <div>
                          <Typography>{t('conveyor_belt_dashboard.control')}</Typography>

                        </div>
                        <div />

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className='w-100 TableAccordion'>

                        {controls && controls.map((variable) => {
                          return (<tr key={variable.id}>
                            <td>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}: </td>
                            <td style={{ width: '100px' }}>{withPlaceholder(variable.value)}</td>
                          </tr>)
                        })}
                      </table>
                    </AccordionDetails>
                  </Accordion>

                </Grid>

                <Grid md='7' lg='8' xl='8' sx={{ display: { xs: 'none', sm: 'none' } }} className='pl-2'>
                  <Grid direction='row' container alignItems='stretch' className='mt-1' style={{ position: 'fixed', width: '40%' }}>
                    <Grid Item xs='12' className='pr-2 pl-2'>
                      <img
                        src='/assets/img/Anlage-Steuerung.png' className='imgfluid'
                        loading='lazy'
                      />
                    </Grid>

                    <Grid Item xs='12'>
                      <Grid direction='row' container justifyContent='space-evenly' className='mt-2 mb-1 ConveyorBeltDashboardStatus'>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-4.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_active')}
                        </Grid>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-7.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_inactive')}
                        </Grid>
                        <Grid alignItems='center' justifyContent='center' className='dflex'>
                          <img src='/assets/img/Ellipse-8.png' />&nbsp;&nbsp;{t('conveyor_belt_dashboard.status_unavailable')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <div />
      </CardContent>
    </Card>
  )
}

export default Sidebars(ConveyorBeltDashboard, { showSearch: false })
