import React from 'react'

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context('../../../../../public/assets/img/scrapers', false, /\.(png|jpe?g|svg)$/));

// get all images and index them by their original name
const images = Object.entries(cache)
                     .map(module => [module[0], module[1].default])
                     .reduce((acc, [key, value])=>({...acc, [key]: value}), {});

const defaultImage = "./51.png"

export const ScraperImage = ({ segmentType }) => {
    // when segmentType is undefined => render default image
    let scraperImage = segmentType ? images[`./${segmentType}.png`] : images[defaultImage]
    
    if (scraperImage == null || scraperImage == undefined) {
      // when segmentType is present but it does not exist an image for it => render default image
      scraperImage = images[defaultImage]
    }

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img style={{maxWidth: "60vw", height: "300px"}} src={scraperImage} />
        </div>
    );
}

export default ScraperImage