import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'

const NotFound = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box
      style={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        marginTop: 20
      }}
    >
      <Container maxWidth='md'>
        <Typography align='center' variant='h3'>
          {t('not_found.description')}
        </Typography>
        <Box style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<HomeIcon />}
            onClick={() => history.push('/')}
          >
            {t('not_found.button')}
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFound
