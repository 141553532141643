import * as customerControlActions from './customerControlActions'

const initialState = {
  loading: false,
  customerControls: [],
  success: false,
  error: null,
  perPage: 10,
  page: 1,
  totalPages: 0,
  deletedId: 0,
  control: null,
  variables: null,
  chartData: [],
  updatedVariable: null,
  variableReloaded: null
}

const customerControl = (state = initialState, action) => {
  switch (action.type) {
    case customerControlActions.CUSTOMER_CONTROL_LIST_REQUEST:
      return { ...state, loading: true }
    case customerControlActions.CUSTOMER_CONTROL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerControls: action.payload.customerControls.map((customerControl) => {
          return { ...customerControl }
        }),
        perPage: action.payload.perPage ? action.payload.perPage : state.perPage,
        page: action.payload.page ? action.payload.page : state.page,
        totalPages: action.payload.totalPages ? action.payload.totalPages : state.totalPages
      }
    case customerControlActions.CUSTOMER_CONTROL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        customerControls: [],
        error: action.error
      }

    case customerControlActions.CUSTOMER_CONTROL_UPDATE_REQUEST:
      return { ...state, loading: true }

    case customerControlActions.CUSTOMER_CONTROL_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true }

    case customerControlActions.CUSTOMER_CONTROL_UPDATE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }

    case customerControlActions.CUSTOMER_CONTROL_DELETE_REQUEST:
      return { ...state, loading: true }

    case customerControlActions.CUSTOMER_CONTROL_DELETE_SUCCESS:
      return { ...state, loading: false, success: action.payload.success, deletedId: action.payload.deletedId }

    case customerControlActions.CUSTOMER_CONTROL_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error, deletedId: 0 }

    case customerControlActions.CUSTOMER_CONTROL_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }

    case customerControlActions.CUSTOMER_CONTROL_GET_CONTROL_SUCCESS:
      // check id of previous control and reset selected scraper and tab if it has changed
      const tabState = {}
      if (state.control && state.control.id !== action.payload.id) {
        // id has changed => reset tab index
        tabState.selectedScraper = 601
        tabState.selectedScraperTab = 0
      }
      return { ...state, loading: false, success: true, control: { ...state.control, ...tabState, ...action.payload } }

    case customerControlActions.CUSTOMER_CONTROL_GET_CONTROL_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload }

    case customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_REQUEST:
      return { ...state, loading: true }
    case customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_SUCCESS:
      const convertedVariables = Object.fromEntries(Object.entries(action.payload).map((variable) => {
        // convert variable Segment Wear in % to Segment Volume in %
        if ([654, 754, 854, 954].includes(variable[1].var_nr)) {
          return [
            variable[0],
            {
              ...variable[1],
              value: (parseInt(variable[1].value)).toString(),
              raw_value: (parseInt(variable[1].raw_value)).toString()
            }
          ]
        } else {
          // return unmodified variable
          return { ...variable }
        }
      }))
      return { ...state, loading: false, success: true, variables: convertedVariables }

    case customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload }

    case customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action.payload.map((chartData) => ({ ...chartData }))
      }
    case customerControlActions.CUSTOMER_CONTROL_GET_VARIABLES_CHART_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload }

    case customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        updatedVariable: action.payload
      }
    case customerControlActions.CUSTOMER_CONTROL_UPDATE_VARIABLES_FAILURE:
      return { ...state, loading: false, success: false, error: { message: action.payload.message } }
    case customerControlActions.CUSTOMER_CONTROL_VARIABLES_RELOAD_SUCCESS:
      return { ...state, loading: false, success: true, variableReloaded: { ...action.payload } }
    case customerControlActions.CUSTOMER_CONTROL_VARIABLES_RELOAD_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload }
    case customerControlActions.CUSTOMER_CONTROL_SCRAPER_REQUEST:
    case customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST:
      return { ...state, currentScraper: { ...state.currentScraper, loading: true } }
    case customerControlActions.CUSTOMER_CONTROL_SCRAPER_SUCCESS:
      return { ...state, currentScraper: { ...action.payload, loading: false, success: true } }
    case customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_SUCCESS:
      return { ...state, currentScraper: { ...action.payload, loading: false, updateSuccess: true } }
    case customerControlActions.CUSTOMER_CONTROL_SCRAPER_FAILURE:
      return { ...state, currentScraper: { loading: false, success: false, error: action.payload } }
    case customerControlActions.CUSTOMER_CONTROL_UPDATE_SCRAPER_FAILURE:
      return { ...state, currentScraper: { loading: false, updateSuccess: false, error: action.payload } }
    case customerControlActions.INFO_SCREEN_SCRAPER_SELECTED:
      return { ...state, control: { ...state.control, selectedScraper: action.payload } }
    case customerControlActions.INFO_SCREEN_SCRAPER_TAB_SELECTED:
      return { ...state, control: { ...state.control, selectedScraperTab: action.payload } }
    default:
      return state
  }
}

export default customerControl
