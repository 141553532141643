import { all } from 'redux-saga/effects'
import userSaga from './users/userSaga'
import customerSaga from './customer/customerSaga'
import customerUserSaga from './customerUser/customerUserSaga'
import customerAreaSaga from './customer-area/customerAreaSaga'
import customerControlSaga from './customerControl/customerControlSaga'
import representationSaga from './representation/representationSaga'
import representationCustomerSaga from './representationCustomer/representationCustomerSaga'
import representativeCustomerSaga from './representation/representationSaga'
import conveyorBeltSaga from './conveyor-belt/conveyorBeltSaga'
import customerLoginSaga from './customerLogin/customerLoginSaga'
import customerTokenSaga from './customerToken/customerTokenSaga'
import representationTokenSaga from './representationToken/representationTokenSaga'
import messageSaga from './message/messageSaga'
import notificationSaga from './noiifications/notificationSaga'
import damageSaga from './damage/damageSaga'
import beltSettingsSaga from './beltSettings/beltSettingsSaga'

function * rootSaga () {
  yield all([
    ...userSaga,
    ...customerSaga,
    ...customerUserSaga,
    ...customerAreaSaga,
    ...customerControlSaga,
    ...representationSaga,
    ...representationCustomerSaga,
    ...representativeCustomerSaga,
    ...conveyorBeltSaga,
    ...customerTokenSaga,
    ...representationTokenSaga,
    ...customerLoginSaga,
    ...messageSaga,
    ...notificationSaga,
    ...damageSaga,
    ...beltSettingsSaga
  ])
}

export default rootSaga
