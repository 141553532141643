import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import {
  CUSTOMER_CONTROL_LIST_REQUEST,
  CUSTOMER_CONTROL_UPDATE_REQUEST
} from '../../store/customerControl/customerControlActions'
import {
  CUSTOMER_AREA_LIST_REQUEST,
  CUSTOMER_AREA_DELETE_REQUEST
} from '../../store/customer-area/customerAreaActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import ReadMoreReact from 'read-more-react'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  userTable: {
    minWidth: 150,
    '& thead': {

      width: '150px',
      position: 'sticky',
      top: '0',
      border: 'solid 2px #707070'

    }
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '260px',
    whiteSpace: 'normal'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
}))

const AreaCustomerControl = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const actionsClasses = useActions()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  // areaId is only used for deleting area
  const [areaId, setAreaId] = useState(null)
  // currentArea is used for displaying user licences in area change dialog
  const [currentArea, setCurrentArea] = useState(null)
  const [newCustomerControls, setNewCustomerControls] = useState([])
  const [newCustomerAreas, setNewCustomerAreas] = useState([])
  const [areaChangeValues, setAreaChangeValues] = useState({})
  useSetParentCustomInnerHeaderHeading()
  const customerControlList = useSelector((state) => state.customerControl)
  const { loading, error, customerControls } = customerControlList
  const customerAreaList = useSelector((state) => state.customerArea)
  const { customer } = useSelector((state) => state.customer)
  const isMount = useRef(false)
  const [openAreaChangeDialog, setOpenAreaChangeDialog] = useState({ show: false, message: '' })
  const {
    loading: areaLoading,
    error: areaError,
    customerAreas,
    isDeleted
  } = customerAreaList

  // Get data on component mount and when currentArea changes
  useEffect(() => {
    // load customer
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
    // load customer area list
    dispatch({ type: CUSTOMER_AREA_LIST_REQUEST, payload: { id: params.id } })
    // load customer controls
    dispatch({ type: CUSTOMER_CONTROL_LIST_REQUEST, payload: { id: params.id } })
  }, [currentArea])

  useEffect(() => {
    if (isDeleted && areaId) {
      setAreaId(null)
      dispatch({
        type: CUSTOMER_AREA_LIST_REQUEST,
        payload: { id: params.id }
      })
      toast.success(t('area_customer_controls.toast.area_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [isDeleted])

  // Handling error and displaying if comes during api call.
  useEffect(() => {
    if (isMount.current && error) {
      setAreaId(null)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  useEffect(() => {
    toast.error(areaError, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }, [areaError])

  useEffect(() => {
    setNewCustomerAreas(customerAreas)
  }, [setNewCustomerAreas, customerAreas])

  useEffect(() => {
    if (customerControls.length) {
      setNewCustomerControls(customerControls)
    }
  }, [setNewCustomerControls, customerControls])

  const handleAreaChange = (e, i, control) => {
    // get previous area of control
    const prevArea = customerAreas.find(x => Number(x.id) === Number(control.area_id))
    // current area that is being changed
    const newArea = customerAreas.find(x => Number(x.id) === Number(e.currentTarget.value))
    // set user licence costs => newArea.next_field_module_costs - oldArea.current_field_module_costs
    let additionalUserLicences = 0
    if (prevArea) {
      additionalUserLicences = newArea.next_field_module_costs - prevArea.current_field_module_costs
    } else {
      additionalUserLicences = newArea.next_field_module_costs
    }
    const message = t('area_customer_controls.dialog.change_area.message', { additionalUserLicences: additionalUserLicences })
    setCurrentArea(newArea)
    // only show message in dialog if additional licences are needed
    setOpenAreaChangeDialog({ show: true, message: additionalUserLicences > 0 ? message : '' })
    setAreaChangeValues({
      value: e.currentTarget.value,
      i,
      control
    })
  }

  const updateAreaChange = (value, i, id) => {
    const duplicateCustomerControls = [...newCustomerControls]
    const areaId = value * 1
    duplicateCustomerControls[i].area_id = areaId
    setNewCustomerControls(duplicateCustomerControls)

    dispatch({
      type: CUSTOMER_CONTROL_UPDATE_REQUEST,
      payload: { id, customerControl: { area_id: areaId } }
    })

    // reload customer area list
    dispatch({ type: CUSTOMER_AREA_LIST_REQUEST, payload: { id: params.id } })
  }

  const handleConfirmDeleteArea = (e) => {
    setOpenDeleteDialog(false)
    if (e.currentTarget.value === 'true') {
      dispatch({
        type: CUSTOMER_AREA_DELETE_REQUEST,
        payload: { id: areaId }
      })
    } else {
      setAreaId(null)
    }
  }

  const handleConfirmAreaChange = (e) => {
    setOpenAreaChangeDialog({ show: false, message: '' })
    if (e.currentTarget.value === 'true') {
      updateAreaChange(areaChangeValues.value, areaChangeValues.i, areaChangeValues.control.id)
    }
    setAreaChangeValues({})
  }

  const handleDeleteArea = (id) => {
    setOpenDeleteDialog(true)
    setAreaId(id)
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>

          <div className='Inner_scrollTwoTable'>
            <ContentTitle title={t('area_customer_controls.title')} />
            <ContentInfoTitle type={t('list_customer.title_singular')} name={customer && customer.name} no={customer && customer.customer_nr} />

            <Grid item xs='12' className='Inner_scrollTwo pb-6 mt-1'>
              <div className='Inner_scroll-con pl-1 pr-1 pt-0 position-relative' style={{ overflow: 'inherit' }}>
                <TableContainer component={Paper}>
                  <div
                    className=' ml-1 mr-1 tableScrollbar TableFixed UserAreaTable'
                    style={{
                      height: '100%',
                      overflowY: 'auto',
                      position: 'absolute',
                      // bottom: '0',
                      left: '0',
                      right: '10px',
                      paddingBottom: '10px',
                      paddingRight: '10px',
                      top: '0px'

                    }}
                  >

                    <Table
                      stickyHeader
                      aria-label='customized table'
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <span className='Text-righthead'>{t('area_customer_controls.control')}</span>
                            <span className='Rotate-shape' />
                            <span className='Text-Bootmhead'>{t('area_customer_controls.areas')}</span>
                          </StyledTableCell>

                          {newCustomerControls &&
                      newCustomerControls.map((control) => (
                        <StyledTableCell key={control.id} className={['TextOverflowTh', !control.area_id ? 'unassigner_control' : ''].join(' ')}>
                          {control.name && <ReadMoreReact
                            text={control.name ? control.name : ''}
                            min={20}
                            max={20}
                            ideal={20}
                            readMoreText='...'
                          />}
                          <div style={{ fontWeight: '400', marginTop: 20 }}>
                            {control.serial_number && <ReadMoreReact
                              text={control.serial_number
                                ? 'ID: ' + control.serial_number : ''}
                              min={30}
                              max={30}
                              ideal={30}
                              readMoreText='...'
                            />}
                          </div>
                        </StyledTableCell>
                      ))}

                          {!newCustomerControls ||
                      (newCustomerControls && newCustomerControls.length === 0)
                            ? <StyledTableCell className='text-center'>
                              {t('noRecordsFound')}
                              </StyledTableCell>
                            : null}
                          <StyledTableCell style={{ width: '60px' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {newCustomerAreas &&
                    newCustomerAreas.map((area, aIndex) => (
                      <StyledTableRow key={area.id}>
                        <TableCell className={classes.stickyCell}>{area.name ? area.name : ''}</TableCell>
                        {newCustomerControls.map((control, i) => {
                          return (
                            <StyledTableCell
                              className={[!control.area_id
                                ? (
                                  (aIndex === newCustomerAreas.length - 1)
                                    ? 'unassigner_control_last_col' : 'unassigner_control_col') : ''].join(' ')}
                              key={i}
                            >
                              <Radio
                                checked={control.area_id === area.id}
                                onChange={(e) =>
                                  handleAreaChange(e, i, control)}
                                value={area.id}
                                name='area_id'
                              />

                            </StyledTableCell>
                          )
                        })}
                        <StyledTableCell style={{ width: '60px' }}>
                          <IconButton
                            onClick={() => handleDeleteArea(area.id)}
                            style={{ color: 'red' }}
                            className=' cancel-icon'
                          >
                            <img src='/assets/img/cancel.png' />
                          </IconButton>

                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                      </TableBody>
                    </Table>
                    {!newCustomerAreas.length && (
                      <div
                        style={{
                          padding: '1rem',
                          textAlign: 'center'
                        }}
                      >
                        {t('noRecordsFound')}
                      </div>
                    )}
                  </div>
                </TableContainer>
              </div>
            </Grid>

            <Loader open={loading || areaLoading} />
          </div>
        </CardContent>
        <CardActions className=' ButtonPanelWrap ButtonPanelWrap-2'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./add/')}
              type='button'
              variant='outlined'

            >
              <img src='/assets/img/plus.png' />&nbsp;&nbsp;
              {t('area_customer_controls.newArea')}
            </Button>
          </Box>
        </CardActions>

      </Card>
      <PopupDialog open={openDeleteDialog} onClick={handleConfirmDeleteArea} />
      <PopupDialog
        open={openAreaChangeDialog.show}
        message={openAreaChangeDialog.message}
        onClick={handleConfirmAreaChange}
      />
    </>
  )
}

export default Sidebars(AreaCustomerControl)
