export const REPRESENTATION_LIST_REQUEST = 'REPRESENTATION_LIST_REQUEST'
export const REPRESENTATION_LIST_SUCCESS = 'REPRESENTATION_LIST_SUCCESS'
export const REPRESENTATION_LIST_FAILURE = 'REPRESENTATION_LIST_FAILURE'

export const REPRESENTATION_DELETE_REQUEST = 'REPRESENTATION_DELETE_REQUEST'
export const REPRESENTATION_DELETE_SUCCESS = 'REPRESENTATION_DELETE_SUCCESS'
export const REPRESENTATION_DELETE_FAILURE = 'REPRESENTATION_DELETE_FAILURE'
export const REPRESENTATION_RESET_DELETE_STATE =
  'REPRESENTATION_RESET_DELETE_STATE'

export const GET_REPRESENTATION_REQUEST = 'GET_REPRESENTATION_REQUEST'
export const GET_REPRESENTATION_SUCCESS = 'GET_REPRESENTATION_SUCCESS'
export const GET_REPRESENTATION_FAILURE = 'GET_REPRESENTATION_FAILURE'
