import request from './request'

export function userLogin (payload) {
  return request.post('oauth/token', payload)
}

export const userProfile = () => {
  return request.get('user_profile')
}

export const updateProfileLanguage = (payload) => {
  return request.patch('user_profile', { locale: payload })
}
