import CssBaseline from '@material-ui/core/CssBaseline'
import Header from './components/Header'
import Footer from './components/Footer'
import Routes from './routes'
import GlobalUilities from './components/global/Global'
import './fonts/Avenir-Next-Font.woff'
import './fonts/Avenir-Next-Font.woff2'
import '@fortawesome/fontawesome-free/css/all.min.css'

const App = () => {
  return (
    <div className='container'>
      <CssBaseline />
      <Header />
      <div className='content-container'>
        <Routes />
      </div>
      <GlobalUilities />
      <Footer />
    </div>
  )
}

export default App
