import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  SUPER_USER,
  SERVICE_USER,
  REPRESENTATION_USER,
  TOP_USER,
  USER
} from './constants/roles'
import ProtectedRoute from './components/ProtectedRoute'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import CustomerList from './pages/customers/ListCustomer'
import ListCustomerControl from './pages/customerControls/ListCustomerControl'
import AddCustomerControl from './pages/customerControls/AddCustomerControl'
import EditCustomerControl from './pages/customerControls/EditCustomerControl'
import AreaCustomerControl from './pages/customerControls/AreaCustomerControl'
import AddAreaCustomerControl from './pages/customerControls/AddAreaCustomerControl'
import ListCustomerUsers from './pages/customerUsers/ListCustomerUsers'
import ListScraperMeasurements from './pages/conveyorBelt/details/ListScraperMeasurements'
import ScraperMeasurementForm from './pages/conveyorBelt/details/ScraperMeasurementForm'
import AddCustomerUser from './pages/customerUsers/AddCustomerUser'
import EditCustomerUser from './pages/customerUsers/EditCustomerUser'
import AreaCustomerUser from './pages/customerUsers/AreaCustomerUser'
import Dashboard from './pages/Dashboard'
import AddCustomer from './pages/customers/AddCustomer'
import EditCustomer from './pages/customers/EditCustomer'
import RepresentationList from './pages/representations/ListRepresentation'
import AddRepresentation from './pages/representations/AddRepresentation'
import EditRepresentation from './pages/representations/EditRepresentation'
import ViewRepresentation from './pages/representations/ViewRepresentation'
import ListCustomerRepresentation from './pages/representationCustomers/ListCustomerRepresentation'
import AddCustomerRepresentation from './pages/representationCustomers/AddCustomerRepresentation'
import ListRepresentationCustomerLogin from './pages/representationCustomerLogin/ListRepresentationCustomerLogin'
import ListRepresentativeCustomers from './pages/representative-customers/ListRepresentativeCustomers'
import ViewRepresentativeCustomers from './pages/representative-customers/ViewRepresentativeCustomers'
import ListConveyorBelt from './pages/conveyorBelt/ListConveyorBelt'
import ConveyorBeltDetailsMenu from './pages/conveyorBelt/details/ConveyorBeltDetailsMenu'
import ViewCustomer from './pages/customers/ViewCustomer'
import Messages from './pages/messages/messages'
import ListCustomerTokens from './pages/customerTokens/ListTokens'
import AddCustomerTokens from './pages/customerTokens/AddTokens'
import ListRepresentationTokens from './pages/representationTokens/ListTokens'
import AddRepresentationTokens from './pages/representationTokens/AddTokens'
import ListCustomerLogin from './pages/customerLogin/ListCustomerLogin'
import segmentTableUpload from './pages/segmentTable/segmentTableUpload'
import ListServicePredictiveMaintenance from './pages/ServicePredictiveMaintenance/ListServicePredictiveMaintenance'
import ConveyorBeltDamage from './pages/conveyorBelt/details/ConveyorBeltDamage'
import ConveyorBeltInfo from './pages/conveyorBelt/details/ConveyorBeltInfo'
import ConveyorBeltInfoBeltConveyor from './pages/conveyorBelt/details/ConveyorBeltInfoBeltConveyor'
import ConveyorBeltInfoControl from './pages/conveyorBelt/details/ConveyorBeltInfoControl'
import ConveyorBeltInfoScraper from './pages/conveyorBelt/details/ConveyorBeltInfoScraper'
import ConveyorBeltMessages from './pages/conveyorBelt/details/ConveyorBeltMessages'
import ConveyorBeltNotifications from './pages/conveyorBelt/details/ConveyorBeltNotifications'
import ConveyorBeltSettings from './pages/conveyorBelt/details/ConveyorBeltSettings'
import ConveyorBeltDashboard from './pages/conveyorBelt/details/ConveyorBeltDashboard'
import SoftwareUpdate from './pages/conveyorBelt/SoftwareUpdate'
import Waiting from './pages/Waiting'
import SessionExpired from './pages/SessionExpired'

const Routes = () => {
  return (
    <>
      <Switch>
        {/* Customer */}

        <Route path='/customers'>
          <ProtectedRoute
            path='/customers'
            exact
            component={CustomerList}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/add'
            exact
            component={AddCustomer}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id'
            exact
            component={EditCustomer}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/detail/:id'
            exact
            component={ViewCustomer}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/controls'
            exact
            component={ListCustomerControl}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/controls/add'
            exact
            component={AddCustomerControl}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/controls/:controlId/edit'
            exact
            component={EditCustomerControl}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/controls/areas'
            exact
            component={AreaCustomerControl}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/controls/areas/add'
            exact
            component={AddAreaCustomerControl}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/users'
            exact
            component={ListCustomerUsers}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/users/add'
            exact
            component={AddCustomerUser}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/users/:userId/edit'
            exact
            component={EditCustomerUser}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/users/areas'
            exact
            component={AreaCustomerUser}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/tokens'
            exact
            component={ListCustomerTokens}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/tokens/add'
            exact
            component={AddCustomerTokens}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/customers/edit/:id/logins'
            exact
            component={ListCustomerLogin}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
        </Route>

        {/* Messages */}
        <ProtectedRoute
          path='/messages'
          exact
          component={Messages}
          roles={[
            SUPER_USER,
            SERVICE_USER,
            REPRESENTATION_USER,
            TOP_USER,
            USER
          ]}
          skipDashboardCheck
          coreRoles={[
            SUPER_USER,
            SERVICE_USER,
            REPRESENTATION_USER,
            TOP_USER,
            USER
          ]}
        />

        {/* Representative Customer */}
        <Route path='/representative-customers'>
          <ProtectedRoute
            path='/representative-customers'
            exact
            component={ListRepresentativeCustomers}
            roles={[REPRESENTATION_USER, SUPER_USER, SERVICE_USER]}
            coreRoles={[REPRESENTATION_USER]}
          />
          {/* <ProtectedRoute
            path="/representative-customers/add"
            exact
            component={AddRepresentativeCustomers}
            roles={[REPRESENTATION_USER]}
          />
          <ProtectedRoute
            path="/representative-customers/edit/:id"
            exact
            component={EditRepresentativeCustomers}
            roles={[REPRESENTATION_USER]}
          /> */}
          <ProtectedRoute
            path='/representative-customers/detail/:id/'
            exact
            component={ViewRepresentativeCustomers}
            roles={[SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[REPRESENTATION_USER]}
          />
        </Route>
        <Route path='/cenveyor-belts/'>
          {/* Cenveyor Belt */}
          <ProtectedRoute
            path='/cenveyor-belts/'
            exact
            component={ListConveyorBelt}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/messages'
            exact
            component={ConveyorBeltMessages}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/notifications'
            exact
            component={ConveyorBeltNotifications}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/settings'
            exact
            component={ConveyorBeltSettings}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/software_update'
            exact
            component={SoftwareUpdate}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id'
            exact
            component={ConveyorBeltDetailsMenu}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/damage'
            exact
            component={ConveyorBeltDamage}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/dashboard'
            exact
            component={ConveyorBeltDashboard}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/info'
            exact
            component={ConveyorBeltInfo}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/info/belt-cenveyor'
            exact
            component={ConveyorBeltInfoBeltConveyor}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/info/control'
            exact
            component={ConveyorBeltInfoControl}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/info/scraper'
            exact
            component={ConveyorBeltInfoScraper}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/scraper/:scraper_nr/measurements'
            exact
            component={ListScraperMeasurements}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

          <ProtectedRoute
            path='/cenveyor-belts/menu/:id/scraper/:scraper_nr/measurements/add'
            exact
            component={ScraperMeasurementForm}
            roles={[TOP_USER, USER, SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
            coreRoles={[TOP_USER, USER]}
          />

        </Route>
        {/* Representation */}
        <Route path='/representations'>
          <ProtectedRoute
            path='/representations'
            exact
            component={RepresentationList}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/add'
            exact
            component={AddRepresentation}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id'
            exact
            component={EditRepresentation}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/detail/:id'
            exact
            component={ViewRepresentation}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id/customers'
            exact
            component={ListCustomerRepresentation}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id/customers/add'
            exact
            component={AddCustomerRepresentation}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id/logins'
            exact
            component={ListRepresentationCustomerLogin}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id/tokens'
            exact
            component={ListRepresentationTokens}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
          <ProtectedRoute
            path='/representations/edit/:id/tokens/add'
            exact
            component={AddRepresentationTokens}
            roles={[SUPER_USER, SERVICE_USER]}
            coreRoles={[SUPER_USER, SERVICE_USER]}
          />
        </Route>

        {/* ServicePredictiveMaintenance */}
        <ProtectedRoute
          path='/service-predictive-maintenance'
          skipDashboardCheck
          predictiveMaintenanceRouteCheck
          exact
          component={ListServicePredictiveMaintenance}
          roles={[SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
          coreRoles={[SUPER_USER, SERVICE_USER, REPRESENTATION_USER]}
        />

        {/* Segment Table */}
        <ProtectedRoute
          path='/segment-tables'
          exact
          component={segmentTableUpload}
          roles={[SUPER_USER, SERVICE_USER]}
          coreRoles={[SUPER_USER, SERVICE_USER]}
        />

        {/* Dashboard */}
        <ProtectedRoute
          path='/'
          exact
          component={Dashboard}
          skipDashboardCheck
          roles={[
            SUPER_USER,
            SERVICE_USER,
            REPRESENTATION_USER,
            TOP_USER,
            USER
          ]}
          coreRoles={[
            SUPER_USER,
            SERVICE_USER,
            REPRESENTATION_USER,
            TOP_USER,
            USER
          ]}
        />

        <Route
          path='/login'
          component={Login}
        />

        <Route
          path='/waiting'
          component={Waiting}
        />

        <Route path='/not-found'>
          <NotFound />
        </Route>

        <Route path='/session-expired' component={SessionExpired} />

        <Redirect from='*' to='/not-found' />
      </Switch>
    </>
  )
}

export default Routes
