

import React from 'react'
import TextInput from '../../../components/forms/TextInput';
import SubmitForm from '../../../components/forms/SubmitForm';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  width: 22em;
`

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const mapVariablesByVarNr = (variables) => {
  if (variables === null || variables === undefined) {
    return []
  }
  return convertArrayToObject(variables, "var_nr")
}

const RfidVariablesForm = ({ variables, onChange }) => {
  const { t } = useTranslation()

  const handleOnChange = (e) => {
    onChange(e.target.name, e.target.value)
  }

  const variablesByNr = mapVariablesByVarNr(variables)

  return (
    <SubmitForm
      onChange={handleOnChange}
      noValidate
      autoComplete='off'
    >
      <InputLayout>
        <Column>
          {/*left column*/}
          {[556, 557, 554, 555].map(varNr => (
            <TextInput
                validations={{
                  number: {
                    message: t('control_rfid_form.validation.must_be_number'
                    )
                  },
                  maxLength: {
                    maxLength: 4,
                    message: t('control_rfid_form.validation.rfid_variable_max_length')
                  }
                }}
              variant='outlined'
              margin='normal'
              fullWidth
              initValue={variablesByNr[varNr]?.value}
              name={`${varNr}`}
              label={t(`control_rfid_form.labels.var_${varNr}`)}
              type='text'
            />
          )) }
        </Column>

        {/*right column*/}
        <Column>
          {[550, 551, 552, 553].map(varNr => (
            <TextInput
              validations={{
                number: {
                  message: t('control_rfid_form.validation.must_be_number'
                  )
                },
                maxLength: {
                  maxLength: 4,
                  message: t('control_rfid_form.validation.rfid_variable_max_length')
                }
              }}
              variant='outlined'
              margin='normal'
              fullWidth
              initValue={variablesByNr[varNr]?.value}
              name={`${varNr}`}
              label={t(`control_rfid_form.labels.var_${varNr}`)}
              type='text'
            />
          )) }
        </Column>
      </InputLayout>
    </SubmitForm>
  )
}

export default RfidVariablesForm