export const CUSTOMER_CONTROL_LIST_REQUEST = 'CUSTOMER_CONTROL_LIST_REQUEST'
export const CUSTOMER_CONTROL_LIST_SUCCESS = 'CUSTOMER_CONTROL_LIST_SUCCESS'
export const CUSTOMER_CONTROL_LIST_FAILURE = 'CUSTOMER_CONTROL_LIST_FAILURE'

export const CUSTOMER_CONTROL_DELETE_REQUEST =
  'CUSTOMER_CONTROL_DELETE_REQUEST'
export const CUSTOMER_CONTROL_DELETE_SUCCESS =
  'CUSTOMER_CONTROL_DELETE_SUCCESS'
export const CUSTOMER_CONTROL_DELETE_FAILURE =
  'CUSTOMER_CONTROL_DELETE_FAILURE'
export const CUSTOMER_CONTROL_RESET_DELETE_STATE =
  'CUSTOMER_CONTROL_RESET_DELETE_STATE'

export const CUSTOMER_CONTROL_UPDATE_REQUEST =
  'CUSTOMER_CONTROL_UPDATE_REQUEST'
export const CUSTOMER_CONTROL_UPDATE_SUCCESS =
  'CUSTOMER_CONTROL_UPDATE_SUCCESS'
export const CUSTOMER_CONTROL_UPDATE_FAILURE =
  'CUSTOMER_CONTROL_UPDATE_FAILURE'

export const CUSTOMER_CONTROL_GET_CONTROL_REQUEST =
  'CUSTOMER_CONTROL_GET_CONTROL_REQUEST'
export const CUSTOMER_CONTROL_GET_CONTROL_SUCCESS =
  'CUSTOMER_CONTROL_GET_CONTROL_SUCCESS'
export const CUSTOMER_CONTROL_GET_CONTROL_FAILURE =
  'CUSTOMER_CONTROL_GET_CONTROL_FAILURE'

export const CUSTOMER_CONTROL_GET_VARIABLES_REQUEST =
  'CUSTOMER_CONTROL_GET_VARIABLES_REQUEST'
export const CUSTOMER_CONTROL_GET_VARIABLES_SUCCESS =
  'CUSTOMER_CONTROL_GET_VARIABLES_SUCCESS'
export const CUSTOMER_CONTROL_GET_VARIABLES_FAILURE =
  'CUSTOMER_CONTROL_GET_VARIABLES_FAILURE'

export const CUSTOMER_CONTROL_GET_VARIABLES_CHART_REQUEST =
  'CUSTOMER_CONTROL_GET_VARIABLES_CHART_REQUEST'
export const CUSTOMER_CONTROL_GET_VARIABLES_CHART_SUCCESS =
  'CUSTOMER_CONTROL_GET_VARIABLES_CHART_SUCCESS'
export const CUSTOMER_CONTROL_GET_VARIABLES_CHART_FAILURE =
  'CUSTOMER_CONTROL_GET_VARIABLES_CHART_FAILURE'

export const CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST =
  'CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST'
export const CUSTOMER_CONTROL_UPDATE_VARIABLES_SUCCESS =
  'CUSTOMER_CONTROL_UPDATE_VARIABLES_SUCCESS'
export const CUSTOMER_CONTROL_UPDATE_VARIABLES_FAILURE =
  'CUSTOMER_CONTROL_UPDATE_VARIABLES_FAILURE'

export const CUSTOMER_CONTROL_VARIABLES_RELOAD_REQUEST =
  'CUSTOMER_CONTROL_VARIABLES_RELOAD_REQUEST'
export const CUSTOMER_CONTROL_VARIABLES_RELOAD_SUCCESS =
  'CUSTOMER_CONTROL_VARIABLES_RELOAD_SUCCESS'
export const CUSTOMER_CONTROL_VARIABLES_RELOAD_FAILURE =
  'CUSTOMER_CONTROL_VARIABLES_RELOAD_FAILURE'

export const CUSTOMER_CONTROL_SCRAPER_REQUEST =
  'CUSTOMER_CONTROL_SCRAPER_REQUEST'
export const CUSTOMER_CONTROL_SCRAPER_SUCCESS =
  'CUSTOMER_CONTROL_SCRAPER_SUCCESS'
export const CUSTOMER_CONTROL_SCRAPER_FAILURE =
  'CUSTOMER_CONTROL_SCRAPER_FAILURE'

export const CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST =
  'CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST'
export const CUSTOMER_CONTROL_UPDATE_SCRAPER_SUCCESS =
  'CUSTOMER_CONTROL_UPDATE_SCRAPER_SUCCESS'
export const CUSTOMER_CONTROL_UPDATE_SCRAPER_FAILURE =
  'CUSTOMER_CONTROL_UPDATE_SCRAPER_FAILURE'

export const INFO_SCREEN_SCRAPER_SELECTED = 'INFO_SCREEN_SCRAPER_SELECTED'
export const INFO_SCREEN_SCRAPER_TAB_SELECTED = 'INFO_SCREEN_SCRAPER_TAB_SELECTED'
