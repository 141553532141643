import React from 'react'
import {
  Button,
  Container,
  Grid
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'

import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import Sidebars from '../../../components/layouts/Sidebars'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import { PollControlData } from '../../../components/polling'

function ConveyorBeltDetailsDashboard (props) {
  const { t } = useTranslation()
  const params = useParams()
  useSetMessageIcon()
  useSetControlTitle(params.id)
  useShowConnectionSymbol(params.id)

  return (
    <Container className='h-100' component='main' maxWidth='xl' fixed>
      <PollControlData />
      <div className='MenuScroll'>
        <Grid container spacing={1} className='AdminDashboard-row'>

          <Grid item xs={12}>
            <Grid container spacing={0} justifyContent='space-between'>
              <Grid item xs={12}>
                <Link to='./info/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('cenveyor_belts_dashbord.info')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent='space-between'>
              <Grid item xs={12}>
                <Link to='./messages/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('cenveyor_belts_dashbord.messages')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12}>
                <Link to='./dashboard/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth

                  >
                    {t('cenveyor_belts_dashbord.belt_dashbord')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12}>

                <Link to='./damage/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('cenveyor_belts_dashbord.belt_damage_detection')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12}>
                <Link to='./notifications/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('cenveyor_belts_dashbord.notifications')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item xs={12}>
                <Link to='./settings/'>
                  <Button
                    className='dashboard-btn'
                    variant='outlined'
                    color='primary'
                    size='large'
                    fullWidth
                  >
                    {t('cenveyor_belts_dashbord.settings')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </div>
    </Container>
  )
}

export default Sidebars(ConveyorBeltDetailsDashboard, { showSearch: false })
