import React from 'react'

const formContext = React.createContext(
  {
    showErrors: false,
    setFormState: (state) => {}
  }
)

export default formContext
