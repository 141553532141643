import { VARIABLE_MAP } from '../constants/variables'

export const mapVariables = (variables, mapId) => {
  const map = VARIABLE_MAP[mapId]
  if (typeof (map) === 'number') {
    return variables.find(variable => Number(map) === Number(variable.var_nr))
  }

  if (map && 'length' in map && map.length > 0) {
    const varNumbers = map.map((mapData) => mapData.varNo)
    return variables.filter(variable => varNumbers.includes(Number(variable.var_nr)))
      .map(filteredVar => {
        const mapVar = map.find((mapVar) => Number(filteredVar.var_nr) === Number(mapVar.varNo))
        return { ...filteredVar, position: mapVar.position, meta: mapVar.meta }
      }).sort((filteredVariable1, filteredVariable2) => {
        return Number(filteredVariable1.position) - Number(filteredVariable2.position)
      }).reduce((currentVars, filteVar) => {
        if (currentVars.length > 0) {
          const lastValue = currentVars[currentVars.length - 1]
          if (('length' in lastValue
            ? (lastValue
              .find((innerFilterValueToFind) =>
                innerFilterValueToFind.position === filteVar.position) != null)
            : lastValue.position === filteVar.position)) {
            if ('length' in lastValue) {
              lastValue.push(filteVar)
              return currentVars
            }
            currentVars[currentVars.length - 1] = [lastValue, filteVar]
            return currentVars
          }
        }
        currentVars.push(filteVar)
        return currentVars
      }, [])
  }
  return []
}
