import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SelectInput from '../../components/forms/SelectInput'
import SubmitForm from '../../components/forms/SubmitForm'
import Loader from '../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import MenuItem from '@material-ui/core/MenuItem'
import { tokenRoles } from '../../constants/roles'
import { useDispatch, useSelector } from 'react-redux'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { REPRESENTATION_TOKEN_ADD_REQUEST } from '../../store/representationToken/representationTokenActions';
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '50px'
    }
  },
  variant: 'menu',
  getContentAnchorEl: null
}
const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > div:nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const AddRepresentationToken = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const routerHistory = useHistory()
  const params = useParams()
  const actionsClasses = useActions()
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const isMount = useRef(false)
  const isMountSuccess = useRef(false)
  const { success, error, data } = useSelector((state) => state.representationToken)
  useSetParentCustomInnerHeaderHeading()
  const handleSubmit = (formData) => {
    setShowLoader(true)
    dispatch({
      type: REPRESENTATION_TOKEN_ADD_REQUEST,
      payload: { ...formData, representation_id: params.id }
    })
  }

  // Handling form submission and redirecting back.
  useEffect(() => {
    if (isMountSuccess.current && data) {
      setShowLoader(false)
      toast.success(t('add_user_tokens.success'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      routerHistory.push('../')
    } else {
      isMountSuccess.current = true
    }
  }, [data])

  // Handiling Error and displaying it
  useEffect(() => {
    if (isMount.current && error && success === false) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  return (
    <Card className={classes.root + ' CardBorderStyle'} border={0}>
      <Loader open={showLoader} />

      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent className=''>
          <div className={classes.paper}>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_user_tokens.validation.name_required')
                  },
                  maxLength: {
                    maxLength: 100,
                    message: t('add_user_tokens.validation.name_max_length')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='name'
                label={t('add_user_tokens.name')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_user_tokens.validation.key_number_required')
                  },
                  length: {
                    length: 4,
                    message: t('add_user_tokens.validation.key_number_length')
                  },
                  digits: {
                    message: t('add_user_tokens.validation.key_number_digits')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='key_number'
                label={t('add_user_tokens.keyNo')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_user_tokens.validation.user_level_required')
                  },
                  length: {
                    length: 4,
                    message: t('add_user_tokens.validation.user_level_length')
                  },
                  digits: {
                    message: t('add_user_tokens.validation.user_level_digits')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='user_level'
                label={t('add_user_tokens.userLevel')}
                type='text'
              />
            </div>
            <div>
              <TextInput
                validations={{
                  required: {
                    message: t('add_user_tokens.validation.uid_required')
                  },
                  length: {
                    length: 8,
                    message: t('add_user_tokens.validation.uid_length')
                  },
                  uid: {
                    message: t('add_user_tokens.validation.uid_digits')
                  }
                }}
                variant='outlined'
                margin='normal'
                fullWidth
                name='uid'
                label={t('add_user_tokens.uid')}
                type='text'
              />
            </div>
            <div>
              <SelectInput
                validations={{
                  required: {
                    message: t('add_user_tokens.validation.role_required')
                  }
                }}
                variant='outlined'
                fullWidth
                name='role'
                label={t('add_user_tokens.role')}
                type='text'
                MenuProps={MenuProps}
              >
                {tokenRoles.map((role) => (
                  <MenuItem value={role.id}>{role.name}</MenuItem>
                ))}
              </SelectInput>
            </div>
          </div>
        </CardContent>
        <CardActions className='ButtonPanelWrap ButtonPanelWrap-2'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'} style={{ justifyContent: 'flex-end' }}>
            <Button
              className={classes.submit}
              type='submit'
              variant='outlined'
            >
              {t('add_user_tokens.save')}
            </Button>
          </Box>
        </CardActions>
      </SubmitForm>
    </Card>
  )
}

export default Sidebars(AddRepresentationToken)
