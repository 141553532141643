import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

function HeaderConnection() {
    const online  = useSelector((state) => state.customerControl?.control?.online);

    return (
        <div>
        { online ? 
        <img className="WifiIcon" style={{ }} src="/assets/img/signal.png" />:
        <img className="WifiIcon" style={{ }} src="/assets/img/no-signal.png" />
        }
        </div>
    );
}

export default HeaderConnection;