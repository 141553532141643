import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Loader from './Ux/Loader'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { USER_LOGOUT_REQUEST } from '../store/users/userActions'
import { useHistory, Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

import InnerHeader from './InnerHeader'
import { MESSAGE_FAULTS_STATUS_REQUEST } from '../store/message/messageAction'
import HeaderConnection from './utilities/HeaderConnection'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(15)
  }
}))

const Header = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user.user)
  const [showLoader, setShowLoader] = useState(false)
  const innerHeaderHeadings = useSelector((state) => state.utility.innerHeader)
  const { showConnectionIcon } = useSelector((state) => state.utility)
  const isMount = useRef(false)
  const language = localStorage.getItem('i18nextLng')
  const megaHeaderClass = useSelector((state) => state.utility.headerClass)
  const showMessageIcons = useSelector((state) => state.utility.showMessageIcons)
  const { messagesUnread } = useSelector(
    (state) => state.messages
  )
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    dispatch({ type: MESSAGE_FAULTS_STATUS_REQUEST })
  }, [])

  useEffect(() => {
    if (isMount.current && !user) {
      history.replace('/login')
      setShowLoader(false)
    } else {
      isMount.current = true
    }
  }, [user])
  // logout frontend, then redirect to backend logout which redirects back to frontend afterwards
  // this also revokes the token
  // TODO: maybe move this to saga
  const handleLogout = async () => {
    setShowLoader(true)
    dispatch({ type: USER_LOGOUT_REQUEST })
  }

  const handleDamageClick = (id) => {
    history.push(`/cenveyor-belts/menu/${id}/damage/`)
  }

  return (
    <div className={classes.root + ' app-header ' + megaHeaderClass}>
      <AppBar position='fixed' color='default'>
        <Loader open={showLoader} />
        <Toolbar style={{ backgroundColor: '#fff' }}>
          <Grid className='right-bar LeftBar' style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute' }}>
            <Box className='header-notifications-alert'>

              {
                showMessageIcons && messagesUnread && messagesUnread.damaged_field_module_ids &&
                       messagesUnread.damaged_field_module_ids.length > 0 &&
                         <IconButton className='hide-repel' onClick={() => handleDamageClick(messagesUnread.damaged_field_module_ids[0])} style={{ color: 'rgb(204, 62, 62)', borderRadius: '0', marginRight: '2px', position: 'relative' }}>
                           <span className='DamageCounter headerDamageCounter'>{messagesUnread.unread_damage_count}</span>
                           <img className='WifiIcon' alt='icon' src='/assets/img/achtung.png' />
                         </IconButton>
              }

              {showMessageIcons && messagesUnread.error
                ? <IconButton className='hide-repel' onClick={() => { history.push('/messages?type=desruptions') }} style={{ color: '#FD9C00', marginRight: '5px', borderRadius: '0' }}>
                  <img className='WifiIcon' alt='messages' src='/assets/img/!-1.png' />
                </IconButton> : null}

              {showMessageIcons && messagesUnread.warning
                ? <IconButton className='hide-repel' onClick={() => { history.push('/messages?type=messages') }} style={{ color: '#EACF00', borderRadius: '0' }}>
                  <img alt='messages' className='WifiIcon' src='/assets/img/!.png' />
                </IconButton> : null}

            </Box>
          </Grid>
          <Grid container alignItems='center' alignContent='center' spacing={1}>
            <Grid item xs='12' align='center'>
              <Link to='/'><img src='/assets/img/starclean-logo.svg' className='big-logo' alt='starclean logo' /></Link>
            </Grid>
            <Grid className='right-bar' style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '15px' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {showConnectionIcon && <HeaderConnection />}
                {/* {         showMessageIcons && (
                        messagesUnread.offline ?
                        <img className="WifiIcon" style={{ }} src="/assets/img/no-signal.png" />
                       : <img className="WifiIcon" style={{ }} src="/assets/img/signal.png" />) || null
                } */}

                {!user ? (
                  <ButtonGroup
                    className='BtnLanguge'
                    variant='contained'
                    aria-label='contained primary button group'
                  >
                    <Button
                      color={language === 'de' ? 'primary' : ''}
                      onClick={(e) => handleLanguageChange('de')}
                    >
                      de
                    </Button>
                    <Button
                      color={language === 'en' ? 'primary' : ''}
                      onClick={(e) => handleLanguageChange('en')}
                    >
                      en
                    </Button>
                  </ButtonGroup>
                ) : null}
                {user ? (
                  <IconButton
                    className='BtnLogout'

                    onClick={handleLogout}
                  >
                    <img className='logout-big' alt='logout' src='/assets/img/logout.png' />
                    <img className='logout-small' alt='logout' style={{ display: 'none' }} src='/assets/img/logout-small.png' />

                  </IconButton>
                ) : null}
              </Box>

            </Grid>

          </Grid>

        </Toolbar>
        <InnerHeader headers={innerHeaderHeadings} />
      </AppBar>
    </div>
  )
}

export default Header
