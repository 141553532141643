import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { makeStyles } from '@material-ui/core/styles'
import theme from '../../../theme'
import Sidebars from '../../../components/layouts/Sidebars'
import { useTranslation } from 'react-i18next'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useParams } from 'react-router-dom'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_CONTROL_REQUEST, CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST } from '../../../store/customerControl/customerControlActions'
import { GET_DAMAGE_MEASUREMENT_CHART_REQUEST, GET_DAMAGE_EVENTS_CHART_REQUEST, DELETE_DAMAGE_EVENTS_REQUEST, MARK_READ_EVENTS_REQUEST } from '../../../store/damage/damageActions'
import Loader from '../../../components/Ux/Loader'
import { toast } from 'react-toastify'
import DamageChart from '../../../components/utilities/DamageChart'
import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
// import useSetParentCustomInnerHeaderHeading from '../../../components/hooks/useSetParentCustomInnerHeaderHeading';

import NumberFormat from '../../../components/utilities/numberFormat'
import {
  BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_1,
  BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_2,
  BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_3
} from '../../../constants/variables'
import { mapVariables } from '../../../helpers/variableMappers'

import { BELT_SETTINGS_MEASUREMENT_RUN_REQUEST } from '../../../store/beltSettings/beltSettingsActions'

import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import Box from '@material-ui/core/Box'
import { MESSAGE_FAULTS_STATUS_REQUEST } from '../../../store/message/messageAction'
import { PollControlData } from '../../../components/polling'
import BeltDamageSettings from './components/BeltDamageSettings'
import {
  SERVICE_USER,
  SUPER_USER,
  REPRESENTATION_USER,
  TOP_USER,
  USER
} from '../../../constants/roles'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const largestDamagePerPosition = (events) => {
  // only show highest value with highest level by position
  // const filteredLevels = levels.map(x => )
  const eventsByPosition = {}
  events.forEach(nextEvent => {
    const key = `${nextEvent.position}`

    const prevEvent = eventsByPosition[key]
    // if there was already an event at this position
    if (prevEvent) {
      // check if value of nextEvent is higher than the previous event
      if (Number(nextEvent.value) > Number(prevEvent.value)) {
        eventsByPosition[key] = { ...nextEvent }
      }
    } else {
      // event did not exist yet
      eventsByPosition[key] = { ...nextEvent }
    }
  })
  return Object.values(eventsByPosition)
}

// one table cell in belt damage table at top left corner of belt damage page
const BeltDamageTableCell = ({ event }) => {
  if (!event) {
    return (<td />)
  }

  const { position, value, timestamp } = event

  return (
    <td>
      <div className={!event.readAt ? 'red-text' : null}>
        {
          position &&
            <>
              <NumberFormat decimalPlaces={2}>{position / 100}</NumberFormat> {' m'}
            </>
        }
        <br />
        {
          value &&
            <>
              <NumberFormat decimalPlaces={0}>{value}</NumberFormat> µV
            </>
        }
      </div>
      {timestamp && <Moment tz="Europe/Berlin" format='YY-MM-DD HH:mm:ss'>{timestamp}</Moment>}
    </td>
  )
}

function ConveyorBeltDamage (props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const actionsClasses = useActions()
  const params = useParams()
  const dispatch = useDispatch()
  useShowConnectionSymbol(params.id)
  // useSetParentCustomInnerHeaderHeading()
  const { control, error: controlError } = useSelector((state) => state.customerControl)
  const { updatedVariable, error: updatedVariableError } = useSelector((state) => state.customerControl)
  const { events, error, isDeleted, isMarkedRead } = useSelector((state) => state.damage)
  const damageData = useSelector((state) => state.damage.measurementRunByFmId[params.id])
  const measurementTimestamp = damageData ? damageData.timestamp : 0
  const [showLoader, setShowLoader] = useState(false)
  const [chartMeasurementData, setChartMeasurementData] = useState([])
  const [damangeChartData, setDamangeChartData] = useState([])
  const [levels, setLevels] = useState([])
  const [damageCol1, setDamageCol1] = useState([])
  const [damageCol2, setDamageCol2] = useState([])
  const [damageCol3, setDamageCol3] = useState([])
  const isControlMount = useRef(false)
  const isDamageChartMount = useRef(false)
  const isErrorMount = useRef(false)
  const isControlErrorMount = useRef(false)
  const isEventsMount = useRef(false)
  const isDamageEventDeleteMounted = useRef(false)
  const isMarkedReadMount = useRef(false)
  const isVariableMount = useRef(false)
  const isUpdatedVariablesMount = useRef(false)
  const isDamageChartAndVariableMount = useRef(false)
  const updateCharts = useRef(false)
  const isUupdatedVariableErrorMountRef = useRef(false)
  const isMeasurementChartShow = useRef(false)
  const variables = useSelector((state) => state.customerControl.variables)
  const [showAcknowledge, setShowAcknowledge] = useState(false)
  const [maxMeasurementX, setMaxMeasurementX] = useState(0)
  const { profile } = useSelector((state) => state.user)
  const actionAllowed = {
    markEventsAsRead: profile.role != REPRESENTATION_USER && profile.role != USER,
    storeTable: profile.role != REPRESENTATION_USER && profile.role != USER,
    changeSettings: profile.role != REPRESENTATION_USER && profile.role != USER,
    readMeasurementRun: profile.role != REPRESENTATION_USER
  }

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: params.id })
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
  }, [params])

  useEffect(() => {
    if (isUpdatedVariablesMount.current) {
      setShowLoader(false)
      toast.success(t('conveyor_belt_damage.toast.update_success'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      if (updateCharts.current) {
        setShowLoader(true)
        dispatch({ type: GET_DAMAGE_MEASUREMENT_CHART_REQUEST, payload: control.latest_measurement_series_id })
      }
    } else {
      isUpdatedVariablesMount.current = true
    }
  },
  [updatedVariable])

  // show error toast if there is any
  useEffect(() => {
    if (isUupdatedVariableErrorMountRef.current) {
      setShowLoader(false)
      toast.error(updatedVariableError && updatedVariableError.message ? updatedVariableError.message : t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isUupdatedVariableErrorMountRef.current = true
    }
  },
  [updatedVariableError])

  useEffect(() => {
    if (isVariableMount.current) {
      setShowLoader(false)
      const damageCol1 = mapVariables(Object.values(variables), BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_1)
      const damageCol2 = mapVariables(Object.values(variables), BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_2)
      const damageCol3 = mapVariables(Object.values(variables), BELT_CONVEYOR_DAMAGE_CONTROL_BOTTOM_COL_3)
      setDamageCol1(damageCol1.map(x => ({ ...x, valid: true })))
      setDamageCol2(damageCol2.map(x => ({ ...x, valid: true })))
      setDamageCol3(damageCol3.map(x => ({ ...x, valid: true })))
    } else {
      isVariableMount.current = true
    }
  }, [variables])

  // delete events and reload resources
  useEffect(() => {
    if (isDamageEventDeleteMounted.current && isDeleted) {
      setShowLoader(false)
      toast.success(t('conveyor_belt_damage.toast.events_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(true)
      dispatch({ type: GET_DAMAGE_MEASUREMENT_CHART_REQUEST, payload: control.latest_measurement_series_id })
      dispatch({ type: GET_DAMAGE_EVENTS_CHART_REQUEST, payload: 'q[event_type_id_eq]=9&q[field_module_id_eq]=' + params.id })
      dispatch({ type: MESSAGE_FAULTS_STATUS_REQUEST })
    } else {
      isDamageEventDeleteMounted.current = true
    }
  },
  [isDeleted])

  // is called when control has changed (when polling it in background)
  useEffect(() => {
    if (isControlMount.current) {
      setShowLoader(false)
      // show message that there is no damage data
      if (!control.latest_measurement_series_id) {
        toast.error(t('conveyor_belt_damage.toast.damage_data_empty'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        setShowLoader(false)
        setShowLoader(true)
        dispatch({ type: GET_DAMAGE_EVENTS_CHART_REQUEST, payload: 'q[event_type_id_eq]=9&q[field_module_id_eq]=' + params.id })
        return
      }
      dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
      dispatch({ type: GET_DAMAGE_MEASUREMENT_CHART_REQUEST, payload: control.latest_measurement_series_id })
    } else {
      isControlMount.current = true
    }
  }, [control])

  useEffect(() => {
    if (isDamageChartMount.current) {
      setShowLoader(false)
      setShowLoader(true)
      dispatch({ type: GET_DAMAGE_EVENTS_CHART_REQUEST, payload: 'q[event_type_id_eq]=9&q[field_module_id_eq]=' + params.id })
    } else {
      isDamageChartMount.current = true
    }
  }, [damageData])

  useEffect(() => {
    if (isDamageChartAndVariableMount.current) {
      if (damageData && damageCol2 && damageCol2.length > 0 && damageCol1 && damageCol1.length > 0) {
        updateDamageChart()
      }
    } else {
      isDamageChartAndVariableMount.current = true
    }
  }, [damageData, damageCol2, damageCol1])

  useEffect(() => {
    if (levels && levels.length > 0) {
      const ureadData = levels.find((data) => data.level1 && !data.level1.readAt || data.level2 && !data.level2.readAt || data.level3 && !data.level3.readAt)
      setShowAcknowledge(!!ureadData)
      return
    }
    setShowAcknowledge(false)
  }, [levels])

  const handleReadMeasurementRun = () => {
    if (control && control.online) {
      dispatch({ type: BELT_SETTINGS_MEASUREMENT_RUN_REQUEST, payload: { id: params.id, data: { field_module_id: params.id } } })
      toast.success(t('conveyor_belt_damage.toast.measurement_update_request'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      // show control is offline message
      toast.error(t('conveyor_belt_settings.toast.field_module_offline'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  const updateDamageChart = () => {
    setChartMeasurementData(damageData.data.map((data) => {
      const chartData = Object.values(data)
      const mappedData = {}
      mappedData.Position = chartData[0] / 100
      mappedData[t('conveyor_belt_damage.measurement')] = chartData[1]
      mappedData[t('conveyor_belt_damage.damage_level_1')] = damageCol2[0].value
      mappedData[t('conveyor_belt_damage.damage_level_2')] = damageCol2[1].value
      mappedData[t('conveyor_belt_damage.damage_level_3')] = damageCol2[2].value
      return mappedData
    }))
    const maxX = damageData.data.reduce((prevValue, data) => {
      const chartData = Object.values(data)
      if (prevValue < chartData[0]) {
        prevValue = chartData[0]
      }
      return prevValue
    }, 0)
    setMaxMeasurementX(maxX / 100)
    isMeasurementChartShow.current = true
  }

  useEffect(() => {
    if (isEventsMount.current) {
      setShowLoader(false)
      const levelsChart = events.map((event) => {
        const metaData = event.meta_data
        return { ...metaData, position: Number(metaData.position) / 100, value: Number(metaData.value) }
      })
      const damageChart = [...levelsChart].sort(function (level1, level2) {
        if (level1.position > level2.position) {
          return 1
        }

        if (level1.position < level2.position) {
          return -1
        }

        return 0
      })

      setDamangeChartData(damageChart)
      const levels = getLevels(events)
      setLevels(levels.map((data) => ({ ...data })))
    } else {
      isEventsMount.current = true
    }
  }, [events, maxMeasurementX])

  useEffect(() => {
    if (isMarkedReadMount.current && isMarkedRead) {
      setShowLoader(false)
      toast.success(`${isMarkedRead.count} ${t('conveyor_belt_damage.toast.events_marked_read')}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(true)
      dispatch({ type: GET_DAMAGE_EVENTS_CHART_REQUEST, payload: 'q[event_type_id_eq]=9&q[field_module_id_eq]=' + params.id })
      dispatch({ type: MESSAGE_FAULTS_STATUS_REQUEST })
    } else {
      isMarkedReadMount.current = true
    }
  }, [isMarkedRead])

  const getLevels = (events) => {
    const sortFunc = (leveData1, leveData2) => {
      if (leveData1.timestamp > leveData2.timestamp) {
        return 1
      }
      if (leveData1.timestamp < leveData2.timestamp) {
        return -1
      }
      return 0
    }

    // add meta_data to each event
    const levels = events.map((event) => {
      return {
        ...event.meta_data,
        timestamp: new Date(event.timestamp),
        readAt: event.read_at
      }
    })

    const uniqueLevels = largestDamagePerPosition(levels)

    const level1 = uniqueLevels.filter((levelData) => Number(levelData.level) === 1).sort(sortFunc)
    const level2 = uniqueLevels.filter((levelData) => Number(levelData.level) === 2).sort(sortFunc)
    const level3 = uniqueLevels.filter((levelData) => Number(levelData.level) === 3).sort(sortFunc)

    let greatestLength = level1.length
    const eventsData = []
    if (greatestLength < level2.length) {
      greatestLength = level2.length
    }

    if (greatestLength < level3.length) {
      greatestLength = level3.length
    }

    for (let i = 0; i < greatestLength; i++) {
      const colLevel1 = level1[i] ? level1[i] : null
      const colLevel2 = level2[i] ? level2[i] : null
      const colLevel3 = level3[i] ? level3[i] : null
      eventsData.push({
        level1: colLevel1,
        level2: colLevel2,
        level3: colLevel3
      })
    }
    return eventsData
  }

  useEffect(() => {
    if (isErrorMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isControlErrorMount.current && controlError) {
      setShowLoader(false)
      toast.error(controlError, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isControlErrorMount.current = true
    }
  }, [controlError])

  const handleDeleteClicked = () => {
    setShowLoader(true)
    dispatch({ type: DELETE_DAMAGE_EVENTS_REQUEST, payload: 'q[event_type_id_eq]=9&q[field_module_id_eq]=' + params.id })
  }

  const handleMarkReadClicked = () => {
    setShowLoader(true)
    dispatch({ type: MARK_READ_EVENTS_REQUEST, payload: `read_until=${new Date().toISOString()}&event_type_id=9&field_module_id=${params.id}` })
  }

  useSetMessageIcon()
  useSetControlTitle(params.id)

  const handleLevelChange = (e, i) => {
    const damageCol3Copy = damageCol3.map((variable) => ({ ...variable }))
    const variable = damageCol3Copy.find((variable, index) => i === index)
    variable.value = parseInt(e.target.value, 10)
    setDamageCol3(damageCol3Copy)
  }

  const updateVariable = (data) => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_UPDATE_VARIABLES_REQUEST, payload: { id: params.id, data } })
  }

  const handleDamageValues = (e, i) => {
    if (!(/^[0-9]*?\.?[0-9]*$/.test(e.target.value))) {
      return
    }

    const damageCol2Copy = damageCol2.map((variable) => ({ ...variable }))
    const variable = damageCol2Copy.find((variable, index) => i === index)
    variable.value = e.target.value
    // validate if value is greater than reference height (minimal damage height)
    // if it is not greater => render error and dont apply value
    const minimalDamageHeight = Number(damageCol1.filter(x => x.var_nr === 1010)[0].value)
    variable.valid = Number(variable.value) >= minimalDamageHeight
    setDamageCol2(damageCol2Copy)
  }

  const handleSave = () => {
    const mappedCol2 = damageCol2.map(col => ({ id: col.id, value: parseFloat(col.value ? col.value : 0) }))
    const mappedCol3 = damageCol3.map(col => ({ id: col.id, value: parseFloat(col.value ? col.value : 0) }))
    const mappedData = [...mappedCol2, ...mappedCol3]
    updateCharts.current = true
    setShowLoader(true)
    updateVariable(mappedData)
  }

  const lineChartYColors = {}
  lineChartYColors[t('conveyor_belt_damage.measurement')] = '#4d71b5'
  lineChartYColors[t('conveyor_belt_damage.damage_level_1')] = 'rgb(193 171 48)'
  lineChartYColors[t('conveyor_belt_damage.damage_level_2')] = 'rgb(126 104 84)'
  lineChartYColors[t('conveyor_belt_damage.damage_level_3')] = '#723b3d'

  return (
    <>
      <PollControlData />
      <Card className={classes.root + ' CardBorderStyle  DamageOuter'} border={0}>
        {/* <Loader open={showLoader} /> */}
        <CardContent>
          <Grid container className='MainContInnerStyle' alignContent='stretch'>
            <div style={{ width: '100%' }} className='Inner_scroll pb-0'>
              <div className='Inner_scroll-con PR-0' style={{ overflow: 'hidden', paddingBottom: '10px', paddingRight: '0!important' }}>
                <div className=' h-100 resize-container-damage-charts' style={{ overflowX: 'hidden', overflowY: 'auto', paddingRight: '6px' }}>
                  <Grid container alignContent='stretch'>
                    <Grid item md='5' xs='12'>
                      <div className='BasicTable Inner_scrollTwoTable mr-2 pt-0 table-list-convey' style={{ paddingBottom: '1em' }}>
                        <h3 className='mt-0 mb-0'>{t('conveyor_belt_damage.table_heading')}</h3>
                        <div className='table' style={{ maxHeight: '343px', overflowY: 'auto', paddingRight: '10px' }}>
                          <table className='w-100' cellPadding='7'>
                            <thead>
                              <th style={{ width: '10%' }}>
                                <strong>{t('conveyor_belt_damage.nr')}</strong>
                              </th>
                              <th style={{ width: '30%' }}>
                                <strong>{t('conveyor_belt_damage.level_1')}</strong>
                              </th>
                              <th style={{ width: '30%' }}>
                                <strong>{t('conveyor_belt_damage.level_2')}</strong>
                              </th>
                              <th style={{ width: '30%' }}>
                                <strong>{t('conveyor_belt_damage.level_3')}</strong>
                              </th>
                            </thead>

                            <tbody>
                              {levels && levels.length > 0 && levels.map((data, i) => {
                                return (<tr>
                                  <td>{i + 1}</td>
                                  {data && <BeltDamageTableCell event={data.level1} />}
                                  {data && <BeltDamageTableCell event={data.level2} />}
                                  {data && <BeltDamageTableCell event={data.level3} />}
                                </tr>
                                )
                              })}

                              {

                                levels && levels.length === 0
                                  ? <tr>
                                    <td colspan={4} className='text-center'>
                                      {t('noRecordsFound')}
                                    </td>
                                  </tr>
                                  : null
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='dflex justify-content-space-between mt-1 DamageButton  '>
                          {actionAllowed.storeTable && <Button disabled={control && !control.latest_measurement_series_id || levels && levels.length === 0} variant='outlined' onClick={handleDeleteClicked} size='medium'>{t('conveyor_belt_damage.delete_table')}</Button>}
                          {showAcknowledge && actionAllowed.markEventsAsRead
                            ? <Button disabled={control && !control.latest_measurement_series_id || levels && levels.length === 0} variant='outlined' onClick={handleMarkReadClicked} size='medium' className='RedText'>{t('conveyor_belt_damage.acknowledge_damage')}</Button>
                            : null}
                        </div>

                      </div>
                    </Grid>
                    <Grid item md='7' xs='12'>
                      <Grid
                        container direction='row' className='h-100 pl-2'
                      >
                        {/* Chart and Header are placed on top of each other using grid layout */}
                        <Grid item xs='12' style={{ display: 'grid', border: '1px solid #d9d9d9', marginBottom: '5px' }}>
                          <div style={{ paddingLeft: 10, paddingBottom: 12, paddingRight: 5, paddingTop: 20, gridColumn: 1, gridRow: 1 }}>
                            {isMeasurementChartShow.current && chartMeasurementData && chartMeasurementData.length > 0
                              ? <DamageChart
                                isLegend
                                xlabel={t('conveyor_belt_damage.measurement_chart_x_label')}
                                ylabel={t('conveyor_belt_damage.measurement_chart_y_label')}
                                data={chartMeasurementData} xAxisName='Position'
                                yAxisNames={[
                                  t('conveyor_belt_damage.measurement'),
                                  t('conveyor_belt_damage.damage_level_1'),
                                  t('conveyor_belt_damage.damage_level_2'),
                                  t('conveyor_belt_damage.damage_level_3')
                                ]}
                                yColors={lineChartYColors}
                                maxX={maxMeasurementX}
                                resizeContainer='.resize-container-damage-charts'
                              /> : <p style={{ fontSize: 12, textAlign: 'center' }}> {t('noRecordsFound')}</p>}
                          </div>
                          <div style={{ display: 'grid', gridTemplateColumns: '60px 1fr 60px', gridColumn: 1, gridRow: 1 }}>
                            <div />
                            <h3
                              style={
                                {
                                  fontSize: 12,
                                  color: '#000',
                                  fontWeight: 500,
                                  textAlign: 'center',
                                  padding: 0,
                                  margin: '4px 0px 1px 0px'
                              }
                              }
                            >{t('conveyor_belt_damage.chart_title', { date: moment(measurementTimestamp).tz("Europe/Berlin").format(t('conveyor_belt_damage.chart_title_date_format')), time: moment(measurementTimestamp).tz("Europe/Berlin").format(t('conveyor_belt_damage.chart_title_time_format')) })}
                            </h3>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                              {actionAllowed.readMeasurementRun && <div onClick={handleReadMeasurementRun} style={{ padding: '5px', height: '32px', width: '32px', cursor: 'pointer', border: '1px solid black' }}>
                                <img style={{ width: '100%', height: '100%' }} src='/assets/img/refresh.png' />
                              </div>}
                            </div>
                            {/* <a href="javascript:void(0)" className="dflex"><img style={ {cursor : 'pointer'} } onClick={handleReadMeasurementRun} src="/assets/img/refresh.png"/></a> */}
                            {/* <button style={{ overflowY: 'visible' }} onClick={handleReadMeasurementRun}>Refresh</button> */}
                          </div>
                        </Grid>
                        <Grid item xs='12' style={{ border: '1px solid #d9d9d9' }}>
                          <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                            {
                              damangeChartData && damangeChartData.length > 0
                                ? <DamageChart
                                  isBarChart
                                  xlabel={t('conveyor_belt_damage.damage_chart_x_label')}
                                  ylabel={t('conveyor_belt_damage.damage_chart_y_label')}
                                  domain={[0, 3]}
                                  tick={[0, 1, 2, 3]}
                                  tickCount={4}
                                  allowDecimals={false}
                                  interval={0}
                                  data={damangeChartData}
                                  maxX={maxMeasurementX}
                                  xAxisName='position'
                                  yAxisNames={['level']}
                                  resizeContainer='.resize-container-damage-charts'
                                />
                                : <p style={{ fontSize: 12, textAlign: 'center' }}> {t('noRecordsFound')}</p>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <BeltDamageSettings
                    damageCol1={damageCol1}
                    damageCol2={damageCol2}
                    damageCol3={damageCol3}
                    handleLevelChange={handleLevelChange}
                    handleDamageValues={handleDamageValues}
                    showSaveButton={actionAllowed.changeSettings}
                    readOnly={!actionAllowed.changeSettings}
                    handleSave={handleSave}
                    classes={classes}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default Sidebars(ConveyorBeltDamage, { showSearch: false })
