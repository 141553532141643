import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CONTROL_UPDATE_INTERVAL } from '../../constants/pollingIntervals'
import { CUSTOMER_CONTROL_GET_CONTROL_REQUEST } from '../../store/customerControl/customerControlActions'

export const PollControlData = ({ pollingInterval, children }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const intervalRef = useRef(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      console.log('polling control...')
      dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: params.id })
    }, pollingInterval || CONTROL_UPDATE_INTERVAL)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [params])

  return <>{children}</>
}

export default PollControlData
