import * as customerAreaActions from './customerAreaActions'

const initialState = {
  loading: false,
  customerAreas: [],
  success: false,
  error: null,
  isDeleted: 0
}

const customerArea = (state = initialState, action) => {
  switch (action.type) {
    case customerAreaActions.CUSTOMER_AREA_LIST_REQUEST:
      return { ...state, loading: true }
    case customerAreaActions.CUSTOMER_AREA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerAreas: action.customerAreas.map((customerArea) => {
          return { ...customerArea }
        })
      }
    case customerAreaActions.CUSTOMER_AREA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        customerAreas: [],
        error: action.error
      }
    case customerAreaActions.CUSTOMER_AREA_DELETE_REQUEST:
      return { ...state, loading: true }
    case customerAreaActions.CUSTOMER_AREA_DELETE_SUCCESS:
      return { ...state, loading: false, success: true, isDeleted: action.payload.id }
    case customerAreaActions.CUSTOMER_AREA_DELETE_FAILURE:
      return { ...state, loading: false, success: false, error: action.error }
    case customerAreaActions.CUSTOMER_AREA_RESET_DELETE_STATE:
      return { ...state, loading: false, success: false, error: null }
    default:
      return state
  }
}

export default customerArea
