import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import useInnerHeaderTitleUpdater from './hooks/useInnerHeaderTitleUpdater'
import { useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
function InnerHeader ({ headers }) {
  const { t } = useTranslation()
  useInnerHeaderTitleUpdater()
  const isLoading = useSelector((state) => state.utility.loading)
  return (
    <div className='green-bg ContentHeader'>
      <Container component='main' maxWidth='xl' fixed>
        <Grid container justifyContent='center'>
          <Grid item xs={1} />
          <Grid item xs='10' className='white-bg'>
            <span class='MuiTypography-root MuiCardHeader-title MuiTypography-h5 MuiTypography-displayBlock'>
              {headers && !isLoading ? headers.map((header) => {
                return (<Link to={header.url} key={header.name}>{t(header.name)}</Link>)
              }).reduce((prev, current, index) => {
                if (index === headers.length - 1) {
                  return [...prev, <span className='current-header'>{current}</span>]
                }
                let arrow = <ArrowForwardIosIcon className='current-header-arrow arrow-active' />
                if (index !== headers.length - 2) {
                  arrow = <ArrowForwardIosIcon className='current-header-arrow arrow-inactive' />
                }
                return [...prev, current, arrow]
              }, []) : <div
                style={{
                  width: 100,
                  height: 38,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                       > <Spinner
                name='ball-clip-rotate-multiple'
                />
              </div>}
            </span>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Container>
    </div>
  )
}

export default InnerHeader
