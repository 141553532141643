import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { Item } from '@mui/material'
import NumberFormat from '../../../../components/utilities/numberFormat'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  leftColumn: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      paddingRight: '10%'
    }
  },
  centerColumn: {
    justifyContent: 'flex-end'
  },
  rightColumn: {
    justifyContent: 'flex-end'
  },
  inputRowLeft: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '30%'
    }
  },
  inputRowCenter: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '30%'
    }
  },
  inputRowRight: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '30%'
    }
  }
}))

const BeltDamageSettings = ({ damageCol1, damageCol2, damageCol3, handleLevelChange, handleDamageValues, handleSave, showSaveButton, readOnly }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const cellStyle = { height: '40px' }
  const inputDivStyle = { height: '40px' }

  const valid = damageCol2.map(x => x.valid).every(Boolean)

  const showError = () => {
    toast.error(t('conveyor_belt_damage.toast.invalid_damage_level'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }

  return (
    <Grid container columns={12} style={{ paddingTop: '5px' }}>

      {/* row with inputs */}
      <Grid container xs={12} style={{ paddingRight: 15 }}>
        {/* left column */}
        <Grid item xs={12} sm={12} md={4}>
          <Grid container className={classes.leftColumn}>
            {
              damageCol1 && damageCol1.length > 0 &&
                                        damageCol1.map((variable) => {
                                          return (
                                            <Grid container className={classes.inputRowLeft}>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='center'>
                                                  <strong style={{ maxWidth: '11em', textAlign: 'right' }}>
                                                    {variable.label}:
                                                  </strong>
                                                </Grid>
                                              </Grid>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='center'>
                                                  <span className='ml-2'>
                                                    {variable.value
                                                      ? <NumberFormat decimalPlaces={0}>
                                                        {variable.value}
                                                      </NumberFormat>
                                                      : null}&nbsp;
                                                    {variable.unit ? variable.unit : null}
                                                  </span>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
            }
          </Grid>
        </Grid>

        {/* center column */}
        <Grid item xs={12} sm={12} md={4}>
          <Grid container className={classes.centerColumn}>
            {
              damageCol2 && damageCol2.length > 0 &&
                                        damageCol2.map((variable, i) => {
                                          return (
                                            <Grid container className={classes.inputRowCenter}>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='center'>
                                                  <strong>
                                                    {variable.label}:
                                                  </strong>
                                                </Grid>
                                              </Grid>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='center'>
                                                  <input disabled={readOnly} style={{ textAlign: 'right', height: '29px', fontSize: '16px', color: variable.valid ? 'black' : 'red' }} onChange={(e) => handleDamageValues(e, i)} size='4' value={variable.value} className='ml-0-5' type='text' />&nbsp;{variable.unit ? variable.unit : null}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
            }
          </Grid>
        </Grid>

        {/* right column */}
        <Grid item xs={12} sm={12} md={4}>
          <Grid container className={classes.rightColumn}>
            {
              damageCol3 && damageCol3.length > 0 &&
                                        damageCol3.map((variable, i) => {
                                          return (
                                            <Grid container className={classes.inputRowRight}>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='flex-end'>
                                                  <strong>
                                                    {variable.label}:
                                                  </strong>
                                                </Grid>
                                              </Grid>
                                              <Grid item>
                                                <Grid container style={cellStyle} alignItems='center' justifyContent='flex-end'>
                                                  <select
                                                    onChange={(e) => handleLevelChange(e, i)}
                                                    value={String(parseInt(variable.value, 10))}
                                                    style={{ height: 29 }}
                                                    disabled={readOnly}
                                                    className='form-control-select ml-3 d-block damage-select'
                                                  >
                                                    <option value='0'> {t('conveyor_belt_damage.action0')} </option>
                                                    <option value='1'> {t('conveyor_belt_damage.action1')} </option>
                                                    <option value='2'> {t('conveyor_belt_damage.action2')} </option>
                                                    <option value='3'> {t('conveyor_belt_damage.action3')} </option>
                                                  </select>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
            }
          </Grid>
        </Grid>
      </Grid>

      {/* button panel (row below the 3 input columns) */}
      <Grid xs={12}>
        <Grid className='ButtonPanel' style={{ justifyContent: 'flex-end', display: 'flex' }}>
          {showSaveButton && <Button
            style={{
              marginTop: 10
            }}
            onClick={valid ? handleSave : showError}
            variant='outlined'
                             >
            <img src='/assets/img/save.png' />&nbsp;&nbsp;
            {t('add_customer_users.save')}
          </Button>}
        </Grid>
      </Grid>

    </Grid>
  )
}

export default BeltDamageSettings
