import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextInput from '../../components/forms/TextInput'
import SubmitForm from '../../components/forms/SubmitForm'
import FileUpload from '../../components/forms/FileUpload'
import Loader from '../../components/Ux/Loader'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addRepresentation } from '../../apis/representations'
import { PROJECT_ID } from '../../constants/projectId'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'

const useActions = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  }
}))

const AddRepresentation = (props) => {
  const classes = useStyles()
  const [showLoader, setShowLoader] = useState(false)
  const { t } = useTranslation()
  const actionsClasses = useActions()
  const routerHistory = useHistory()
  useSetParentCustomInnerHeaderHeading()
  const handleSubmit = async (formData) => {
    try {
      const newFormData = { ...formData, project_id: PROJECT_ID }
      setShowLoader(true)
      const res = await addRepresentation(newFormData)
      if (res.status === 201) {
        setShowLoader(false)
        routerHistory.push('/representations')
        toast.success(t('add_representation.toast.representation_added'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
        return
      }
      throw new Error(t('error.something_went_wrong'))
    } catch (error) {
      let message = error.message
      if (error && error.response && error.response.status === 422) {
        message = error.response.data.message
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      setShowLoader(false)
    }
  }

  return (

    <Card className={classes.root + ' CardBorderStyle'}>
      <Loader open={showLoader} />
      <SubmitForm
        className={classes.form}
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
      >
        <CardContent>
          <Grid container alignContent='space-between' className='MainContInnerStyle'>
            <Grid item xs='12' className='Inner_scroll pl-1 pr-1'>
              <div className='Inner_scroll-con Pr-2'>
                <div className={classes.paper}>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.number_required')
                        },
                        maxLength: {
                          maxLength: 7,
                          message: t('add_representation.validation.number_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='representation_nr'
                      label={t('add_representation.representationNumber')}
                      type='email'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.name_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.name_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='name'
                      label={t('add_representation.name')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.facility_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='facility'
                      label={t('add_representation.facility')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.city_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.city_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='city'
                      label={t('add_representation.city')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.country_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.country_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='country'
                      label={t('add_representation.country')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.street_required')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.street_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='street'
                      label={t('add_representation.street')}
                      type='text'
                    />
                  </div>
                  <div>
                    <TextInput
                      validations={{
                        required: {
                          message: t('add_representation.validation.email_required')
                        },
                        email: {
                          message: t('add_representation.validation.email_validation')
                        },
                        maxLength: {
                          maxLength: 100,
                          message: t('add_representation.validation.email_max_length')
                        }
                      }}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='contact_email'
                      label={t('add_representation.email')}
                      type='email'
                    />
                  </div>
                  <div className='fullwidth'>
                    <FileUpload
                      name='image'
                      withIcon
                      buttonText={t('add_representation.uploadLogo')}
                      withPreview
                      singleImage
                      imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                      maxFileSize={5242880}
                    />
                  </div>
                  <div />
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel'}>
            <Box className={classes.addButton} style={{ justifyContent: 'flex-end' }}>
              <Button
                className={classes.submit}
                type='submit'
                variant='outlined'
              >
                <img src='/assets/img/save.png' />&nbsp;&nbsp;
                {t('add_customer.save')}
              </Button>
            </Box>
          </Box>
        </CardActions>
      </SubmitForm>
    </Card>

  )
}

export default Sidebars(AddRepresentation)
