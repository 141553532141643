import React from 'react'
import { useTranslation } from 'react-i18next'

export const ScraperStatusText = ({ status, available }) => {
  const { t } = useTranslation()

  if (status === 0 || !available) {
    return <div>{t('conveyor_belt_dashboard.status_unavailable')}</div>
  } else if (status === 1) {
    return <div className='greenText'>{t('conveyor_belt_dashboard.status_active')}</div>
  } else if (status === 2) {
    return <div className='red-text'>{t('conveyor_belt_dashboard.status_inactive')}</div>
  }
}

export default ScraperStatusText
