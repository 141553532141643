
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SegmentChart from './SegmentChart'
import ScraperParamsForm from './ScraperParamsForm'
import { useSelector, useDispatch } from 'react-redux'
import { CUSTOMER_CONTROL_SCRAPER_REQUEST } from '../../../../store/customerControl/customerControlActions'

const TabSegment = ({ segmentList, scraperNr }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const controlId = params.id
  const { control, currentScraper, error } = useSelector((state) => state.customerControl)
  const scraperIds = control.scraper_ids || []
  const scraperId = scraperIds[scraperNr]
  const {
    segmentMeasurements, operatingHours, updateSuccess,
    m, b, segmentPredictions, segmentVolume,
    chartData, volumeCritPct, isCarbideSegment
  } = currentScraper || {}

  const { profile } = useSelector((state) => state.user)
  const { hasRole } = profile

  // Get scraper details
  useEffect(() => {
    scraperId && dispatch({ type: CUSTOMER_CONTROL_SCRAPER_REQUEST, payload: scraperId })
  }, [dispatch, scraperId])

  return (
    <div className='chart-container Inner_scroll-con pl-1 pr-1  pb-2 InfoTabContent'>
      <Grid container direction='row' justifyContent='space-between'>
        {
          segmentList && segmentList.length > 0
            ? (<Grid item xs={6}>
              <div style={{ marginLeft: 20 }} className='pb-1'><strong>{t('conveyor_belt_info_scraper_segment_tab.chart.header')}</strong></div>
              {
                chartData
                  ? <SegmentChart style={{ overflow: 'scroll', width: '100%' }} data={chartData} volumeCritPct={volumeCritPct} isCarbideSegment={isCarbideSegment} />
                  : <p>{t('noRecordsFound')}</p>
              }

               </Grid>) : null

        }

        <Grid
          style={{
            marginRight: 38,
            marginTop: 32
          }} item xs={5}
        >
          {
            segmentList && segmentList.map((variable) => {
              try {
                if ([654, 754, 854, 954].includes(variable.var_nr) && isCarbideSegment) {
                  return (
                    <Grid container direction='row'>
                      <Grid item xs={10}><strong>{variable.label}  {variable.unit ? '[' + variable.unit + ']' : null}:</strong></Grid>
                      <Grid item xs={2} className='text-left'>{segmentVolume == null ? '-' : Number.parseFloat(segmentVolume).toFixed(1)}</Grid>
                    </Grid>
                  )
                }
              } catch (error) {
                console.log(error)
              }


              return (
                <Grid container direction='row'>
                  <Grid item xs={8}><strong>{variable.label}  {variable.unit ? '[' + variable.unit + ']' : null}:</strong></Grid>
                  <Grid item xs={4} className='text-left'>{variable.value}</Grid>
                </Grid>
              )
            })
          }
          {segmentList && !segmentList.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}

          {isCarbideSegment && <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{t('conveyor_belt_info_scraper_segment_tab.regression_line_calculated')}: </span><br />
              <span>y = {m || '0'} * x + {b || '0'}</span><br />
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{t('conveyor_belt_info_scraper_segment_tab.wear_limit')}: </span>{currentScraper && currentScraper.volumeCritPct}
            </div>
          </div>}

          {isCarbideSegment && hasRole.superUser && <ScraperParamsForm currentScraper={currentScraper} controlId={controlId} scraperNr={scraperNr} />}
        </Grid>
      </Grid>
    </div>
  )
}

export default TabSegment
