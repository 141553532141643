import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import {
  REPRESENTATION_CUSTOMER_LIST_REQUEST,
  REPRESENTATION_CUSTOMER_DELETE_REQUEST
} from '../../store/representationCustomer/representationCustomerActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { GET_REPRESENTATION_REQUEST } from '../../store/representation/representationActions'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ReadMoreReact from 'read-more-react'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    paddingBottom: '6em',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '45px'
    }
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const useActionStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0fr 1fr 1fr'
    }
  }
}))

const Customers = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const actionClasses = useActionStyles()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const [newCustomers, setNewCustomers] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [customerId, setCustomerId] = useState(null)
  const isMount = useRef(false)
  // Setting state with redux store values
  const customerList = useSelector((state) => state.representationCustomer)
  const representationData = useSelector((state) => state.representation)
  const { representation } = representationData
  const { loading, error, representationCustomers, perPage, page, totalPages, isDeleted } = customerList
  useSetParentCustomInnerHeaderHeading()

  useEffect(() => {
    if (isDeleted && customerId) {
      setNewCustomers([])
      dispatch({
        type: REPRESENTATION_CUSTOMER_LIST_REQUEST,
        payload: { id: params.id, data: { per_page: perPage, page: 1 } }
      })
      toast.success(t('list_representation_customer.toast.customer_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [isDeleted])

  useEffect(() => {
    dispatch({ type: GET_REPRESENTATION_REQUEST, payload: { id: params.id } })
  }, [])

  // Dispatching action to fetch  customer list
  useEffect(() => {
    dispatch({
      type: REPRESENTATION_CUSTOMER_LIST_REQUEST,
      payload: { id: params.id, data: { per_page: perPage, page } }
    })
  }, [dispatch])

  useEffect(() => {
    setNewCustomers(representationCustomers)
  }, [setNewCustomers, representationCustomers])

  useEffect(() => {
    if (isMount.current && error) {
      setCustomerId(null)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  // Dispatching action to delete the customer
  const handleConfirmDeleteCustomer = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      dispatch({
        type: REPRESENTATION_CUSTOMER_DELETE_REQUEST,
        payload: { representationId: params.id, customerId: customerId }
      })
    } else {
      setCustomerId(null)
    }
  }

  // Open confirm delete dialog and set customer id to be deleted
  const handleDeleteCustomer = (id) => {
    setOpenDialog(true)
    setCustomerId(id)
  }

  const onPageChange = (e, selectedPage) => {
    const payload = { per_page: perPage, page: selectedPage }
    dispatch({
      type: REPRESENTATION_CUSTOMER_LIST_REQUEST,
      payload: { id: params.id, data: { ...payload } }
    })
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle ListCustomerRep'} border={0}>
        <CardContent>
          <Loader open={loading} />
          <div className='ListCustomerRepHeader'>
            <ContentTitle title={t('list_representation_customer.title')} />
            <ContentInfoTitle
              type={t('list_representation.title')}
              name={representation && representation.name}
              no={representation && representation.representation_nr} className='dsdsds'
            />
          </div>
          <Grid container className={classes.table + ' MainContInnerStyleThree'} alignContent='space-between'>

            <Grid item xs='12' className='Inner_scrollTwo'>
              <div className='Inner_scroll-con pr-1   pl-1 mt-1 '>
                {newCustomers.map((customer) => (
                  <Grid container key={customer.id} direction='row' justifyContent='space-between' alignItems='stretch' className='RowData TokenUpRow '>
                    <Grid item xs={3} className='TextLeft'>
                      {customer && <ReadMoreReact
                        text={customer.name ? customer.name : ''}
                        min={50}
                        max={50}
                        ideal={50}
                        readMoreText='...'
                      />}

                    </Grid>
                    <Grid item xs={8} className='TextCenter'>
                      {customer.customer_nr}
                    </Grid>
                    <Grid item xs={1} className='TextCenter cancel-icon TextLeft dflex align-items-center justify-content-center cancel-icon-col'>
                      <img onClick={() => handleDeleteCustomer(customer.id)} src='/assets/img/cancel.png' />
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
          {!newCustomers.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2' style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box className={actionClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            <div />
            {newCustomers.length && totalPages
              ? <Pagination
                className='Pagination'
                page={page}
                onChange={onPageChange}
                count={totalPages}
                showFirstButton
                showLastButton
              /> : null}
            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./add/')}
              type='button'
              variant='outlined'
            ><img src='/assets/img/plus.png' />&nbsp;&nbsp;
              {t('edit_representation_customer.addCustomer')}
            </Button>
          </Box>
        </CardActions>

      </Card>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteCustomer} />
    </>
  )
}

export default Sidebars(Customers, { showSearch: true })
